import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Modal from 'react-bootstrap/Modal'
import Loading from '../Loading'
import { Button } from ".."
import './style.scss'

const CustomModal = (props) => {
  const {
    show,
    centered,
    className,
    children,
    loading,
    onHide,
    size,
    noPadding,
    title,
    textSave,
    isHiddenCancel,
    isHiddenFooter,
    handleOnSave,
    ...bootstrapModalProps
  } = props
  const customClasses = cn('custom-modal', { 'no-padding': noPadding }, [
    className,
  ])

  const handleOnHide = () => {
    typeof onHide === 'function' && onHide()
  }

  return (
    <Modal
      className={customClasses}
      show={show}
      centered={centered}
      onHide={handleOnHide}
      size={size}
      {...bootstrapModalProps}
    >
      {
        title ?
          (
            <Modal.Header closeButton>

              <Modal.Title>
                {title}
              </Modal.Title>
            </Modal.Header>
          ) : null
      }

      <Modal.Body className="modal-content-wrapper">
        <Loading
          loading={loading}
          className="modal-loading-state"
          childLoading
        />
        <div className="modal-container">{children}</div>
        {
          isHiddenFooter ? null : (
            <>
              <hr className="modal-content__line" />
              <div className="modal-content__group">
                {!isHiddenCancel ? (<Button onClick={() => { handleOnHide() }} size="md" text="Cancel" />) : null}
                <Button onClick={() => {
                  handleOnSave()
                }} className="modal-content__button" typeClass="second" size="md" text={textSave} />
              </div>
            </>
          )
        }
      </Modal.Body>
    </Modal>
  )
}

CustomModal.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  show: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'md', 'xl']),
  noPadding: PropTypes.bool,
  title: PropTypes.string,
  isHiddenFooter: PropTypes.bool,
  textSave: PropTypes.string,
  isHiddenCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleOnSave: PropTypes.func,
}

CustomModal.defaultProps = {
  className: '',
  loading: false,
  children: null,
  show: false,
  centered: false,
  size: 'sm',
  noPadding: false,
  title: null,
  textSave: "Save",
  isHiddenCancel: false,
  isHiddenFooter: false,
  handleOnSave: () => { }
}

export default CustomModal
