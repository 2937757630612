import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-rangeslider'

import 'react-rangeslider/lib/index.css'
import './style.scss';

const Rating = props => {
  const {
    max,
    min,
    value,
    onSelect
  } = props

  const renderLabels = () => {
    const lables = {};

    for (let i = min; i <= max; i++) {
      lables[i] = i;
    }

    return lables;
  }

  return (
    <Slider
      min={min}
      max={max}
      value={value}
      labels={renderLabels()}
      className="rating"
      orientation="horizontal"
      onChange={onSelect}
    />
  )
}

Rating.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  onSelect: PropTypes.func
}

Rating.defaultProps = {
  max: 5,
  min: 0,
  value: 0
}

export default Rating
