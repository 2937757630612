/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Line } from 'react-chartjs-2';

import { randomRGBA } from '../../../utils/common';

import './style.scss';

const LineChart = props => {
  const { options, height, colors, labels, datasets, onLegendColorsUpdated } = props;
  const chartRef = useRef(null);
  const [legend, setLegend] = useState();

  const chartData = {
    labels,
    datasets
  };

  chartData.datasets = chartData.datasets.map((item, index) => {
    let borderColor;

    if (colors && colors.length > index) {
      borderColor = colors[index];
    } else {
      borderColor = randomRGBA(0.8);
    }

    return {
      ...item,
      fill: true,
      lineTension: 0.4,
      borderColor,
      pointRadius: 4,
      pointHitRadius: 4,
      pointBackgroundColor: 'white',
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointHoverBackgroundColor: borderColor.replace(/[^,]+(?=\))/, '0.5'),
      borderWidth: 2,
      backgroundColor: borderColor.replace(/[^,]+(?=\))/, '0.05')
    }
  })

  useEffect(() => {
    if (options && options.legendCallback) {
      const legend = chartRef.current.chartInstance.generateLegend();
      setLegend(legend);
    }

    if (onLegendColorsUpdated) {
      onLegendColorsUpdated(chartData.datasets.map(i => i.borderColor))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, datasets, labels, onLegendColorsUpdated]);

  return (
    <div className="line-chart">
      { options && options.legendCallback && <div className="chart-legend" dangerouslySetInnerHTML={{ __html: legend }} /> }
      <Line data={chartData} ref={chartRef} height={height} options={options} />
    </div>
  );
};

LineChart.propTypes = {
  datasets: PropTypes.array,
  height: PropTypes.number,
  labels: PropTypes.array,
  colors: PropTypes.array,
  options: PropTypes.object,
  onLegendColorsUpdated: PropTypes.func
};

LineChart.defaultProps = {
  datasets: [],
  labels: [],
  height: 120,
  colors: [],
  options: undefined
};

export default LineChart;
