import BaseApi from "./baseApi";

class UserApi extends BaseApi {
  getUserInfo() {
    return super.execute(this.Methods.GET, this.Urls.users.me, null, true)
      .then((res) => {
        return res;
      });
  }

  getList(query) {
    return super.execute(this.Methods.GET, `${this.Urls.users.list}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.users.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.users.update}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  delete(query) {
    return super
      .execute(this.Methods.DELETE, `${this.Urls.users.delete}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  assignMultipleRoles(query, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.users.AssignMultipleRoles}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  uploadAvatar(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.users.UploadAvatar}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  deleteAvatar(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.users.DeleteAvatar}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  startTrial() {
    return super.execute(this.Methods.POST, this.Urls.users.StartTrial, null, true, null).then((res) => {
      return res;
    });
  }

  setPremiumExpiration(data) {
    return super.execute(this.Methods.POST, `${this.Urls.users.SetPremiumExpiration}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

}

export default new UserApi();
