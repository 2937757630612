/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { coreService, programService } from "../../services";
import { DataTable, Loading, Text } from "../../components/common";
import { BOOTSTRAP_VARIANTS } from "../../constants/common";
import { downlLoadFile } from "../../utils/common";
import "./style.scss";

const ItemPerProcess = 40;
const Status = {
  valid: <span style={{ color: "green" }}>Valid</span>,
  inValid: <span style={{ color: "red" }}>InValid</span>,
  processing: <span style={{ color: "gray" }}>Processing</span>,
};
export const ApplicationUrlView = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [processedProgram, setProcessedProgram] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);

  const checkingApplicationUrl = (programs) => {
    const promises = [];
    programs.forEach((p) => {
      if (p.applicationLink && p.applicationLink.length > 0) {
        const processData = coreService.checkApplicationUrl(p);
        promises.push(processData);
      }
    });
    return Promise.all(promises);
  };

  const handleFetchDefault = () => {
    const filter = {
      skip: currentPosition,
      count: ItemPerProcess,
    };
    setLoading(true);
    programService.getList(filter).then((res) => {
      const { success, data } = res;
      if (success && data.entities) {
        checkingApplicationUrl(data.entities).then((values) => {
          const results = values.map((value) => {
            const { data, success, statusCode } = value;
            data.statusCode = statusCode;
            if (success) {
              data.isApplicationLinkValid = "valid";
            } else {
              data.isApplicationLinkValid = "inValid";
            }
            return data;
          });
          setProcessedProgram([...processedProgram, ...results]);
          if (total === 0) {
            setTotal(data.totalEntities);
          }
          if (currentPosition === 0 || currentPosition < total) {
            setCurrentPosition(currentPosition + ItemPerProcess);
          } else {
            toastr.success("All application links had been checked!");
          }
          setLoading(false);
        });
      }
    });
  };

  function handleExportByIds(programIds) {
    setLoading(true);
    programService.exportByIds(programIds).then((res) => {
      const { success } = res;
      if (success) {
        downlLoadFile(res);
      } else {
        toastr.error("Actions failed please try again!");
      }
      setLoading(false);
    });
  }

  const exportInvalidApplicationUrl = () => {
    if (total === 0 || loading === true) {
      toastr.warning(
        "Please Click Check button and waiting for checking done!"
      );
    } else {
      const inValidProgramIds = processedProgram
        .filter((p) => p.isApplicationLinkValid === "inValid")
        .map((s) => s.id);
      handleExportByIds(inValidProgramIds);
    }
  };

  useEffect(() => {
    if (currentPosition > 0) {
      handleFetchDefault();
    }
  }, [currentPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      key: "program_name",
      header: <div>{t("applicationurlview.program_name")}</div>,
      colSpan: 2,
      sortable: false,
      renderItem: (row) => {
        const { name } = row;
        return (
          <Text as="div" lines={1} popover>
            {name}
          </Text>
        );
      },
    },
    {
      key: "url",
      header: <div>{t("applicationurlview.url")}</div>,
      colSpan: 6,
      renderItem: (row) => {
        const { applicationLink } = row;
        return (
          <Text as="div" lines={1} popover>
            {applicationLink}
          </Text>
        );
      },
    },
    {
      key: "status_code",
      header: <div>{t("applicationurlview.status_code")}</div>,
      colSpan: 2,
      renderItem: (row) => {
        const { statusCode } = row;
        return <div>{statusCode}</div>;
      },
    },
    {
      key: "state",
      header: <div>{t("applicationurlview.state")}</div>,
      colSpan: 2,
      renderItem: (row) => {
        const { isApplicationLinkValid } = row;
        return <div>{Status[isApplicationLinkValid]}</div>;
      },
    },
  ];

  return (
    <div className="applicationUrl">
      <Loading loading={loading} />
      <div className="buttons">
        <Button
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
          onClick={handleFetchDefault}
        >
          Check
        </Button>
        <Button
          variant={BOOTSTRAP_VARIANTS.PRIMARY}
          onClick={exportInvalidApplicationUrl}
        >
          Export InValid Application Urls
        </Button>
      </div>
      <div className="scrollLinks">
        {processedProgram && (
          <DataTable
            noDataText="No data"
            data={processedProgram}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
};

ApplicationUrlView.propTypes = {};

ApplicationUrlView.defaultProps = {};

export default ApplicationUrlView;
