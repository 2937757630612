/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-useless-path-segments */
import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import cn from "classnames";
import { Map } from "immutable";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { useAuth0 } from "../../Auth0";
import CustomDropdown from "./CustomDropdown";
import Avatar from "../Avatar";
import { pathKeys, common } from "../../../constants";
import { STATE_NAME, UTILITY_NAME, PROGRAM_NAME, COUNTY_NAME } from "../../../constants/pathKeys";
import SearchInput from "../SearchInput";
import HelpModal from "../../modules/HelpModal";
import { IconGroup, Dropdown, DropdownMobile, CountDown, Button } from "../../common";
// Redux CountDown
import { routeActions, dropDownActions, productionActions, profileActions } from "../../../redux/actions";
import { historySelector } from "../../../redux/selectors/historySelectors";
import { productSelectors } from '../../../redux/selectors/productSelectors';
import { dropDownCategorySelectors } from '../../../redux/selectors/dropDownSelectors';
import programService from '../../../services/programService';
import { profileSelectors } from '../../../redux/selectors/profileSelectors';
import { checkAuthorize } from "../../../utils/common"
import mapboxService from "../../../services/mapboxService";

import './style.scss';
import {
  IconArrowLeft,
  IconQuestion,
  IconPerson,
  IconDownload,
  IconEdit,
  IconClose,
  IconMenu,
  IconViewTable,
  IconViewMap,
  IconViewCard,
  IconFilterCategory
} from "../../../assests/icons";
import { useNavigate } from "react-router-dom";
import { productService } from "services";
import LazyLoadingDropdown from "../Dropdown/LazyLoadingDropdown";

export const Header = (props) => {
  const {
    isToggled,
    currentRouteState,
    goBack,
    // historyPush,
    onSidebarToggle,
    changeFilterItems,
    filterItems = [],
    changeCategoryItemDropdown,
    changeProductItemDropdown,
    categoryItems,
    category = [],
    product = [],
    county = [],
    utility = [],
    countyItems = [],
    utilityItems = [],
    categoryHistory = [],
    utilityHistory = [],
    countyHistory = [],
    productHistory = [],
    changeProductHistory,
    changeCategoryHistory,
    changeCountyHistory,
    changeUtilityHistory,
    changeCountyItemDropdown,
    changeUtilityItemDropdown,
    isSelectProfile,
    changeSelectDropdown,
    totalCategory,
    totalCounty,
    totalUtility,
    totalProduct,
    isEmbed = false,
    isBulk = false,
    productItems = [],
    productScrollEnd = () => {},
    isProductLoadMore,
    loading,
    searchProduct,
    productResponse,
  } = props;

  function handleAcitveDefault() {
    const { REBATES, MAPCARDVIEW, TABLEVIEW, BULK_PROGRAMS, BULK_UTILITIES, BULK_REBATES } = pathKeys
    const { pathname } = window.location;
    if (pathname.indexOf(REBATES) !== -1 || pathname === "/" || pathname.indexOf(BULK_UTILITIES) !== -1) {
      return 0
    } else if (pathname.indexOf(MAPCARDVIEW) !== -1 || pathname.indexOf(BULK_PROGRAMS) !== -1) {
      return 1
    } else if (pathname.indexOf(TABLEVIEW) !== -1 || pathname.indexOf(BULK_REBATES) !== -1) {
      return 2
    }
    return -1
  }
  const { logout, user = {} } = useAuth0();
  const indexAutherizeAdmin = checkAuthorize(user)
  const { remainingSecond } = user
  const [t] = useTranslation();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [indexActive, setIndexActive] = useState(handleAcitveDefault())
  const [indexActiveDropDown, setIndexActiveDropDown] = useState(-1)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [isSelectCategory, setIsSelectCategory] = useState(false)
  const [searchOptions, setSearchOptions] = useState([])
  const [isBlurSearch, setIsBlurSearch] = useState(false)
  const [globalSearchData, setGlobalSearchData] = useState(null)

  const navigate = useNavigate();

  useEffect(() => {
    if (!isBulk) {
      setIndexActive(handleAcitveDefault())
    }
  }, [isBulk])

  const delayQuery = useCallback(debounce(async searchStr => {
    if (searchStr && searchStr !== "") {
      setIsSearching(true)
      const resp = await productService.GlobalSearch(searchStr);
      if (resp && resp.data) {
        setSearchOptions(resp.data)
        const { county, product, utility } = resp.data;
        setGlobalSearchData(resp.data)
        setIsSearchLoading(false)
      } else {
        setIsSearchLoading(false)
      }
    } else {
      setIsSearching(false)
      setIsSearchLoading(false)
    }
  }, 1000, 'later'), [])

  const onCountyChanged = useCallback(debounce(async (value, setLoading) => {
    const result = await mapboxService.forwardGeocoding(value);
    const newData = [];
    if (result.data && result.data.length > 0) {
      result.data.forEach(item => {
        newData.push({
          ...item,
          label: item.label,
          value: item.featureId,
          parentId: null,
          isParent: false,
          type: common.ProgramFilterType.County
        })
      });
    }
    setLoading(false);
    changeCountyItemDropdown(newData);
  }, 500, 'later'), [county])

  const goToMyProfile = (indexActive) => {
    navigate(`${pathKeys.MY_PROFILE}/${indexActive}`);
  };

  const handleLogOut = () => {
    logout();
  }

  const dropdownItems = [
    {
      icon: <IconPerson />,
      text: t("header.profile"),
      onClick: () => {
        onSidebarToggle(false);
        goToMyProfile(0)
      },
      key: "personal-info"
    },
    {
      icon: <IconEdit />,
      text: t("header.preferences"),
      onClick: () => {
        onSidebarToggle(false);
        goToMyProfile(1)
      },
      key: 'contact-info'
    },
    {
      icon: <IconDownload />,
      text: t("header.saved_items"),
      textSub: t("header.coming_soon"),
      onClick: () => {
        onSidebarToggle(false);
        navigate(pathKeys.SAVEDITEMS);
      },
      disable: true,
      key: 'pharmacy-info'
    },
    {
      divider: true
    },
    {
      icon: <IconClose />,
      text: t("header.log_out"),
      onClick: handleLogOut,
      isLogout: true
    },
  ];

  function handleSetIndexActiveMobile(index) {
    setIndexActiveDropDown(-1)
    setTimeout(() => {
      setIndexActiveDropDown(index)
    }, 500)
  }

  const DropdownItemsMobile = [
    {
      text: t("table.location"),
      onClick: (index) => { handleSetIndexActiveMobile(index) },
    },
    {
      text: t("table.category"),
      onClick: (index) => { handleSetIndexActiveMobile(index) },
    },
    {
      text: t("table.utility"),
      onClick: (index) => { handleSetIndexActiveMobile(index) },
    },
  ];

  const ButtonGroupProps = {
    items: [
      {
        key: "ICON_1",
        icon: <IconViewMap />,
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.REBATES) },
        text: t("button_group.map_view")
      },
      {
        key: "ICON_2",
        icon: <IconViewCard />,
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.MAPCARDVIEW); },
        text: t("button_group.card_view")
      },
      {
        key: "ICON_3",
        icon: <IconViewTable />,
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.TABLEVIEW) },
        text: t("button_group.table_view"),
        disable: true
      }
    ]
  }

  const ButtonGroupPropsBulk = {
    items: [
      {
        key: "ICON_1",
        isText: true,
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.BULK_UTILITIES) },
        text: "Utilities"
      },
      {
        key: "ICON_2",
        isText: true,
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.BULK_PROGRAMS); },
        text: "Programs"
      },
      {
        key: "ICON_3",
        onClick: (index) => { setIndexActive(index); navigate(pathKeys.BULK_REBATES) },
        text: "Rebates",
        isText: true,
      }
    ]
  }

  if (!user) {
    return null;
  }

  const showBackButton = currentRouteState.get("showBackButton", false);
  const handleBackBtnClick = () => {
    goBack();
  };
  const getGlobalSearchHistories = programService.getGlobalSearchHistories();

  function onRedirectRebateProgram(programName, utilityName, state, countyName = undefined) {
    const { REBATES, MAPCARDVIEW, TABLEVIEW, TABLEVIEW_UTILITY, MAPCARDVIEW_UTILITY, REBATES_PROGRAM } = pathKeys;
    const { pathname } = window.location;
    if (pathname.indexOf(REBATES) > -1) {
      const path = REBATES_PROGRAM.replace(STATE_NAME, state).replace(UTILITY_NAME, utilityName).replace(PROGRAM_NAME, programName).replace(COUNTY_NAME, countyName);
      navigate(path);
    } else if (pathname.indexOf(MAPCARDVIEW) > -1) {
      const path = MAPCARDVIEW_UTILITY.replace(':state_name', state).replace(':utility_name', utilityName).replace(':program_name', programName);
      navigate(path);
    } else if (pathname.indexOf(TABLEVIEW) > -1) {
      const path = TABLEVIEW_UTILITY.replace(STATE_NAME, state).replace(UTILITY_NAME, utilityName).replace(PROGRAM_NAME, programName);
      navigate(path);
    } else {
      const path = REBATES_PROGRAM.replace(STATE_NAME, state).replace(UTILITY_NAME, utilityName).replace(PROGRAM_NAME, programName).replace(COUNTY_NAME, countyName);
      navigate(path);
    }
  }

  const initSearchProps = {
    value: null,
    onChange: (option) => {
      const newValue = [{ type:  option.type, id: option.value, 
            value: option.value, isSearch: true, 
            label: option.label, name: option.name || option.label, 
            counties: option.counties, state: option.state, data: option.data }];
      changeFilterItems(newValue);
      programService.saveGlobalSearchHistory(option);
      const { pathname } = window.location;
      const paths = pathname.split('/');
      if (paths.length > 2) {
        navigate(`/${paths[1]}`);
      }
    },
    options: isSearching ? searchOptions : getGlobalSearchHistories,
    className: "header-search",
    placeholder: t("header.search"),
    isLoading: isSearchLoading,
    onInputChange: (text) => {
      setIsSearchLoading(true)
      delayQuery(text);
    },
    setIsBlurProps: (value) => { setIsBlurSearch(value) },
    searchData: isSearching ? globalSearchData : {history: getGlobalSearchHistories},
    sectionLabels: [
      {
        item: common.ProgramFilterType.County,
        label: t("globalSearch.label_county")
      },
      {
        item: common.ProgramFilterType.Utility,
        label: t("globalSearch.label_utility")
      },
      {
        item: common.ProgramFilterType.Product,
        label: t("globalSearch.label_product")
      },
      {
        item: "history",
        label: t("globalSearch.label_history")
      },
    ],
    footerText: t("globalSearch.footer_text")
  };

  function onChangeDropDown(items) {
    changeFilterItems(items)
  }

  function handleChangeHistory(name, itemValue, items) {
    const newData = [...items]
    const value = window.localStorage.getItem(name)
    let newDataTmp = []
    if (value) {
      newDataTmp = JSON.parse(value)
    }

    newData.forEach(item => {
      const index = newDataTmp.findIndex(el => el.label === item.label)
      if (index === -1 && newDataTmp.length < 5) {
        newDataTmp.push(item)
      }
    })
    if (newDataTmp.length >= 5) {
      newDataTmp.shift()
    }
    const { label = "" } = itemValue
    const index = newDataTmp.findIndex(el => el.label === label)

    if (index === -1) {
      newDataTmp.push({
        ...itemValue
      })
      window.localStorage.setItem(name, JSON.stringify(newDataTmp))
    }
  }

  function handleReplaceHistory(slug, slugTmp, handleChangeAction) {
    const value = window.localStorage.getItem(slugTmp)
    if (value) {
      const newData = JSON.parse(value)
      window.localStorage.setItem(slug, value)
      handleChangeAction([...newData])
    }
    window.localStorage.removeItem(slugTmp)
  }

  function handleChangeProductHistory (historyItem) {
    // productHistory
    const tempArray = [...productHistory, historyItem];
    const newSelectedList = tempArray.filter((tag, index, array) => array.findIndex(t => t.dataType === tag.dataType && t.itemName === tag.itemName) === index);
    const lastFiveItems = newSelectedList.slice(-5);
    window.localStorage.setItem(common.productHistory, JSON.stringify(lastFiveItems))
    changeProductHistory(lastFiveItems)
  }

  return (
    <Navbar
      variant={common.BOOTSTRAP_VARIANTS.LIGHT}
      className={cn(`custom-header `, { toggled: !isToggled })}
    >
      <Nav className={cn("header-nav", { toggled: !isToggled })}>
        {showBackButton && (
          <Nav.Item
            className="header-nav header-back-button"
            onClick={handleBackBtnClick}
          >
            <IconArrowLeft /> <span>{t("header.back")}</span>
          </Nav.Item>
        )}
        {
          isEmbed ? null : (<Nav.Item onClick={onSidebarToggle} className="header-text header-text-icon"><IconMenu /></Nav.Item>)
        }

        <Nav.Item className="header-text ">
          {
            isEmbed ? null : (<IconGroup indexActive={indexActive} items={isBulk ? ButtonGroupPropsBulk.items : ButtonGroupProps.items} />)
          }


          <Dropdown
            key="dropdownCounty"
            className="custom-header-item"
            dropdownClass={totalCounty ? 'active' : ''}
            onChange={(item) => {
              onChangeDropDown(item)
            }}
            onChangeHistory={(item) => {
              handleChangeHistory(common.countyHistoryTmp, item, countyHistory)
            }}
            itemsHistory={countyHistory}
            items={countyItems}
            itemsAll={county}
            selectedValues={filterItems}
            total={totalCounty}
            lable={`${t("table.location")}${totalCounty ? `: ${totalCounty}` : ''}`}
            isSelectOpen={!indexActiveDropDown}
            setIsSelectOpen={() => {
              handleReplaceHistory(common.countyHistory, common.countyHistoryTmp, changeCountyHistory)
              setIndexActiveDropDown(-1)
            }}
            onClickParentItem={(id) => {
              const newData = county.filter(item => item.parentId === id)
              changeCountyItemDropdown(newData)
            }}
            type={common.ProgramFilterType.County}
            changeCategoryItem={(items) => { changeCountyItemDropdown(items) }}
            onBack={() => {
              setIsSelectCategory(true)
            }}
            onHandleTextChanged={onCountyChanged}
            placeholder="Search by Address, City, State, or Zip."
          />
          <Dropdown
            key="dropdownCategory"
            className="custom-header-item custom-header-item-category"
            dropdownClass={totalCategory ? 'active' : ''}
            onChange={(item) => {
              onChangeDropDown(item);
            }}
            onChangeHistory={async (item) => {
              await handleChangeHistory(common.categoryHistoryTmp, item, categoryHistory)
              handleReplaceHistory(common.categoryHistory, common.categoryHistoryTmp, changeCategoryHistory)
            }}
            itemsHistory={categoryHistory}
            items={categoryItems}
            itemsAll={category}
            selectedValues={filterItems}
            total={totalCategory}
            lable={`${t("table.category")}${totalCategory ? `: ${totalCategory}` : ''} `}
            type={common.ProgramFilterType.Category}
            isSelectOpen={indexActiveDropDown === 1}
            setIsSelectOpen={() => {
              handleReplaceHistory(common.categoryHistory, common.categoryHistoryTmp, changeCategoryHistory)
              setIndexActiveDropDown(-1)
            }}
            onClickParentItem={(id) => {
              const newData = category.filter(item => item.parentId === id)
              changeCategoryItemDropdown(newData)
            }}
            changeCategoryItem={(items) => {
              changeCategoryItemDropdown(items) 
              }}
            onBack={() => {
              setIsSelectCategory(true)
            }}

          />
          {
            isBulk && (!indexActive || indexActive === -1) ? null : (
              <Dropdown
                key="dropdownUtility"
                className="custom-header-item"
                dropdownClass={totalUtility ? 'active' : ''}
                onChange={(item) => {
                  onChangeDropDown(item);
                }}
                onChangeHistory={async (item) => {
                  await handleChangeHistory(common.utilityHistoryTmp, item, utilityHistory)
                  handleReplaceHistory(common.utilityHistory, common.utilityHistoryTmp, changeUtilityHistory)
                }}
                itemsHistory={utilityHistory}
                items={utilityItems}
                itemsAll={utility}
                selectedValues={filterItems}
                total={totalUtility}
                lable={`${t("table.utility")}${totalUtility ? `: ${totalUtility}` : ''}`}
                isSelectOpen={indexActiveDropDown === 2}
                setIsSelectOpen={() => {
                  handleReplaceHistory(common.utilityHistory, common.utilityHistoryTmp, changeUtilityHistory)
                  setIndexActiveDropDown(-1)
                }}
                onClickParentItem={(id) => {
                  const newData = utility.filter(item => item.parentId === id)
                  changeUtilityItemDropdown(newData)
                }}
                type={common.ProgramFilterType.Utility}
                changeCategoryItem={(items) => { changeUtilityItemDropdown(items) }}
                onBack={() => {
                  setIsSelectCategory(true)
                }}
              />
            )
          }

          <LazyLoadingDropdown
            key="dropdownProduct"
            className="custom-header-item custom-header-item-category"
            dropdownClass={totalProduct ? 'active' : ''}
            onChangeHistory={(item) => {
              // call handle change product history
              handleChangeProductHistory(item)
            }}
            itemsHistory={productHistory}
            items={productItems}
            itemsAll={product}
            selectedValues={filterItems}
            total={totalProduct}
            lable={`Product ${totalProduct ? `: ${totalProduct}` : ''} `}
            type={common.ProgramFilterType.Product}
            onClickParentItem={(target) => {
              const newData = target ? product.filter(item => item.dataType === target.dataType && item.itemName === target.itemName) : product
              changeProductItemDropdown(newData)
            }}
            changeProductItem={(items) => {
              changeProductItemDropdown(items) 
            }}
            onChangeSelectedProduct={(items) => changeFilterItems(items)}
            footerText={t("globalSearch.footer_text")}
            scrollEnd={productScrollEnd}
            isLoadMore={isProductLoadMore}
            loading={loading}
            searchProduct={searchProduct}
            productResponse={productResponse}
          />

          {
            filterItems.length ? (
              <div className={`custom-header__Reset ${!isBlurSearch ? "hidden__small_phone" : ""}`}>
                <Button onClick={() => { changeFilterItems([]) }} typeClass="second" text="Reset" />
              </div>
            ) : null
          }
        </Nav.Item>
      </Nav>

      <Nav className="ml-auto header-nav">
        <Nav.Item>
          {
            indexAutherizeAdmin || isBulk || isEmbed ? null : (
              <div className={`custom-header__CountDown ${!isBlurSearch ? "hidden__small_phone" : ""}`}>
                <CountDown time={remainingSecond} onClick={() => { handleLogOut() }} />
              </div>
            )
          }
          <div className={`hiddenDesktop custom-header-item-second ${!isBlurSearch ? "hidden__small_phone" : ""}`}>
            <DropdownMobile isSelect={isSelectCategory} setIsSelect={setIsSelectCategory} items={DropdownItemsMobile}>
              <IconFilterCategory />
            </DropdownMobile>
            <DropdownMobile maxItems={4} isSelect={isSelectProfile} setIsSelect={changeSelectDropdown} items={dropdownItems} />


          </div>
          {
            isBulk ? null : (<SearchInput {...initSearchProps} />)
          }
        </Nav.Item>
        {
          isEmbed ? null : (
            <>
              {
                isBulk ? null : (
                  <Nav.Link className="questionParent hiddenMobile" onClick={() => setShowHelpModal(true)}>
                    <IconQuestion className="iconHover iconHoverQuestion" />
                  </Nav.Link>
                )
              }
              <div className="nav-avatar hiddenMobile nav-link">
                <CustomDropdown user={user} items={dropdownItems} >
                  <Avatar src={user.avatar} size="sm" />
                </CustomDropdown>
              </div>
            </>
          )
        }
      </Nav>
      {
        isBulk || isEmbed ? null : (
          <HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />)
      }

    </Navbar>
  );
};

Header.propTypes = {
  isToggled: PropTypes.bool,
  goBack: PropTypes.func,
  // historyPush: PropTypes.func,
  currentRouteState: PropTypes.oneOfType([PropTypes.instanceOf(Object), Map]),
  showWaithFullMenu: PropTypes.bool,
  onSidebarToggle: PropTypes.func,
  changeFilterItems: PropTypes.func,
  filterItems: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  isSelectProfile: PropTypes.bool,
  changeSelectDropdown: PropTypes.func,
  changeCategoryDropdown: PropTypes.func,
  changeCategoryItemDropdown: PropTypes.func,
  categoryItems: PropTypes.array,
  category: PropTypes.array,
  changeCategoryDropdownUtility: PropTypes.func,
  changeCategoryDropdownCounty: PropTypes.func,
  county: PropTypes.array,
  utility: PropTypes.array,
  product: PropTypes.array,
  productHistory: PropTypes.array,
  changeCountyItemDropdown: PropTypes.func,
  changeUtilityItemDropdown: PropTypes.func,
  countyItems: PropTypes.array,
  utilityItems: PropTypes.array,
  categoryHistory: PropTypes.array,
  utilityHistory: PropTypes.array,
  countyHistory: PropTypes.array,
  changeCategoryHistory: PropTypes.func,
  changeCountyHistory: PropTypes.func,
  changeUtilityHistory: PropTypes.func,
  changeProductItemDropdown: PropTypes.func,
  changeProductHistory: PropTypes.func,
  totalCounty: PropTypes.number,
  totalCategory: PropTypes.number,
  totalUtility: PropTypes.number,
  totalProduct: PropTypes.number,
  isEmbed: PropTypes.bool,
  isBulk: PropTypes.bool,
  productItems: PropTypes.array,
  productScrollEnd: PropTypes.func,
  isProductLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  searchProduct: PropTypes.func,
  productResponse: PropTypes.shape({ totalItems: PropTypes.number, totalPages: PropTypes.number, count: PropTypes.number, skip: PropTypes.number, }),
};

Header.defaultProps = {
  productScrollEnd: () => {},
  productResponse: { totalItems: 0, totalPages: 0, count: 0, skip: 0, },
  isProductLoadMore: false,
  loading: false,
  searchProduct: (keyword) => {},
}

/* istanbul ignore next */
export const mapStateToProps = (state) => {
  return {
    currentRouteState: historySelector.selectCurrentRouteState(state),
    filterItems: productSelectors.selectFilterItems(state),
    categoryHistory: dropDownCategorySelectors.categoryHistory(state),
    utilityHistory: dropDownCategorySelectors.utilityHistory(state),
    productHistory: dropDownCategorySelectors.productHistory(state),
    countyHistory: dropDownCategorySelectors.countyHistory(state),
    isSelectProfile: profileSelectors.isSelect(state),
    totalCounty: productSelectors.countCounties(state),
    totalCategory: productSelectors.countCategories(state),
    totalUtility: productSelectors.countUtilities(state),
    totalProduct: productSelectors.countProducts(state),
  };
};

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch) => ({
  goBack: () => dispatch(routeActions.goBack()),
  // historyPush: (path, data) => dispatch(routeActions.push(path, data)),
  changeFilterItems: (items) => dispatch(productionActions.changeFilterItems(items)),
  changeCategoryHistory: (value) => dispatch(dropDownActions.changeCategoryHistory(value)),
  changeProductHistory: (value) => dispatch(dropDownActions.changeProductHistory(value)),
  changeCountyHistory: (value) => dispatch(dropDownActions.changeCountyHistory(value)),
  changeUtilityHistory: (value) => dispatch(dropDownActions.changeUtilityHistory(value)),
  changeSelectDropdown: (value) => dispatch(profileActions.changeSelectDropdown(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
