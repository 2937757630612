import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from "lodash"
import { IconBoltRebates, IconBookMarkInActive, IconChevron, IconUserRoles, IconCategoryManagement, IconManagementUtility, IconManagementProgram, IconTargetLink } from "../../../assests/icons";
import { pathKeys } from '../../../constants'
import { Administrator } from '../../../constants/common'
import LeftMenuItem from './LeftMenuItem'
import { useAuth0 } from "../../Auth0";

const useCustomTranslation = () => {
  const [t] = useTranslation()
  return {
    rebates: t('side_menu.rebates'),
    savedItems: t('side_menu.savedItems'),
    comingSoon: t('side_menu.comingSoon'),
    userManagement: t('side_menu.userManagement'),
    categoryManagement: t('side_menu.categoryManagement'),
    utilityManagement: t('side_menu.utilityManagement'),
    programManagement: t('side_menu.programManagement'),
    adminPanel: t('side_menu.adminPanel'),
    checkApplicationUrl: t('side_menu.checkApplicationUrl'),
  }
}

const CoachLeftMenu = () => {
  const translator = useCustomTranslation()
  const { user } = useAuth0();
  const roles = _.get(user, "roles", []);
  const index = roles.findIndex(item => item.roleType === Administrator)

  return (
    <>
      <LeftMenuItem
        to={pathKeys.REBATES}
        icon={<IconBoltRebates />}
        text={translator.rebates}
      />

      {
        index !== -1 ? (
          <>
            <LeftMenuItem
              to={pathKeys.ROLE}
              icon={<IconUserRoles />}
              text={translator.userManagement}
            />
            <LeftMenuItem
              to={pathKeys.UTILITY_MANAGEMENT}
              icon={<IconManagementUtility />}
              text={translator.utilityManagement}
            />
            <LeftMenuItem
              to={pathKeys.PROGRAM_MANAGEMENT}
              icon={<IconManagementProgram />}
              text={translator.programManagement}
            />
            <LeftMenuItem
              to={pathKeys.CATEGORY_MANAGEMENT}
              icon={<IconCategoryManagement />}
              text={translator.categoryManagement}
            />
            <LeftMenuItem
              to={pathKeys.BULK_UTILITIES}
              icon={<IconUserRoles />}
              text={translator.adminPanel}
            />
            <LeftMenuItem
              to={pathKeys.CHECK_APPLICATION_URL}
              icon={<IconTargetLink />}
              text={translator.checkApplicationUrl}
            />
          </>
        ) : null
      }
      <LeftMenuItem
        to={pathKeys.SAVEDITEMS}
        icon={<IconBookMarkInActive />}
        text={translator.savedItems}
        subText={translator.comingSoon}
        subIcon={<IconChevron />}
      />
    </>
  )
}

export default CoachLeftMenu
