import { createSelector } from 'reselect'
import { handleGetParentRootCategory } from '../../utils/common'

const programsReducer = state => {
  return {
    programsState: state.get('programsReducer'),
    categoryState: state.get('dropDownReducer'),
  }
}



export const programSelectors = {

  selectProgramsItems: createSelector(programsReducer, state => {
    const items = state.programsState.get('programs')
    const categories = state.categoryState.get('category')
    const newData = []
    const newDataTmp = Array.isArray(items) ? items : []
    const treeCategoris = Array.isArray(categories) ? categories : []
    newDataTmp.forEach(item => {
      const newItem = item
      if (item.categories && !item.categoryRoots) {
        const categoryRoots = []
        item.categories.forEach(category => {
          handleGetParentRootCategory(category, treeCategoris, categoryRoots)
        })
        newItem.categoryRoots = categoryRoots
      }
      newData.push(newItem)
    })
    return newData
  }
  ),
}
