import get from "lodash/get";
import { common } from '../constants';

export default class County {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data);
    }
  }

  init(data) {
    if (!data) return;
    this.id = get(data, "id", null);
    this.created = get(data, "created", null);
    this.modified = get(data, "modified", null);
    this.eia_Id = get(data, "eia_Id", null);
    this.name = get(data, "name", null);
    this.state = get(data, "state", null);
    this.stateAbbreviated = get(data, "stateAbbreviated", null);
    this.type = get(data, "type", common.ProgramFilterType.County);
  }

  toJSON() {
    const { id, created, modified, eia_Id, name, state, stateAbbreviated, type } = this;
    return { id, created, modified, eia_Id, name, state, stateAbbreviated, type };
  }
}
