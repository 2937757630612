import React from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import cn from 'classnames'

import './style.scss'
import { Text } from '..'

const CustomButton = props => {
  const {
    variant,
    onClick,
    text,
    className,
    loading,
    loadingText,
    disabled,
    size,
    leftContent,
    rightContent,
    lines,
    noPadding,
    badge,
    typeClass,
    ...otherProps
  } = props

  const handleOnclick = () => {
    typeof onClick === 'function' && onClick()
  }
  const textClass = cn('button-text', { 'dotLoading': loading })
  const displayText = loading ? (loadingText || text) : text
  return (
    <Button
      className={cn("custom-button", { noPadding }, [className], [typeClass])}
      onClick={handleOnclick}
      variant={variant}
      disabled={loading || disabled}
      size={size}
      {...otherProps}
    >
      <div className="btn-content-wrapper">
        {leftContent}
        {(text || loadingText) && (
          <Text as="span" size={size} lines={lines} className={textClass}>
            {displayText}
          </Text>
        )}
        {rightContent}
      </div>
      {badge != null && <Badge className="button-badge" variant="danger">{badge}</Badge>}
    </Button>
  );
}

CustomButton.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  noPadding: PropTypes.bool,
  loadingText: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  lines: PropTypes.number,
  badge: PropTypes.number,
  typeClass: PropTypes.string,
}

CustomButton.defaultProps = {
  variant: undefined,
  onClick: null,
  text: null,
  className: '',
  loading: false,
  disabled: false,
  noPadding: false,
  loadingText: null,
  size: 'md',
  leftContent: null,
  rightContent: null,
  lines: null,
  badge: undefined,
  typeClass: "",
}

export default CustomButton
