
import BaseService from "./baseService";
import customerTypeServiceApi from "../api/customerTypeServiceApi";
import { getQueryString } from "../utils/common";


class CustomerTypeService extends BaseService {
  getAll(query = {}) {
    const newQuery = getQueryString(query)
    return customerTypeServiceApi.getAll(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  addOrUpdateMultipleByProgramId(query, params) {
    const newQuery = getQueryString(query)
    return customerTypeServiceApi.addOrUpdateMultipleByProgramId(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }
}
export default new CustomerTypeService();
