/* eslint-disable import/no-named-as-default */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _, { get } from 'lodash'
import { toastr } from 'react-redux-toastr'
import { useParams } from "react-router-dom";
import { dropDownCategorySelectors } from '../../redux/selectors/dropDownSelectors';
import { productSelectors } from '../../redux/selectors/productSelectors';
import { InitialVisitMessage } from '../../components/common'
import { GroupRightSidebar } from '../../components/modules/GroupRightSidebar'
import { countyService, programService, rebateService } from "../../services"
import { pathKeys, STATE_NAME, UTILITY_NAME, COUNTY_NAME } from "../../constants/pathKeys"
import { ProgramFilterType, MAPBOX_CONFIG } from "../../constants/common";
import './style.scss'

const centerDefault = [-100.486052, 37.830348]
let myRemoveEvent = null
let myRemoveOnClickChild = null
let myRemoveOnClickParent = null

const geometryType = [
  {
    name: 'Polygon',
  },
  {
    name: 'MultiPolygon',
  }
]

export const Rebates = (props) => {
  const { filterItems, pathUrl, location = {}, categories } = props
  const { state_name, county_name, utility_name } = useParams();
  const { mapboxgl } = window
  const [mapState, setMapSate] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isShowCounty, setIsShowCounty] = useState(false)
  const [isShowUtilities, setIsShowUtilities] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [detail, setDetail] = useState({})
  const [utilityId, setUtilityId] = useState(null)
  const [, setLayerParent] = useState()
  const [layerChild, setLayerChild] = useState()
  const [mapData, setMapData] = useState({ features: [] })
  const [newDataState, setNewDataState] = useState([])
  const [newDataCountyiesItems, setNewDataCountyiesItems] = useState([])

  const [listStateForDraw, setListStateForDraw] = useState([]);
  const [stateMarkers, setStateMarkers] = useState([]);

  const [listCountyForDraw, setListCountyForDraw] = useState([]);
  const [countyMarkers, setCountyMarkers] = useState([]);

  const [completeFetchJson, setCompleteFetchJson] = useState(false);

  const [filterDataState, setFilterDataState] = useState([]);

  const [maxCountRebate, setMaxCountRebate] = useState(0);
  const [maxCountRebateCounty, setMaxCountRebateCounty] = useState(0);

  const [targetCounty, setTargetCounty] = useState([])

  const filterDataRef = useRef(filterDataState);
  const newDataCountyiesItemsRef = useRef(newDataCountyiesItems);
  const newDataStateRef = useRef(newDataState);
  const maxCountRebateStateRef = useRef(maxCountRebate);

  const targetCountyRef = useRef(targetCounty);

  async function fetchJson() {
    setCompleteFetchJson(false);
    const hostName = window.location.origin
    const dataRes = await fetch(`${hostName}/mapJson/State.json`).then((res) => res.json())
    dataRes.features.forEach((element, index) => {
      newDataState.push({
        ...element,
        id: index,
      });
    });

    mapData.type = dataRes.type
    mapData.features = newDataState

    setMapData({ ...mapData })
    const dataResCounty = await fetch(`${hostName}/mapJson/Counties.json`).then((res) => res.json())
    const newDataCountiesLayer = dataResCounty.features
    newDataCountiesLayer.forEach((item, index) => {
      newDataCountyiesItems.push({
        id: index,
        ...item,
      })
    })
    setNewDataCountyiesItems([...newDataCountyiesItems]);

    countingAllRebate();
  }

  function makeupParamForCountingRebate(filterData) {
    let param = null;
    if (Array.isArray(filterData) && filterData.length > 0) {
      const categories = [];
      const products = [];
      filterData.forEach((item) => {
        if (item.type === ProgramFilterType.Category) {
          categories.push(item.id)
        } else if (item.type === ProgramFilterType.Product) {
            products.push(item.id)
        }
      });

      param = {
        CategoryIds: categories,
        ProductIds: products
      }
    }

    return param;
  }

  async function countingAllRebate(forceFilterData = null) {
    const param = makeupParamForCountingRebate(forceFilterData || filterData);
    if (!param || (param && param.CategoryIds.length === 0 && param.ProductIds.length === 0)) {
      setCompleteFetchJson(true);
      return;
    }

    await rebateService.allRebate(param).then(res => {
      const { success, data } = res;
      if (success && data.countyRebate && Array.isArray(data.countyRebate) && 
      data.stateRebate && Array.isArray(data.stateRebate)) {
        const categoryIds = get(param, 'CategoryIds', [])
        const productIds = get(param, 'ProductIds', [])

        let stateArray = [];
        const { countyRebate, stateRebate } = data;
        mapData.features.forEach((item) => {
          const targetItem = (stateRebate && stateRebate.length > 0) && stateRebate.find((x) => {
            return x.place.toLowerCase() === item.properties.NAME.toLowerCase()
          });

          const tempItem = {
            ...item,
            properties: {
              ...item.properties,
              rebateCount: ((param && (categoryIds.length > 0 || productIds.length > 0)) && targetItem) ? targetItem.rebateCount : 0
            }
          };

          stateArray.push({ ...targetItem, ...tempItem });
        });

        setNewDataState(stateArray);
        newDataStateRef.current = stateArray;
        if (stateRebate && stateRebate.length > 0) {
          const maxInCounty = Math.max(...stateArray.map((i) => i.properties.rebateCount));
          setMaxCountRebate(maxInCounty);
          maxCountRebateStateRef.current = maxInCounty;
        } else {
          setMaxCountRebate(0);
          maxCountRebateStateRef.current = 0;
        }

        let tempCountyArray = [];
        newDataCountyiesItems.forEach((x) => {
          const targetState = newDataState.find((item) => {
            return item.properties.STATE === x.properties.STATE;
          })
          const targetItem = (countyRebate && countyRebate.length > 0) && countyRebate.find((item) => {
            return item.state.toLowerCase() === targetState.properties.NAME.toLowerCase() && item.place.toLowerCase() === x.properties.NAME.toLowerCase()
          });
          const tempItem = {
            ...x,
            properties: {
              ...x.properties,
              rebateCount: ((param && (categoryIds.length > 0 || productIds.length > 0)) && targetItem) ? targetItem.rebateCount : 0
            }
          };

          tempCountyArray.push({ ...targetItem, ...tempItem});
        });
        setNewDataCountyiesItems(tempCountyArray);
        newDataCountyiesItemsRef.current = tempCountyArray;

        if (countyRebate && countyRebate.length > 0) {
          const maxInState = Math.max(...tempCountyArray.map((i) => i.properties.rebateCount));
          setMaxCountRebateCounty(maxInState);
        } else {
          setMaxCountRebateCounty(0);
        }

        if (mapState) {
          const stateSource = mapState.getSource(`states`);
          if (stateSource) {

            stateSource.setData({
              ...mapData,
              features: stateArray
            });
          }

          const countySource = mapState.getSource(`state-child`)
          if (countySource) {

            countySource.setData({
              type: "FeatureCollection",
              features: tempCountyArray
            });
          }
        }

      } else {
        setMaxCountRebate(0)
        maxCountRebateStateRef.current = 0;
        setNewDataState([...newDataState])
      }

      setCompleteFetchJson(true);
    })
  }

  const average = (arr) => {
    return arr.reduce((p, c) => p + c, 0) / arr.length;
  }

  const averagePoint = (arr) => {
    const allX = arr.map((item) => {
      return item[0];
    })

    const allY = arr.map((item) => {
      return item[1];
    })

    return [average(allX), average(allY)];
  }

  const averagePointAllForMultipolygon = (arr) => {
    return averagePoint(arr.map((item) => {
      return averagePoint(item[0]);
    }))
  }

  const drawMarkerForState = (item) => {
    if (mapState && item) {
      const el = document.createElement('div');
      el.className = 'rebates-marker';
      el.innerHTML = `<div class="rebates-marker-text">${item.rebateCount}</div>`

      let centroidPoint = [];
      if (item.geometry.type === geometryType[0].name) {
        centroidPoint = item.geometry.coordinates.map((item) => {
          return averagePoint(item);
        })[0]
      } else {
        centroidPoint = averagePointAllForMultipolygon(item.geometry.coordinates);
      }
      const marker = new mapboxgl.Marker(el).setLngLat(centroidPoint).addTo(mapState)

      stateMarkers.push(marker);
      setStateMarkers(stateMarkers);
    }
  }

  const drawMarkerForCounty = (item) => {
    if (mapState && item && item.geometry) {
      const el = document.createElement('div');
      el.className = 'rebates-marker';
      el.innerHTML = `<div class="rebates-marker-text">${item.rebateCount}</div>`

      let centroidPoint = [];
      if (item.geometry.type === geometryType[0].name) {
        centroidPoint = item.geometry.coordinates.map((item) => {
          return averagePoint(item);
        })[0]
      } else {
        centroidPoint = averagePointAllForMultipolygon(item.geometry.coordinates);
      }
      const center = mapState.getCenter()

      if (Math.abs(centroidPoint[1] - center.lat) < 5 && Math.abs(centroidPoint[0] - center.lng) < 10) {
        const marker = new mapboxgl.Marker(el).setLngLat(centroidPoint).addTo(mapState)
        countyMarkers.push(marker);
        setCountyMarkers(countyMarkers);
      }
    }
  }

  function drarAllMarkerForStates() {
    listStateForDraw.forEach((item) => {
      drawMarkerForState(item);
    })
  }

  useMemo(() => {
    if (listStateForDraw && listStateForDraw.length > 0) {
      drarAllMarkerForStates()
    }
  }, [listStateForDraw]);

  function drarAllMarkerForCounty() {
    listCountyForDraw.forEach((item) => {
      drawMarkerForCounty(item);
    })
  }

  useMemo(() => {
    if (listCountyForDraw && listCountyForDraw.length > 0) {
      drarAllMarkerForCounty()
    }
  }, [listCountyForDraw])

  useEffect(() => {
    setCompleteFetchJson(false);
    fetchJson();
  }, [])

  function historyPush(path) {
    const newPath = `${path}${location.search || ''}`
    window.history.replaceState(null, null, newPath)
  }

  function handleReplaceUrl(state, county = '', utility = '') {
    let { REBATES_UTILITY } = pathKeys
    if (pathUrl) {
      REBATES_UTILITY = pathUrl
    }
    REBATES_UTILITY = REBATES_UTILITY.replace(STATE_NAME, state);
    if (county !== '') {
      REBATES_UTILITY = REBATES_UTILITY.replace(COUNTY_NAME, county);
    } else {
      REBATES_UTILITY = REBATES_UTILITY.replace(`/${COUNTY_NAME}`, '');
    }
    if (utility !== '') {
      REBATES_UTILITY = REBATES_UTILITY.replace(UTILITY_NAME, utility);
    } else {
      REBATES_UTILITY = REBATES_UTILITY.replace(`/${UTILITY_NAME}`, '');
    }

    historyPush(REBATES_UTILITY)
  }

  function handleSetActice(arryaEl, map) {
    newDataCountyiesItems.forEach(item => {
      map.setFeatureState(
        { source: 'state-child', id: item.id },
        { active: false }
      );
    })
    if (map && Array.isArray(arryaEl)) {
      arryaEl.forEach(item => {
        map.setFeatureState(
          { source: 'state-child', id: item.id },
          { active: true }
        );
      })
    }
  }

  function handleSetShowCounty(value, isOpenUtility) {
    setIsShowCounty(value)
    if (!value && !isOpenUtility) {
      setLayerChild({})
      // handleRemoveMarker()
    }
  }

  function handleSetShowUtilities(value, isOpenCounty) {
    setIsShowUtilities(value)
    if (!value && !isOpenCounty) {
      setLayerChild({});
      // handleRemoveMarker()
    }
  }

  function handleActionHover(map, slug, source,) {
    let hoveredStateId = null;

    function handleMouseMoveMap(e) {
      if (e.features.length > 0) {
        if (hoveredStateId !== null) {

          map.setFeatureState(
            { source, id: hoveredStateId },
            { hover: false }
          );

        }

        hoveredStateId = e.features[0].id;
        map.getCanvas().style.cursor = 'pointer'

        map.setFeatureState(
          { source, id: hoveredStateId },
          { hover: true }
        );
      }
    }

    function handleMouseLeave() {
      if (hoveredStateId !== null) {

        map.getCanvas().style.cursor = ''
        map.setFeatureState(
          { source, id: hoveredStateId },
          { hover: false }
        );
        hoveredStateId = null;

      }
    }

    map.on('mousemove', slug, handleMouseMoveMap);
    map.on('mouseleave', slug, handleMouseLeave);
    myRemoveEvent = () => {
      map.off('mousemove', slug, handleMouseMoveMap);
      map.off('mouseleave', slug, handleMouseLeave);
    }

  }

  //TODO: Tweak
  function handleSetMapCouties(map, isZooming = false, countRebate = 0, isShowHeatFill = false) {
    if (map.getSource(`states`)) {
      if (map.getLayer(`state-fills`)) {
        map.removeLayer(`state-fills`)
      }
    }
    if (map.getLayer(`state-child-fills`)) {
      map.removeLayer(`state-child-fills`);
    }

    if (!map.getLayer(`state-child-fills`)) {
      map.addLayer({
        'id': `state-child-fills`,
        'type': 'fill',
        'source': `state-child`,
        'layout': {},
        'paint': {
          'fill-color':
          [
            'case', 
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(224,82,20,0.63)',
            ['boolean', ['feature-state', 'active'], false],
            'rgba(235,103,45,0.72)',
            (countRebate !== 0 && isShowHeatFill && (targetCountyRef.current === null || targetCountyRef.current.length === 0)) ?
            [
              'interpolate',
              ['exponential', 0.95],
              ['get', 'rebateCount'],
              0, '#f2f2f4',
              countRebate, 'rgba(235,103,45,0.49)',
            ] : '#f2f2f4'
          ],
          'fill-opacity':
          [
            'case', 
            ['boolean', ['feature-state', 'hover'], false],
            0.7,
            ['boolean', ['feature-state', 'active'], false],
            0.3,
            (countRebate !== 0 && isShowHeatFill && (targetCountyRef.current === null || targetCountyRef.current.length === 0)) ?
            [
              'interpolate',
              ['exponential', 0.45],
              ['get', 'rebateCount'],
              0, 0.01,
              countRebate, 0.4,
            ] : 0.1
          ]
        }
      });

      if (!map.getLayer(`state-child-borders`)) {
        map.addLayer({
          'id': `state-child-borders`,
          'type': 'line',
          'source': `state-child`,
          'layout': {},
          'paint': {
            'line-color': '#c7c7c7',
            'line-width': 1,
            'line-opacity': 1
          }
        }, "state-borders");
      }

      if (!map.getLayer(`state-child-labels`)) {
        map.addLayer({
          'id': 'state-child-labels',
          'type': 'symbol',
          'source': 'state-child',
          'minzoom': 6,
          'layout': {
            'text-field': ['format', ['get', 'NAME'], " County"],
            'text-justify': 'auto',
            'text-size': 14,
            'text-allow-overlap': true
          },
          'paint': {
            'text-color': '#555555',
            'text-halo-color': '#ffffff',
            'text-halo-width': 2,
            'text-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              1,
              // ['boolean', ['feature-state', 'active'], false],
              // 1,
              0,
            ]
          }
        });
      }

      const handleOnClickChildFill = (e) => {
        map.setCenter(e.lngLat)
        map.setZoom(9);
        const layerChild = e.features[0]
        const { NAME: countName, STATE } = layerChild.properties;
        const newItem = newDataState.filter(item => item.properties && item.properties.STATE === STATE)

        setTargetCounty([layerChild]);
        targetCountyRef.current = [layerChild];
        handleSetActice([layerChild], map)
        if (newItem[0]) {
          const { NAME, COUNTY, LSAD, STATE } = newItem[0].properties
          setFilterData({})
          setDetail({
            name: NAME,
            county: COUNTY,
            lsad: LSAD,
            state: STATE,
            countyName: countName
          })
          setLayerChild(layerChild)
          setTimeout(() => {
            setFilterData({
              name: countName,
              skip: 0,
              count: 1000
            })
            handleReplaceUrl(NAME, countName)
          }, 500)
        }

        if (targetCountyRef.current) {
          handleSetMapCouties(map, false, 0, false);
        }
      }
      if (myRemoveOnClickChild) {
        myRemoveOnClickChild()
        myRemoveOnClickChild = null
      }

      map.on('click', `state-child-fills`, handleOnClickChildFill)
      myRemoveOnClickChild = () => {
        map.off('click', `state-child-fills`, handleOnClickChildFill);
      }
      handleActionHover(map, `state-child-fills`, `state-child`);
      if (!isZooming) {
        map.setZoom(6);
      }
    }
  }

  //TODO: Tweak
  function drawStateLayer(map, isShowHeatFill = false) {
    if(map.getLayer(`state-fills`)) {
      map.removeLayer(`state-fills`)
    }
    map.addLayer({
      'id': `state-fills`,
      'type': 'fill',
      'source': `states`,
      'layout': {
      },
      'paint': {
        'fill-color':
        [
          'case', 
          ['boolean', ['feature-state', 'hover'], false],
          'rgba(235,103,45,0.49)',
          isShowHeatFill && (maxCountRebateStateRef.current !== 0) ?
          [
            'interpolate',
            ['exponential', 0.90],
            ['get', 'rebateCount'],
            0, '#f2f2f4',
            maxCountRebateStateRef.current, 'rgba(235,103,45,0.89)',
          ] : '#f2f2f4'
        ],
        'fill-opacity': 
        [
          'case', 
          ['boolean', ['feature-state', 'hover'], false],
          isShowHeatFill  && (maxCountRebateStateRef.current !== 0) ? 0.7 : 0.1,
          isShowHeatFill && (maxCountRebateStateRef.current !== 0) ? 
          [
            'interpolate',
            ['exponential', 0.45],
            ['get', 'rebateCount'],
            0, 0.01,
            maxCountRebateStateRef.current, 0.4,
          ] : 0.1
        ]
      }
    });
  }

  function handleSetParentAction(map, isShowHeatFill = false) {
    if (map.getSource(`state-child`)) {
      if (map.getLayer(`state-child-borders`)) {
        map.removeLayer(`state-child-borders`)
      }
      if (map.getLayer(`state-child-labels`)) {
        map.removeLayer(`state-child-labels`)
      }
      if (map.getLayer(`state-child-fills`)) {
        map.removeLayer(`state-child-fills`)
      }
    }

    if (!map.getLayer(`state-fills`)) {
      drawStateLayer(map, isShowHeatFill);

      if (!map.getLayer(`state-borders`)) {
        map.addLayer({
          'id': `state-borders`,
          'type': 'line',
          'source': `states`,
          'layout': {},
          'paint': {
            'line-color': '#273137',
            'line-width': 1,
            'line-opacity': 1
          }
        });
      }

      const handleOnClickParentFill = (e) => {
        map.setMaxZoom(22)
        map.setMinZoom(0)
        if (map.getSource(`states`)) {
          if (map.getLayer(`state-fills`)) {
            map.removeLayer(`state-fills`)
          }
        }

        map.setCenter(e.lngLat)
        const layerParent = e.features[0]
        setTargetCounty([]);
        targetCountyRef.current = [];

        handleSetMapCouties(map, false, maxCountRebateCounty, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0))
        setLayerParent(layerParent)
        handleReplaceUrl(layerParent.properties.NAME)
      }

      if (myRemoveOnClickParent) {
        myRemoveOnClickParent()
        myRemoveOnClickParent = null
      }

      map.on('click', `state-fills`, handleOnClickParentFill)

      myRemoveOnClickParent = () => {
        map.off('click', `state-fills`, handleOnClickParentFill);
      }

      handleActionHover(map, `state-fills`, `states`)
    }
  }

  const calculateLatLinear = (x) => {
    if (x < 6) {
      return 2;
    } else if (x >= 10) {
      return 0.2;
    } else return 2-(((2-0.2)*(x-6))/10-6)
  }
  
  const calculateLngLinear = (y) => {
    if (y < 6) {
      return 5
    } else if (y >= 10) {
      return 0.5
    } else return 5-(((5-0.5)*(y-6))/10-6)
  }

  const maxCountRebateInCounties = (zoomNow, latNumber, lngNumber) => {
    const listItemInArea = newDataCountyiesItemsRef.current.filter((item) => {
      return (Math.abs(item.geometry.coordinates[0][0][1] - zoomNow.lat) < latNumber && 
            Math.abs(item.geometry.coordinates[0][0][0] - zoomNow.lng) < lngNumber)
    })

    const maxRebateCountInCounty = listItemInArea.length > 0 ?
      Math.max(...listItemInArea.map((i) => i.properties.rebateCount)) : 0;

    return maxRebateCountInCounty;
  }

  useEffect(() => {
    if (mapboxgl && completeFetchJson) {
      mapboxgl.accessToken = MAPBOX_CONFIG.REACT_APP_MAPBOX_TOKEN;

      const mapItem = document.getElementById('map');
      if (!mapItem.innerHTML || mapItem.innerHTML.length === 0) {
        const map = new mapboxgl.Map({
          container: 'map',
          style: MAPBOX_CONFIG.REACT_APP_MAPBOX_STYLE,
          center: centerDefault,
          zoom: 4,
          maxZoom: 22,
          minZoom: 0,
        });
        map.addControl(new mapboxgl.NavigationControl());
        const isFirstLoad = localStorage.getItem('isFirstLoad')

        map.on('load', () => {

          const dataForMap = {
            ...mapData,
            features: (newDataState && newDataState.length > 0) ? newDataState : mapData.features
          };

          map.addSource(`states`, {
            'type': 'geojson',
            'data': dataForMap
          });

          // adding marker here by mapdata and list if reabte count by county
          // and rebate count by state

          map.addSource(`state-child`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: newDataCountyiesItems
            }
          });

          if (!isFirstLoad) {
            setIsVisible(true)
            localStorage.setItem('isFirstLoad', true)
          } else {
            handleSetParentAction(map, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0))
          }
          handleMapWhenAddressUrlDefault(map)
        });

        map.on('zoomend', () => {
          const zoomNow = map.getZoom();
          if (zoomNow <= 5) {
            // draw rebate count for state
            handleSetParentAction(map, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0))
          } else if (zoomNow < 11) {
            const nowCenter = map.getCenter();
            const maxCount = maxCountRebateInCounties(nowCenter, calculateLatLinear(zoomNow), calculateLngLinear(zoomNow));
            setMaxCountRebateCounty(maxCount);
            handleSetMapCouties(map, true, maxCount, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0))
          }
        });

        let lastCenter = map.getCenter();
        map.on('dragend', () => {
          const zoomNow = map.getZoom();
          const nowCenter = map.getCenter();
          if (zoomNow >= 6 && zoomNow < 11) {
            const latNumber = calculateLatLinear(zoomNow);
            const lngNumber = calculateLngLinear(zoomNow);

            if (Math.abs(lastCenter.lat - nowCenter.lat) < latNumber && Math.abs(lastCenter.lng - nowCenter.lng) < lngNumber) {
              return;
            }
            lastCenter = nowCenter;
            const maxCount = maxCountRebateInCounties(nowCenter, latNumber, lngNumber)
            setMaxCountRebateCounty(maxCount);
            handleSetMapCouties(map, true, maxCount, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0));
          }
        });
        setMapSate(map)
      } else {
        const zoomNow = mapState.getZoom();
        if (zoomNow >= 6 && zoomNow < 11 ) {
          const maxCount = maxCountRebateInCounties(mapState.getCenter(), calculateLatLinear(zoomNow), calculateLngLinear(zoomNow));
          setMaxCountRebateCounty(maxCount);
          handleSetMapCouties(mapState, false, maxCount, (filterDataRef && filterDataRef.current && filterDataRef.current.length > 0))
        } else {
          drawStateLayer(mapState, true);
        }
      }
    }
  }, [state_name, county_name, utility_name, mapData, newDataCountyiesItems, newDataState, maxCountRebate, completeFetchJson])

  function handleFitBounds(mapState, resultCoordinates) {
    if (mapState && resultCoordinates.length) {
      const bounds = resultCoordinates.reduce((bounds, coord) => {
        if (Object.keys(coord).length) {
          return bounds.extend(coord);
        } else {
          return bounds
        }

      }, new mapboxgl.LngLatBounds(resultCoordinates[0], resultCoordinates[0]));

      if (Object.keys(bounds).length) {
        mapState.fitBounds(bounds);
      }

    }
  }

  function handleGetBoundsStates(locations, parentArrayJson) {
    const newData = []
    locations.forEach(state => {
      const newItemStates = parentArrayJson.filter(el => state && el.properties.NAME.toLowerCase() === state.toLowerCase())
      newItemStates.forEach((el) => {
        if (el.geometry && el.geometry.coordinates) {
          el.geometry.coordinates.forEach((el2) => {
            el2.forEach(el3 => {
              if (el3[0] && el3[0][0]) {
                el3.forEach(el4 => {
                  newData.push(el4)
                })
              } else {
                newData.push(el3)
              }
            })
          })
        }
      })
    })
    return newData
  }

  function handleGetBoundsCounty(locations, map) {
    const newData = []
    const newLocation = []
    locations.forEach(item => {
      const newItem = { ...item }
      const stateMap = newDataState.filter(el => el.properties.NAME.toLowerCase() === newItem.state.toLowerCase())
      newItem.stateId = stateMap && stateMap[0] ? stateMap[0].properties.STATE : null
      newLocation.push(newItem)
    })

    const newCountyActive = []

    newLocation.forEach(item => {
      let itemName = item.name
      if (itemName) {
        itemName = itemName.toLowerCase().replace(" city", "")
      }

      const newItemCounty = newDataCountyiesItems.filter(el => item.name && item.stateId === el.properties.STATE && el.properties.NAME.toLowerCase() === itemName)
      newItemCounty.forEach((el) => {
        if (el.geometry && el.geometry.coordinates) {
          newCountyActive.push(el)
          el.geometry.coordinates.forEach((el2) => {
            el2.forEach(el3 => {
              if (el3[0] && el3[0][0]) {
                el3.forEach(el4 => {
                  newData.push(el4)
                })
              } else {
                newData.push(el3)
              }

            })
          })
        }
      })
    })
    const newMap = map || mapState

    if (newMap) {
      if (newCountyActive && newCountyActive.length > 0) {
        setTargetCounty(newCountyActive)
        targetCountyRef.current = newCountyActive;
        handleSetActice(newCountyActive, newMap)
      } else if (targetCountyRef.current && targetCountyRef.current.length > 0) {
        handleSetActice(targetCountyRef.current, newMap)
      }
    }

    return newData
  }

  function handleShowRightSidebarByLocation(locations) {
    setFilterData({})
    setDetail({
      name: locations[0].state,
      countyName: locations[0].name
    })
    setTimeout(() => {
      setFilterData({
        name: locations[0].name,
        skip: 0,
        count: 1000
      })
    }, 500)
  }

  function getByStateAndUtilityName(filterData) {
    setUtilityId(null)

    programService.getByStateAndUtilityName(filterData).then(res => {
      const { success, data } = res
      if (success && data.programs && data.programs.length) {
        setDetail({
          name: filterData.state,
          countyName: filterData.countyName,
          utility: data
        })
        setTimeout(() => {
          setUtilityId(data.id)
        }, 500)
      } else {
        const { utility } = filterData
        toastr.warning(`${utility} no Utilities available  `)
      }
    })
  }

  function handleMapWhenAddressUrlDefault(map) {
    if (state_name) {
      map.setZoom(12)
      let resultCoordinates = []
      const newLocation = [{
        name: county_name,
        state: state_name
      }]
      if (state_name && county_name && utility_name) {
        resultCoordinates = handleGetBoundsCounty(newLocation, map)
        getByStateAndUtilityName({
          utility: utility_name,
          state: state_name,
          countyName: county_name
        })
      } else if (state_name && county_name) {
        handleShowRightSidebarByLocation(newLocation)
        resultCoordinates = handleGetBoundsCounty(newLocation, map)
      } else {
        resultCoordinates = handleGetBoundsStates([state_name], newDataState)
      }
      handleFitBounds(map, resultCoordinates)
    }
  }

  useEffect(() => {
    if (mapState && mapState.getLayer(`state-child-fills`)) {
      // handleRemoveMarker()
      if (layerChild.id) {
        newDataCountyiesItems.forEach(item => {
          if (item.id !== layerChild.id) {
            mapState.setFeatureState(
              { source: 'state-child', id: item.id },
              { hover: false }
            );
          }
        })
        if (typeof myRemoveEvent === 'function') {
          myRemoveEvent()
          myRemoveEvent = null
        }
        handleActionHover(mapState, `state-child-fills`, `state-child`, {})

      } else {
        if (typeof myRemoveEvent === 'function') {
          myRemoveEvent()
          myRemoveEvent = null
        }

        handleActionHover(mapState, `state-child-fills`, `state-child`, {})
      }
    }
  }, [layerChild])



  function handleGetCountiesByUtilityIds(params, coordinates) {
    let resultCoordinates = coordinates
    if (params.length) {
      countyService.getCountiesByUtilityIds(params).then(res => {
        const { success, data } = res
        if (success && data.entities && data.entities.length) {
          const newCounties = []
          data.entities.forEach(item => {
            newCounties.push(item)
          })

          const countyCoordisnates = handleGetBoundsCounty(newCounties)
          resultCoordinates = [...resultCoordinates, ...countyCoordisnates]
        }

        handleFitBounds(mapState, resultCoordinates)
      })
    } else {
      handleFitBounds(mapState, resultCoordinates)
    }

  }

  function handleGetCountyByUtilityId(utility) {
    if (utility.id) {
      setUtilityId(null)

      setDetail({})
      countyService.getCountyByUtilityId(utility.id).then(res => {
        const { success, data } = res
        if (success && data.entities && data.entities[0]) {

          const { state, name } = data.entities[0]
          const newDetail = {
            utility,
          }
          newDetail.name = state
          newDetail.countyName = name
          setDetail(newDetail)
          setTimeout(() => { setUtilityId(utility.id) }, 500)
        } else {
          toastr.warning(`${utility.name} has no Rebates available`)
        }
      })
    }
  }

  function handleZoomToMap(filterItems) {
    const states = []
    const locations = []
    const utilities = []
    const tmpUtility = []
    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        if (item.label === item.state) {
          states.push(item.label)
        } else {
          locations.push(item)
        }
      } else if (item.type === ProgramFilterType.Utility) {
        utilities.push(item.id)
        tmpUtility.push(item)
      }
    })
    if (utilities.length === 1 && !locations.length && !states.length) {
      handleGetCountyByUtilityId(tmpUtility[0])
    } else if (locations.length === 1 && !utilities.length && !states.length) {
      handleShowRightSidebarByLocation(locations)
    }
    //  else {
    //   setIsShowCounty(false)
    //   setIsShowUtilities(false)
    // }

    const countyCoordisnates = handleGetBoundsCounty(locations)
    const statesCoordisnates = handleGetBoundsStates(states, newDataState)
    let resultCoordinates = []
    resultCoordinates = [...countyCoordisnates]
    if (statesCoordisnates.length) {
      resultCoordinates = [...statesCoordisnates]
    }
    handleGetCountiesByUtilityIds(utilities, resultCoordinates)
  }

  function handleZoomToMapWithProgram(item) {
    if (item.counties) {
      const id = _.get(item, "data.utility.id", null);
      if (id) {
        const resultCoordinates = handleGetBoundsCounty([item])
        const countyName = _.get(item, "counties[0]", '');

        const newDetail = {
          name: item.state,
          countyName,
          programId: item.id,
          utility: item.data.utility,
        }
        setDetail(newDetail)
        setTimeout(() => { setUtilityId(item.data.utility.id) }, 500)
        handleFitBounds(mapState, resultCoordinates, true)
      }

    }
  }

  useEffect(() => {
    filterDataRef.current = filterItems;
    setFilterDataState(filterItems)
    if (filterItems && filterItems.length) {
      handleZoomToMap(filterItems);
      setCompleteFetchJson(false);
      countingAllRebate(filterDataRef.current);
    } else if (mapState) {
      handleSetShowCounty(false)
      handleSetShowUtilities(false);
      countingAllRebate(filterDataRef.current);
    } 
    
    if (filterItems && filterItems.length === 0 && mapState) {
      handleSetActice([], mapState)
    }
  }, [filterItems])

  return (
    <div className="rebates">
      {
        isVisible ? (
          <InitialVisitMessage onClick={() => {
            handleSetParentAction(mapState);
            setIsVisible(false)
          }} />
        ) : null
      }
      <div id="map" />
      <GroupRightSidebar
        onClose={() => {
          if (mapState) {
            mapState.setZoom(5)
          }
        }}
        isShowCounty={isShowCounty}
        setIsShowCounty={handleSetShowCounty}
        isShowUtilities={isShowUtilities}
        setIsShowUtilities={handleSetShowUtilities}
        filterData={filterData}
        detail={detail}
        utilityId={utilityId}
        handleReplaceUrl={handleReplaceUrl}
        categories={categories}
        filterItems={filterItems || []}
      />
    </div>
  )
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
    categories: dropDownCategorySelectors.category(state)
  }
}

/* istanbul ignore next */
const mapDispatchToProps = () => ({
  // historyPush: (path, data) => dispatch(routeActions.replace(path, data)),
})

Rebates.propTypes = {
  filterItems: PropTypes.array,
  pathUrl: PropTypes.string,
  categories: PropTypes.array,
}

Rebates.defaultProps = {
  filterItems: [],
  pathUrl: null,
  categories: []
}


export default connect(mapStateToProps, mapDispatchToProps)(Rebates)
