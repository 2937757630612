import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconCloseWhite } from '../../../assests/icons'

import './styles.scss'



const PillButton = props => {
  const {
    onCloseButtonClick,
    text,
    size,
    className,
  } = props

  const handleClose = () => {
    onCloseButtonClick && onCloseButtonClick()
  }

  const wrapperClass = cn('pill-button', [className, `pill-button-${size}`])

  return (
    <span className={wrapperClass}>
      {text}
      <span
        onClick={handleClose}
        className="close-icon"
        role="button"
      >
        <IconCloseWhite />
      </span>
    </span>
  )
}

PillButton.propTypes = {
  onCloseButtonClick: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
}

PillButton.defaultProps = {
  onCloseButtonClick: null,
  text: '',
  size: 'sm',
  className: '',
}

export default PillButton
