/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes, { string } from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { DataTable, Progress, Button, Checkbox, TopLabelFormGroup, DatePicker, TopLabelDropDownGroup, TopLabelSearchMultipleGroup } from "../../components/common"
import SuccessFul from './successful'
import ErrorImport from './errorImport'
import { pathKeys, common } from '../../constants'
import { programService, rebateService } from "../../services"
import { JsonToFile, compareEqualFieldExcecl, compareEqualTimeFieldExcecl, compareEqualString } from '../../utils/common'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { bulkImportSelector } from 'redux/selectors/bulkImportSelectors';
import { connect } from 'react-redux';
import { RebateValueType } from 'constants/common';

const OPTIONS_TYPE = [
  {
    value: "Custom",
    label: "Custom"
  },
  {
    value: "Calculated",
    label: "Calculated"
  },
]

const Add = "add"
const Update = "update"
const Delete = "delete"

export const ImportRebates = (props) => {
  const navigate = useNavigate()
  const { setIsBulk, rebateImportData: data } = props
  const [searchProgram, setSearchPrograme] = useState([])
  const [dataError, setDataError] = useState([])
  const [DataAdd, setDataAdd] = useState([])
  const [DataUpdate, setDataUpdate] = useState([])
  const [DataDelete, setDataDelete] = useState([])
  const [isImport, setIsImport] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isProgress, setIsProgress] = useState(false)
  const [isError, setIsError] = useState(false)
  const [now, setNow] = useState(0)
  let handleSearchProgram = null

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  function checkValueType (typeValue, property) {
    if (typeValue) {
      const targetItem = RebateValueType.find(x => {
        return x[property].toLocaleLowerCase() === typeValue.toString().toLocaleLowerCase();
      });

      if (targetItem) {
        return targetItem[property];
      }
    }
    return RebateValueType[0][property];
  }

  function getListUpdateByIds(ids, dataItem) {
    rebateService.getByIds(ids).then(res => {
      const { success, data } = res
      if (success && data.items) {
        const returnItemInUpdate = [];
        data.items.forEach(item => {
          const index = dataItem.findIndex(el => el.Id === item.id)
          if (index !== -1 && dataItem[index]) {
            if (!compareEqualFieldExcecl(item.name, dataItem[index].Name)) {
              dataItem[index].isWarningName = true
            }
            if (!compareEqualFieldExcecl(item.programId, dataItem[index].ProgramId)) {
              dataItem[index].isWarningProgramId = true
            }
            if (!compareEqualTimeFieldExcecl(item.startDate, dataItem[index].StartDate)) {
              dataItem[index].isWarningStartDate = true
            }
            if (!compareEqualTimeFieldExcecl(item.created, dataItem[index].Created)) {
              dataItem[index].isWarningCreated = true
            }
            if (!compareEqualTimeFieldExcecl(item.expirationDate, dataItem[index].ExpirationDate)) {
              dataItem[index].isWarningExpirationDate = true
            }
            if (!compareEqualFieldExcecl(item.notes, dataItem[index].Notes)) {
              dataItem[index].isWarningNotes = true
            }
            if (!compareEqualFieldExcecl(item.websiteUrl, dataItem[index].WebsiteUrl)) {
              dataItem[index].isWarningWebsiteUrl = true
            }
            if (!compareEqualFieldExcecl(item.rebateType, dataItem[index].RebateType)) {
              dataItem[index].isWarningRebateType = true
            }
            if (!compareEqualFieldExcecl(item.minCustomerContribution, dataItem[index].MinCustomerContribution)) {
              dataItem[index].isWarningMinCustomerContribution = true
            }

            if (!compareEqualFieldExcecl(item.rebateValue1Units, dataItem[index].RebateValue1Units)) {
              dataItem[index].isWarningRebateValue1Units = true
            }
            
            if (!compareEqualFieldExcecl(item.rebateValue1Value, dataItem[index].RebateValue1Value)) {
              dataItem[index].isWarningRebateValue1Value = true
            }

            if (!compareEqualFieldExcecl(item.rebateValue1Type, dataItem[index].RebateValue1Type)) {
              dataItem[index].isWarningRebateValue1Type = true
            }

            if (!compareEqualFieldExcecl(item.rebateValue2Units, dataItem[index].RebateValue2Units)) {
              dataItem[index].isWarningRebateValue2Units = true
            }
            
            if (!compareEqualFieldExcecl(item.rebateValue2Value, dataItem[index].RebateValue2Value)) {
              dataItem[index].isWarningRebateValue2Value = true
            }

            if (!compareEqualFieldExcecl(item.rebateValue2Type, dataItem[index].RebateValue2Type)) {
              dataItem[index].isWarningRebateValue2Type = true
            }

            dataItem[index] = {
              ...dataItem[index],
              rebateValue_1_Type: [{
                value: checkValueType(dataItem[index].RebateValue1Type, 'value'),
                label: checkValueType(dataItem[index].RebateValue1Type, 'label')
              }],
              rebateValue_2_Type: [{
                value: checkValueType(dataItem[index].RebateValue2Type, 'value'),
                label: checkValueType(dataItem[index].RebateValue2Type, 'label')
              }],
            };

            returnItemInUpdate.push(dataItem[index]);
          }
        })
        setDataUpdate(returnItemInUpdate)
      }
    })
  }

  function makeupDataForRebateValue (item) {
    if (Array.isArray(item) && item.length > 0) {
      return item.map((itemData) => {
        itemData = {
          ...itemData,
          rebateValue_1_Type: [{
            value: checkValueType(itemData.RebateValue1Type, 'value'),
            label: checkValueType(itemData.RebateValue1Type, 'label'),
          }],
          rebateValue_2_Type: [{
            value: checkValueType(itemData.RebateValue2Type, 'value'),
            label: checkValueType(itemData.RebateValue2Type, 'label'),
          }],
        }

        return itemData;
      })
    }

    return [];
  }

  useEffect(() => {
    const DataAddDefault = data.filter(item => compareEqualString(item.Action, Add))
    const DataUpdateDefault = data.filter(item => compareEqualString(item.Action, Update))
    const DataDeleteDefault = data.filter(item => compareEqualString(item.Action, Delete))

    const DataUpdateIds = []
    data.forEach(item => {
      if (item.Id && item.Id !== "") {
        DataUpdateIds.push(item.Id)
      }
    });

    setDataAdd(makeupDataForRebateValue(DataAddDefault))
    setDataUpdate(makeupDataForRebateValue(DataUpdateDefault))
    setDataDelete(makeupDataForRebateValue(DataDeleteDefault))
    getListUpdateByIds(DataUpdateIds, DataUpdateDefault)
  }, [data])

  function importData(file) {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", "importData.csv");

    rebateService.importFile(formData).then(res => {
      const { success, data } = res
      if (success && !data.errors.length) {
        setIsSuccess(true)
        setIsError(false)
        setDataError([])
      } else {
        setIsError(true)
        setIsSuccess(false)
        if (data.errors) {
          setDataError(data.errors)
        }
      }
      setNow(100)
      setIsProgress(false)
    })
  }

  function handleBackToList() {
    navigate(pathKeys.BULK_REBATES)
  }

  function handleOnChange(name, value, index, action) {
    if (compareEqualString(action, Add)) {
      DataAdd[index][name] = value
      if (name === "Name" && value !== "") {
        DataAdd[index].isErrorName = false
      }
      if (name === "ProgramId" && value !== "") {
        DataAdd[index].isErrorProgramId = false
      }

      setDataAdd([...DataAdd])
    } else if (compareEqualString(action, Update)) {
      DataUpdate[index][name] = value
      if (name === "Name" && value !== "") {
        DataUpdate[index].isErrorName = false
      }
      if (name === "Id" && value !== "") {
        DataUpdate[index].isErrorId = false
      }
      if (name === "ProgramId" && value !== "") {
        DataUpdate[index].isErrorProgramId = false
      }
      setDataUpdate([...DataUpdate])
    } else {
      if (name === "Name" && value !== "") {
        DataDelete[index].isErrorName = false
      }
      if (name === "ProgramId" && value !== "") {
        DataDelete[index].isErrorProgramId = false
      }
      if (name === "Id" && value !== "") {
        DataDelete[index].isErrorId = false
      }
      DataDelete[index][name] = value
      setDataDelete([...DataDelete])
    }
  }

  function handleValidateAdd() {
    let check = true
    DataAdd.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataAdd[index].isErrorName = true
        }
        if (!item.ProgramId || item.ProgramId === "") {
          check = false
          DataAdd[index].isErrorProgramId = true
        }
      }
    }
    )
    setDataAdd([...DataAdd])
    return check

  }

  function handleValidateUpdate() {
    let check = true
    DataUpdate.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataUpdate[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataUpdate[index].isErrorId = true
        }
        if (!item.ProgramId || item.ProgramId === "") {
          check = false
          DataUpdate[index].isErrorProgramId = true
        }
      }
    }
    )
    setDataUpdate([...DataUpdate])
    return check
  }

  function handleValidateDelete() {
    let check = true
    DataDelete.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataDelete[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataDelete[index].isErrorId = true
        }
        if (!item.ProgramId || item.ProgramId === "") {
          check = false
          DataDelete[index].isErrorProgramId = true
        }
      }
    }
    )
    setDataDelete([...DataDelete])
    return check

  }

  function getProgramBySearch(text) {
    if (!text || text === "") {
      setSearchPrograme([])
    } else {

      programService.getProgramBySearch(text).then(res => {
        const { success, data } = res
        if (success && data.entities) {
          const newData = data.entities
          setSearchPrograme(newData)
        }

      })
    }
  }

  const columns = [
    {
      key: "keepChange",
      colSpan: 3,
      header: <div>Keep Change</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("KeepChange", !record.KeepChange, index, record.Action)
          }} isFull checked={record.KeepChange} />
        )
      }
    },
    {
      key: "ProgramId",
      colSpan: 10,
      header: <div>Program</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelSearchMultipleGroup
            className="Roles__form-group"

            placeholder="Search"
            values={record.ProgramId && record.ProgramId !== "" ? [{ id: record.ProgramId, name: record.ProgramName }] : []}
            onChangeText={(text) => {
              if (handleSearchProgram) {
                clearTimeout(handleSearchProgram)
                handleSearchProgram = null
              }
              handleSearchProgram = setTimeout(() => {
                getProgramBySearch(text)
              }, 1000)
            }}
            onChangeOne={(values) => {
              handleOnChange("ProgramId", values.id || "", index, record.Action)
              handleOnChange("ProgramName", values.name || "", index, record.Action)
            }}
            status={record.isErrorProgramId ? common.BOOTSTRAP_VARIANTS.DANGER : record.isWarningProgramId ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            options={searchProgram}

          />
        )
      }
    },
    {
      key: "Name",
      colSpan: 6,
      header: <div>Name Rebates</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Name", value, index, record.Action)
            }}
            status={record.isErrorName ? common.BOOTSTRAP_VARIANTS.DANGER : record.isWarningName ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.Name}
            isTextArea={false}

          />
        )
      }
    },
    {
      key: "StartDate",
      colSpan: 6,
      header: <div>Start Date</div>,
      renderItem: (record, index) => {
        let dateSelected = new Date(record.StartDate)
        if (Number.isNaN(dateSelected.getTime())) {
          dateSelected = new Date()
        }
        return (
          <>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dateSelected}
              onChange={(date) => {
                handleOnChange("StartDate", date.toISOString(), index, record.Action)
              }}
              status={record.isWarningStartDate ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            />
          </>
        )
      }
    },
    {
      key: "ExpirationDate",
      colSpan: 6,
      header: <div>Expiration Date</div>,
      renderItem: (record, index) => {
        let dateSelected = new Date(record.ExpirationDate)
        if (Number.isNaN(dateSelected.getTime())) {
          dateSelected = new Date()
        }
        return (
          <>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dateSelected}
              onChange={(date) => {
                handleOnChange("ExpirationDate", date.toISOString(), index, record.Action)
              }}
              status={record.isWarningExpirationDate ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            />
          </>
        )
      }
    },
    {
      key: "Notes",
      colSpan: 18,
      header: <div>Notes</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Notes", value, index, record.Action)
            }}
            status={record.isWarningNotes ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.Notes}
          />
        )
      }
    },
    {
      key: "RebateType",
      colSpan: 6,
      header: <div>Rebate Type</div>,
      renderItem: (record, index) => {
        let newValue = OPTIONS_TYPE.filter(item => item.value === record.RebateType)
        if (!newValue.length) {
          newValue = OPTIONS_TYPE[0]
        }
        return (
          <TopLabelDropDownGroup
            controlId="RebateType"
            className="Roles__form-group"
            placeholder="Choose Cap Type"
            value={newValue}
            onChange={(data) => {
              handleOnChange("RebateType", data.value, index, record.Action)
            }}
            status={record.isWarningRebateType ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            options={OPTIONS_TYPE}

          />

        )
      }
    },
    {
      key: "MinCustomerContribution",
      colSpan: 6,
      header: <div>Min Customer Contribution</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("MinCustomerContribution", value, index, record.Action)
            }}
            status={record.isWarningMinCustomerContribution ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.MinCustomerContribution}
            isTextArea={false}
          />
        )
      }
    },
     
    {
      key: "RebateValues1Unit",
      colSpan: 6,
      header: <div>Rebate Values 1 - Unit</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            key={`${index}-units`}
            className="Roles__form-group"
            placeholder="Input Units"
            controlId="units"
            value={record.RebateValue1Units || ""}
            onChange={(name, value) => {
              handleOnChange("RebateValue1Units", value, index, record.Action)
            }}
            isTextArea={false}
            status={record.isWarningRebateValue1Units ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "RebateValues1Value",
      colSpan: 6,
      header: <div>Rebate Values 1 - Value</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("RebateValue1Value", value, index, record.Action)
            }}
            value={record.RebateValue1Value || ""}
            placeholder="Input Value"
            isTextArea={false}
            status={record.isWarningRebateValue1Value ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            type="number"
          />
        )
      }
    },
    {
      key: "RebateValues1Type",
      colSpan: 6,
      header: <div>Rebate Values 1 - Type</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelDropDownGroup
            controlId="type"
            className="Roles__form-group"
            value={record.rebateValue_1_Type || []}
            textLabel="label"
            textValue="value"
            onChange={(value) => {
              handleOnChange('rebateValue_1_Type', [{ ...value }], index, record.Action)
            }}
            options={RebateValueType}
            status={record.isWarningRebateValue1Type ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },

    {
      key: "RebateValues2Unit",
      colSpan: 6,
      header: <div>Rebate Values 2 - Unit</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            key={`${index}-units`}
            className="Roles__form-group"
            placeholder="Input Units"
            controlId="units"
            value={record.RebateValue2Units || ""}
            onChange={(name, value) => {
              handleOnChange("RebateValue2Units", value, index, record.Action)
            }}
            isTextArea={false}
            status={record.isWarningRebateValue2Units ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "RebateValues2Value",
      colSpan: 6,
      header: <div>Rebate Values 2 - Value</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("RebateValue2Value", value, index, record.Action)
            }}
            placeholder="Input Value"
            value={record.RebateValue2Value || ""}
            isTextArea={false}
            status={record.isWarningRebateValue2Value ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            type="number"
          />
        )
      }
    },
    {
      key: "RebateValues2Type",
      colSpan: 6,
      header: <div>Rebate Values 2 - Type</div>,
      sortable: false,
      renderItem: (record, index) => {
        return (
          <TopLabelDropDownGroup
            controlId="type"
            className="Roles__form-group"
            value={record.rebateValue_2_Type || []}
            textLabel="label"
            textValue="value"
            onChange={(value) => {
              handleOnChange('rebateValue_2_Type', [{ ...value }], index, record.Action)
            }}
            options={RebateValueType}
            status={record.isWarningRebateValue2Type ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },

    {
      key: "Requirement1",
      colSpan: 6,
      header: <div>Existing Wattage</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement1", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement1 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement2",
      colSpan: 6,
      header: <div>Controls Required</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement2", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement2 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement3",
      colSpan: 6,
      header: <div>Minimum Watts Saved</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement3", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement3 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement4",
      colSpan: 6,
      header: <div>New Watts per Foot</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement4", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement4 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement5",
      colSpan: 6,
      header: <div> New Wattage </div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement5", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement5 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement6",
      colSpan: 6,
      header: <div>New Length</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement6", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement6 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement7",
      colSpan: 6,
      header: <div>New Lumens</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement7", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement7 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement8",
      colSpan: 6,
      header: <div>Watts Controlled</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement8", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement8 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement9",
      colSpan: 6,
      header: <div>New Efficacy</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement9", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement9 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement10",
      colSpan: 6,
      header: <div>Motion Sensors Required</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement10", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement10 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement11",
      colSpan: 6,
      header: <div>Minimum Watts Saved</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement11", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement11 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement12",
      colSpan: 6,
      header: <div>New Lifetime</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement12", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement12 || ""}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "Requirement13",
      colSpan: 6,
      header: <div>Operating Hours</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirement13", value, index, record.Action)
            }}
            placeholder="Max-Min-Amount"
            value={record.Requirement13 || ""}
            isTextArea={false}
          />
        )
      }
    }

  ];

  const columnsV2 = [
    ...columns,
    {
      key: "Id",
      colSpan: 7,
      header: <div>Id</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Id", value, index, record.Action)
            }}
            status={record.isErrorId ? common.BOOTSTRAP_VARIANTS.DANGER : null}
            value={record.Id} isTextArea={false} />
        )
      }
    },
  ]

  return (
    <>
      {
        isImport ? (
          <div className="import-view-second">
            <div className="import-view__progress">
              {isProgress ? <Progress now={now} text="Processing Import..." /> : null}
              {
                isSuccess ? <SuccessFul onClick={handleBackToList} /> : null
              }
              {
                isError ? <ErrorImport data={dataError} onClick={handleBackToList} /> : null
              }
            </div>
          </div>
        ) : (
          <Scrollbars
            onScrollFrame={() => {

            }}
            autoHide >
            <div className="Roles import-view import-view-rebates">
              <div className="Roles__contain">
                <div className="Roles__contain__title">
                  Import Changes
                </div>

                <div className="Roles__contain__subTitle">
                  Add New Rebates
                </div>
                <DataTable
                  sortable
                  data={DataAdd}
                  columns={columns}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Update Existing Rebates
                </div>
                <DataTable
                  sortable
                  data={DataUpdate}
                  columns={columnsV2}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Delete Existing Rebates
                </div>
                <DataTable
                  sortable
                  data={DataDelete}
                  columns={columnsV2}
                />

                <div className="import-view__buton">
                  <Button
                    onClick={() => {
                      const checkAdd = handleValidateAdd()
                      const checkUpdate = handleValidateUpdate()
                      const checkDelete = handleValidateDelete()
                      if (checkAdd && checkUpdate && checkDelete) {
                        setIsImport(true)
                        setIsProgress(true)
                        setIsSuccess(false)
                        setIsError(false)
                        setNow(0)
                        setTimeout(() => {
                          setNow(10)

                          const newData = [...DataDelete, ...DataUpdate, ...DataAdd]
                          const realData = []
                          newData.forEach(item => {
                            if (item.KeepChange) {
                              realData.push({
                                Action: item.Action,
                                Name: item.Name,
                                StartDate: item.StartDate,
                                ProgramId: item.ProgramId,
                                ProgramName: item.ProgramName,
                                Notes: item.Notes,
                                WebsiteUrl: item.WebsiteUrl,
                                RebateType: item.RebateType,
                                ExpirationDate: item.ExpirationDate,
                                MinCustomerContribution: item.MinCustomerContribution,
                                Created: item.Created,
                                Id: compareEqualString(item.Action, Add) ? "" : item.Id,

                                RebateValue1Units: item.RebateValue1Units,
                                RebateValue1Value: item.RebateValue1Value,
                                RebateValue1Type: item.rebateValue_1_Type[0].value,

                                RebateValue2Units: item.RebateValue2Units,
                                RebateValue2Value: item.RebateValue2Value,
                                RebateValue2Type: item.rebateValue_2_Type[0].value,

                                // Requirement1: item.Requirement1,
                                // Requirement2: item.Requirement2,
                                // Requirement3: item.Requirement3,
                                // Requirement4: item.Requirement4,
                                // Requirement5: item.Requirement5,
                                // Requirement6: item.Requirement6,
                                // Requirement7: item.Requirement7,
                                // Requirement8: item.Requirement8,
                                // Requirement9: item.Requirement9,
                                // Requirement10: item.Requirement10,
                                // Requirement11: item.Requirement11,
                                // Requirement12: item.Requirement12,
                                // Requirement13: item.Requirement13,
                                
                                'Existing Wattage': item.Requirement1,
                                'Controls Required': item.Requirement2,
                                'Minimum Watts Saved (percentage)': item.Requirement3,
                                'New Watts per Foot': item.Requirement4,
                                'New Wattage': item.Requirement5,
                                'New Length': item.Requirement6,
                                'New Lumens': item.Requirement7,
                                'Watts Controlled': item.Requirement8,
                                'New Efficacy': item.Requirement9,
                                'Motion Sensors Required': item.Requirement10,
                                'Minimum Watts Saved': item.Requirement11,
                                'New Lifetime': item.Requirement12,
                                'Operating Hours': item.Requirement13,
                              })
                            }
                          })

                          const newFile = JsonToFile(realData)
                          importData(newFile)
                        }, 500)
                        setTimeout(() => {
                          setNow(30)
                        }, 1000)
                        setTimeout(() => {
                          setNow(50)
                        }, 2000)
                        setTimeout(() => {
                          setNow(75)
                        }, 2500)

                      } else {
                        toastr.error("Import file has got filed invalid")
                      }

                    }}
                    typeClass="second"
                    size="md"
                    text="Import"
                  />
                </div>
              </div>

            </div>
          </Scrollbars>
        )
      }


    </>

  )
}

ImportRebates.propTypes = {
  setIsBulk: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  rebateImportData: PropTypes.array
}

ImportRebates.defaultProps = {
  setIsBulk: () => { },
  location: {
    state: {
      data: []
    }
  },
  history: {
    push: () => { }
  },
  rebateImportData: []
}

const mapStateToProps = (state) => {
  return {
    rebateImportData: bulkImportSelector.rebateImportData(state)
  }
}

export default connect(mapStateToProps, null)(ImportRebates)
