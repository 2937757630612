/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/display-name */


import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types'
import _ from "lodash"
import { toastr } from 'react-redux-toastr'
import { BOOTSTRAP_VARIANTS as Status, ProgramFilterType } from "../../constants/common";
import { handleGetArraychild, sortBy } from '../../utils/common'
import { DataTable, Loading, Text, Pagination, TopFilterTable, Modal, TopLabelFormGroup, TopLabelDropDownGroup } from "../../components/common"
import { IconAddChildCategory, IconDeleteRoles, IconWarningCategory } from "../../assests/icons"
import { categoryService } from "../../services"

import './style.scss'

const CATEGORY_SOURCE = [
  {
    value: "RebateBus",
    label: "RebateBus"
  },
  {
    value: "EnergyStar",
    label: "Energy Star"
  },
  {
    value: "DLC",
    label: "DLC"
  },
]
const pageSize = 10

export const CategoryManagement = (props) => {
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize,
  })

  const { category, setCategory } = props
  const [isShowModalDelete, setShowModalDetelte] = useState(false)
  const [isShowModalDeleteWarning, setShowModalDetelteWarning] = useState(false)
  const [isShowModalEdit, setShowModalEdit] = useState(false)
  const [isShowModalMove, setShowModalMove] = useState(false)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [detail, setDetail] = useState({})
  const [objectError, setObjectError] = useState({})

  function handeCloseAddModal() {
    setDetail({})
    setShowModalEdit(false)
  }

  function handleCloseDeleteModal() {
    setDetail({})
    setShowModalDetelte(false)
  }

  function handleCloseMoveModal() {
    setDetail({})
    setShowModalMove(false)
  }
  function handleValidate() {
    let isPass = true
    const message = "This field not allow empty"
    if (!detail.name || detail.name === "") {
      objectError.name = message
      isPass = false
    }

    if (!detail.categorySource || detail.categorySource === "" || !detail.categorySource.length) {
      objectError.categorySource = message
      isPass = false
    }


    if (!isPass) {
      setObjectError({
        ...objectError
      })
    } else {
      setObjectError({})
    }

    return isPass
  }

  function handleAddCategory() {
    if (handleValidate()) {
      setLoading(true)
      const parentCategoryId = _.get(detail, "category[0].id", null)
      const categorySource = _.get(detail, "categorySource[0].value", "")
      const newData = {
        name: detail.name,
        parentCategoryId,
        categorySource,
        isHidden: false,
        productNumber: 0,
        rebateNumber: 0
      }

      categoryService.create(newData).then(res => {
        const { success, data } = res
        if (success) {
          const newItem = {
            ...data,
            label: data.name,
            value: data.id,
            parentId: data.parentCategoryId,
            isParent: data.isParent,
            type: ProgramFilterType.Category,
            productNumber: data.productNumber,
            rebateNumber: data.rebateNumber
          }
          category.push(newItem)
          setCategory([...category])
          handeCloseAddModal()
          toastr.success("Category creation successfully")
        } else {
          toastr.error("Actions failed please try again!")
        }
        setLoading(false)
      })
    }
  }

  function handleDeleteCategory() {
    if (detail.id) {
      setLoading(true)
      const newData = {
        id: detail.id
      }
      categoryService.delete(newData).then(res => {
        const { success } = res
        if (success) {
          const newItems = category.filter(item => item.id !== newData.id)
          setCategory([...newItems])
          handleCloseDeleteModal()

          toastr.success("Category remove successfully")
        } else {
          toastr.error("Actions failed please try again!")
        }
        setLoading(false)
      })
    }
  }

  function handleUpdateCategory() {
    if (detail.id) {
      setLoading(true)
      const parentCategoryId = _.get(detail, "category[0].id", null)
      const categorySource = _.get(detail, "categorySource[0].value", "")
      const newData = {
        name: detail.name,
        parentCategoryId,
        categorySource,
        isHidden: true,
        productNumber: detail.productNumber || 0,
        rebateNumber: detail.rebateNumber || 0
      }
      const query = {
        Id: detail.id,
        oldParentId: detail.parentCategoryId
      }

      categoryService.updateWithRebateProductNumber(query, newData).then(res => {
        const { success, data } = res
        if (success) {
          const newDataCategory = category.filter(item => item.id !== data.id)
          const newItem = {
            ...data,
            label: data.name,
            value: data.id,
            parentId: data.parentCategoryId,
            isParent: data.isParent,
            type: ProgramFilterType.Category,
            rebateNumber: detail.rebateNumber,
            productNumber: detail.productNumber
          }
          newDataCategory.push(newItem)
          setCategory([...newDataCategory])
          handleCloseMoveModal()

          toastr.success("Category moved successfully")
        } else {
          toastr.error("Actions failed please try again!")
        }
        setLoading(false)
      })
    }
  }

  function handleOnDrop(itemNow, itemMove) {
    const { value = {} } = itemMove
    const { id, categorySource, name, rebateNumber, productNumber, parentCategoryId } = value
    const newCategorySource = CATEGORY_SOURCE.filter(item => item.value === categorySource)
    setDetail({
      id,
      name,
      category: [{ ...itemNow }],
      categorySource: newCategorySource,
      rebateNumber,
      productNumber,
      parentCategoryId
    });
    setShowModalMove(true)
  }

  function handlePageSizeChange(pageSize) {
    const newFilter = {
      skip: 0,
      count: pageSize,

    }
    setFilter(newFilter)
  }

  function handlePageChange(page) {
    const newFilter = {
      ...filter,
      skip: page - 1,
    }
    setFilter(newFilter)
  }

  useEffect(() => {
    if (Array.isArray(category)) {
      const newData = []
      category.forEach((item, index) => {
        newData.push({
          ...item,
          index
        })
      })
      const newFilterItems = handleGetArraychild(newData)

      setItems([...newFilterItems])
      setTotal(newFilterItems.length)

    }
  }, [category])


  const newChildren = []
  if (filter.orderField) {
    items.sort(sortBy(filter.orderField, filter.isDesc, (a) => a.toUpperCase()))
  }
  items.forEach((item, index) => {

    if (!filter.skip && index < filter.count) {
      newChildren.push(item)
    } else if (filter.skip * filter.count <= index && index < (filter.skip + 1) * filter.count) {
      newChildren.push(item)
    }
  })

  const newItems = [{
    label: 'Root',
    value: null,
    parentId: null,
    isParent: true,
    name: "Root",
    children: newChildren,
    defaultShow: true
  }]


  const columns = [
    {
      key: "name",
      header: <div>{t("category.name")}</div>,
      colSpan: 16,
      sortable: true,
      renderItem: (row) => {
        const { name } = row
        return (
          <div className="CategoryManagement__contain__title">
            <Text as="div" lines={1} popover>
              {name}
            </Text>
          </div>
        )
      }
    },
    {
      key: "rebateNumber",
      header: <div>{t("category.rebateCount")}</div>,

      colSpan: 2,
      style: {
        width: 130,
      },
      renderItem: (row) => {
        const { rebateNumber } = row
        return (
          <>
            {rebateNumber || 0}
          </>
        )
      }
    },
    {
      key: "productNumber",
      header: <div>{t("category.productCount")}</div>,

      colSpan: 2,
      style: {
        width: 130,
      },
      renderItem: (row) => {
        const { productNumber } = row
        return (
          <div >
            {productNumber || 0}
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("category.actions")}</div>,
      colSpan: 2,
      renderItem: (row) => {
        const { id, categorySource, children } = row
        const newCategorySource = CATEGORY_SOURCE.filter(item => item.value === categorySource)
        function handleSeDataRow() {
          setDetail({
            ...detail,
            id,
            category: [{ ...row }],
            categorySource: newCategorySource
          });
        }

        return (
          <div className="table-groupIcon">
            <IconAddChildCategory onClick={() => {
              handleSeDataRow()
              setShowModalEdit(true)
            }} />
            <IconDeleteRoles onClick={() => {
              if (children && children.length) {
                setShowModalDetelteWarning(true)
              } else {
                handleSeDataRow()
                setShowModalDetelte(true)
              }
            }} className="Roles__contain__delete" />
          </div>
        )
      }
    }
  ];

  return (

    <div className="Roles CategoryManagement">
      <Loading loading={loading} />
      <div className="Roles__contain">
        <div className="Roles__contain__title">
          {t("side_menu.categoryManagement")}
        </div>
        <DataTable
          sortable
          noDataText="No data"
          data={newItems}
          columns={columns}
          onDrop={handleOnDrop}
          orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
          isDrag
          onSort={str => {
            if (str) {
              const arrayValue = str.split("-")
              if (arrayValue[0] && arrayValue[1]) {
                const dataBody = {
                  ...filter,
                  orderField: arrayValue[0],
                  isDesc: arrayValue[1] !== 'asc',
                  skip: 0,
                  count: pageSize,
                }
                setFilter(dataBody)

              }
            }
          }}
          topChildren={(
            <TopFilterTable
              isAdd
              textButton="Add Main Category"
              pageSize={filter.count}
              onPageSizeChange={(value) => { handlePageSizeChange(value) }}
              onClick={() => { setDetail({}); setShowModalEdit(true) }}
            />
          )}
        >
          <Pagination data={
            {
              totalItems: total,
              pageSize: filter.count,
              currentPage: filter.skip + 1
            }
          }
            onPageChange={(value) => {
              handlePageChange(value)
            }}
          />
        </DataTable>
        <Modal onHide={() => { handleCloseMoveModal() }} handleOnSave={() => { handleUpdateCategory() }} size="md" centered show={isShowModalMove} textSave="Yes" title="Move Category">
          <div>
            Are you sure you want to move category group to another parent ?
           </div>
        </Modal>

        <Modal onHide={() => { handleCloseDeleteModal() }} handleOnSave={() => { handleDeleteCategory() }} size="md" centered show={isShowModalDelete} textSave="Yes" title="Remove Category">
          <div>
            Are you sure you want to delete this category ?
           </div>
        </Modal>

        <Modal handleOnSave={() => { handleAddCategory() }} onHide={() => { handeCloseAddModal() }} size="md" centered show={isShowModalEdit} title="Create New Category">
          <div>
            <TopLabelFormGroup
              label={t("category.name")}
              controlId="name"
              onChange={(name, value) => {
                setDetail({
                  ...detail,
                  [name]: value
                })

                delete objectError[name]
                setObjectError({
                  ...objectError
                })
              }}
              value={detail.name || ""}
              isTextArea={false}
              status={objectError.name ? Status.DANGER : null}
              message={objectError.name}
            />
            <TopLabelDropDownGroup
              className="Roles__form-group"
              label={t("category.category_parent")}
              placeholder={`- ${t("category.category_parent")} -`}
              value={detail.category || []}
              onChange={(value) => {
                setDetail({
                  ...detail,
                  category: [{ ...value }]
                })

              }}
              textLabel="name"
              textValue="id"
              isMutiple
              options={items}

            />

            <TopLabelDropDownGroup
              className="Roles__form-group"
              label={t("category.category_source")}
              placeholder={`- ${t("category.category_source")} -`}
              value={detail.categorySource || []}
              onChange={(value) => {
                setDetail({
                  ...detail,
                  categorySource: [{ ...value }]
                })
                delete objectError.categorySource
                setObjectError({
                  ...objectError
                })
              }}
              options={CATEGORY_SOURCE}
              status={objectError.categorySource ? Status.DANGER : ""}
              message={objectError.categorySource}
            />

          </div>
        </Modal>
        <Modal isHiddenCancel handleOnSave={() => { setShowModalDetelteWarning(false) }} onHide={() => { setDetail({}); setShowModalDetelteWarning(false) }} size="md" centered show={isShowModalDeleteWarning} textSave="OK" title="Delete Category">
          <div className="CategoryManagement__parent">
            <IconWarningCategory />
            <div className="CategoryManagement__parent__content">
              <div>Error Delete</div>
              You can’t delete categories that have children. Try moving or deleting all the children first. ?
            </div>
          </div>
        </Modal>

      </div>
    </div>

  )
}

CategoryManagement.propTypes = {
  category: PropTypes.array,
  setCategory: PropTypes.func
}

CategoryManagement.defaultProps = {
  category: [],
  setCategory: () => { }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = () => ({


})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryManagement)
