/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import { Scrollbars } from 'react-custom-scrollbars';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import moment from "moment"
import _ from "lodash"
import { toastr } from 'react-redux-toastr'
import { useParams } from "react-router-dom";
import { emptyTable } from '../../assests/images'
import { coreService, programService } from "../../services"
import { productSelectors } from '../../redux/selectors/productSelectors';
import { programSelectors } from '../../redux/selectors/programSelectors';
import { programActions } from "../../redux/actions"
import { DataTable, Loading, StatusText, Text, Tooltip, DrawerUtilites, EmptyState } from "../../components/common"
import { IconCardLable, IconRX, IconCalCulate, IconCategory5, IconCategoryHouse, IconCategoryHammer, IconThunder } from "../../assests/icons"
import { Icons } from "../../constants/categories";
import { ProgramFilterType } from "../../constants/common";
import { dropDownCategorySelectors } from '../../redux/selectors/dropDownSelectors';
import { pathKeys, STATE_NAME, UTILITY_NAME, PROGRAM_NAME } from "../../constants/pathKeys"
import './style.scss'
import { formatDate } from 'utils/dates';

const pageSize = 50
export const TableView = (props) => {
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize
  })
  const { filterItems, items, setItems, categories } = props
  const [loading, setLoading] = useState(false)
  const [isFirstFresh, setIsFirstFresh] = useState(true)
  const [total, setTotal] = useState(0)
  const [isShowUtilities, setIsShowUtilities] = useState(false)
  const [utilites, setUtilites] = useState({})
  const [programDetail, setProgramDetail] = useState({})
  const [isGettingProgramDetail, setIsGettingProgramDetail] = useState(true)
  const [qpls, setQpls] = useState([])
  const [indexActive, setIndexActive] = useState(-1)
  const { state_name, utility_name, program_name } = useParams();

  const handleFetchProgram = (filter) => {
    setLoading(true)
    coreService.getProgram(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        setItems([...items, ...data.entities])
      }
      setLoading(false)
    })
  }

  function historyPush(path) {
    window.history.replaceState(null, null, path)
  }


  function getQPLs() {
    coreService.getQPLs().then(res => {
      const { success, data } = res
      if (success) {
        setQpls(data.items)
      }
    })
  }

  function handleFetchDefault(filterData) {
    setLoading(true)
    coreService.getProgram(filterData).then(res => {
      const { success, data } = res

      if (success && data.entities) {
        const newData = data.entities;
        setItems(newData)
        setTotal(data.totalEntities)
        if (isFirstFresh) {
          setIsFirstFresh(false)
        }
      } else {
        setItems([])
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    const locations = []
    const categories = []
    const utilities = []
    const products = [];
    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        locations.push(item.id)
      } else if (item.type === ProgramFilterType.Category) {
        categories.push(item.id)
      } else if (item.type === ProgramFilterType.Utility) {
        utilities.push(item.id)
      } else if (item.type === ProgramFilterType.Product) {
        products.push(item.id)
      }
    })


    const dataBody = {
      skip: 0,
      count: pageSize,
      locations,
      categories,
      utilities,
      products
    }

    setFilter(dataBody)
    handleFetchDefault(dataBody)
  }, [filterItems])

  function getProgramMetadatasByName(name, programs) {
    setIsGettingProgramDetail(true)
    programService.getProgramMetadatasByName(name).then(res => {
      const { success, data } = res
      if (success && data) {
        programs.forEach((item, index) => {
          if (item.id === data.id) {
            setProgramDetail(data)
            setIndexActive(index)
          }
        })
      }
      setIsGettingProgramDetail(false)
    })
  }

  function getByStateAndUtilityName(filterData) {
    programService.getByStateAndUtilityName(filterData).then(res => {
      const { success, data } = res
      if (success && data.programs && data.programs.length) {
        if (program_name) {
          getProgramMetadatasByName(program_name, data.programs)
        }
        setUtilites({
          name: filterData.state,
          utility: data,
          items: data.programs
        })
        setIsShowUtilities(true)
      } else {
        const { utility } = filterData
        toastr.warning(`${utility} no Utilities available  `)
      }
    })
  }


  useEffect(() => {
    if (state_name && utility_name) {
      getByStateAndUtilityName({
        utility: utility_name,
        state: state_name
      })
    }
  }, [state_name, utility_name])

  useEffect(() => {
    getQPLs()
  }, [])

  function handleRenderStatus(isActive) {
    if (isActive) {
      return 'active'
    } else if (typeof isActive === 'object') {
      return 'upcoming'
    }
    return 'closed'
  }

  function handleRenderType(data) {
    const newData = []
    if (data) {
      data.forEach(item => {
        if (item && item.rebate) {
          const { rebateType } = item.rebate
          const index = newData.findIndex(el => el === rebateType)
          if (index === -1) {
            newData.push(rebateType)
          }
        }
      })
    }

    return (
      <>
        {newData.map((el, index) => {
          if (el === 'Calculated') {
            return (
              <Tooltip key={`${el}-${index}-Calculated`} childrenTooltip="Calculated" >
                <IconCalCulate />
              </Tooltip>

            )
          } else {
            return (
              <Tooltip key={`${el}-${index}`} childrenTooltip="Prescriptive" >
                <IconRX />
              </Tooltip>
            )
          }
        })} </>
    )
  }

  function getProgramByUtilityId(filterData, detail, index) {
    if (index === 0) {
      setIsGettingProgramDetail(true)
    }
    coreService.getProgramByUtilityId(filterData.id).then(res => {
      const { success, data } = res
      if (success) {
        setProgramDetail(data)
        setIndexActive(index)
        
      } else {
        const { name } = detail
        toastr.warning(`${name} no Programs available `)
      }

      setIsGettingProgramDetail(false)
    })
  }

  function getByUtilityId(filterData, detail, program) {
    coreService.getByUtilityId(filterData).then(res => {
      const { success, data } = res
      setIndexActive(-1)
      if (success && data.entities && data.entities.length) {
        data.entities.forEach((item, index) => {
          if (item.id === program.id) {
            getProgramByUtilityId({ id: item.id, }, item, index)
          }
        })
        setUtilites({
          ...detail,
          items: data.entities
        })
        setIsShowUtilities(true)

      } else {
        const { utility = {} } = detail
        toastr.warning(`${utility.name} no Utilities available  `)
      }
    })
  }

  function handleReplaceUrl(state, utility, program) {
    if (state) {
      let { TABLEVIEW_UTILITY } = pathKeys
      TABLEVIEW_UTILITY = TABLEVIEW_UTILITY.replace(UTILITY_NAME, utility);
      TABLEVIEW_UTILITY = TABLEVIEW_UTILITY.replace(STATE_NAME, state);
      TABLEVIEW_UTILITY = TABLEVIEW_UTILITY.replace(PROGRAM_NAME, program);
      historyPush(TABLEVIEW_UTILITY)
    }
  }

  function handleClickTable(record) {
    const state = _.get(record, "key.state", "");
    const utility = _.get(record, "key.utility", {});
    const program = _.get(record, "key.program", {});
    const categoryRoots = _.get(record, "categoryRoots", []);
    if (utility.id) {
      getByUtilityId({
        utilityId: utility.id,
        skip: 0,
        count: 1000
      }, {
        name: state,
        utility,
        categoryRoots
      }, program)
      handleReplaceUrl(state, utility.name, program.name)
    } else {
      toastr.warning(`${state} no Utilities available  `)
    }

  }


  const columns = [
    {
      key: "stt",
      colSpan: 1,
      header: <></>,
      style: {
        width: 60,
        paddingLeft: "20px"
      },
      renderItem: () => {
        return <IconCardLable />
      }
    },
    {
      key: "Status",
      colSpan: 4,
      sortable: true,
      header: <div>{t("table.status")}</div>,
      renderItem: (record) => {
        const { isActive } = _.get(record, "key.program", {});

        return <StatusText status={handleRenderStatus(isActive)}>{handleRenderStatus(isActive)}</StatusText>
      }
    },
    {

      key: "State",
      colSpan: 6,
      sortable: true,
      header: <div>{t("table.state")}</div>,
      renderItem: (record) => {

        const name = _.get(record, "key.state", "");
        return <Text className="table-tootip-float" as="div" lines={1} popover>{name}</Text>
      }
    },
    {
      key: "Utility",
      header: <div>{t("table.utility")}</div>,
      colSpan: 6,
      sortable: true,
      renderItem: (record) => {
        const { name } = _.get(record, "key.utility", {});
        return (
          <Text className="table-tootip-float" as="div" lines={1} popover>
            {name}
          </Text>
        )
      }
    },
    {
      key: "Category",
      header: <div>{t("table.category")}</div>,
      colSpan: 4,
      renderItem: (record) => {
        const categoryRoots = _.get(record, "categoryRoots", []);

        let IconList = categoryRoots.map((el) => {
          const Icon = el ? Icons[el.name] : undefined;
          return Icon ? (
            <Tooltip key={el.name} childrenTooltip={el.name} >
              <Icon key={el.name} />
            </Tooltip>
          ) : null;
        })
        IconList = IconList.filter(r => r !== null);

        return (
          <div className="table-groupIcon">
            {
              IconList
            }
          </div>
        )
      }
    },
    {
      key: "Rebate",
      header: <div>{t("table.rebate")}</div>,
      colSpan: 6,
      renderItem: (record) => {

        const { websiteLink = [] } = _.get(record, "key.program", {});
        return (
          <>
            {websiteLink ? (
              <div
                role="presentation"
                onClick={() => {
                  window.open(websiteLink, '_blank');
                }} className="table-link-page"
              >
                Outdoor Fixtures - General
              <IconCategory5 />
              </div>
            ) : ""}
          </>
        )
      }
    },
    {
      key: "Type",
      header: <div>{t("table.type")}</div>,
      colSpan: 3,
      renderItem: (record) => {
        const newData = _.get(record, "items", []);
        return (
          <div className="table-groupIcon">
            {handleRenderType(newData)}
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("table.project")}</div>,
      colSpan: 3,
      renderItem: (record) => {
        const { isRetroFit, isNewConstruction, isInstant } = _.get(record, "key.program", {});
        return (
          <div className="table-groupIcon">
            {isNewConstruction ? (
              <Tooltip childrenTooltip="New Construction" >
                <IconCategoryHouse />
              </Tooltip>
            ) : null}
            {isRetroFit ? (
              <Tooltip childrenTooltip="Retrofit" >
                <IconCategoryHammer />
              </Tooltip>
            ) : null}
            {
              !!isInstant && (
                <Tooltip childrenTooltip="Instant" >
                  <IconThunder />
                </Tooltip>
              )
            }
          </div>
        )
      }
    },
    {
      key: "Start",
      header: <div>{t("table.start")}</div>,
      colSpan: 4,
      sortable: true,
      renderItem: (record) => {
        const { startDate } = _.get(record, "key.program", {});
        return startDate ? (<div className="table-time">{formatDate(startDate)}</div>) : null
      }
    },
    {
      key: "End",
      header: <div>{t("table.end")}</div>,
      colSpan: 4,
      sortable: true,
      renderItem: (record) => {
        const { endDate } = _.get(record, "key.program", {});
        return endDate ? (<div className="table-time">{formatDate(endDate)}</div>) : null
      }
    },
  ];

  return (
    <>
      <Loading loading={loading} />
      {
        items.length || isFirstFresh ? (
          <Scrollbars
            onScrollFrame={(values) => {
              const { top } = values
              const { skip, count } = filter
              if (top >= 0.9 && (total >= (skip + 1) * count)) {
                const filterNow = {
                  ...filter,
                  skip: skip + 1
                }
                setFilter(filterNow)
                const fillterCurrent = {
                  ...filterNow,
                  skip: (skip + 1) * count
                }
                handleFetchProgram(fillterCurrent)
              }
            }}
            autoHide >
            <div className="tableView">

              <DataTable
                sortable
                noDataText="No data"
                data={items}
                columns={columns}
                orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
                onClick={(data) => { handleClickTable(data) }}
                onSort={str => {
                  if (str) {
                    const arrayValue = str.split("-")
                    if (arrayValue[0] && arrayValue[1]) {
                      const dataBody = {
                        ...filter,
                        orderField: arrayValue[0],
                        isDesc: arrayValue[1] !== 'asc',
                        skip: 0,
                        count: pageSize,
                      }
                      setFilter(dataBody)
                      handleFetchDefault(dataBody)
                    }
                  }
                }}
              />
            </div>
            <DrawerUtilites
              onClick={(el, index, rebateValuePageIndex) => {
                getProgramByUtilityId({ id: el.id, }, el, index);
              }}
              categories={categories}
              indexActive={indexActive}
              data={utilites}
              onClose={() => {
                setIsShowUtilities(false);
              }} isShow={isShowUtilities}
              itemData={programDetail}
              qpls={qpls}
              reloadingRebate={isGettingProgramDetail}
            />
          </Scrollbars>
        ) : (
            <EmptyState
              className="custom-empty-state-second"
              photo={emptyTable}
              text={() => {
                return (
                  <>
                    <div className="custom-empty-state-second__title">
                      NO PROGRAMS MATCH YOUR SELECTED FILTERS.
                    </div>
                    <div className="custom-empty-state-second__title-sub">
                      Please update your filters to find matching programs or
                    </div>
                    <a className="custom-empty-state-second__link" href="mailto:support@rebatebus.com">
                      let us know if we are missing a program.
                    </a>
                  </>
                );
              }}
            />
          )
      }
    </>

  )
}

TableView.propTypes = {
  filterItems: PropTypes.array,
  items: PropTypes.array,
  setItems: PropTypes.func,
  categories: PropTypes.array,
}

TableView.defaultProps = {
  filterItems: [],
  items: [],
  setItems: () => { },
  categories: []
}

const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
    items: programSelectors.selectProgramsItems(state),
    categories: dropDownCategorySelectors.category(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  setItems: (items) => dispatch(programActions.changePrograms(items)),

})

export default connect(mapStateToProps, mapDispatchToProps)(TableView)
