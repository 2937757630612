/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { StatusText, Tooltip } from ".."
import { IconCloseDrawer, IconArrow, IconCloseDrawerMobile } from "../../../assests/icons"
import { IconsStates } from "../../../constants/states"
import './style.scss'

const Drawer = (props) => {
  const {
    className,
    isShow,
    onClose,
    onClick,
    data
  } = props

  const DrawerClasses = cn(
    'custom-Drawer',
    'CustormScrollBar',
    [className],
  )

  const { name = 'Iowa', items = [], county = '', lsad = '', state = '', countyName = '' } = data
  const IconMapImage = IconsStates[name] ? IconsStates[name] : null

  return (
    <div className={DrawerClasses} >
      <div className={cn("custom-Drawer custom-custom-Drawer", { toggled: !isShow })}>
        <div className="custom-custom-Drawer__header">
          <IconCloseDrawer onClick={() => { onClose() }} className="custom-custom-Drawer__header__icon hiddenMobile" />
          <IconCloseDrawerMobile onClick={() => { onClose() }} className="custom-custom-Drawer__header__icon hiddenDesktop" />
        </div>
        <div className="custom-custom-Drawer__title">
          <div>
            {IconMapImage ? <IconMapImage /> : null}
          </div>
          <div className="custom-custom-Drawer__title__parent">
            <div className="custom-custom-Drawer__title__text">{name}</div>
            <div className="custom-custom-Drawer__title__text-sub">{countyName || 'LINN'} COUNTY</div>
          </div>
        </div>
        <div className="custom-Drawer-content">
          <div className="custom-Drawer-content__title">Choose a utility</div>
          {
            items.map((item, index) => {
              return (
                <div role="presentation" key={`${item.name}-Drawer-${index}`}
                  onClick={() => {
                    if (item.programNumber) {
                      onClick(
                        item, {
                        name,
                        county,
                        lsad,
                        state,
                        countyName
                      })
                    }
                  }} className="custom-Drawer-content__item">
                  <div className="custom-Drawer-content__item-text">
                    {item.name}
                    {
                      item.programNumber ? (
                        <IconArrow />
                      ) : (
                        <Tooltip childrenTooltip="This utility isn’t currently offering rebates" >
                          <div className="custom-Drawer-content__item-status" >
                            <StatusText status="closed">No Rebates</StatusText>
                          </div>
                        </Tooltip>
                      )
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

Drawer.propTypes = {
  className: PropTypes.string,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  data: PropTypes.object
}

Drawer.displayName = 'Drawer'

Drawer.defaultProps = {
  className: "",
  isShow: false,
  onClose: () => { },
  onClick: () => { },
  data: {}
}

export default Drawer
