import React from 'react'
import ContentLoader from 'react-content-loader'
import cn from 'classnames'

import './style.scss'


// eslint-disable-next-line react/prop-types
const AvatarLoader = ({ size }) => {
  const wrapperClasses = cn('avatar-loader', {
    [size]: typeof size === 'string'
  })
  return (
    <ContentLoader
      speed={2}
      width={typeof size === 'string' ? '100%' : `${size}px`}
      height={typeof size === 'string' ? '100%' : `${size}px`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className={wrapperClasses}
    >
      <circle cx="50%" cy="50%" r="50%" />
    </ContentLoader>
  )
}

export default AvatarLoader
