/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-properties */
import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { toastr } from 'react-redux-toastr'
import { Button, TopLabelFormGroup } from "../../common";
import { rebateService } from "../../../services"
import { IconSuccessful, IconUploadFile, IconDeleteFile, IconManuallyFileLink } from "../../../assests/icons";
import "./style.scss";

const ApplyRebateModal = (props) => {
  const { onClickLink, utilityName, programName } = props
  const [isSuccess, setIsSuccess] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [objecContent, setObjectContent] = useState({})
  const uploadRef = useRef(null);

  function handleSendMail() {
    const formData = new FormData();
    if (objecContent.names && objecContent.names.length) {
      formData.append("FormFiles", objecContent.names)
    }
    formData.append("Message", objecContent.message || "");
    formData.append("UtilityName", utilityName);
    formData.append("ProgramName", programName);
    setIsDisable(true)
    rebateService.sendEmail(formData).then(res => {
      setIsDisable(false)
      const { success } = res
      if (success) {
        setIsSuccess(true)
      } else {
        toastr.warning("Action fail please try Again!")
      }
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  return (
    <Modal
      dialogClassName="ApplyRebateModal"
      {...props}
      animation={false}
      centered
      size="lg"
      onHide={() => {
        if (props.onHide) {
          props.onHide()
          setIsSuccess(false)
          setObjectContent({})
          setIsDisable(false)
        }
      }}
      data-testid="test-apply-for-rebate-modal"
    >
      <Modal.Header closeButton>

        <Modal.Title>
          Apply for Rebates
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccess ? (
          <>
            <IconSuccessful />
            <div className="ApplyRebateModal__title ApplyRebateModal__title-second">
              Success!
              </div>
            <div className="ApplyRebateModal__sub-title">
              <div>We have receive your proposal and our fulfillment team is reviewing it.</div>
              <div>They will contact you with 2 business days to update you on what they have found, and what rebates are available.</div>
              <div className="ApplyRebateModal__sub-title ApplyRebateModal__sub-title__second">
                Thank you for choosing Rebate Bus.
              </div>
              <Button
                onClick={() => {
                  if (props.onHide) {
                    props.onHide()
                    setIsSuccess(false)
                    setObjectContent({})
                    setIsDisable(false)
                  }
                }}
                className="ApplyRebateModal__button"
                typeClass="second"
                size="md"
                text="DONE"
              />
            </div>
          </>
        ) : (
            <>
              <div className="ApplyRebateModal__title">
                <div>Too Much Paperwork? Not Enough time?</div>
                <div>Let Rebate Bus handle your rebates for you!</div>
              </div>
              <div className="ApplyRebateModal__sub-title">
                Rebate paperwork is a challenge. It's time-consuming and tedious, and it gets in the way of what you do best - selling your products. Let Rebate Bus do what we do best and handle that paper work for you, so you can get back to your customers with the best possible rebate for their project
              </div>
              <div className="ApplyRebateModal__sub-title ApplyRebateModal__sub-title__second">
                Tell us about your project or upload your proposal or scope of work here, and our team will handle the applicable rebates for you.
              </div>
              <div className="ApplyRebateModal__content">
                <div
                  role="presentation"
                  onDragOver={(e) => { e.preventDefault() }}
                  onDragEnter={(e) => { e.preventDefault() }}
                  onDragLeave={(e) => { e.preventDefault() }}
                  onDrop={(e) => {
                    e.preventDefault()
                    if (e.dataTransfer.files) {
                      const { files } = e.dataTransfer
                      const names = []
                      let size = 0
                      Object.keys(files).forEach(key => {
                        names.push(files[key])
                        size += files[key].size

                      })
                      setObjectContent({
                        ...objecContent,
                        names,
                        size: formatBytes(size)
                      })
                    }
                  }}
                  onClick={() => {
                    uploadRef.current.click();
                  }}
                  className="ApplyRebateModal__upload"
                >
                  <IconUploadFile className="ApplyRebateModal__upload__icon" />
                  {
                    objecContent.names && objecContent.names.length ? (
                      <>
                        {
                          objecContent.names.map((item, index) => (
                            <div key={item.name} className="ApplyRebateModal__upload__title">
                              {item.name}<IconDeleteFile className="ApplyRebateModal__upload__icon--delete" onClick={(e) => {
                                e.stopPropagation();
                                const names = objecContent.names.filter((_, index2) => index !== index2)
                                let size = 0
                                names.forEach(item => {
                                  size += item.size
                                })
                                setObjectContent({
                                  ...objecContent,
                                  names,
                                  size: formatBytes(size)
                                })
                              }} />
                            </div>
                          ))
                        }
                        {
                          objecContent.size ? (
                            <div className="ApplyRebateModal__upload__sub">
                              Add up to {objecContent.size} of documents
                            </div>
                          ) : null
                        }

                      </>
                    ) : (
                        <>
                          <div className="ApplyRebateModal__upload__title-second">
                            Drag and Drop
                          </div>
                          <div>
                            or
                          </div>
                          <div>
                            Click to Upload your proposal
                          </div>

                        </>
                      )
                  }
                  <input
                    type="file"
                    className="hide"
                    ref={uploadRef}
                    onChange={(e) => {
                      if (!e.target.files.length) {
                        return;
                      }
                      const { files } = e.target
                      const names = []
                      let size = 0
                      Object.keys(files).forEach(key => {
                        names.push(files[key])
                        size += files[key].size
                      })

                      setObjectContent({
                        ...objecContent,
                        names,
                        size: formatBytes(size)
                      })
                    }}
                    multiple
                  />
                </div>
                <div className="ApplyRebateModal__content__or">- OR -</div>
                <TopLabelFormGroup
                  label="Tell us about your project"
                  controlId="message"
                  isTextArea
                  value={objecContent.message || ""}
                  onChange={(name, value) => {
                    setObjectContent({
                      ...objecContent,
                      [name]: value
                    })
                  }}
                />

              </div>
              <Button typeClass={((objecContent.names && objecContent.names.length) || (objecContent.message && objecContent.messag !== "")) && !isDisable ? "second" : ""} onClick={() => {
                if (((objecContent.names && objecContent.names.length) || (objecContent.message && objecContent.messag !== "")) && !isDisable) {
                  handleSendMail()
                }
              }} size="md" text="SUBMIT" />
              <div
                role="presentation"
                onClick={() => {
                  if (onClickLink) {
                    onClickLink()
                  }
                }} className="ApplyRebateModal__link">
                CLICK HERE TO APPLY FOR REBATES MANUALLY <IconManuallyFileLink />
              </div>

            </>
          )}

      </Modal.Body>
    </Modal>
  );
};

ApplyRebateModal.propTypes = {
  onHide: PropTypes.func,
  onClickLink: PropTypes.func,
  utilityName: PropTypes.string,
  programName: PropTypes.string
};

ApplyRebateModal.defaultProps = {
  onHide: () => { },
  onClickLink: () => { },
  programName: "",
  utilityName: ""
};

export default ApplyRebateModal;
