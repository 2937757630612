
import BaseService from "./baseService";
import utilityApi from "../api/utilityApi";
import { getQueryString } from "../utils/common";


class UtilityService extends BaseService {
  getAllUtility(query = {}, headers, isAuthen = true) {
    const newQuery = getQueryString(query)
    return utilityApi.getAllUtility(newQuery, headers, isAuthen).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getList(query = {}) {
    const newQuery = getQueryString(query)
    return utilityApi.getList(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getUtilitiesByProgramId(query = {}) {
    const newQuery = getQueryString(query)
    return utilityApi.getUtilitiesByProgramId(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  create(params) {
    return utilityApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return utilityApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }


  exportAll(params = {}) {

    return utilityApi.exportAll(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportByFilter(params = {}) {
    return utilityApi.exportByFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportFilter(params = {}) {
    return utilityApi.exportFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportBlank() {
    return utilityApi.exportBlank().then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getByIds(params = {}) {
    return utilityApi.getByIds(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  importFile(params = {}) {
    return utilityApi.importFile(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }
}

export default new UtilityService();
