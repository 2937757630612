

export const STATE_NAME = ':state_name'
export const COUNTY_NAME = ':county_name'
export const UTILITY_NAME = ':utility_name'
export const PROGRAM_NAME = ':program_name'
export const rootBulk = `/bulk`
export const pathKeys = {
  ROOT: "/",
  ROLE: '/roles',

  HOME: "/home",
  MODAL: "/modal",
  LOGIN: "/login",
  SIGNUP: "/signup",
  SAVEDITEMS: "/Saved-items",
  HELP_CENTER: "/help-center",
  SETTINGS: "/settings",
  MY_PROFILE: "/my-profile",
  REBATES: "/rebate-map",
  REBATES_STATE: `/rebate-map/${STATE_NAME}`,
  REBATES_COUNTY: `/rebate-map/${STATE_NAME}/${COUNTY_NAME}`,
  REBATES_UTILITY: `/rebate-map/${STATE_NAME}/${COUNTY_NAME}/${UTILITY_NAME}`,
  REBATES_PROGRAM: `/rebate-map/${STATE_NAME}/${COUNTY_NAME}/${UTILITY_NAME}/${PROGRAM_NAME}`,
  MAPCARDVIEW: "/rebate-list",
  MAPCARDVIEW_UTILITY: `/rebate-list/${STATE_NAME}/${UTILITY_NAME}/${PROGRAM_NAME}`,
  TABLEVIEW: "/rebate-table",
  TABLEVIEW_UTILITY: `/rebate-table/${STATE_NAME}/${UTILITY_NAME}/${PROGRAM_NAME}`,
  CATEGORY_MANAGEMENT: "/category-management",
  UTILITY_MANAGEMENT: '/utility-management',
  PROGRAM_MANAGEMENT: '/program-management',
  REBATES_MANAGEMENT: '/rebates-management/:programId',
  EMBED: "/embed",
  EMBED_STATE: `/embed/${STATE_NAME}`,
  EMBED_COUNTY: `/embed/${STATE_NAME}/${COUNTY_NAME}`,
  EMBED_UTILITY: `/embed/${STATE_NAME}/${COUNTY_NAME}/${UTILITY_NAME}`,
  BULK_UTILITIES: `${rootBulk}/utilities`,
  BULK_PROGRAMS: `${rootBulk}/programs`,
  BULK_REBATES: `${rootBulk}/rebates`,
  BULK_UTILITIES_IMPORT: `${rootBulk}/import/utilities`,
  BULK_PROGRAMS_IMPORT: `${rootBulk}/import/programs`,
  BULK_REBATES_IMPORT: `${rootBulk}/import/rebates`,
  CHECK_APPLICATION_URL: `/check-application-url`,
  TRIAL_VIEW: "/trial"
}

export default pathKeys 
