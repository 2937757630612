import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from "react-bootstrap";
import cn from 'classnames'
import './style.scss';

const Progress = props => {
  const {
    now,
    className,
    text
  } = props
  const customClasses = cn('custom-progress', [
    className,
  ])
  return (
    <>
      <ProgressBar className={customClasses} now={now} />
      {
        text ? <div className="custom-progress__text">{text}</div> : null
      }
    </>
  )
}

Progress.propTypes = {
  now: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
}

Progress.defaultProps = {
  now: 60,
  className: '',
  text: null
}

export default Progress
