
import BaseService from "./baseService";
import rebateRequirementApi from "../api/rebateRequirementApi";
import { getQueryString } from "../utils/common";


class RebateRequirementService extends BaseService {
  getListByRebateId(query = {}) {
    const newQuery = getQueryString(query)
    return rebateRequirementApi.getListByRebateId(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }


  addOrUpdateMultipleByRebateId(query, params) {
    const newQuery = getQueryString(query)
    return rebateRequirementApi.addOrUpdateMultipleByRebateId(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new RebateRequirementService();
