/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Form from 'react-bootstrap/Form'
import './style.scss'

const Switches = (props) => {
  const {
    className,
    label,
    id,
    disabled,
    checked,
    onChange
  } = props


  const SwitchesClasses = cn(
    'custom-Switches',
    'Switches-autosize',
    [className]
  )

  return (
    <div className="Switches-wrapper">
      <Form.Check
        onChange={onChange}
        disabled={disabled}
        type="switch"
        id={id}
        label={label}
        checked={checked}
        className={SwitchesClasses}
      />
    </div>
  )
}

Switches.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

Switches.displayName = 'Switches'

Switches.defaultProps = {
  className: "",
  label: "",
  id: "custom-switch",
  disabled: false,
  onChange: () => { },
  checked: false
}

export default Switches
