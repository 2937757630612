/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import debounce from 'lodash/debounce'
import {
  IconSearch,
  IconClearSearch,
  IconArrow,
  IconLeftChevron
} from "../../../assests/icons";
import { Checkbox } from ".."
import { removeVietnameseAccent } from "../../../utils/common";
import "./second.scss"

const DropdownSecond = (props) => {
  const {
    className,
    items,
    onChange,
    selectedValues,
    onClickParentItem,
    itemsAll,
    changeCategoryItem,
    key,
    onHandleTextChanged,
    children
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isChildren, setIsChilDren] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState(items);
  const [objectBack, setObjectBack] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [maxScroll, setMaxScroll] = useState(30)
  const dropDownRef = useRef(null);

  function handleScroll() {
    const element = document.querySelector(`.Dropdown-wrapper__scroll-${key}`);
    let scrollPrev = 0
    if (element) {
      element.addEventListener("scroll", (e) => {
        const { scrollTop } = e.target
        if (scrollTop % 100 > 0 && scrollTop > 100) {
          const newMaxScroll = scrollTop / 100 * 30
          if (newMaxScroll > scrollPrev) {
            scrollPrev = newMaxScroll
            setMaxScroll(newMaxScroll)
          }
        }
      }, false)
    }
  }

  useEffect(() => {
    handleScroll()
  }, []);



  useEffect(() => {
    setSearchData(items)
  }, [items]);


  useEffect(() => {
    function handleClickOutside(event) {
      const dropDownOpen = dropDownRef.current.getAttribute("data-open");
      if (dropDownRef.current && !dropDownRef.current.contains(event.target) && dropDownOpen === 'true') {
        setIsOpen(false);

      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);


  const handleSearch = debounce((value, itemsAll) => {
    const newData = [];
    itemsAll.forEach((element) => {
      const { label } = element;
      if (
        removeVietnameseAccent(label.toLowerCase()).indexOf(
          removeVietnameseAccent(value.toLowerCase())
        ) > -1
      ) {
        if (value && value !== '') {
          newData.push(element);
        } else if ((!value || (value && value === '')) && !element.parentId) {
          newData.push(element);
        }
      }
    });
    changeCategoryItem(newData);
    setObjectBack({})
    setIsLoading(false)
  }, 100, 'later');

  const handleOnChangeValue = (newValue) => {
    onChange([...newValue]);
  }

  const hadleGetChildren = (arrayTmp) => {
    let check = true
    const newTmp = [...arrayTmp]
    for (let i = 0; i < arrayTmp.length; i++) {
      for (let j = 0; j < itemsAll.length; j++) {
        if (itemsAll[j].parentId === arrayTmp[i].value) {
          const index = newTmp.findIndex(el => el.value === itemsAll[j].value)

          if (index === -1) {
            newTmp.push({
              ...itemsAll[j],
            })
            if (itemsAll[j].isParent) {
              check = false
            }

          }
        }
      }
    }
    if (check) {
      return newTmp
    } else {
      return hadleGetChildren(newTmp)
    }
  }

  const handleGetChildrenByArray = (arrayChildren, arrayCurrent) => {
    let check = true
    const newArray = [...arrayChildren]
    for (let i = 0; i < newArray.length; i++) {
      for (let j = 0; j < arrayCurrent.length; j++) {
        if (arrayCurrent[j].parentId === newArray[i].value) {
          const index = newArray.findIndex(el => el.value === arrayCurrent[j].value)

          if (index === -1) {
            newArray.push({
              ...arrayCurrent[j],
            })
            if (arrayCurrent[j].isParent) {
              check = false
            }
          }
        }
      }
    }
    if (check) {
      return newArray
    } else {
      return handleGetChildrenByArray(newArray, arrayCurrent)
    }
  }

  const handleSetIsFull = (newValue) => {
    const TmpArray = []
    newValue.forEach(el => {
      const arrayNow = newValue.filter(item => item.parentId === el.value)
      const arrayParent = itemsAll.filter(item => item.parentId === el.value)
      const newItem = { ...el }
      if ((arrayNow.length && arrayNow.length === arrayParent.length) || !el.isParent) {
        newItem.isFull = true
      } else {
        newItem.isFull = false
      }
      TmpArray.push(newItem)
    })

    const RealArray = []
    for (let i = 0; i < TmpArray.length; i++) {
      const newData = handleGetChildrenByArray([{ ...TmpArray[i] }], TmpArray)
      let newItem = TmpArray[i]
      for (let j = 1; j < newData.length; j++) {

        if (!newData[j].isFull) {
          newItem = {
            ...TmpArray[i],
            isFull: false
          }
          break
        }
      }
      RealArray[i] = newItem
    }
    return RealArray
  }

  const handleAutoCheckParent = (newValue, el) => {
    if (!el.parentId) {
      return newValue
    } else {
      const index = itemsAll.findIndex(item => item.value === el.parentId)
      if (index !== -1) {
        const index2 = newValue.findIndex(item => item.value === itemsAll[index].value)
        const newDataValue = [...newValue]
        if (index2 === -1) {
          newDataValue.push({
            ...itemsAll[index]
          })
        }
        return handleAutoCheckParent(newDataValue, itemsAll[index])
      } else {
        return newValue
      }
    }
  }

  const handleRemoveAutoCheckParent = (newValue, el) => {
    if (!el.parentId) {
      return newValue
    } else {
      const index = itemsAll.findIndex(item => item.value === el.parentId)
      const arrayNow = newValue.filter(item => item.parentId === el.parentId)
      if (index !== -1) {
        let newValueData = [...newValue]
        if (!arrayNow.length) {
          newValueData = newValueData.filter(item => item.value !== el.parentId)
        }
        return handleRemoveAutoCheckParent(newValueData, itemsAll[index])
      } else {
        return newValue
      }
    }
  }

  const handleChangeItem = (isCheck, item) => {
    const { value } = item;
    let newValue = []
    if (isCheck) {
      newValue = selectedValues.filter(el => el.value !== item.value)
      newValue = handleRemoveAutoCheckParent(newValue, item)
    } else {
      newValue = [...selectedValues]
      const index = newValue.findIndex(el => el.value === item.value)
      if (index === -1) {
        const newItem = {
          ...item,

          value
        }
        newValue.push(newItem)
        //newValue = handleAutoCheckParent(newValue, item)
      }
    }
    const newRealValue = handleSetIsFull(newValue)
    handleOnChangeValue(newRealValue)
  }

  const handleChangeItemRoot = (isCheck, item) => {
    const { value } = item;
    let newValue = []
    if (isCheck) {
      newValue = selectedValues.filter(el => el.value !== item.value)
    } else {
      newValue = [...selectedValues]
      const index = newValue.findIndex(el => el.value === item.value)
      if (index === -1) {
        const newItem = {
          ...item,
          value
        }
        newValue.push(newItem)
      }
    }
    handleOnChangeValue(newValue)
  }


  const handleChangeCheckParent = (isCheck, item) => {
    const newItem = { ...item }
    const arrayTmp = [newItem]
    //const newArray = hadleGetChildren(arrayTmp)

    const newArray = arrayTmp

    let newValue = [...selectedValues]

    if (!isCheck) {
      newArray.forEach(el => {
        const index = selectedValues.findIndex(el2 => el2.value === el.value)
        if (index === -1) {
          newValue.push({
            ...el,
          })
          //newValue = handleAutoCheckParent(newValue, el)
        }
      })
    } else {
      newArray.forEach(el => {
        const index = newValue.findIndex(el2 => el2.value === el.value)
        if (index !== -1) {
          newValue.splice(index, 1)
        }
      })
      newValue = handleRemoveAutoCheckParent(newValue, item)
    }
    const newRealValue = handleSetIsFull(newValue)
    handleOnChangeValue(newRealValue)
  }

  function handleOnClickGroup(item, isCheck) {
    const { isParent, parentId } = item
    if (isParent) {
      handleChangeCheckParent(isCheck, item)
    } else if (!parentId) {
      handleChangeItemRoot(isCheck, item)
    } else {
      handleChangeItem(isCheck, item)
    }
  }

  const renderDropDownItem = () => {
    if (isLoading) {
      return <div className="Dropdown-wrapper_dropdown__paren"><div className="Dropdown-wrapper_item">Loading ...</div></div>
    }
    return searchData.length ? searchData.map((item, indexDropDown) => {
      const { label = "", value, isParent, parentId, subLabel } = item;
      const className = cn("Dropdown-wrapper_item");
      const indexSelect = selectedValues.findIndex(fi => fi.value === value)
      const isCheck = indexSelect !== -1
      if (indexDropDown < maxScroll) {

        return (
          <div key={`${value}  renderDropDownItem`} className={className}>
            <Checkbox isFull={!isParent || (isCheck && selectedValues[indexSelect].isFull)} onClick={() => {
              handleOnClickGroup(item, isCheck)
            }} checked={isCheck} />
            {label ? (
              <div
                onClick={() => {
                  if (isParent) {
                    setSearchText("")
                    setIsChilDren(true);
                    onClickParentItem(value)
                    setObjectBack(item)
                  } else if (!parentId) {
                    handleChangeItemRoot(isCheck, item)
                  } else {
                    handleChangeItem(isCheck, item)
                  }
                }}
                role="presentation"
                className="Dropdown-wrapper_item_text"
              >
                {label} {subLabel ? <div className="Dropdown-wrapper_item_subText">({subLabel})</div> : ''}
              </div>
            ) : null}
            {isParent ? (
              <IconArrow
                onClick={() => {
                  setTimeout(() => {
                    setSearchText("")
                    setIsChilDren(true)
                    onClickParentItem(value)
                    setObjectBack(item)
                  }, 100)
                }}
                role="presentation"
                className="Dropdown-wrapper_item__icon Dropdown-wrapper_item__icon-2"
              />
            ) : null}
          </div>
        )
      } else {
        return null
      }
    }) : <div className="Dropdown-wrapper_dropdown__paren"><div className="Dropdown-wrapper_item">No data</div></div>
  };

  const renderSearch = () => {
    return (
      <div className="Dropdown-wrapper-search">
        <input
          onChange={(e) => {
            const { value } = e.target;
            setSearchText(value);
            setIsLoading(true)
            if (onHandleTextChanged) {
              onHandleTextChanged(value, setIsLoading)
            } else {
              handleSearch(value, itemsAll)
            }
          }}
          placeholder="Search"
          value={searchText}
          className="Dropdown-wrapper-search-input"
        />
        {searchText.length ? (
          <IconClearSearch
            onClick={() => {
              setTimeout(() => {
                setSearchText("")
                setIsChilDren(true)
                onClickParentItem(null)
                setIsLoading(false)
              }, 100)
            }}
            className="Dropdown-wrapper-search__icon Dropdown-wrapper-search__icon-2"
          />
        ) : (
          <IconSearch className="Dropdown-wrapper-search__icon " />
        )}
      </div>
    );
  };

  const handleOnClickBack = () => {
    setSearchText("")
    onClickParentItem(objectBack.parentId)
    const index = itemsAll.findIndex(item => item.value === objectBack.parentId)
    if (index !== -1) {
      setObjectBack(itemsAll[index])
    } else {
      setIsChilDren(false);
      setObjectBack({})
    }
  }

  return (
    <div className={`Dropdown-wrapper-parent  Dropdown-wrapper-parent-second CustormScrollBar ${className}`} ref={dropDownRef} data-open={isOpen}>
      <div
        role="presentation"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {children}
      </div>
      <div
        className={`Dropdown-wrapper_dropdown ${
          !isOpen ? "Dropdown-wrapper_dropdown-close" : ""
        }`}
      >

        {renderSearch()}

        <div className="Dropdown-wrapper_dropdown__parent">
          <div
            onClick={() => {
              if (searchText.length || isChildren || selectedValues.length) {
                setIsChilDren(false);
                setSearchText("");
                onClickParentItem(null);
                const newValue = []

                handleOnChangeValue(newValue)
                setObjectBack({})
              }
            }}
            className={`Dropdown-wrapper_dropdown__reset ${
              searchText.length || isChildren || selectedValues.length ? "active" : ""
            }`}
          >
            Reset
          </div>
          <div className="Dropdown-wrapper_dropdown__total">
            {searchText.length
              ? `${searchData.length} of ${items.length} `
              : `${items.length} items`}
          </div>
        </div>
        {objectBack.value ? (
          <div role="presentation" onClick={() => {
            handleOnClickBack()
          }} className="Dropdown-wrapper__back">
            <IconLeftChevron />
            <div className="Dropdown-wrapper__back-text"> {objectBack.label}</div>
          </div>
        ) : null}
        <div className={`Dropdown-wrapper__scroll Dropdown-wrapper__scroll-second Dropdown-wrapper__scroll-${key}`}>
          {renderDropDownItem()}
        </div>
      </div>
    </div >
  );
};

DropdownSecond.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  itemsAll: PropTypes.array,
  onChange: PropTypes.func,
  selectedValues: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  onClickParentItem: PropTypes.func,
  changeCategoryItem: PropTypes.func,
  key: PropTypes.string,
  onHandleTextChanged: PropTypes.func,
  children: PropTypes.element
};

DropdownSecond.displayName = "Dropdown";

DropdownSecond.defaultProps = {
  className: "",
  items: [],
  onChange: () => { },
  selectedValues: [],
  onClickParentItem: () => { },
  itemsAll: [],
  changeCategoryItem: () => { },
  key: "unique",
  onHandleTextChanged: undefined,
  children: ""
};

export default DropdownSecond;
