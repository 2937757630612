import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import cn from 'classnames'

import { BOOTSTRAP_VARIANTS } from '../../../constants/common'
import './style.scss'

export const TabHeader = ({ headerNames }) =>
  headerNames.map(({ title, eventKey, className }, index) => (
    <Nav.Item key={eventKey} as="li">
      <Nav.Link className={`${className} ${index === headerNames.length - 1 ? "non-line" : ""}`} eventKey={eventKey}>{title}</Nav.Link>
    </Nav.Item>
  ))

export const CustomTitle = ({ renderTitle, activeKey }) => {
  return typeof renderTitle === 'function' ? renderTitle({ activeKey }) : renderTitle
}

const Tabs = props => {
  const { headerNames, defaultActiveKey, children, renderTitle, onTabChange, className } = props
  const [key, setKey] = useState(defaultActiveKey)
  const handleChangeTab = newKey => {
    if (newKey !== key) {
      setKey(newKey)
      onTabChange(newKey)
    }
  }
  return (
    <div className={cn("custom-tabs", [className])}>
      <Tab.Container
        id="left-tabs-example"
        onSelect={handleChangeTab}
        activeKey={key}
      >
        <div className="custom-tabs-header-second">
          <Nav variant={BOOTSTRAP_VARIANTS.PILLS} className="tabs-nav flex-row" as="ul">
            <TabHeader headerNames={headerNames} />
          </Nav>
          <div className="tabs-custom-title">
            <CustomTitle renderTitle={renderTitle} activeKey={key} />
          </div>
        </div>
        <Tab.Content>{children}</Tab.Content>
      </Tab.Container>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array),
  headerNames: PropTypes.instanceOf(Array),
  defaultActiveKey: PropTypes.string,
  renderTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onTabChange: PropTypes.func,
  className: PropTypes.string,
}

Tabs.defaultProps = {
  renderTitle: null,
  defaultActiveKey: '',
  children: [],
  headerNames: [],
  onTabChange: () => { },
  className: '',
}


export default Tabs
