/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, DataTable } from "../../components/common"
import { IconError } from "../../assests/icons"
// IconDeleteRoles, IconEditRoles, IconAddChildCategory 
// const Add = "add"
// const Update = "update"
// const Delete = "delete"
export const ErrorImport = ({ onClick, data }) => {
  const columns = [
    // {
    //   key: "Row",
    //   colSpan: 3,
    //   header: <div>Row</div>,
    //   renderItem: (record) => {
    //     return (
    //       <>
    //         {record.row || 0}
    //       </>
    //     )
    //   }
    // },
    // {
    //   key: "Name",
    //   colSpan: 3,
    //   header: <div>Name</div>,
    //   renderItem: (record) => {
    //     return (
    //       <>
    //         {record.name || "N/A"}
    //       </>
    //     )
    //   }
    // },
    // {
    //   key: "Action",
    //   colSpan: 3,
    //   header: <div>Action</div>,
    //   renderItem: (record) => {
    //     return (
    //       <>
    //         {record.action === Add ? <IconAddChildCategory /> : ""}
    //         {record.action === Update ? <IconEditRoles /> : ""}
    //         {record.action === Delete ? <IconDeleteRoles /> : ""}
    //       </>
    //     )
    //   }
    // },
    {
      key: "Reson",
      colSpan: 3,
      header: <div>Reson</div>,
      renderItem: (record) => {
        return (
          <>
            {record || "N/A"}
          </>
        )
      }
    },
  ]
  return (
    <div>
      <IconError />
      <div className="import-view__successful__text">
        {data.length} Errors While Importing
      </div>
      <div className="import-view__table__error">
        <DataTable
          data={data}
          columns={columns}
        />
      </div>
      <Button
        onClick={() => {
          onClick()
        }}
        typeClass="second"
        size="sm"
        text="Back To List"
      />
    </div>

  )
}

ErrorImport.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.array
}

ErrorImport.defaultProps = {
  onClick: () => { },
  data: []
}
export default ErrorImport
