
import BaseService from "./baseService";
import rebateCategoryApi from "../api/rebateCategoryApi";
import { getQueryString } from "../utils/common";


class RebateCategoryService extends BaseService {

  addOrUpdateMultipleByRebateId(query, params) {
    const newQuery = getQueryString(query)
    return rebateCategoryApi.addOrUpdateMultipleByRebateId(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new RebateCategoryService();
