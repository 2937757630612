import React from 'react'
import PropTypes from 'prop-types'
import Tab from 'react-bootstrap/Tab'

const TabItem = props => {
  const { eventKey, children, key } = props
  return (
    <Tab.Pane key={key} eventKey={eventKey} unmountOnExit>
      {children}
    </Tab.Pane>
  )
}

TabItem.propTypes = {
  eventKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  key: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.symbol,
  ]),
}

TabItem.defaultProps = {
  children: [],
  key: undefined,
}

export default TabItem
