import get from "lodash/get";

export default class CustomerType {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data)
    }
  }

  init(data) {
    if (!data) return;
    this.id = get(data, 'id', null)
    this.created = get(data, 'created', null)
    this.modified = get(data, 'modified', null)
    this.name = get(data, 'name', null)
  }

  toJSON() {
    const { id, created, modified, name } = this;
    return { id, created, modified, name };
  }
}
