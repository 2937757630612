/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */

import { Scrollbars } from 'react-custom-scrollbars';
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { emptyTable } from '../../assests/images'
import { utilityService } from "../../services"
import { productSelectors } from '../../redux/selectors/productSelectors';
import { DataTable, Loading, EmptyState, Button, Modal } from "../../components/common"
import { pathKeys } from '../../constants'
import { ProgramFilterType } from "../../constants/common";
import { convertExcelToJson, downlLoadFile } from '../../utils/common'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { bulkImportActions } from 'redux/actions';

const pageSize = 50
export const BulkUtilities = (props) => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize
  })
  const { setIsBulk, filterItems, setUtilityItems } = props;
  
  const [total, setTotal] = useState(0)
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const uploadRef = useRef(null);

  function handleFetchDefault(filterData) {
    setLoading(true)
    utilityService.exportFilter(filterData).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setTotal(data.totalEntities)
        setItems(newData)
      } else {
        setItems([])
      }
      setLoading(false)
    })
  }

  const handleFetchUtilities = (filter) => {
    setLoading(true)
    utilityService.exportFilter(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        setItems([...items, ...data.entities])
      }
      setLoading(false)
    })
  }

  function handleExportAll() {
    setLoading(true)
    utilityService.exportAll().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "utilitiesAll")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportBlank() {
    setLoading(true)
    utilityService.exportBlank().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "utilitiesBlank")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportByFilter(filterData) {
    setLoading(true)
    utilityService.exportByFilter(filterData).then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "utilitiesList")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  async function handleImportFile(file) {
    const result = await convertExcelToJson(file)

    const newData = []
    result.forEach(item => {
      newData.push({
        ...item,
        KeepChange: true
      })
    })
    // set data for utility import data
    setUtilityItems(newData)
    navigate(pathKeys.BULK_UTILITIES_IMPORT)
  }

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  useEffect(() => {
    const locations = []
    const categories = []
    const utilities = [];
    const products = [];

    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        locations.push(item.id)
      } else if (item.type === ProgramFilterType.Category) {
        categories.push(item.id)
      } else if (item.type === ProgramFilterType.Product) {
        products.push(item.id)
      }
    })
    const dataBody = {
      skip: 0,
      count: pageSize,
      locations,
      categories,
      utilities,
      products
    }

    setFilter(dataBody)
    handleFetchDefault(dataBody)
  }, [filterItems])

  const columns = [
    {
      key: "Name",
      colSpan: 6,
      sortable: true,
      header: <div>Utility Name</div>,
      renderItem: (record) => {
        return <>{record.name}</>
      }
    },
    {
      key: "WebsiteUrl",
      colSpan: 6,
      sortable: true,
      header: <div>Website URL</div>,
      renderItem: (record) => {
        const { websiteUrl } = record
        return (
          <>
            {websiteUrl ? (
              <div
                role="presentation"
                onClick={() => {
                  const newUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://") ? websiteUrl : `https://${websiteUrl}`
                  window.open(newUrl, '_blank');
                }} className="table-link-page"
              >
                {websiteUrl}

              </div>
            ) : "N/A"}
          </>
        )
      }
    },
    {
      key: "CommercialRate",
      header: <div>Commercial Rate</div>,
      sortable: true,
      colSpan: 6,
      renderItem: (record) => {
        const { commercialRate } = record
        return (
          <>
            {commercialRate || 0}
          </>
        )
      }
    },
    {
      key: "IndustrialRate",
      header: <div>Industrial Rate</div>,
      colSpan: 4,
      sortable: true,
      renderItem: (record) => {
        const { industrialRate } = record
        return (
          <>
            {industrialRate || 0}
          </>
        )
      }
    },
    {
      key: "ResidentialRate",
      header: <div>Residential Rate</div>,
      sortable: true,
      colSpan: 6,
      renderItem: (record) => {

        const { residentialRate } = record
        return (
          <>
            {residentialRate || 0}
          </>
        )
      }
    },

  ];

  return (
    <>
      <Loading loading={loading} />
      <Scrollbars
        onScrollFrame={(values) => {
          const { top } = values
          const { skip, count } = filter
          if (top >= 0.9 && (total >= (skip + 1) * count)) {
            const filterNow = {
              ...filter,
              skip: skip + 1
            }
            setFilter(filterNow)
            const fillterCurrent = {
              ...filterNow,
              skip: (skip + 1) * count
            }
            handleFetchUtilities(fillterCurrent)
          }
        }}
        autoHide
      >
        <div className="bulk">
          <div className="bulk__WrapButton">
            <Button
              onClick={() => {
                setIsShowExportModal(true)
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Export" />

            <Button
              onClick={() => {
                uploadRef.current.click();
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Import" />
            <input
              type="file"
              className="hide"
              ref={uploadRef}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const file = e.target.files[0]
                handleImportFile(file)
              }}
              multiple={false}
            />
          </div>
          <DataTable
            sortable
            data={items}
            columns={columns}
            orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
            onSort={str => {
              if (str) {
                const arrayValue = str.split("-")
                if (arrayValue[0] && arrayValue[1]) {
                  const dataBody = {
                    ...filter,
                    orderField: arrayValue[0],
                    isDesc: arrayValue[1] !== 'asc',
                    skip: 0,
                    count: pageSize,
                  }
                  setFilter(dataBody)
                  handleFetchDefault(dataBody)
                }
              }
            }}
            noDataText={(
              <EmptyState
                className="custom-empty-state-second"
                photo={emptyTable}
                text={() => {
                  return (
                    <>
                      <div className="custom-empty-state-second__title">
                        NO DATA
                      </div>

                    </>
                  );
                }}
              />
            )}
          />
        </div>

      </Scrollbars>

      <Modal
        onHide={() => { setIsShowExportModal(false) }}
        size="md"
        centered
        isHiddenFooter
        show={isShowExportModal}
        title="Export Utilities to CSV"
      >
        <div className="bulk__center">
          Select which utilities to include in the export
        </div >
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                const newFilter = {
                  ...filter,
                  skip: 0,
                  count: total
                }
                handleExportByFilter(newFilter)
              }}
              typeClass="second"
              size="md"
              text="Current Filtered List" />
          </div>
          <div className="col-12 col-md-6 bulk__col">

            <p className="bulk__text">
              Contains all Utilities that match the current filters in the list
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportAll()
              }}
              typeClass="second"
              size="md"
              text="All Utilities" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains all Utilities in RebatesBus
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportBlank()
              }}
              typeClass="second"
              size="md"
              text="Blank Template" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains only the column headers for Utilities
            </p>
          </div>
        </div>
      </Modal >
    </>

  )
}

BulkUtilities.propTypes = {
  setIsBulk: PropTypes.func,
  setUtilityItems: PropTypes.func,
  filterItems: PropTypes.array
}

BulkUtilities.defaultProps = {
  setIsBulk: () => { },
  setUtilityItems: () => { },
  filterItems: []
}

const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUtilityItems: (items) => dispatch(bulkImportActions.changeUtilityImportData(items)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkUtilities)
