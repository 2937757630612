import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import cn from 'classnames'
import { IconSearch, IconDeleteRoles } from "../../../assests/icons"
import { Text } from ".."
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import './style.scss'


const TopLabelSearchMultipleGroup = React.forwardRef((props, inputRef) => {
  const {
    values,
    type,
    label,
    controlId,
    readOnly,
    options,
    onChange,
    onChangeText,
    className,
    size,
    subLabel,
    status,
    message,
    loading,
    placeholder,
    onChangeOne
  } = props
  const [text, setText] = useState("")
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(null);

  const inputProps = {
    as: 'input',
    plaintext: readOnly,
    size
  }

  useEffect(() => {
    function handleClickOutside(event) {
      const dropDownOpen = dropDownRef.current.getAttribute("data-open");
      if (dropDownRef.current && !dropDownRef.current.contains(event.target) && dropDownOpen === 'true') {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  return (
    <Form.Group
      controlId={controlId}
      className={cn('top-label-form-group top-label-search-mutiple-group form-input', {
        readOnly,
      }, status && status, [className])}
    >
      {
        label ? <Form.Label>{label} {subLabel ? <div className="subLabel">{subLabel}</div> : null}</Form.Label> : null
      }
      <div className="top-label-search-mutiple-group__parent" ref={dropDownRef} data-open={isOpen}>
        <div className="top-label-form-group__search" >
          <Form.Control
            ref={inputRef}
            type={type}
            readOnly={readOnly}
            value={text}
            placeholder={placeholder}
            onChange={(e) => {
              const { value } = e.target
              setText(value)
              onChangeText(value)
              setIsOpen(true)
            }}
            {...inputProps}
          />
          <IconSearch className="top-label-form-group__icon" />
        </div>
        {
          options && isOpen ? (
            <>
              {
                options.length ? (
                  <div className="top-label-search-mutiple-group__values top-label-search-mutiple-group__values-second">
                    {options.map(item => {
                      return (
                        <div role="presentation" onClick={() => {
                          onChangeText("")
                          setText("")
                          setIsOpen(false)
                          const index = values.findIndex(el => el.id === item.id)
                          if (index === -1) {
                            values.push(item)
                            onChange(values)
                            if (onChangeOne) {
                              onChangeOne(item)
                            }
                          }
                        }} key={item.id} className="top-label-search-mutiple-group__values__item top-label-search-mutiple-group__values__item-second">
                          {item.name}

                        </div>
                      )
                    })}
                  </div>
                ) : (
                    <div className="top-label-search-mutiple-group__values top-label-search-mutiple-group__values-second">
                      <div role="presentation" onClick={() => {
                        onChangeText("")
                        setText("")
                        setIsOpen(false)
                      }} className="top-label-search-mutiple-group__values__item top-label-search-mutiple-group__values__item-second">
                        {loading ? "Loading ..." : "No Data"}
                      </div>
                    </div>
                  )
              }
            </>
          ) : null
        }
      </div>
      {status && message && <div className="message">{message}</div>}

      {
        values && values.length ? (
          <div className="top-label-search-mutiple-group__values">
            {values.map(item => {
              return (
                <div key={item.id} className="top-label-search-mutiple-group__values__item">
                  <Text as="div" lines={1} popover>
                    {item.name}
                  </Text>
                  <IconDeleteRoles onClick={() => {
                    const newFilter = values.filter(el => el.id !== item.id)
                    onChange(newFilter)
                    if (onChangeOne) {
                      onChangeOne({})
                    }
                  }} />
                </div>
              )
            })}
          </div>
        ) : null
      }
    </Form.Group>
  )
})

TopLabelSearchMultipleGroup.displayName = "TopLabelSearchMultipleGroup";

TopLabelSearchMultipleGroup.propTypes = {
  controlId: PropTypes.string,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  values: PropTypes.array,
  type: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  subLabel: PropTypes.string,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null]),
  message: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onChangeOne: PropTypes.func,
}

TopLabelSearchMultipleGroup.defaultProps = {
  onChange: () => { },
  onChangeOne: () => { },
  values: [],
  type: "string",
  label: undefined,
  readOnly: false,
  className: undefined,
  size: 'md',
  subLabel: null,
  status: null,
  message: null,
  placeholder: null,
  options: [],
  onChangeText: () => { },
  loading: false
}

export default TopLabelSearchMultipleGroup
