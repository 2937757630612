/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from "../../components/common"
import { IconSuccessful, } from "../../assests/icons"

export const Successful = ({ onClick }) => {

  return (
    <div>

      <IconSuccessful />
      <div className="import-view__successful__text">
        Import Successful!
          </div>
      <Button
        onClick={() => {
          onClick()
        }}
        typeClass="second"
        size="sm"
        text="Back To List" />
    </div>

  )
}

Successful.propTypes = {
  onClick: PropTypes.func,
}

Successful.defaultProps = {
  onClick: () => { },
}
export default Successful
