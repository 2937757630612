/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { IconSearch, IconArrowDown, IconArrowUp } from "../../../assests/icons";
import './style.scss'
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { ProgramFilterType } from "constants/common";

const Skeleton = () => {
  return(<div className="color-div"/>)
}

const SectionItem = (props) => {
  const { data, title, onSelectRow, isLoading, showState } = props;
  const [open, setOpen] = useState(true);

  const handleSelectResult = (itemRow) => {
    // trigger select row
    onSelectRow(itemRow);
  }
  
  return (
    <Row>
      <Card.Body>
        <Row>
          <Col>
            <Card.Title className="pl-2">
              {title}
            </Card.Title>
          </Col>
          <Col>
            <span className="pr-2" style={{'float': 'right', 'cursor': 'pointer'}} onMouseDown={(e) => {
              setOpen(!open);
              e.preventDefault();
            }}>
              {
                open ? <IconArrowUp/> : <IconArrowDown/> 
              }
            </span>
          </Col>
        </Row>
        <div className="title-under-line mb-3"/>
        <Collapse in={open}>
          <Col className="scroll-section pl-4">
            {
              !!isLoading ? (
                [...Array(5).keys()].map((item) => {
                  return <Skeleton key={item} />
                })
              ) : (
                (data && data.length > 0) ? (
                  data.map((item, index) => {
                    let label = item.label;
                    if (!!showState && item.state) {
                      label = `${item.label}, ${item.state}`
                    }
                    return (
                      <Row key={`${title}-${index}`} className="pt-1 pb-1 mb-0 mr-0 pointer-cursor result-row" onMouseDown={() => handleSelectResult(item)}>
                        {
                          <span>
                            {label}
                          </span>
                        }
                      </Row>
                    )
                  })
                ) : (
                  <Row key={`${title}-notfound`} className="pt-1 pb-1 mb-0 mr-0 default-cursor" onMouseDown={(e) => e.preventDefault()}>
                    No matching {title} found
                  </Row>
                )
              )
            }
          </Col>
        </Collapse>
      </Card.Body>
    </Row>
  )
}

const SearchInput = (props) => {
  const { className, setIsBlurProps, searchData, isLoading, sectionLabels, footerText } = props;
  const [isBlur, setIsBlur] = useState(true)
  const [isInputing, setIsInputing] = useState(false)

  useEffect(() => {
    if (setIsBlurProps) {
      setIsBlurProps(isBlur)
    }
  }, [isBlur, setIsBlurProps])

  const handleSelectRow = (type, item) => {
    setIsBlur(true)
    props.onChange(item);
  }

  const footerTextElement = (footerText) => {
    if (!footerText) {
      return null;
    }
    return (
      <Card.Footer className="text-center default-cursor">
        <span className="footer-text">
          {
            footerText
          }
        </span>
      </Card.Footer>
    )
  }

  return (
    <div id="searchArea" 
      className="searchInput-parent" 
      onBlur={() => setIsBlur(true)}
    >
      <Row id="inputSearchArea" className={`${!isBlur && "searchInput-long"} input-search-area`}>
        <IconSearch className="searchInput__control_icon" />
        <input className={`flex-all input-search-text`}
          onFocus={() => setIsBlur(false)}
          placeholder="search"
          onChange={(e) => {
            setIsBlur(false)
            if (e.target.value) {
              setIsInputing(true)
            } else {
              setIsInputing(false)
            }
            props.onInputChange(e.target.value)
          }}/> 
      </Row>
      {
        !isBlur && (
          isInputing ? (
            // show search result
            <Card id="resultSearchArea" className="searchInput-long result-item" onMouseDown={(e) => e.preventDefault()}>
              <SectionItem 
                title={sectionLabels && sectionLabels.find(x => x.item === ProgramFilterType.County)?.label}
                isLoading={isLoading} 
                data={searchData?.county || []} 
                onSelectRow={(item) => handleSelectRow(ProgramFilterType.County, item)}/>

              <SectionItem 
                title={sectionLabels && sectionLabels.find(x => x.item === ProgramFilterType.Utility)?.label}
                showState isLoading={isLoading} 
                data={searchData?.utility || []} 
                onSelectRow={(item) => handleSelectRow(ProgramFilterType.Utility, item)}/>

              <SectionItem 
                title={sectionLabels && sectionLabels.find(x => x.item === ProgramFilterType.Product)?.label}
                isLoading={isLoading} 
                data={searchData?.product || []}
                onSelectRow={(item) => handleSelectRow(ProgramFilterType.Product, item)}/>
                
              {
                footerTextElement(footerText)
              }
            </Card>
          ) : (
            // show history ony
            <Card id="historySearchArea" className="searchInput-long result-item" onMouseDown={(e) => e.preventDefault()} >
              <SectionItem 
                title={sectionLabels && sectionLabels.find(x => x.item === 'history').label}
                data={searchData?.history || []} 
                isLoading={isLoading} 
                onSelectRow={(item) => handleSelectRow("history", item)}
              />
              {
                footerTextElement(footerText)
              }
            </Card>
          )
        )
      }
    </div>
  );
};

export default SearchInput;
