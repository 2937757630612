/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* istanbul ignore file */
import React, { useEffect, useState } from "react";
import queryString from 'query-string'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { RebatesMap } from "../index"
import { common, pathKeys } from '../../constants'
import { EmptyState } from "../../components/common"
import { authenticationService } from "../../services"

import { appActions } from "../../redux/actions"
import { useSearchParams } from "react-router-dom";

export const EmbedView = (props) => {
  const [isValid, setIsValid] = useState(false)
  const [isDefault, setIsDefault] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams();
  const { showLoading, hideLoading } = props
  useEffect(() => {
    const token = searchParams.get("token");
    const header = {
      ...((token && token[0]) && {Authorization: `Bearer ${token}`})
    }
    showLoading()
    authenticationService.checkEmbedAuthentication(header).then(res => {
      const { success } = res
      if (success) {
        setIsValid(true)
      } else {
        setIsValid(false)
      }
      hideLoading()
      setIsDefault(false)
    })
  }, [])
  

  return (
    <div>
      {
        isDefault ? null : (
          <>
            {isValid ?
              (<RebatesMap pathUrl={pathKeys.EMBED_UTILITY} {...props} />) :
              (
                <EmptyState
                  className="custom-empty-state-second"
                  text={() => {
                    return (
                      <>
                        <div className="custom-empty-state-second__title">
                          YOUR KEY IS INVALID OR EXPIRED
                         </div>

                        <a className="custom-empty-state-second__link" href="mailto:support@rebatebus.com">
                          Please contact our team to request a new key
                      </a>
                      </>
                    );
                  }}
                />
              )}
          </>
        )
      }
    </div>
  );
};

EmbedView.propTypes = {
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  location: PropTypes.object,
}

EmbedView.defaultProps = {
  hideLoading: () => { },
  showLoading: () => { },
  location: {}
}

const mapDispatchToProps = (dispatch) => ({
  showLoading: () => dispatch(appActions.showLoading()),
  hideLoading: () => dispatch(appActions.hideLoading()),
})

export default connect(null, mapDispatchToProps)(EmbedView)

