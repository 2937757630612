import BaseApi from "./baseApi";

class BillingApi extends BaseApi {
  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.billing.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

}

export default new BillingApi();
