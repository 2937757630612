/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { roleService } from "../../services"
import { DataTable, Text, Pagination, TopFilterTable, Modal, Button, TopLabelFormGroup, TopLabelDropDownGroup, Loading } from "../../components/common"
import { IconDeleteRoles } from "../../assests/icons"
import { BOOTSTRAP_VARIANTS as Status } from "../../constants/common";

const pageSize = 10
const OPTIONS = [
  {
    value: "Administrator",
    label: "Administrator"
  },
  {
    value: "Support",
    label: "Support"
  },
  {
    value: "User",
    label: "User"
  }
]
export const Roles = ({ handleGetAllRoles }) => {
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize,

  })
  const [isShowModalDelete, setShowModalDetelte] = useState(false)
  const [isShowModalEdit, setShowModalEdit] = useState(false)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [objectError, setObjectError] = useState({})
  const [dataItem, setDataItem] = useState({})

  function handleOnChange(name, value) {
    setDataItem({
      ...dataItem,
      [name]: value
    })

    delete objectError[name]
    setObjectError({
      ...objectError
    })
  }

  function handleValidate() {
    let isPass = true
    const message = "This field not allow empty"
    if (!dataItem.displayName || dataItem.displayName === "") {
      objectError.displayName = message
      isPass = false
    }

    if (!dataItem.roleType || dataItem.roleType === "" || !dataItem.roleType.length) {
      objectError.roleType = message
      isPass = false
    }

    if (!isPass) {
      setObjectError({
        ...objectError
      })
    } else {
      setObjectError({})
    }

    return isPass
  }

  function handleFetchDefault(filterData) {
    setLoading(true)
    const newFilter = {}
    Object.keys(filterData).forEach(key => {
      if ((filterData[key] && filterData[key] !== '') || key === "isDesc") {
        newFilter[key] = filterData[key]
      }
    })
    if (newFilter.skip) {
      newFilter.skip *= newFilter.count
    } else {
      newFilter.skip = 0
    }
    roleService.getList(newFilter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;

        setItems(newData)
        setTotal(data.totalEntities)
      }
      setLoading(false)
    })
  }

  function handleAddRole() {
    if (handleValidate()) {
      const newData = {
        displayName: dataItem.displayName,
        roleType: dataItem.roleType && dataItem.roleType[0] ? dataItem.roleType[0].value : "",
      }

      roleService.create(newData).then(res => {
        const { success } = res
        if (success) {
          handleFetchDefault({ ...filter, skip: 0 })
          setShowModalEdit(false)
          toastr.success("Role creation successfully")
          if (handleGetAllRoles) {
            handleGetAllRoles()
          }
        } else {
          toastr.error("Actions failed please try again!")
        }

      })
    }

  }

  function handleDeleteRole() {
    if (dataItem.id) {
      const newData = {
        id: dataItem.id
      }
      roleService.delete(newData).then(res => {
        const { success } = res
        if (success) {
          handleFetchDefault(filter)
          setShowModalDetelte(false)
          toastr.success("Role remove successfully")
          if (handleGetAllRoles) {
            handleGetAllRoles()
          }
        } else {
          toastr.error("Actions failed please try again!")
        }
      })
    }
  }

  function handlePageSizeChange(pageSize) {
    const newFilter = {
      skip: 0,
      count: pageSize,
    }
    setFilter(newFilter)
    handleFetchDefault(newFilter)
  }

  function handlePageChange(page) {
    const newFilter = {
      ...filter,
      skip: page - 1,
    }
    setFilter(newFilter)

    handleFetchDefault(newFilter)
  }

  useEffect(() => {
    handleFetchDefault(filter)
  }, [])


  const columns = [
    {
      key: "DisplayName",
      header: <div>{t("roles.user")}</div>,
      colSpan: 6,
      sortable: true,
      renderItem: (record) => {
        const { displayName } = record
        return (
          <div className="Roles__contain__user">

            <Text className="table-tootip-float Roles__contain__user__role" as="div" lines={1} popover>
              {displayName}
            </Text>
          </div>
        )
      }
    },

    {
      key: "RoleType",
      header: <div>{t("roles.roles_type")}</div>,
      sortable: true,
      colSpan: 6,
      renderItem: (record) => {
        const { roleType } = record
        return (
          <div >
            {roleType}
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("roles.actions")}</div>,
      colSpan: 2,
      renderItem: (record) => {
        return (
          <div className="table-groupIcon">
            <IconDeleteRoles onClick={() => { setShowModalDetelte(true); setDataItem(record); setObjectError({}) }} className="Roles__contain__delete" />
          </div>
        )
      }
    }
  ];


  return (
    <>
      <Loading loading={loading} />
      <div className="Roles__contain__subTitle Roles__contain__subTitle-second">
        {t("roles.role")}
        <Button
          onClick={
            () => {
              setShowModalEdit(true);
              setObjectError({});
              setDataItem({})
            }}
          className="Roles__contain__button"
          typeClass="second"
          size="sm"
          text="Add Role"
        />
      </div>
      <DataTable
        sortable
        noDataText="No data"
        data={items}
        columns={columns}
        orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
        onSort={str => {
          if (str) {
            const arrayValue = str.split("-")
            if (arrayValue[0] && arrayValue[1]) {
              const dataBody = {
                ...filter,
                orderField: arrayValue[0],
                isDesc: arrayValue[1] !== 'asc',
                skip: 0,
                count: pageSize,
              }
              setFilter(dataBody)
              handleFetchDefault(dataBody)
            }
          }
        }}
        topChildren={(
          <TopFilterTable
            pageSize={filter.count}
            onPageSizeChange={(value) => { handlePageSizeChange(value) }}
            onClick={() => { setShowModalEdit(true) }}
          />
        )}
      >
        <Pagination data={
          {
            totalItems: total,
            pageSize: filter.count,
            currentPage: filter.skip + 1
          }
        }
          onPageChange={(value) => {
            handlePageChange(value)
          }}
        />
      </DataTable>
      <Modal onHide={() => { setShowModalDetelte(false) }} handleOnSave={() => { handleDeleteRole() }} size="md" centered show={isShowModalDelete} textSave="Yes" title="Remove Role">
        <div>
          Are you sure you want to remove this role ?
           </div>
      </Modal>
      <Modal onHide={() => { setShowModalEdit(false) }} handleOnSave={() => { handleAddRole() }} size="md" centered show={isShowModalEdit} title="Create Role">
        <div>
          <TopLabelFormGroup
            label={t("roles.name")}
            controlId="displayName"
            onChange={(name, value) => { handleOnChange(name, value) }}
            isTextArea={false}
            value={dataItem.displayName || ""}
            status={objectError.displayName ? Status.DANGER : null}
            message={objectError.displayName}
          />
          < TopLabelDropDownGroup
            controlId="roleType"
            className="Roles__form-group"
            label={t("roles.role_type")}
            placeholder={`- ${t("roles.role_type")} -`}
            value={dataItem.roleType || []}
            status={objectError.roleType ? Status.DANGER : ""}
            message={objectError.roleType}
            onChange={(value) => {
              handleOnChange("roleType", [{ ...value }])
            }}
            options={OPTIONS}
          />
        </div>
      </Modal>
    </>
  )
}

Roles.propTypes = {
  handleGetAllRoles: PropTypes.func
}

Roles.defaultProps = {
  handleGetAllRoles: () => { }
}

export default Roles
