import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import {
  Active,
  Closed,
  Upcoming
} from "../../../constants/common";
import "./style.scss";

const Status = {
  active: Active,
  closed: Closed,
  upcoming: Upcoming,
};

const StatusText = ({ children, className, status, size }) => {
  const wrapperClasses = cn("status-text", Status[status] && Status[status], [
    className,
    size
  ]);

  return <span className={wrapperClasses}>{children}</span>;
};

StatusText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.string,
};

StatusText.defaultProps = {
  children: "",
  className: "",
  status: "active",
  size: 'sm'
};

export default StatusText;
