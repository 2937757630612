/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { toastr } from 'react-redux-toastr'
import moment from "moment";
import { Roles } from "./role";
import { handleRenderArrayText, validateEmail } from "../../utils/common"
import { BOOTSTRAP_VARIANTS as Status, Administrator } from "../../constants/common";
import { userService, roleService } from "../../services"
import { DataTable, Loading, Text, Pagination, TopFilterTable, Avatar, Modal, TopLabelFormGroup, TopLabelDropDownGroup, DatePicker } from "../../components/common"
import { IconEditRoles, IconCalendarGray } from "../../assests/icons"
import './style.scss'

const pageSize = 10

export const Users = () => {
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize,
    SearchQuery: ''
  })
  const [isShowModalExpireTime, setShowModalExpireTime] = useState(false)
  const [isShowModalEdit, setShowModalEdit] = useState(false)
  const [items, setItems] = useState([])
  const [roles, setRoles] = useState([])
  const [dataItem, setDataItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [objectError, setObjectError] = useState({})
  let mySearch = null

  function handleGetRoles(filterData) {
    roleService.getList(filterData).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setRoles(newData)
      }
    })
  }

  function handleFetchDefault(filterData, isSearch) {
    if (!isSearch) {
      setLoading(true)
    }
    const newFilter = {}
    Object.keys(filterData).forEach(key => {
      if ((filterData[key] && filterData[key] !== '') || key === "isDesc") {
        newFilter[key] = filterData[key]
      }
    })
    if (newFilter.skip) {
      newFilter.skip *= newFilter.count
    } else {
      newFilter.skip = 0
    }

    userService.getList(newFilter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;

        setItems(newData)
        setTotal(data.totalEntities)
      }
      setLoading(false)
    })
  }



  function handlePageSizeChange(pageSize) {
    const newFilter = {
      skip: 0,
      count: pageSize,
      locations: [],
      categories: [],
      utilities: []
    }
    setFilter(newFilter)
    handleFetchDefault(newFilter)
  }

  function handlePageChange(page) {
    const newFilter = {
      ...filter,
      skip: page - 1,
    }
    setFilter(newFilter)

    handleFetchDefault(newFilter)
  }

  function handleOnChange(name, value) {
    setDataItem({
      ...dataItem,
      [name]: value
    })

    delete objectError[name]
    setObjectError({
      ...objectError
    })
  }

  function handleValidate() {
    let isPass = true
    const message = "This field not allow empty"
    if (!dataItem.fullName || dataItem.fullName === "") {
      objectError.fullName = message
      isPass = false
    }

    if (!dataItem.emailAddress || dataItem.emailAddress === "") {
      objectError.emailAddress = message
      isPass = false
    } else if (dataItem.emailAddress && !validateEmail(dataItem.emailAddress)) {
      objectError.emailAddress = "Invalid email"
      isPass = false
    }

    if (!isPass) {
      setObjectError({
        ...objectError
      })
    } else {
      setObjectError({})
    }

    return isPass
  }

  function handleAssignMultipleRoles(data, roles, isAdd) {
    const query = {
      userId: data.id
    }
    const newData = []
    roles.forEach(item => {
      newData.push(item.id)
    })

    userService.assignMultipleRoles(query, newData).then(res => {
      const { success } = res
      if (!success) {
        toastr.error("Actions assign role failed please try again!")
      }
      if (isAdd) {
        handleFetchDefault({ ...filter, skip: 0 })
      } else {
        handleFetchDefault(filter)
      }

    })

  }


  function handleAddUser() {
    const newData = {
      emailAddress: dataItem.emailAddress,
      emailVerified: "true",
      fullName: dataItem.fullName,
      company: ""
    }
    userService.create(newData).then(res => {
      const { success, data } = res
      if (success && data && data.id) {
        setShowModalEdit(false)
        handleAssignMultipleRoles(data, dataItem.roles, true)
        toastr.success("User creation successfully")
      } else {
        toastr.error("Actions failed please try again!")
      }
    })
  }

  function handleSetTrialExpireDate() {
    const newData = {
      trialExpireDate: dataItem.trialExpireDate || new Date().toISOString(),
      userId: dataItem.id,
    }
    userService.setPremiumExpiration(newData).then(res => {
      const { success } = res
      if (success) {
        setShowModalExpireTime(false)
        handleFetchDefault(filter)
        toastr.success("Update expire date successfully")
      } else {
        toastr.error("Update expire date failed please try again!")
      }
    })
  }



  function handleUpdateUser() {
    const query = {
      id: dataItem.id,
    }
    const newData = {
      emailAddress: dataItem.emailAddress,
      emailVerified: "true",
      fullName: dataItem.fullName,
      company: dataItem.company || "",
      phoneNumber: dataItem.company || "",
      emailNewProduct: dataItem.emailNewProduct,
      emailWeeklyUpdate: dataItem.emailWeeklyUpdate,
      emailProductSurveys: dataItem.emailProductSurveys,
      productUsabilityStudies: dataItem.productUsabilityStudies
    }
    userService.update(query, newData).then(res => {
      const { success } = res
      if (success) {
        handleAssignMultipleRoles(query, dataItem.roles)
        toastr.success("Actions successfully")
        setShowModalEdit(false)
      } else {
        toastr.error("Actions failed please try again!")
      }

    })
  }

  function handleOnSaveModal() {
    if (handleValidate()) {
      if (dataItem.id) {
        handleUpdateUser()
      } else {
        handleAddUser()
      }
    }
  }

  useEffect(() => {
    handleFetchDefault(filter)
    handleGetRoles({
      skip: 0,
      count: 1000
    })
  }, [])


  const columns = [
    {
      key: "fullName",
      header: <div>{t("roles.user")}</div>,
      colSpan: 6,
      sortable: true,
      renderItem: (record) => {
        const { fullName } = record

        return (
          <div className="Roles__contain__user">
            <Avatar src={null} size="sm" />
            <Text className="table-tootip-float Roles__contain__user__name" as="div" lines={1} popover>
              {fullName && fullName !== "" && fullName !== " " ? fullName : "N/A"}
            </Text>
          </div>
        )
      }
    },
    {
      key: "emailAddress",
      header: <div>{t("roles.email")}</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { emailAddress = '' } = record
        return (
          <>
            {emailAddress}
          </>
        )
      }
    },
    {
      key: "displayName",
      header: <div>{t("roles.roles")}</div>,
      sortable: true,
      colSpan: 6,
      renderItem: (record) => {
        const { roles } = record
        return (
          <div >
            {handleRenderArrayText(roles, "displayName")}
          </div>
        )
      }
    },
    {
      key: "trialExpireDate",
      header: <div>{t("roles.expireDate")}</div>,
      colSpan: 6,
      renderItem: (record) => {
        const { roles } = record
        const index = roles.findIndex(item => item.roleType === Administrator)

        return (
          <div >
            {
              index === -1 ? (
                <>
                  {record.trialExpireDate ? moment(record.trialExpireDate).format("YYYY-MM-DD hh:mm:ss") : "N/A"}
                </>
              ) : <div className="roles__infinite"> ∞</div>
            }
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("roles.actions")}</div>,
      colSpan: 2,
      renderItem: (record) => {
        const { roles } = record
        const index = roles.findIndex(item => item.roleType === Administrator)
        return (
          <div className="table-groupIcon">
            {
              index === -1 ? (
                <IconCalendarGray style={{ marginRight: "5px" }} onClick={() => {
                  setDataItem(record);
                  setShowModalExpireTime(true)
                }} />
              ) : null
            }
            <IconEditRoles onClick={() => { setDataItem(record); setShowModalEdit(true); setObjectError({}) }} />


          </div>
        )
      }
    }
  ];

  return (

    <div className="Roles">
      <Loading loading={loading} />
      <div className="Roles__contain">
        <div className="Roles__contain__title">
          {t("roles.title")}
        </div>
        <div className="Roles__contain__subTitle">
          {t("roles.user")}
        </div>

        <DataTable
          sortable
          noDataText="No data"
          data={items}
          columns={columns}
          orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
          onSort={str => {
            if (str) {
              const arrayValue = str.split("-")
              if (arrayValue[0] && arrayValue[1]) {
                const dataBody = {
                  ...filter,
                  orderField: arrayValue[0],
                  isDesc: arrayValue[1] !== 'asc',
                  skip: 0,
                  count: pageSize,
                }
                setFilter(dataBody)
                handleFetchDefault(dataBody)
              }
            }
          }}
          topChildren={(
            <TopFilterTable
              isSearch
              isAdd
              searchText={filter.SearchQuery}
              pageSize={filter.count}
              onPageSizeChange={(value) => { handlePageSizeChange(value) }}
              onClick={() => { setDataItem({}); setShowModalEdit(true); setObjectError({}) }}
              onSearch={(value) => {
                const newFilter = {
                  ...filter,
                  skip: 0,
                  SearchQuery: value,
                }
                setFilter(newFilter)
                if (mySearch) {
                  clearTimeout(mySearch)
                }

                mySearch = setTimeout(() => {
                  handleFetchDefault(newFilter, true)
                }, 1000)
              }}
            />
          )}
        >
          <Pagination data={
            {
              totalItems: total,
              pageSize: filter.count,
              currentPage: filter.skip + 1
            }
          }
            onPageChange={(value) => {
              handlePageChange(value)
            }}
          />
        </DataTable>
        <Roles handleGetAllRoles={handleGetRoles} />
        <Modal
          onHide={() => { setShowModalExpireTime(false) }}
          handleOnSave={() => {
            handleSetTrialExpireDate()
          }}
          size="md"
          centered
          show={isShowModalExpireTime}
          title="Update Expiration"
        >
          <div>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dataItem.trialExpireDate ? new Date(dataItem.trialExpireDate) : new Date()}
              onChange={(date) => {

                setDataItem({
                  ...dataItem,
                  trialExpireDate: date.toISOString()
                })
              }}
            />
          </div>
        </Modal>
        <Modal
          onHide={() => { setShowModalEdit(false) }}
          handleOnSave={() => {
            handleOnSaveModal()
          }}
          size="md"
          centered
          show={isShowModalEdit}
          title={`${dataItem.id ? "Update" : "Create"} User`}
        >
          <div>
            <TopLabelFormGroup
              label={t("roles.name")}
              controlId="fullName"
              placeholder="Silvain Halstead"
              value={dataItem.fullName || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
              status={objectError.fullName ? Status.DANGER : null}
              message={objectError.fullName}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("roles.email")}
              placeholder="bgallemore6@boston.com"
              controlId="emailAddress"
              value={dataItem.emailAddress || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
              status={objectError.emailAddress ? Status.DANGER : null}
              message={objectError.emailAddress}
            />
            <TopLabelDropDownGroup
              className="Roles__form-group"
              label={t("roles.roles_type")}
              placeholder="Adminstrator"
              value={dataItem.roles}
              onChange={(value) => {
                if (!dataItem.roles) {
                  dataItem.roles = []
                }
                const index = dataItem.roles.findIndex(item => item.id === value.id)

                if (index === -1) {
                  dataItem.roles.push(value)
                } else {
                  dataItem.roles = dataItem.roles.filter(item => item.id !== value.id)
                }

                setDataItem({
                  ...dataItem
                })
              }}
              textLabel="displayName"
              textValue="id"
              isChecked
              options={roles}
            />
          </div>
        </Modal>
      </div>
    </div>

  )
}

Users.propTypes = {

}

Users.defaultProps = {


}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = () => ({


})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
