import {
  Washington,
  Idaho,
  Oregon,
  Montana,
  NorthDakota,
  Minnesota,
  Wisconsin,
  Michigan,
  Wyoming,
  SouthDakota,
  Iowa,
  Nebraska,
  Illinois,
  Indiana,
  Ohio,
  Pennsylvania,
  NewYork,
  Vermont,
  NewHampshire,
  Maine,
  Massachusetts,
  Connecticut,
  RhodeIsland,
  NewJersey,
  Maryland,
  Virginia,
  WestVirginia,
  Kentucky,
  Missouri,
  Kansas,
  Colorado,
  Utah,
  Nevada,
  California,
  Arizona,
  NewMexico,
  Oklahoma,
  Texas,
  Louisiana,
  Mississippi,
  Tennessee,
  Alabama,
  NorthCarolina,
  SouthCarolina,
  Georgia,
  Florida,
  Arkansas,
  Delaware,
  DistrictOfColumbia,
  Hawaii,
  Alaska
} from "../assests/state";

export const IconsStates = {
  "Washington": Washington,
  "Idaho": Idaho,
  "Oregon": Oregon,
  "Montana": Montana,
  "North Dakota": NorthDakota,
  "Minnesota": Minnesota,
  "Wisconsin": Wisconsin,
  "Michigan": Michigan,
  "Wyoming": Wyoming,
  "South Dakota": SouthDakota,
  "Iowa": Iowa,
  "Nebraska": Nebraska,
  "Illinois": Illinois,
  "Indiana": Indiana,
  "Ohio": Ohio,
  "Pennsylvania": Pennsylvania,
  "New York": NewYork,
  "Vermont": Vermont,
  "New Hampshire": NewHampshire,
  "Maine": Maine,
  "Massachusetts": Massachusetts,
  "Connecticut": Connecticut,
  "Rhode Island": RhodeIsland,
  "New Jersey": NewJersey,
  "Maryland": Maryland,
  "Virginia": Virginia,
  "WestVirginia": WestVirginia,
  "Kentucky": Kentucky,
  "Missouri": Missouri,
  "Kansas": Kansas,
  "Colorado": Colorado,
  "Utah": Utah,
  "Nevada": Nevada,
  "California": California,
  "Arizona": Arizona,
  "New Mexico": NewMexico,
  "Oklahoma": Oklahoma,
  "Texas": Texas,
  "Louisiana": Louisiana,
  "Mississippi": Mississippi,
  "Tennessee": Tennessee,
  "Alabama": Alabama,
  "North Carolina": NorthCarolina,
  "South Carolina": SouthCarolina,
  "Georgia": Georgia,
  "Florida": Florida,
  "Arkansas": Arkansas,
  "Delaware": Delaware,
  "District of Columbia": DistrictOfColumbia,
  "Hawaii": Hawaii,
  "Alaska": Alaska
};
