/* istanbul ignore file */
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { reducer as toastrReducer } from 'react-redux-toastr'

import loadingReducer from './loadingReducer'
import localeReducer from './localeReducer'
import productsReducer from './productsReducer'
import dropDownReducer from './dropDownReducer'
import programsReducer from './programsReducer'
import profileReducer from './profileReducer'
import bulkImportReducer from './bulkImportReducer'

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    toastrReducer,
    localeReducer,
    loadingReducer,
    productsReducer,
    dropDownReducer,
    programsReducer,
    profileReducer,
    bulkImportReducer
  })

export default rootReducer
