/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Modal from 'react-bootstrap/Modal'

import { Button } from '..'
import { common } from '../../../constants'
import './style.scss'

const ConfirmModal = (props) => {
  const {
    show,
    centered,
    className,
    title,
    message,
    confirmText,
    denyText,
    onConfirm,
    onDeny,
    onHide,
    loading,
    denyButtonVariant,
    confirmButtonVariant,
    size,
    ...bootstrapModalProps
  } = props
  const customClasses = cn('custom-confirm-modal', `confirm-modal-${size}`, [className])

  const handleConfirm = () => {
    typeof onConfirm === 'function' && onConfirm()
  }
  const handleDeny = () => {
    typeof onDeny === 'function' && onDeny()
  }

  return (
    <Modal
      className={customClasses}
      show={show}
      size={size}
      centered={centered}
      {...bootstrapModalProps}
      onHide={onHide}
      test-id="confirm-modal"
    >
      <Modal.Header className="confirm-modal-header">
        <Modal.Title
          test-id="confirm-modal-title"
          className="modal-text-title"
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        test-id="confirm-modal-message"
        className="modal-text-message"
      >
        {message}
      </Modal.Body>
      <Modal.Footer className="confirm-modal-footer">
        <Button
          variant={denyButtonVariant}
          onClick={handleDeny}
          text={denyText}
          disabled={loading}
          test-id="modal-deny-button"
        />
        <Button
          variant={confirmButtonVariant}
          onClick={handleConfirm}
          text={confirmText}
          loading={loading}
          test-id="modal-confirm-button"
        />
      </Modal.Footer>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  denyText: PropTypes.string,
  onConfirm: PropTypes.func,
  onDeny: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  centered: PropTypes.bool,
  denyButtonVariant: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  size: PropTypes.oneOf([
    'sm', 'lg', 'xl'
  ]),
}

ConfirmModal.defaultProps = {
  className: '',
  loading: false,
  show: false,
  centered: false,
  title: '',
  message: '',
  confirmText: '',
  denyText: '',
  onConfirm: null,
  onDeny: null,
  onHide: null,
  denyButtonVariant: common.BOOTSTRAP_VARIANTS.OUTLINE_LIGHT,
  confirmButtonVariant: common.BOOTSTRAP_VARIANTS.LIGHT_BLUE,
  size: 'sm',
}

export default ConfirmModal
