import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

const Select = props => {
  const {
    size, items, disabledValues, placeholder, value, 
    canSelectNull, onChange, labelKey, valueKey
  } = props

  const handleSelect = e => onChange(e.target.value)

  return (
    <Form.Control as="select" value={value} size={size} onChange={handleSelect}>
      { placeholder && <option value="" disabled={!canSelectNull}>{placeholder}</option> }
      {
        (items || []).map(item => {
          let val = item;
          let name = item;

          if (typeof item === 'object') {
            val = item[valueKey];
            name = item[labelKey];
          }

          const disabled = disabledValues.indexOf(val) >= 0;

          return <option key={val} disabled={disabled} value={val}>{name}</option>
        })
      }
    </Form.Control>
  )
}

Select.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  items: PropTypes.array,
  disabledValues: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  canSelectNull: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string
}

Select.defaultProps = {
  items: [],
  disabledValues: [],
  placeholder: '',
  value: '',
  size: 'sm',
  canSelectNull: true,
  labelKey: 'name',
  valueKey: 'id'
}

export default Select
