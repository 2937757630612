import BaseApi from "./baseApi";

class RequirementApi extends BaseApi {
  getAll(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.requirement.getAll}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

}

export default new RequirementApi();
