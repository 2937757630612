import React from 'react'
import PropTypes from 'prop-types'
import { IconCheckbox, IconCheckboxChecked, IconCheckParent } from '../../../assests/icons'
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import './style.scss'

const Checkbox = props => {
  const {
    onClick,
    checked,
    children,
    disabled,
    isFull,
    status
  } = props

  const handleCheckBoxClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    typeof onClick === 'function' && !disabled && onClick()
  }

  return (
    <div className={`checkbox ${status}`} role="button" onClick={handleCheckBoxClick} data-testid="test-checkbox">
      {!checked && <IconCheckbox />}
      {checked ? <>{isFull ? <IconCheckboxChecked /> : <IconCheckParent />}</> : null}
      {children || null}
    </div>
  )
}

Checkbox.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isFull: PropTypes.bool,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null, "", ``]),
}

Checkbox.defaultProps = {
  children: null,
  checked: false,
  disabled: false,
  onClick: undefined,
  isFull: true,
  status: ""
}

export default Checkbox
