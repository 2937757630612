import get from "lodash/get";
import head from "lodash/head";
import Rebate from "./Rebate";
import CustomerType from "./CustomerType";
import Utility from "./Utility";
import Program from "./Program";
import Category from "./Category";

export default class ProgramFilter {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data);
    }
  }

  init(data) {
    if (!data) return;

    this.id = get(data, "programId", "string", null);
    this.created = get(data, "created", null);
    this.modified = get(data, "modified", null);
    this.program = new Program(get(data, "program", null));
    const rebates = get(data, "program.rebates", [])
    this.rebates = rebates.map((r) => new Rebate(r));
    this.tmpCategories = []
    rebates.forEach(item => {
      if (item.categories && Array.isArray(item.categories)) {
        item.categories.forEach(el => {
          const index = this.tmpCategories.findIndex(el2 => el2.id === el.id)
          if (index === -1) {
            this.tmpCategories.push(el)
          }
        })
      }
    })

    this.categories = this.tmpCategories.map((r) => new Category(r));
    this.customerTypes = get(data, "program.programCustomerTypes", []).map(
      (c) => new CustomerType(c)
    );
    this.utility = new Utility(get(data, "utility", {}));
    this.state = get(head(get(data, "utility.counties", [])), "state", null);
  }

  toJSON() {
    const { id, created, modified, state } = this;

    return {
      id,
      created,
      modified,
      key: {
        program: this.program.toJSON(),
        state,
        utility: this.utility.toJSON(),
      },
      categories: this.categories,
      items: this.rebates.map((r) => ({
        rebate: r.toJSON(),
      })),
    };
  }
}
