/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore file */
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'
import cn from 'classnames'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import {
  LoginView,
  NotFoundView,
  SavedItems,
  Signup,
  RebatesMap,
  MyProfile,
  TableView,
  CardView,
  Roles,
  CategoryManagement,
  EmbedView,
  UtilityView,
  ProgramView,
  RebatesView,
  BulkUtilities,
  BulkPrograms,
  BulkRebates,
  ImportUtilities,
  ImportPrograms,
  ImportRebates,
  ApplicationUrlView,
  TrialView
} from '.'
import { Header, LeftMenu, Loading } from '../components/common'
import { pathKeys, common } from '../constants'
import { useAuth0, setToken } from '../components/Auth0'
import { appSelectors } from '../redux/selectors/appSelectors'
import { handleGetArraychild, checkAuthorize } from '../utils/common'
import { dropDownActions, productionActions } from "../redux/actions";
import { coreService, categoryService, utilityService } from "../services"
import i18next from '../translations/i18next'
import Forbidden from './Forbidden'
import { setCookie, deleteCookie } from 'utils/cookie'
import { productSelectors } from 'redux/selectors/productSelectors'
import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce';
import {DEFAULT_PRODUCT_PAGE_SIZE} from "../constants/common";

const App = (props) => {
  const { changeCategoryDropdown, productDataForHeaderDropdown, changeProductDataForHeaderDropdown } = props
  const language = props.locale || navigator.language.split(/[-_]/)[0];
  const [categoryItems, setCategoryItems] = useState([])
  const [categoryItemsParent, setCategoryItemsParent] = useState([])
  const [category, setCategory] = useState([])
  const [countyItems, setCountyItems] = useState([])
  const [categoryWithData, setCategoryWithData] = useState([])
  const [county, setCounty] = useState([])
  const [utilityItems, setUtilityItems] = useState([])
  const [utility, setUtility] = useState([])
  // const { pathname, search } = window.location
  const [isBulk, setIsBulk] = useState(false)
  const [productItems, setProductItems] = useState([])
  const [productWithData, setProductWithData] = useState([])
  const [productLoadMore, setProductLoadMore] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [productFilter, setProductFilter] = useState({ skip: 0, page: 1, count: DEFAULT_PRODUCT_PAGE_SIZE, searchText: '' })
  const [productResponse, setProductResponse] = useState({ totalPages: 0, totalItems: 0, count: DEFAULT_PRODUCT_PAGE_SIZE });

  const isEmbed = window.location.pathname.includes(pathKeys.EMBED);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  if (token && isEmbed) {
    setToken(token);
  }

  const manufacturers = urlParams.get('manufacturers');
  if (manufacturers && isEmbed) {
    setCookie(common.MANUFACTURER_KEY, manufacturers)
  } else {
    deleteCookie(common.MANUFACTURER_KEY)
  }

  i18next.changeLanguage(language);

  const [showFullMenu, changeShowFullMenu] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const { isAuthenticated, user = {}, loginWithRedirect } = useAuth0();
  const { trialExpireDate } = user
  const indexAutherizeAdmin = checkAuthorize(user)

  useEffect(() => {
    handleFetchProduct(productFilter)
  }, [productFilter])

  function handleFetchCategory() {

    categoryService.getCategory().then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities
        const categoryData = []
        newData.forEach(el => {
          const index = categoryData.findIndex(item => item.id === el.id)
          if (index === -1) {
            categoryData.push({
              ...el,
              label: el.name,
              value: el.id,
              parentId: el.parentCategoryId,
              isParent: el.isParent,
              type: common.ProgramFilterType.Category,
            })
          }
        })
        const categoryDataItemsParent = categoryData.filter(item => !item.parentId)
        const categoryDataItems = categoryDataItemsParent.filter(item => item.rebateNumber && item.rebateNumber > 0)
        const categoryWithDataNumbers = categoryData.filter(item => item.rebateNumber && item.rebateNumber > 0)
        setCategoryItemsParent(categoryDataItemsParent)
        setCategoryItems(categoryDataItems)
        setCategory(categoryData)
        setCategoryWithData(categoryWithDataNumbers)
        const categoryTreeData = handleGetArraychild(categoryData)
        changeCategoryDropdown(categoryTreeData)

      }
    })
  }

  function handleFetchUtility() {
    utilityService.getAllUtility().then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities
        const newUtility = []
        newData.forEach(el => {
          newUtility.push({
            ...el,
            label: el.name,
            value: el.id,
            parentId: null,
            isParent: false,
            type: common.ProgramFilterType.Utility
          })
        })
        setUtility(newUtility)
        setUtilityItems(newUtility)
      }
    })
  }

  function handleFetchCounty() {
    coreService.getCounty().then(res => {
      const { success, data } = res
      if (success && data.length > 0) {
        const newCounty = []
        data.forEach(el => {
          newCounty.push({
            ...el,
            label: el.name,
            value: el.id,
            parentId: null,
            isParent: false,
            subLabel: el.isState ? null : el.stateAbbreviated,
            type: common.ProgramFilterType.County
          })
        })
        setCounty(newCounty)
      }
    })
  }

  async function handleFetchProduct(query) {
    if (query.searchText.length > 0) {
      setProductLoading(true);
    }
    
    await coreService.getProductFilterData(query).then(res => {
        const { success, data } = res
        if (success && data) {
          const { items, totalPages, totalItems } = data

          setProductResponse({ ...productResponse, totalPages, totalItems, skip: query.skip });

          if (!isSearching) {
            const mergedData = uniqBy([...productItems, ...items], 'label')
            setProductItems(mergedData)

            const mergedProductWithData = uniqBy([...productWithData, ...items], 'label')
            setProductWithData(mergedProductWithData)

            changeProductDataForHeaderDropdown(mergedData)
            if (query.searchText.length > 0) {
              setProductItems(items)
              changeProductDataForHeaderDropdown(items)

              setIsSearching(false)
            }

          } else {
            setProductItems(items)
            changeProductDataForHeaderDropdown(items)

            setIsSearching(false)
          }

          setProductLoadMore(false)
          setProductLoading(false)
        }
      });

  }

  function productScroll() {
    if (productFilter.searchText.length > 0) {
      setProductLoading(false);
    }
    if (!productLoadMore) {
      setProductLoadMore(true)
      setProductFilter(state => ({
        ...state,
        skip: state.page + 1,
        page: state.page + 1,
      }));
    }
  }

  function searchProduct(value) {
    setProductFilter(state => ({ ...state, skip: 0, searchText: value }));
    setProductLoading(false);
  }

  function handleFetchFilter() {
    handleFetchCategory()
    handleFetchUtility()
    handleFetchCounty()
  }

  useEffect(() => {
    handleFetchFilter();
  }, [])

  let showLeftMenuAndHeader = !!isAuthenticated
  if (!!isEmbed) {
    showLeftMenuAndHeader = true;
  } else if (!trialExpireDate && !indexAutherizeAdmin) {
    showLeftMenuAndHeader = false
  }

  let homeURL = !isAuthenticated ? pathKeys.LOGIN : pathKeys.REBATES;
  if (!!isEmbed) {
    homeURL = pathKeys.REBATES
  } else if (!trialExpireDate && isAuthenticated && !indexAutherizeAdmin) {
    homeURL = pathKeys.TRIAL_VIEW
  }

  const toggleSidebarMenu = (stateValue) => {
    if (typeof stateValue !== "object" && typeof stateValue !== "undefined") {
      changeShowFullMenu(stateValue)
    } else {
      changeShowFullMenu(!showFullMenu)
    }
  }

  const renderScrollBarsTrackHorizontal = (props) => (
    <div {...props} className="track-horizontal" style={{ display: "none" }} />
  );
  const renderScrollBarsThumbHorizontal = (props) => (
    <div {...props} className="thumb-horizontal" style={{ display: "none" }} />
  );

  const ProtectedRoute = (path, ViewTag, userTypes = null, isCheckAuthorize = false, otherProps = null) => {
    let isAuthorized = false;
    if (!!isAuthenticated) {
      isAuthorized = !userTypes || userTypes.includes(user.userType)
      if (isAuthorized && indexAutherizeAdmin === -1 && isCheckAuthorize) {
        isAuthorized = false
      }
    } else {
      loginWithRedirect({
        appState: { targetUrl: "/"}
      });
    }

    return (
      <Route path={path} element={isAuthorized ? <ViewTag {...otherProps} /> : <Forbidden/>}/>
    )
  }

  return (
    <I18nextProvider i18n={i18next}>
      <Loading loading={props.loading} />
      <BrowserRouter>
      {showLeftMenuAndHeader && !isEmbed && <LeftMenu showFullMenu={showFullMenu} forceUpdate={forceUpdate} onSidebarToggle={toggleSidebarMenu} />}
      <div className={cn("main", { toggled: !showFullMenu, 'no-menu': !showLeftMenuAndHeader })}>
        <div className={cn('content-wrapper')}>
          {showLeftMenuAndHeader && (
            <Header
              category={categoryWithData}
              categoryItems={categoryItems}
              countyItems={countyItems}
              county={county}
              utilityItems={utilityItems}
              utility={utility}
              product={productWithData}
              changeCategoryItemDropdown={setCategoryItems}
              changeCountyItemDropdown={setCountyItems}
              changeUtilityItemDropdown={setUtilityItems}
              changeProductItemDropdown={setProductItems}
              onSidebarToggle={toggleSidebarMenu}
              isToggled={showFullMenu}
              isEmbed={isEmbed}
              isBulk={isBulk}
              productItems={productItems}
              productScrollEnd={() => {productScroll()}}
              isProductLoadMore={productLoadMore}
              loading={productLoading}
              searchProduct={(value) => {searchProduct(value)}}
              productResponse={productResponse}
            />
          )}
          <DndProvider backend={HTML5Backend}>
            <Scrollbars
              className={`scroll-wrap ${!!showFullMenu ? 'flex-left-view': 'flex-right-view'}`}
              renderTrackHorizontal={renderScrollBarsTrackHorizontal}
              renderThumbHorizontal={renderScrollBarsThumbHorizontal}
              autoHide
            >
                <Routes>
                  <Route
                    path={`${pathKeys.EMBED}`}
                    element={<EmbedView/>}
                  />
                 
                  <Route
                    path={`${pathKeys.EMBED_STATE}`}
                    element={<EmbedView/>}
                  />
                  
                  <Route
                    path={`${pathKeys.EMBED_COUNTY}`}
                    element={<EmbedView/>}
                  />
                   <Route
                    path={`${pathKeys.EMBED_UTILITY}`}
                    element={<EmbedView/>}
                  />
                  <Route path={pathKeys.LOGIN} element={<LoginView />} />
                  <Route path={pathKeys.SIGNUP} element={<Signup />} />
                  {
                    !!isAuthenticated && (
                      <>
                        {
                          ProtectedRoute(pathKeys.TRIAL_VIEW, TrialView)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.ROLE}`}
                          //   component={Roles}
                          //   isCheckAuthorize
                          // />
                          ProtectedRoute(pathKeys.ROLE, Roles, null, true)
                        }
                        {
                          // TO DO send category to view by other ways

                          //   <ProtectedRoute
                          //   path={`${pathKeys.CATEGORY_MANAGEMENT}`}
                          //   category={category}
                          //   setCategory={setCategory}
                          //   component={CategoryManagement}
                          //   isCheckAuthorize
                          // />
                          ProtectedRoute(pathKeys.CATEGORY_MANAGEMENT, CategoryManagement, null, true, { category, setCategory})
                        }
                        {
                        //   <ProtectedRoute
                        //   path={`${pathKeys.UTILITY_MANAGEMENT}`}
                        //   component={UtilityView}
                        //   isCheckAuthorize
                        // />
                          ProtectedRoute(pathKeys.UTILITY_MANAGEMENT, UtilityView, null, true)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.PROGRAM_MANAGEMENT}`}
                          //   component={ProgramView}
                          //   utility={utility}
                          //   isCheckAuthorize
                          // />
                          ProtectedRoute(pathKeys.PROGRAM_MANAGEMENT, ProgramView, null, true, { utility })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.REBATES_MANAGEMENT}`}
                          //   component={RebatesView}
                          //   category={category}
                          //   categoryItemsParent={categoryItemsParent}
                          //   isCheckAuthorize
                          // />
                          ProtectedRoute(pathKeys.REBATES_MANAGEMENT, RebatesView, null, true, { category, categoryItemsParent })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.BULK_UTILITIES}`}
                          //   component={BulkUtilities}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_UTILITIES, BulkUtilities, null, true, { setIsBulk })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.BULK_REBATES}`}
                          //   component={BulkRebates}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_REBATES, BulkRebates, null, true, { setIsBulk })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.BULK_PROGRAMS}`}
                          //   component={BulkPrograms}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_PROGRAMS, BulkPrograms, null, true, { setIsBulk })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.BULK_PROGRAMS_IMPORT}`}
                          //   component={ImportPrograms}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_PROGRAMS_IMPORT, ImportPrograms, null, true, { setIsBulk })
                        }
                        {
                          // <ProtectedRoute
                          //   path={`${pathKeys.BULK_UTILITIES_IMPORT}`}
                          //   component={ImportUtilities}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_UTILITIES_IMPORT, ImportUtilities, null, true, { setIsBulk })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.BULK_REBATES_IMPORT}`}
                          //   component={ImportRebates}
                          //   isCheckAuthorize
                          //   setIsBulk={setIsBulk}
                          // />
                          ProtectedRoute(pathKeys.BULK_REBATES_IMPORT, ImportRebates, null, true, { setIsBulk })
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.REBATES_UTILITY}`}
                          //   component={RebatesMap}
                          // />
                          ProtectedRoute(pathKeys.REBATES_UTILITY, RebatesMap, null, true)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.REBATES_COUNTY}`}
                          //   component={RebatesMap}
                          // />
                          ProtectedRoute(pathKeys.REBATES_COUNTY, RebatesMap, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.REBATES_STATE}`}
                          //   component={RebatesMap}
                          // />
                          ProtectedRoute(pathKeys.REBATES_STATE, RebatesMap, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={`${pathKeys.REBATES}`}
                          //   component={RebatesMap}
                          // />
                          ProtectedRoute(pathKeys.REBATES, RebatesMap, null, false)
                        }
                        {
                        //   <ProtectedRoute
                        //   path={pathKeys.SAVEDITEMS}
                        //   component={SavedItems}
                        // />
                          ProtectedRoute(pathKeys.SAVEDITEMS, SavedItems, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={pathKeys.MY_PROFILE}
                          //   component={MyProfile}
                          // />
                          ProtectedRoute(`${pathKeys.MY_PROFILE}`, MyProfile, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={pathKeys.MY_PROFILE}
                          //   component={MyProfile}
                          // />
                          ProtectedRoute(`${pathKeys.MY_PROFILE}/:tabIndex`, MyProfile, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={pathKeys.MAPCARDVIEW_UTILITY}
                          //   component={CardView}
                          // />
                          ProtectedRoute(pathKeys.MAPCARDVIEW_UTILITY, CardView, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={pathKeys.TABLEVIEW_UTILITY}
                          //   component={TableView}
                          // />
                          ProtectedRoute(pathKeys.TABLEVIEW_UTILITY, TableView, null, false)
                        }
                        {
                        //   <ProtectedRoute
                        //   path={pathKeys.TABLEVIEW}
                        //   component={TableView}
                        // />
                          ProtectedRoute(pathKeys.TABLEVIEW, TableView, null, false)
                        }
                        {
                          //   <ProtectedRoute
                          //   path={pathKeys.MAPCARDVIEW}
                          //   component={CardView}
                          // />
                          ProtectedRoute(pathKeys.MAPCARDVIEW, CardView, null, false)
                        }
                        {
                        //   <ProtectedRoute
                        //   path={`${pathKeys.CHECK_APPLICATION_URL}`}
                        //   component={ApplicationUrlView}
                        //   isCheckAuthorize
                        // />
                          ProtectedRoute(pathKeys.CHECK_APPLICATION_URL, ApplicationUrlView, null, true)
                        }
                      </>
                    )
                  }
                  <Route
                    exact
                    path="/"
                    element={<Navigate to={homeURL} replace />}
                  />
                  <Route path="*" element={<NotFoundView />}  key={`route-key-not-found`} />
                </Routes>
            </Scrollbars>
          </DndProvider>
        </div>
      </div>
      </BrowserRouter>
    </I18nextProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: appSelectors.selectLocale(state),
    loading: appSelectors.selectLoading(state),
    productDataForHeaderDropdown: productSelectors.productDataForHeaderDropdown(state)
  }
}
const mapDispatchToProps = (dispatch) => ({
  changeCategoryDropdown: (value) => dispatch(dropDownActions.changeCategoryDropdown(value)),
  changeProductDataForHeaderDropdown: (value) => dispatch(productionActions.changeProductDataForHeaderDropdown(value)),
})


App.propTypes = {
  locale: PropTypes.string,
  loading: PropTypes.bool,
  changeCategoryDropdown: PropTypes.func,
  productDataForHeaderDropdown: PropTypes.array,
  changeProductDataForHeaderDropdown: PropTypes.func,
}

App.defaultProps = {
  locale: "",
  loading: false,
  changeCategoryDropdown: () => { },
  productDataForHeaderDropdown: [],
  changeProductDataForHeaderDropdown: () => { },
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
