
import {
  CHANGE_CATEGORY_DROPDOWN,
  CHANGE_CATEGORY_ITEM_DROPDOWN,
  CHANGE_COUNTY_DROPDOWN,
  CHANGE_UTILITY_DROPDOWN,
  CHANGE_COUNTY_ITEM_DROPDOWN,
  CHANGE_UTILITY_ITEM_DROPDOWN,
  CHANGE_CATEGORY_HISTORY,
  CHANGE_UTILITY_HISTORY,
  CHANGE_COUNTY_HISTORY,
  CHANGE_PRODUCT_HISTORY
} from '../constants/dropDownActions'


export function changeCategoryDropdown(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_CATEGORY_DROPDOWN,
      payload: data
    })
  }
}

export function changeCategoryItemDropdown(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_CATEGORY_ITEM_DROPDOWN,
      payload: data
    })
  }
}

export function changeCategoryDropdownUtility(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_UTILITY_DROPDOWN,
      payload: data
    })
  }
}

export function changeCategoryDropdownCounty(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_COUNTY_DROPDOWN,
      payload: data
    })
  }
}

export function changeCountyItemDropdown(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_COUNTY_ITEM_DROPDOWN,
      payload: data
    })
  }
}

export function changeUtilityItemDropdown(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_UTILITY_ITEM_DROPDOWN,
      payload: data
    })
  }
}

export function changeCategoryHistory(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_CATEGORY_HISTORY,
      payload: data
    })
  }
}

export function changeProductHistory(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_PRODUCT_HISTORY,
      payload: data
    })
  }
}

export function changeUtilityHistory(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_UTILITY_HISTORY,
      payload: data
    })
  }
}

export function changeCountyHistory(data) {
  return dispatch => {
    dispatch({
      type: CHANGE_COUNTY_HISTORY,
      payload: data
    })
  }
}