import { createSelector } from 'reselect'

const bulkImportReducer = state => state.get('bulkImportReducer')

export const bulkImportSelector = {
    utilityImportData: createSelector(bulkImportReducer, state => {
        const data = state.get('utilityImportData');
        return Array.isArray(data) ? data : []
        }
    ),
    rebateImportData: createSelector(bulkImportReducer, state => {
        const data = state.get('rebateImportData');
        return Array.isArray(data) ? data : []
        }
    ),
    programImportData: createSelector(bulkImportReducer, state => {
        const data = state.get('programImportData');
        return Array.isArray(data) ? data : []
        }
    )
}
