/* eslint-disable react/prop-types */

import React, { } from 'react'
import { connect } from 'react-redux'


export const HomeView = () => {

  return (
    <div >
      test
    </div>
  )
}

/* istanbul ignore next */
const mapStateToProps = () => {
  return {
    // documents: fn(state),
  }
}

/* istanbul ignore next */
const mapDispatchToProps = () => ({
  // getDocuments: (clientId, skip) => dispatch(fn(clientId, skip)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
