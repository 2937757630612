import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Dropdown } from 'react-bootstrap'
import { TopLabelFormGroup, Button } from ".."
import { IconChevronRightDropdown } from "../../../assests/icons"
import "./style.scss";

const OPTIONS = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "20",
    label: "20"
  },
  {
    value: "50",
    label: "50"
  },
  {
    value: "100",
    label: "100"
  }
]
const TopFilterTableSecond = ({ className, isSearch, pageSize, onPageSizeChange, onClick, isAdd, textButton, onSearch, searchPlaceholder, children }) => {
  const [searchText, setSearchText] = useState("")
  return (
    <div className={cn('topFilterTable', [className])}>
      <Dropdown className="topFilterTable__dropDown" size="sm">
        Show
        <Dropdown.Toggle >
          {pageSize} <IconChevronRightDropdown />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {OPTIONS.map(item => (
            <Dropdown.Item onClick={() => { onPageSizeChange(item.value) }} key={item.label} >{item.label}</Dropdown.Item>
          ))}

        </Dropdown.Menu>
      </Dropdown>
      {
        isSearch ? (
          <>
            {
              children
            }
            <TopLabelFormGroup
              isSearch
              className="topFilterTable-second__search"
              placeholder={searchPlaceholder}
              controlId="Search"
              value={searchText}
              onChange={(_, value) => {
                setSearchText(value)
              }}
              isTextArea={false}
            />
            <Button onClick={() => { onSearch(searchText) }} className="topFilterTable-second__search" typeClass="second" size="md" text="Search" />
          </>
        ) : null
      }

      <div className="topFilterTable__group">
        {isAdd ? <Button onClick={() => { onClick() }} className="topFilterTable__button topFilterTable__button-add " typeClass="second" size="md" text={textButton} /> : null}
      </div>
    </div>
  )
};

TopFilterTableSecond.propTypes = {
  isSearch: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.any,
  ]),
  className: PropTypes.string,
  pageSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.any,
  ]),
  onPageSizeChange: PropTypes.func,
  onClick: PropTypes.func,
  isAdd: PropTypes.bool,
  textButton: PropTypes.string,
  onSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  children: PropTypes.element,
};

TopFilterTableSecond.defaultProps = {
  isSearch: false,
  className: "",
  pageSize: "10",
  onPageSizeChange: () => { },
  onClick: () => { },
  isAdd: false,
  textButton: "Add New User",
  onSearch: () => { },
  searchPlaceholder: "Search",
  children: ""
}

export default TopFilterTableSecond;
