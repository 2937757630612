import { fromJS } from 'immutable'

import { createReducer } from '../../utils/reduxHelpers'
import {SET_UTILITY_IMPORT_DATA, SET_PROGRAM_IMPORT_DATA, SET_REBATE_IMPORT_DATA} from '../constants/bulkImportActions'

const initialState = fromJS({
  utilityImportData: [],
  rebateImportData: [],
  programImportData: [],
})

const setUtilityImportData = (state, action) => {
    const { items } = action.payload
    return state.set('utilityImportData', items)
}

const setProgramImportData = (state, action) => {
  const { items } = action.payload
  return state.set('programImportData', items)
}

const setRebateImportData = (state, action) => {
  const { items } = action.payload
  return state.set('rebateImportData', items)
}

export default createReducer(initialState, {
  [SET_UTILITY_IMPORT_DATA]: setUtilityImportData,
  [SET_PROGRAM_IMPORT_DATA]: setProgramImportData,
  [SET_REBATE_IMPORT_DATA]: setRebateImportData,
})
