import { common } from "../constants";
import { getCookie } from "utils/cookie";
import BaseApi from "./baseApi";

class RebateApi extends BaseApi {
  filterByProgramId(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.rebate.filterByProgramId}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  getList(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.rebate.getList}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.rebate.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.rebate.update}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  delete(query) {
    return super
      .execute(this.Methods.DELETE, `${this.Urls.rebate.delete}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  exportAll(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.exportAll}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportByFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.exportByFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.exportFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportBlank(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.exportBlank}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  getByIds(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.getByIds}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  importFile(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.importFile}`, null, true, data)
      .then((res) => {
        return res;
      });
  }


  sendEmail(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebate.sendEmail}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  allRebateCountByCounty(param) {
    const manufacturers = getCookie(common.MANUFACTURER_KEY)
    if (manufacturers) {
      param = {
        ...param,
        manufacturers: manufacturers
      }
    }
    return super
      .execute(this.Methods.POST, this.Urls.rebate.allRebateByCountyForProductsAndCategories, null, true, param)
      .then((res) => {
        return res;
      });
  }

  allRebateByState(param) {
    const manufacturers = getCookie(common.MANUFACTURER_KEY)

    if (manufacturers) {
      param = {
        ...param,
        manufacturers: manufacturers
      }
    }

    return super
      .execute(this.Methods.POST, this.Urls.rebate.allRebateByStateForProductsAndCategories, null, true, param)
      .then((res) => {
        return res;
      });
  }

  allRebate(param) {
    const manufacturers = getCookie(common.MANUFACTURER_KEY)

    if (manufacturers) {
      param = {
        ...param,
        manufacturers: manufacturers
      }
    }

    return super
      .execute(this.Methods.POST, this.Urls.rebate.allRebate, null, true, param)
      .then((res) => {
        return res;
      });
  }
}

export default new RebateApi();
