/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import { Scrollbars } from 'react-custom-scrollbars';
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from "moment"
import { toastr } from 'react-redux-toastr'
import { emptyTable } from '../../assests/images'
import { ProgramFilterType } from "../../constants/common";
import { programService } from "../../services"
import { productSelectors } from '../../redux/selectors/productSelectors';
import { programSelectors } from '../../redux/selectors/programSelectors';
import { bulkImportActions, programActions } from "../../redux/actions"
import { DataTable, Loading, StatusText, Text, Tooltip, EmptyState, Button, Modal } from "../../components/common"
import { IconCategoryHouse, IconCategoryHammer, IconThunder } from "../../assests/icons"
import { pathKeys } from '../../constants'
import { downlLoadFile, convertExcelToJson } from '../../utils/common'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/dates';

const pageSize = 50
export const BulkPrograms = (props) => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize
  })
  const { setIsBulk, filterItems, setProgramItems } = props
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [isFirstFresh, setIsFirstFresh] = useState(true)
  const [items, setItems] = useState([])
  const uploadRef = useRef(null);

  function handleFetchDefault(filterData) {
    setLoading(true)
    programService.exportFilter(filterData).then(res => {
      const { success, data } = res

      if (success && data.entities) {
        const newData = data.entities;
        setTotal(data.totalEntities)
        setItems(newData)
        if (isFirstFresh) {
          setIsFirstFresh(false)
        }
      } else {
        setItems([])
      }
      setLoading(false)
    })
  }

  function handleFetchPrograms(filter) {
    setLoading(true)
    programService.exportFilter(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        setItems([...items, ...data.entities])
      }
      setLoading(false)
    })
  }


  function handleExportAll() {
    setLoading(true)
    programService.exportAll().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "programsAll")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportBlank() {
    setLoading(true)
    programService.exportBlank().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "programsBlank")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportByFilter(filterData) {
    setLoading(true)
    programService.exportByFilter(filterData).then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "programsList")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  async function handleImportFile(file) {
    const result = await convertExcelToJson(file)
    const newData = []
    result.forEach(item => {
      newData.push({
        ...item,
        KeepChange: true
      })
    })
    setProgramItems(newData);
    navigate(pathKeys.BULK_PROGRAMS_IMPORT)
  }

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  useEffect(() => {
    const locations = []
    const categories = []
    const utilities = [];
    const products = [];

    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        locations.push(item.id)
      } else if (item.type === ProgramFilterType.Category) {
        categories.push(item.id)
      } else if (item.type === ProgramFilterType.Utility) {
        utilities.push(item.id)
      } else if (item.type === ProgramFilterType.Product) {
        products.push(item.id)
      }
    })
    const dataBody = {
      skip: 0,
      count: pageSize,
      locations,
      categories,
      utilities,
      products
    }

    setFilter(dataBody)
    handleFetchDefault(dataBody)
  }, [filterItems])


  function handleRenderStatus(isActive) {
    if (isActive) {
      return 'active'
    } else if (typeof isActive === 'object') {
      return 'upcoming'
    }
    return 'closed'
  }


  const columns = [
    {
      key: "Name",
      colSpan: 6,
      header: <div>Programs Name</div>,
      renderItem: (record) => {
        const { name } = record
        return <Text className="table-tootip-float" as="div" lines={1} popover>{name}</Text>
      }
    },
    {
      key: "Start",
      colSpan: 4,
      sortable: true,
      header: <div>Start Date</div>,
      renderItem: (record) => {
        const { startDate } = record;
        return <div className="table-time"> {startDate ? formatDate(startDate) : 'N/A'}</div>
      }
    },
    {
      key: "End",
      sortable: true,
      header: <div>End Date</div>,
      colSpan: 4,
      renderItem: (record) => {
        const { endDate } = record
        return <div className="table-time"> {endDate ? formatDate(endDate) : 'N/A'}</div>
      }
    },
    {
      key: "WebsiteLink",
      header: <div>Website</div>,
      colSpan: 4,
      renderItem: (record) => {
        const { websiteLink } = record
        return (
          <>
            {websiteLink ? (
              <div
                role="presentation"
                onClick={() => {
                  const newUrl = websiteLink.startsWith("http://") || websiteLink.startsWith("https://") ? websiteLink : `https://${websiteLink}`
                  window.open(newUrl, '_blank');
                }} className="table-link-page"
              >
                <Text className="table-tootip-float" as="div" lines={1} popover>{websiteLink}</Text>


              </div>
            ) : "N/A"}
          </>
        )
      }
    },
    {
      key: "Rebate",
      sortable: true,
      header: <div>Application</div>,
      colSpan: 6,
      renderItem: (record) => {
        const { applicationLink } = record
        return (
          <>
            {applicationLink ? (
              <div
                role="presentation"
                onClick={() => {
                  const newUrl = applicationLink.startsWith("http://") || applicationLink.startsWith("https://") ? applicationLink : `https://${applicationLink}`
                  window.open(newUrl, '_blank');
                }} className="table-link-page"
              >
                <Text className="table-tootip-float" as="div" lines={1} popover>{applicationLink}</Text>

              </div>
            ) : "N/A"}
          </>
        )
      }
    },
    {
      key: "Status",
      sortable: true,
      header: <div>Status</div>,
      colSpan: 3,
      renderItem: (record) => {
        const { isActive } = record
        return <StatusText status={handleRenderStatus(isActive)}>{handleRenderStatus(isActive)}</StatusText>
      }
    },
    {
      className: "tab-center",
      key: "Requirements",
      header: <div>Requirements</div>,
      colSpan: 6,
      renderItem: (record) => {
        const { requirements } = record
        return (
          <div >
            {
              requirements ? <Text className="table-tootip-float" as="div" lines={2} popover>{requirements}</Text> : "N/A"
            }

          </div>
        )
      }
    },
    {
      key: "IsNewConstruction",
      colSpan: 3,
      header: <div>Project</div>,
      renderItem: (record) => {
        const { isRetroFit, isNewConstruction, isInstant } = record
        return (
          <div className="table-groupIcon">
            {isNewConstruction ? (
              <Tooltip childrenTooltip="New Construction" >
                <IconCategoryHouse />
              </Tooltip>
            ) : null}
            {isRetroFit ? (
              <Tooltip childrenTooltip="Retrofit" >
                <IconCategoryHammer />
              </Tooltip>
            ) : null}
            {
              !!isInstant && (
                <Tooltip childrenTooltip="Instant" >
                  <IconThunder />
                </Tooltip>
              )
            }
          </div>
        )
      }
    },
    {
      key: "IncentiveCap",
      header: <div>Incentive Cap</div>,
      colSpan: 4,
      renderItem: (record) => {
        const { incentiveCap } = record
        return <div >{incentiveCap || 0}</div>
      }
    },

  ];

  return (
    <>
      <Loading loading={loading} />

      <Scrollbars
        onScrollFrame={(values) => {
          const { top } = values
          const { skip, count } = filter
          if (top >= 0.9 && (total >= (skip + 1) * count)) {
            const filterNow = {
              ...filter,
              skip: skip + 1
            }
            setFilter(filterNow)
            const fillterCurrent = {
              ...filterNow,
              skip: (skip + 1) * count
            }
            handleFetchPrograms(fillterCurrent)
          }
        }}
        autoHide >
        <div className="bulk">
          <div className="bulk__WrapButton">
            <Button
              onClick={() => {
                setIsShowExportModal(true)
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Export" />
            <Button
              onClick={() => {
                uploadRef.current.click();
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Import" />
            <input
              type="file"
              className="hide"
              ref={uploadRef}
              onChange={(e) => {
                const file = e.target.files[0]
                handleImportFile(file)
              }}
              multiple={false}
            />
          </div>
          <DataTable
            sortable
            data={items}
            columns={columns}
            orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
            onSort={str => {
              if (str) {
                const arrayValue = str.split("-")
                if (arrayValue[0] && arrayValue[1]) {
                  const dataBody = {
                    ...filter,
                    orderField: arrayValue[0],
                    isDesc: arrayValue[1] !== 'asc',
                    skip: 0,
                    count: pageSize,
                  }
                  setFilter(dataBody)
                  handleFetchDefault(dataBody)
                }
              }
            }}
            noDataText={(
              <EmptyState
                className="custom-empty-state-second"
                photo={emptyTable}
                text={() => {
                  return (
                    <>
                      <div className="custom-empty-state-second__title">
                        NO DATA
                      </div>

                    </>
                  );
                }}
              />
            )}
          />
        </div>

      </Scrollbars>
      <Modal
        onHide={() => { setIsShowExportModal(false) }}

        size="md"
        centered
        isHiddenFooter
        show={isShowExportModal}
        title="Export Programs to CSV"
      >
        <div className="bulk__center">
          Select which Programs to include in the export
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                const newFilter = {
                  ...filter,
                  skip: 0,
                  count: total
                }
                handleExportByFilter(newFilter)
              }}
              typeClass="second"
              size="md"
              text="Current Filtered List" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains all Programs that match the current filters in the list
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportAll()
              }}
              typeClass="second"
              size="md"
              text="All Programs" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains all Programs in RebatesBus
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportBlank()
              }}
              typeClass="second"
              size="md"
              text="Blank Template" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains only the column headers for Programs
            </p>
          </div>
        </div>
      </Modal >
    </>

  )
}

BulkPrograms.propTypes = {
  setIsBulk: PropTypes.func,
  filterItems: PropTypes.array,
  setProgramItems: PropTypes.func
}

BulkPrograms.defaultProps = {
  setIsBulk: () => { },
  filterItems: [],
  setProgramItems: () => {}
}

const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
    items: programSelectors.selectProgramsItems(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setItems: (items) => dispatch(programActions.changePrograms(items)),
  setProgramItems: (items) => dispatch(bulkImportActions.changeProgramImportData(items)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BulkPrograms)
