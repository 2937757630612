/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Dropdown } from 'react-bootstrap'
import cn from 'classnames'
import { Text, DropdownSecond } from ".."
import { IconChevronRightDropdown } from "../../../assests/icons"
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import { handleGetArraychild, printTree } from "../../../utils/common";

const DropDownItemMultipleSecond = React.forwardRef((props, ref) => {
  const {
    values,
    controlId,
    onChange,
    className,
    status,
    placeholder,
    options,
    textLabel,
    label,
    key,
    items
  } = props
  const [dataItems, setDataItems] = useState([])
  function handleRenderValue() {

    const newValue = handleGetArraychild(values)

    return (
      < >
        {
          newValue.map((el, index) => {
            const { children = [], isFull } = el
            const arrayStringText = []
            printTree(children, '> ', arrayStringText)

            if (isFull || !children.length) {
              return (
                <>
                  {index !== 0 ? ", " : ""} {el[textLabel]}

                </>
              )
            }
            return (
              <>
                {
                  arrayStringText.map((item, index2) => (
                    <>
                      {index2 !== 0 || index !== 0 ? ", " : ""}  {el[textLabel]} {item.textSub}

                    </>
                  ))
                }
              </>
            )

          })
        }
      </>
    )

  }

  useEffect(() => {
    setDataItems(items)
  }, [items])

  return (
    <>
      {
        label ? <Form.Label className="top-label-dropdown-group__label">{label} </Form.Label> : null
      }
      <DropdownSecond
        key={key}
        className={className}
        onChange={(item) => {
          onChange(item);
        }}
        items={dataItems}
        itemsAll={options}
        selectedValues={values}
        onClickParentItem={(id) => {
          const newData = options.filter(item => item.parentId === id)
          setDataItems(newData)
        }}
        changeCategoryItem={(items) => {
          setDataItems(items)
        }}
      >

        <Form.Group
          controlId={controlId}
          className={cn('top-label-dropdown-group', status && status)}
        >

          <div className={`top-label-dropdown-group__dropDown ${values.length ? "" : "top-label-dropdown-group__placeholder"}`}>
            <Dropdown.Toggle >
              {
                values.length ? (
                  <Text as="div" lines={1} popover>
                    {handleRenderValue()}
                  </Text>
                ) : (
                  <>
                    {
                      placeholder
                    }
                  </>
                )
              }

              <IconChevronRightDropdown className="top-label-dropdown-group__dropDown__iconParent" />
            </Dropdown.Toggle>

          </div>


        </Form.Group>
      </DropdownSecond >
    </>
  )
})

DropDownItemMultipleSecond.displayName = "TopLabelDropDownGroup";

DropDownItemMultipleSecond.propTypes = {
  controlId: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.array,
  className: PropTypes.string,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null, "", ``]),
  textLabel: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  key: PropTypes.string,
  items: PropTypes.array,
}

DropDownItemMultipleSecond.defaultProps = {
  onChange: null,
  values: [],
  className: undefined,
  textLabel: "name",
  placeholder: '',
  options: [],
  label: null,
  key: "programView__utitily",
  items: []
}

export default DropDownItemMultipleSecond
