/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"

import { useAuth0 } from "../../Auth0"
import { userTypes } from '../../../constants'
import { checkAuthorize } from '../../../utils/common'
import ScrollToView from '../ScrollToView'
import Forbidden from '../../../views/Forbidden'

export const ProtectedRoute = props => {
  const { history, component: Component, path, location, userTypes, category, categoryItemsParent, setCategory, utility, isCheckAuthorize, setIsBulk, ...rest } = props
  const { isAuthenticated, loginWithRedirect, user = {} } = useAuth0();
  // const { trialExpireDate } = user
  // const { pathname } = window.location
  const indexAutherizeAdmin = checkAuthorize(user)
  // if (!trialExpireDate && pathname !== pathKeys.TRIAL_VIEW && !indexAutherizeAdmin && history) {
  //   history.push(pathKeys.TRIAL_VIEW)
  // }

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: "/"}
        });
      }

    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path, location]);

  let GuardedComp = null

  if (isAuthenticated === true) {
    let isAuthorized = !userTypes || userTypes.includes(user.userType)
    if (isAuthorized && indexAutherizeAdmin === -1 && isCheckAuthorize) {
      isAuthorized = false
    }
    GuardedComp = isAuthorized ? Component : Forbidden
  }
  const { lastState = {}, currentState = {} } = location.state || {}
  const render = props => (
    <>
      <ScrollToView />
      {GuardedComp && <GuardedComp {...props} setIsBulk={setIsBulk} utility={utility} 
      categoryItemsParent={categoryItemsParent} category={category} 
      setCategory={setCategory} lastRouteState={lastState} currentRouteState={currentState} />}
    </>
  )

  return (
    <Route path={path} render={render}  {...rest} />
  )
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.func])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.instanceOf(Object),
  }).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  userTypes: PropTypes.arrayOf(PropTypes.oneOf([userTypes.COACH, userTypes.CLIENT])),
  history: PropTypes.instanceOf(Object),
  category: PropTypes.array,
  setCategory: PropTypes.func,
  utility: PropTypes.array,
  categoryItemsParent: PropTypes.array,
  isCheckAuthorize: PropTypes.bool,
  setIsBulk: PropTypes.func,
};

ProtectedRoute.defaultProps = {
  userTypes: null,
  category: [],
  setCategory: () => { },
  utility: [],
  categoryItemsParent: [],
  isCheckAuthorize: false,
  setIsBulk: () => { },
}

// function withRouter(Component) {
//   function ComponentWithRouterProp(props) {
//     let location = useLocation();
//     let navigate = useNavigate();
//     let params = useParams();
//     return (
//         <Component
//           {...props}
//           location={location}
//           params={params}
//           navigate={navigate}
//         />
//     );
//   }
//   return ComponentWithRouterProp;
// }

export default ProtectedRoute;
