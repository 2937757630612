/* istanbul ignore file */
import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyState } from '../../components/common'
import { emptyActivities } from '../../assests/images'

const useCustomTranslation = () => {
  const [t] = useTranslation()
  return {
    noclientActivitiesText: t(
      'client_screens.my_activities.no_activities_text_weekly'
    )
  }
}

const SavedItems = () => {
  const translator = useCustomTranslation()

  return (
    <div>
      <EmptyState
        photo={emptyActivities}
        text={translator.noclientActivitiesText}
        className="animated fadeIn"
      />
    </div>
  );
};

export default SavedItems;
