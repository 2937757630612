/* istanbul ignore file */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import { EmptyState } from '../../components/common'
import './style.scss'

const useCustomTranslation = () => {
  const { t } = useTranslation()
  return {
    notFoundTitle: t('not_found.not_found_title'),
    notFoundMessage: t('not_found.not_found_message'),
    notFoundLinkText: t('not_found.not_found_link_text')
  }
}

const NotFound = () => {
  const translator = useCustomTranslation()
  return (
    <Container className="not-found-page">

      <EmptyState
        text={translator.notFoundMessage}
        title={translator.notFoundTitle}
      />
    </Container>
  )
}

export default NotFound
