/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { Form } from 'react-bootstrap'
import cn from 'classnames'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import CustomInput from './CustomInput'
import { IconArrowLeft } from '../../../assests/icons'

import './style.scss'

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const DatePicker = props => {
  const {
    className,
    label,
    subLabel,
    status,
    onChange, type, selectedDate, readOnly, dateFormat, useCustomHeader, customInput,
    onFocus, onBlur, options, defaultValue, placeholder, ...otherProps
  } = props
  const [startDate, changeStartDate] = useState(defaultValue === undefined ? new Date() : defaultValue)

  const handleOnChange = date => {
    changeStartDate(date)
    typeof onChange === 'function' && onChange(date)
  }

  /* istanbul ignore next */
  const handleOnFocus = () => {
    typeof onFocus === 'function' && onFocus()
  }

  /* istanbul ignore next */
  const handleOnBlur = () => {
    typeof onBlur === 'function' && onBlur()
  }

  let renderCustomHeader
  if (useCustomHeader) {
    const years = _.range(useCustomHeader.fromYear || 1930, useCustomHeader.toYear || new Date().getFullYear(), 1)
    renderCustomHeader = ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled
    }) => (
      <div className="datepicker-custom-header">
        <button className="react-datepicker__navigation react-datepicker__navigation--previous"
          type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>
        <select value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(value)}>
          {years.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} >
          {months.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button className="react-datepicker__navigation react-datepicker__navigation--next"
          type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>
      </div>
    )
  }

  return (
    <Form.Group
      className={cn('top-label-form-group', className, status)}
    >
      {
        label ? <Form.Label>{label} {subLabel ? <div className="subLabel">{subLabel}</div> : null}</Form.Label> : null
      }
      <div className="custom-datepicker">
        <ReactDatePicker
          selected={selectedDate || startDate}
          openToDate={selectedDate || startDate || new Date()}
          onChange={handleOnChange}
          readOnly={readOnly}
          customInput={customInput || <CustomInput selectedDate={selectedDate} type={type} dateFormat={dateFormat} placeholderText={placeholder} />}
          useWeekdaysShort
          renderCustomHeader={renderCustomHeader}
          // withPortal
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          {...(options || {})}
          {...otherProps}
        />
      </div>
    </Form.Group>
  )
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  customInput: PropTypes.node,
  type: PropTypes.oneOf(['date', 'date-input', 'week']),
  readOnly: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  dateFormat: PropTypes.string,
  options: PropTypes.object,
  defaultValue: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  useCustomHeader: PropTypes.object,
  subLabel: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null, "", ``]),
}

DatePicker.defaultProps = {
  onChange: null,
  onFocus: null,
  onBlur: null,
  customInput: null,
  selectedDate: null,
  readOnly: false,
  dateFormat: undefined,
  options: {},
  useCustomHeader: null,
  subLabel: "",
  label: "",
  className: "",
  status: ""
}

export const useDatePicker = ({ onChange, type, selectedDate, readOnly, ...otherProps }) => {

  const [startDate, changeStartDate] = useState(selectedDate || new Date())

  useEffect(() => {
    const isSelectedDateChanged = !moment(selectedDate).isSame(moment(startDate), 'day')
    if (!!selectedDate && isSelectedDateChanged) {
      changeStartDate(selectedDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  const handleOnChange = date => {
    changeStartDate(date)
    typeof onChange === 'function' && onChange(date)
  }
  const isInCurDay = moment(startDate).isSame(new Date(), 'day')

  const goToPrevDay = () => {
    if (readOnly) return
    const prevWeekDay = moment(startDate).subtract(1, 'day').toDate()
    handleOnChange(prevWeekDay)
  }
  const goToDay = () => {
    if (readOnly || isInCurDay) return
    handleOnChange(new Date())
  }
  const goToNextDay = () => {
    if (readOnly) return
    const nextWeekDay = moment(startDate).add(1, 'day').toDate()
    handleOnChange(nextWeekDay)
  }
  return {
    picker: (
      <DatePicker
        onChange={handleOnChange}
        type={type}
        readOnly={readOnly}
        selectedDate={startDate}
        {...otherProps}
      />
    ),
    navigator: (
      <div className="picker-navigator">
        <span
          className={cn('shadow-sm rounded nav-btn nav-left noselect prevDay-button', {
            disabled: readOnly,
          })}
          onClick={goToPrevDay}
          role="button"
        >
          <IconArrowLeft />
        </span>
        <span
          className={cn('shadow-sm rounded nav-btn nav-center noselect today-button', {
            disabled: readOnly || isInCurDay,
          })}
          onClick={goToDay}
          role="button"
        >
          Today
        </span>
        <span
          className={cn('shadow-sm rounded nav-btn nav-right noselect nextDay-button', {
            disabled: readOnly,
          })}
          onClick={goToNextDay}
          role="button"
        >
          <IconArrowLeft />
        </span>
      </div>
    ),
  }
}

export default DatePicker
