/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { toastr } from 'react-redux-toastr'
import _ from "lodash"
import { emptyProgram } from '../../assests/images'
import { handleRenderArrayText, handleRenderStatus, inIframe, numberToPrice } from "../../utils/common"
import {
  BOOTSTRAP_VARIANTS as Status,
  ProjectCap,
  MaterialCap,
  LaborCap,
  Active,
  Closed,
  Upcoming
} from "../../constants/common";
import { pathKeys } from '../../constants'
import { programService, programUtilityService, utilityService, customerTypeService } from "../../services"
import { DataTable, Loading, Text, Pagination, TopFilterTableSecond, Modal, TopLabelFormGroup, DropDownItemMultipleSecond, StatusText, Tooltip, EmptyState, TopLabelDropDownGroup, DatePicker } from "../../components/common"
import { IconEditRoles, IconCategory5, IconCategoryHouse, IconCategoryHammer, IconRebate, IconThunder } from "../../assests/icons"
import { IconCustomerInActive } from "../../constants/customers";
import { useNavigate } from 'react-router-dom';
import './style.scss'
import moment from 'moment';


const OPTIONS = [
  {
    value: "isNewConstruction",
    label: "New Construction"
  },
  {
    value: "isRetroFit",
    label: "Retrofit"
  },
  {
    value: "isInstant",
    label: "Instant"
  }
]

const OPTIONS_STATUS = [
  {
    value: 1,
    label: Active
  },
  {
    value: 0,
    label: Closed
  },
  {
    value: -1,
    label: Upcoming
  },

]


const OPTIONS_CAP_TYPE = [
  {
    value: ProjectCap,
    label: ProjectCap
  },
  {
    value: MaterialCap,
    label: MaterialCap
  },
  {
    value: LaborCap,
    label: LaborCap
  },
]

const pageSize = 10

const DEFAULT_PROGRAM = {
  endDate: null
}

export const ProgramView = (props) => {
  const navigate = useNavigate()
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize,
    searchText: '',
  })
  const { utility, history } = props
  const [isShowModalEdit, setShowModalEdit] = useState(false)
  const [items, setItems] = useState([])
  const [customerTypes, setCustomertype] = useState([])
  const [customerValues, setCustomerValues] = useState([])
  const [utilityValuesFilter, setutilityValuesFilter] = useState([])
  const [valuesUtility, setValueUtility] = useState([])
  const [dataItem, setDataItem] = useState({ ...DEFAULT_PROGRAM })
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [objectError, setObjectError] = useState({})

  let handleSearch = null

  function handleFetchDefault(filterData) {
    setLoading(true)
    const newFilter = {}
    Object.keys(filterData).forEach(key => {
      if ((filterData[key] && filterData[key] !== '') || key === "isDesc") {
        newFilter[key] = filterData[key]
      }
    })
    if (newFilter.skip) {
      newFilter.skip *= newFilter.count
    } else {
      newFilter.skip = 0
    }

    programService.filterBySearchTextAndUtilityId(newFilter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setItems(newData)
        setTotal(data.totalEntities)
      }
      setLoading(false)
    })
  }

  function getUtilitiesByProgramId(filter) {
    utilityService.getUtilitiesByProgramId(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = [];
        data.entities.forEach(item => {
          newData.push({
            ...item,
            value: item.id,
            parentId: null,
            isParent: false,
          })
        })
        setValueUtility(newData)
      }
    })
  }

  function getAllCustomerType() {
    customerTypeService.getAll().then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = []
        data.entities.forEach(item => {
          newData.push({
            ...item,
            value: item.id,
            parentId: null,
            isParent: false,
          })
        })

        setCustomertype(newData)
      }
    })
  }

  function handlePageSizeChange(pageSize) {
    const newFilter = {
      skip: 0,
      count: pageSize,
    }
    setFilter(newFilter)
    handleFetchDefault(newFilter)
  }

  function handlePageChange(page) {
    const newFilter = {
      ...filter,
      skip: page - 1,
    }
    setFilter(newFilter)

    handleFetchDefault(newFilter)
  }

  function handleOnChange(name, value) {
    setDataItem({
      ...dataItem,
      [name]: value
    })

    delete objectError[name]
    setObjectError({
      ...objectError
    })
  }

  function handleValidate() {
    let isPass = true
    const message = "This field not allow empty"
    if (!dataItem.name || dataItem.name === "") {
      objectError.name = message
      isPass = false
    }

    if (!dataItem.capType || !dataItem.capType.length) {
      objectError.capType = message
      isPass = false
    }

    if (!isPass) {
      setObjectError({
        ...objectError
      })
    } else {
      setObjectError({})
    }

    return isPass
  }

  function handSetDataValue(dataItem) {
    let isActive = null
    if (dataItem.isActive) {
      const { value } = dataItem.isActive[0]
      if (value === 1) {
        isActive = true
      } else if (value === -1) {
        isActive = null
      } else {
        isActive = false
      }
    }
    const projects = _.get(dataItem, "projects", []);
    const isRetroFit = projects.findIndex(el => el.value === "isRetroFit") !== -1
    const isNewConstruction = projects.findIndex(el => el.value === "isNewConstruction") !== -1
    const isInstant = projects.findIndex(el => el.value === "isInstant") !== -1
    const capType = _.get(dataItem, "capType[0].value", ProjectCap);

    const newData = {
      name: dataItem.name,
      websiteLink: dataItem.websiteLink || "",
      applicationLink: "",
      isActive,
      requirements: dataItem.requirements || "",
      incentiveCap: dataItem.incentiveCap || 0,
      capType,
      maxAnnualIncetive: dataItem.maxAnnualIncetive || 0,
      isRetroFit,
      isNewConstruction,
      isInstant,
      endDate: dataItem.endDate ? moment(dataItem.endDate).format() : null,
      preApprovalRequiredAmount: dataItem.preApprovalRequiredAmount || 0
    }

    return newData
  }

  async function addOrUpdateMultipleCustomerTypeByProgramId(data, customTypes) {
    const query = {
      programId: data.id
    }
    const newData = []
    customTypes.forEach(item => {
      newData.push(item.id)
    })
    await customerTypeService.addOrUpdateMultipleByProgramId(query, newData)
  }

  function addOrUpdateMultipleByProgramId(data, programs, isAdd) {
    const query = {
      programId: data.id
    }
    const newData = []
    programs.forEach(item => {
      newData.push(item.id)
    })

    programUtilityService.addOrUpdateMultipleByProgramId(query, newData).then(res => {
      const { success } = res
      if (!success) {
        toastr.error("Actions update multiple utility failed please try again!")
      }
      if (isAdd) {
        handleFetchDefault({ ...filter, skip: 0 })
      } else {
        handleFetchDefault(filter)
      }

    })

  }

  function handleAddProgram() {
    const newData = handSetDataValue(dataItem)

    programService.create(newData).then(res => {
      const { success, data } = res
      if (success && data && data.id) {
        addOrUpdateMultipleCustomerTypeByProgramId(data, customerValues)
        addOrUpdateMultipleByProgramId(data, valuesUtility, true)
        setShowModalEdit(false)
        toastr.success("Action creation successfully")
      } else {
        toastr.error("Actions failed please try again!")
      }
    })
  }


  function handleUpdateProgram() {
    const query = {
      id: dataItem.id,
    }
    const newData = handSetDataValue(dataItem);
    programService.update(query, newData).then(res => {
      const { success } = res
      if (success) {
        addOrUpdateMultipleCustomerTypeByProgramId(query, customerValues)
        addOrUpdateMultipleByProgramId(query, valuesUtility)

        toastr.success("Actions update successfully")
        setShowModalEdit(false)
      } else {
        toastr.error("Actions failed please try again!")
      }

    })
  }

  function handleOnSaveModal() {
    if (handleValidate()) {
      if (dataItem.id) {
        handleUpdateProgram()
      } else {
        handleAddProgram()
      }
    }
  }

  function handleClickEditIcon(record) {
    let isActive = []
    const status = handleRenderStatus(record.isActive)
    if (status === Active) {
      isActive = [OPTIONS_STATUS[0]]
    } else if (status === Closed) {
      isActive = [OPTIONS_STATUS[1]]
    } else {
      isActive = [OPTIONS_STATUS[2]]
    }

    const projects = []
    if (record.isNewConstruction) {
      projects.push(OPTIONS[0])
    }
    if (record.isRetroFit) {
      projects.push(OPTIONS[1])
    }
    if (!!record.isInstant) {
      projects.push(OPTIONS[2])
    }

    const newData = {
      ...record,
      capType: [{ value: record.capType, label: record.capType }],
      isActive,
      projects,
      endDate: record.endDate ? new Date(record.endDate) : null
    }
    const newProgramCustomerTypes = []
    if (record.programCustomerTypes) {
      record.programCustomerTypes.forEach(item => {
        if (item.customerType && item.customerType.name) {
          newProgramCustomerTypes.push({
            ...item,
            name: item.customerType.name,
            id: item.customerType.id,
            value: item.id,
            parentId: null,
            isParent: false,
          })
        }
      })
    }

    setCustomerValues([...newProgramCustomerTypes])
    setDataItem(newData);
    setShowModalEdit(true);
    setObjectError({});
    getUtilitiesByProgramId({
      programId: record.id,
    })
  }

  useEffect(() => {
    handleFetchDefault(filter)
    getAllCustomerType()
  }, [])


  const columns = [
    {
      key: "Name",
      header: <div>{t("program.name")}</div>,
      colSpan: 6,
      sortable: true,
      renderItem: (record) => {
        const { name } = record
        return (
          <div className="Roles__contain__user">
            <Text className="table-tootip-float " as="div" lines={1} popover>
              {name || "N/A"}
            </Text>
          </div>
        )
      }
    },
    {
      key: "rebates",
      header: <div>{t("program.rebate")}</div>,
      colSpan: 6,
      renderItem: (record) => {
        const { rebates = [] } = record
        return (
          <div className="Roles__contain__user">
            <Text className="table-tootip-float " as="div" lines={1} popover>
              {handleRenderArrayText(rebates, "name")}
            </Text>
          </div>
        )
      }
    },
    {
      key: "websiteLink",
      header: <div>{t("program.application")}</div>,

      colSpan: 5,
      renderItem: (record) => {
        const { websiteLink } = record
        return (
          <>
            {websiteLink && !inIframe() ? (
              <div
                role="presentation"
                onClick={() => {
                  window.open(websiteLink, '_blank');
                }} className="table-link-page"
              >
                Apply for rebate
                <IconCategory5 />
              </div>
            ) : "N/A"}
          </>
        )
      }
    },
    {
      key: "Status",
      colSpan: 3,

      header: <div>{t("table.status")}</div>,
      renderItem: (record) => {
        const { isActive } = record
        return <StatusText status={handleRenderStatus(isActive)}>{handleRenderStatus(isActive)}</StatusText>
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("table.project")}</div>,
      colSpan: 3,
      renderItem: (record) => {
        const { isRetroFit, isNewConstruction, isInstant } = record;
        return (
          <div className="table-groupIcon">
            {isNewConstruction ? (
              <Tooltip childrenTooltip="New Construction" >
                <IconCategoryHouse />
              </Tooltip>
            ) : null}
            {isRetroFit ? (
              <Tooltip childrenTooltip="Retrofit" >
                <IconCategoryHammer />
              </Tooltip>
            ) : null}
            {
              !!isInstant && (
                <Tooltip childrenTooltip="Instant" >
                  <IconThunder />
                </Tooltip>
              )
            }
          </div>
        )
      }
    },
    {
      key: "Customers",
      header: <div>{t("program.customers")}</div>,
      colSpan: 5,
      renderItem: (record) => {
        const { programCustomerTypes } = record
        return (
          <div >
            {
              programCustomerTypes.map(item => {
                const name = _.get(item, "customerType.name", "");
                const Ico = IconCustomerInActive[name]
                return (
                  <>
                    {
                      Ico ? (
                        <Tooltip key={name} childrenTooltip={name} >
                          <div className="tooltip-drawer">
                            <Ico />
                          </div>
                        </Tooltip>

                      ) : null
                    }
                  </>
                )
              })
            }
          </div>
        )
      }
    },
    {
      key: "capType",
      header: <div>{t("program.cap_type")}</div>,
      colSpan: 3,

      renderItem: (record) => {
        const { capType } = record
        return (
          <div >
            {capType}
          </div>
        )
      }
    },
    {
      key: "incentiveCap",
      header: <div>{t("program.incetive_cap")}</div>,
      colSpan: 3,
      renderItem: (record) => {
        const { capType, incentiveCap } = record
        return (
          <div >
            {capType === LaborCap && incentiveCap ? `${incentiveCap} %` : ''}
            {capType === ProjectCap && incentiveCap ? `${incentiveCap} %` : ''}
            {capType === MaterialCap && incentiveCap ? `${incentiveCap} %` : ''}
          </div>
        )
      }
    },

    {
      key: "MaxAnnualIncetive",
      header: <div>{t("program.max_annual_incentive")}</div>,

      colSpan: 5,
      renderItem: (record) => {
        const { maxAnnualIncetive } = record
        return (
          <div >
            {maxAnnualIncetive ? `$${numberToPrice(maxAnnualIncetive)}` : 'N/A'}
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("utility.action")}</div>,
      colSpan: 3,
      renderItem: (record) => {
        return (
          <div className="table-groupIcon">
            <IconEditRoles onClick={() => {
              handleClickEditIcon(record)
            }} />
            <IconRebate className="Roles__contain__delete" onClick={() => {
              const newPath = pathKeys.REBATES_MANAGEMENT.replace(':programId', record.id)
              navigate(newPath, { data: record })
            }} />
          </div>
        )
      }
    }
  ];

  return (

    <div className="Roles programView">
      <Loading loading={loading} />
      <div className="Roles__contain">
        <div className="Roles__contain__title">
          {t("side_menu.programManagement")}
        </div>
        <div className="Roles__contain__subTitle">
          {t("program.program")}
        </div>

        <DataTable
          sortable
          noDataText={(
            <EmptyState
              className="custom-empty-state-second"
              photo={emptyProgram}
              text={() => {
                return (
                  <>
                    <div className="custom-empty-state-second__title">
                      NO RESULTS MATCH YOUR SEARCHED.
                    </div>
                    <div className="custom-empty-state-second__title-sub">
                      Please update your searches to find matching results or
                    </div>
                    <a className="custom-empty-state-second__link" href="mailto:support@rebatebus.com">
                      let us know if we are missing a searches.
                    </a>
                  </>
                );
              }}
            />
          )}
          data={items}
          columns={columns}
          orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
          onSort={str => {
            if (str) {
              const arrayValue = str.split("-")
              if (arrayValue[0] && arrayValue[1]) {
                const dataBody = {
                  ...filter,
                  orderField: arrayValue[0],
                  isDesc: arrayValue[1] !== 'asc',
                  skip: 0,
                  count: pageSize,
                }
                setFilter(dataBody)
                handleFetchDefault(dataBody)
              }
            }
          }}
          topChildren={(
            <TopFilterTableSecond
              isSearch
              isAdd
              pageSize={filter.count}
              textButton="Create Program"
              searchPlaceholder="Search Program By Name"
              onPageSizeChange={(value) => { handlePageSizeChange(value) }}
              onClick={() => { setDataItem({ ...DEFAULT_PROGRAM }); setShowModalEdit(true); setObjectError({}) }}
              onSearch={(value) => {
                const utilityIds = utilityValuesFilter.map(item => item.id)
                const newFilter = {
                  ...filter,
                  skip: 0,
                  searchText: value,
                  utilityIds
                }
                setFilter(newFilter)
                if (handleSearch) {
                  clearTimeout(handleSearch)
                  handleSearch = null
                }
                handleSearch = setTimeout(() => {
                  handleFetchDefault(newFilter, true)
                }, 1000)
              }}
            >
              <DropDownItemMultipleSecond
                className="programView__combox"
                placeholder="- Please Choose Utility -"
                values={utilityValuesFilter}
                onChange={(values) => {
                  setutilityValuesFilter(values)
                }}
                textLabel="name"
                options={utility}
                items={utility}
              />
            </TopFilterTableSecond>
          )}
        >
          {
            total ? (
              <Pagination data={
                {
                  totalItems: total,
                  pageSize: filter.count,
                  currentPage: filter.skip + 1
                }
              }
                onPageChange={(value) => {
                  handlePageChange(value)
                }}
              />
            ) : null
          }
        </DataTable>
        <Modal
          onHide={() => { setShowModalEdit(false) }}
          handleOnSave={() => {
            handleOnSaveModal()
          }}
          size="md"
          centered
          show={isShowModalEdit}
          title={`${dataItem.id ? "Update" : "Create"} Program`}
        >
          <div>
            <TopLabelFormGroup
              label={t("program.name")}
              controlId="name"
              placeholder="Input Name"
              value={dataItem.name || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
              status={objectError.name ? Status.DANGER : null}
              message={objectError.name}
            />

            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("utility.website")}
              placeholder="Input Website"
              controlId="websiteLink"
              value={dataItem.websiteLink || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />

            < TopLabelDropDownGroup
              controlId="isActive"
              className="Roles__form-group"
              label={t("table.status")}
              placeholder="Choose Status"
              value={dataItem.isActive || []}
              onChange={(value) => {
                handleOnChange("isActive", [{ ...value }])
              }}
              options={OPTIONS_STATUS}
            />

            < TopLabelDropDownGroup
              controlId="projects"
              className="Roles__form-group"
              label={t("table.project")}
              placeholder="Choose Project"
              value={dataItem.projects || []}
              status={objectError.projects ? Status.DANGER : ""}
              message={objectError.projects}
              isChecked
              textValue="value"
              onChange={(value) => {
                if (!dataItem.projects) {
                  dataItem.projects = []
                }
                const index = dataItem.projects.findIndex(item => item.value === value.value)
                if (index === -1) {
                  dataItem.projects.push(value)
                } else {
                  dataItem.projects = dataItem.projects.filter(item => item.value !== value.value)
                }
                handleOnChange("projects", dataItem.projects)
              }}
              options={OPTIONS}
            />

            < TopLabelDropDownGroup
              controlId="capType"
              className="Roles__form-group"
              label={t("program.cap_type")}
              placeholder="Choose Cap Type"
              value={dataItem.capType || []}
              onChange={(value) => {
                handleOnChange("capType", [{ ...value }])
              }}
              options={OPTIONS_CAP_TYPE}
              status={objectError.capType ? Status.DANGER : ""}
              message={objectError.capType}
            />

            <TopLabelDropDownGroup
              controlId="customerValues"
              className="Roles__form-group"
              label={t("program.customers")}
              placeholder="Choose customers"
              value={customerValues || []}
              status={objectError.customerValues ? Status.DANGER : ""}
              message={objectError.customerValues}
              isChecked
              textValue="id"
              textLabel="name"
              onChange={(value) => {
                const index = customerValues.findIndex(item => item.id === value.id)
                if (index === -1) {
                  customerValues.push(value)
                  setCustomerValues([...customerValues])
                } else {
                  const newCustomerValues = customerValues.filter(item => item.id !== value.id)
                  setCustomerValues(newCustomerValues)
                }

              }}
              options={customerTypes}
            />

            <TopLabelFormGroup
              className="Roles__form-group"
              label="Requirements"
              placeholder="Input Requirements"
              controlId="requirements"
              value={dataItem.requirements || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />


            <TopLabelFormGroup
              className="Roles__form-group"
              label={`${t("program.incetive_cap")} (%)`}
              placeholder="Input Incetive Cap"
              controlId="incentiveCap"
              value={dataItem.incentiveCap || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />

            <TopLabelFormGroup
              className="Roles__form-group"
              label={`${t("program.max_annual_incentive")} ($)`}
              placeholder="Input Incetive Cap"
              controlId="maxAnnualIncetive"
              value={dataItem.maxAnnualIncetive || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />
            <TopLabelFormGroup
              className="Roles__form-group preApprovalRequiredAmount"
              label={t("program.pre_approval_required")}
              placeholder={t("program.pre_approval_required")}
              controlId="preApprovalRequiredAmount"
              value={dataItem.preApprovalRequiredAmount}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
              type="number"
            />
            <DropDownItemMultipleSecond
              label={t("utility.utility")}
              placeholder="- Choose Utility -"
              values={valuesUtility}
              onChange={(values) => {
                setValueUtility(values)
              }}
              textLabel="name"
              options={utility}
              items={utility}
            />
            <DatePicker
              className="Roles__form-group"
              controlId="endDate"
              label={t("program.end_date")}
              placeholder="Choose Date"
              type="date-input"
              dateFormat={"YYYY/MM/DD"}
              selectedDate={dataItem.endDate}
              onChange={(date) => {
                handleOnChange("endDate", date)
              }}
            />
          </div>
        </Modal>
      </div>
    </div>

  )
}

ProgramView.propTypes = {
  utility: PropTypes.array
}

ProgramView.defaultProps = {
  utility: []
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = () => ({


})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramView)
