
import BaseService from "./baseService";
import BillingApi from "../api/billingApi";

class BillingService extends BaseService {
  create(params) {
    return BillingApi.create(params).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }
}

export default new BillingService();
