import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'
import { toastr } from 'react-redux-toastr'
import { IconPremium20 } from "../../../assests/icons";
import StripeForm from './StripeForm'
import { billingService, userService } from "../../../services"
import { useAuth0 } from "../../Auth0"
import { NewMember } from "../../../constants/common"
import "./style.scss";

const ModalPremium = (props) => {
  const { title, actionText, actionClick, onHide, isExpire } = props
  const [showStripeForm, changeShowStripeForm] = useState(false)
  const [clientSecret, setClienSecret] = useState(null)
  const { user = {}, setUser } = useAuth0();
  const [t] = useTranslation()
  let myReTry = null
  let countReTry = 0
  useEffect(() => {
    return () => {
      changeShowStripeForm(false)
    }
  }, [])

  function onPaymentSuccess(funcAfterSuccess) {
    myReTry = setInterval(() => {
      userService.getUserInfo().then(res => {
        const { success, data = {} } = res;
        if (success && data.userType && data.userType !== NewMember) {
          toastr.success("Actions payment successfully")
          setUser({
            ...user,
            ...data
          })
          onHide()
          if (funcAfterSuccess) {
            funcAfterSuccess()
          }
          clearInterval(myReTry)
        } else if (countReTry > 3) {
          if (funcAfterSuccess) {
            funcAfterSuccess()
          }
          toastr.success("We’re still processing your payment. Please refresh your browser after a few minutes")
          clearInterval(myReTry)
        }
        countReTry += 1
      })
    }, 4000)
  }

  function createPayment() {
    billingService.create({
      priceId: process.env.REACT_APP_PRICE_ID,
    }).then((res) => {
      const { success, data } = res;
      if (success) {
        setClienSecret(data.clientSecret)
      }
    })
  }

  function showErrorNotification(text) {
    toastr.warning(text || "Actions payment fail")
  }

  return (
    <Modal
      dialogClassName="modalPremium"
      {...props}
      size="xl"
      onHide={() => {
        if (!isExpire) {
          onHide()
        }
      }}
      centered
      animation={false}
    >
      <Modal.Body className="modalPremium__body">

        <div className="trial__text modalPremium__text">
          {title}
        </div>
        <div className="trial__sub-text modalPremium__sub-text ">
          Upgrading to Premium Gets You:
          </div>
        <ul className="modalPremium__sub-text-second ">
          <li>
            Unlimited Access to our Incentive Program Overviews

            </li>
          <li>
            5 Direct Application Downloads per Month
            </li>
          <li>
            Utility comparisons using the new and innovative Rebate Bus Utility Barometer™
            </li>
          <li>
            Utility Service Territory Mapping
            </li>
          <li>
            State Level Heating Mapping for Highest Rebate and Program penetration
            </li>
        </ul>
        <div className="modalPremium__sub-text-second modalPremium__sub-text-third">With premium, you will be able to save your results, personalize your views, and tag your most-reviewed utilities and programs.</div>
        {
          showStripeForm ? (
            <>
              <div className="modalPremium__payment-card">
                <div className="modalPremium__sub-text-second  trial__sub-text modalPremium__sub-text-three">{t('cognitive_baseline.stripe.title')}</div>
                <StripeForm
                  createPayment={createPayment}
                  clientSecret={clientSecret}
                  showErrorNotification={showErrorNotification}
                  onPaymentSuccess={onPaymentSuccess}
                  setClienSecret={setClienSecret}
                />
              </div>
            </>
          ) : (
              <IconPremium20
                onClick={() => {
                  changeShowStripeForm(true)
                }} className="modalPremium__icon"
              />
            )
        }

        <div role="presentation" onClick={() => { actionClick() }} className="modalPremium__actionText">
          {actionText}
        </div>
        <div className="modalPremium__question">Already have Premium? <a className="trial__link" href="mailto:support@rebatebus.com">Contact</a> Us if this is a mistake.</div>

      </Modal.Body>
    </Modal>
  );
};

ModalPremium.propTypes = {
  title: PropTypes.string,
  actionText: PropTypes.string,
  actionClick: PropTypes.func,
  onHide: PropTypes.func,
  isExpire: PropTypes.bool
};

ModalPremium.defaultProps = {
  title: "You’ve selected a Premium Feature",
  actionText: "BACK TO TRIAL",
  actionClick: () => { },
  onHide: () => { },
  isExpire: false
};

export default ModalPremium;
