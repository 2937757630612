import get from "lodash/get";
import BaseService from "./baseService";
import coreApi from "../api/coreApi";
import { getQueryString } from "../utils/common";
import ProgramFilter from "../models/ProgramFilter";
import County from "../models/County";
import { ProgramFilterType } from "constants/common";

class CoreService extends BaseService {
  getCounty(query = {}, headers, isAuthen = true) {
    const newQuery = getQueryString(query)
    return coreApi.getCounty(newQuery, headers, isAuthen).then(response => {
      let states = [];
      const counties = get(response, "data.entities", []).map(item => {
        return new County(item).toJSON();
      });
      states = states.concat(counties);

      return {
        data: states,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getProgram(data = {}) {
    return coreApi.getProgram(data).then(response => {
      return {
        data: { entities: get(response, "data.entities", []).map(d => new ProgramFilter(d).toJSON()), totalEntities: get(response, "data.totalEntities", 0) },
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getByCountyName(query = {}) {
    const newQuery = getQueryString(query)
    return coreApi.getByCountyName(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }


  getByUtilityId(query = {}) {
    const newQuery = getQueryString(query)
    return coreApi.getByUtilityId(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getProgramByUtilityId(id, rebateValuePageIndex, filterItems = []) {
    // covnert filterItems to param before send it to request
    const filterItemParam = filterItems.map((itemFilter) => {
      if (itemFilter.type === ProgramFilterType.County) {
        return {
          id: itemFilter.id.replace('neighborhood.', ''),
          type: itemFilter.type
        }
      }
      return {
        id: itemFilter.id,
        type: itemFilter.type
      }
    });

    return coreApi.getProgramByUtilityId(id, rebateValuePageIndex, filterItemParam).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getQPLs(query = {}) {
    const newQuery = getQueryString(query)
    return coreApi.getQPLs(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  checkApplicationUrl(program) {
    return coreApi.checkApplicationUrl(program.applicationLink).then(response => {
      const { status, statusText } = response;
      let isSuccess = true;
      if (status === 404 || status === 500) {
        isSuccess = false;
      }
      return {
        data: program,
        message: statusText,
        success: isSuccess,
        statusCode: status
      };
    }).catch(error => {
      const { statusText } = error;
      return {
        data: program,
        message: statusText,
        success: false,
        statusCode: 408
      };
    });
  }

  getProductFilterData(query) {
    return coreApi.getProductFilterData(query).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }
}

export default new CoreService();
