import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import _ from 'lodash'

import { IconSearch } from '../../../assests/icons'

import './style.scss'

const SearchBar = props => {
  const { onChange, placeholder, min, value, debounce, size, onPressEnter } = props

  const searchInput = useRef(null)
  const [typingTimeout, setTypingTimeOut] = useState(0)
  const [, updateState] = useState()
  const inputValue = _.get(searchInput, ['current', 'value'], null)

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = value
    }
  }, [value])

  const handleOnChange = () => {
    const { value } = searchInput.current

    const doChanging = () => {
      if (value === '' || value.length >= min) {
        onChange(value)
      }
    }
    if (debounce) {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      const newTypingTimeOut = setTimeout(doChanging, 500)
      setTypingTimeOut(newTypingTimeOut)
    }
    if (!debounce) {
      doChanging()
    }

  }

  const forceUpdate = useCallback(() => updateState({}), []);

  const handleOnClear = () => {
    searchInput.current.value = ''
    searchInput.current.focus()
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    onChange('')
    forceUpdate()
  }

  const handlePressEnter = e => {
    const { value } = searchInput.current

    if (e.key === 'Enter' && typeof onPressEnter === 'function') {
      onPressEnter(value)
    }
  }

  const clearIconClass = inputValue || value ? 'search-bar-icon' : 'search-bar-icon invisible'

  return (
    <div className="custom-search-bar">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl className="custom-search-bar-input" ref={searchInput} placeholder={placeholder} onChange={handleOnChange} size={size} onKeyPress={handlePressEnter} />
        <InputGroup.Prepend className={clearIconClass} onClick={handleOnClear}>
          <InputGroup.Text>
            <AiOutlineCloseCircle />
          </InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.number,
  value: PropTypes.string,
  debounce: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onPressEnter: PropTypes.func,
}

SearchBar.defaultProps = {
  placeholder: '',
  onChange: () => { },
  min: 0,
  value: null,
  debounce: true,
  size: 'md',
  onPressEnter: () => { },
}

export default SearchBar
