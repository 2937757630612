/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* istanbul ignore file */
import React, { useEffect } from "react";
import { IconLogoTrial, IconButtonTrial } from "../../assests/icons"
import { userService } from "../../services"
import { pathKeys } from '../../constants'
import { useAuth0 } from "../../components/Auth0"
import './index.scss'

export const TrialView = () => {
  const { user = {}, setUser, logout } = useAuth0();
  useEffect(() => {
    userService.getUserInfo().then(res => {
      const { success, data = {} } = res;
      if (success) {
        setUser({
          ...user,
          ...data
        })
      } else {
        logout();
      }
    })
  }, [])

  return (
    <div className="trial">
      <IconLogoTrial className="trial__logo" />
      <div className="trial__text">
        Welcome to RebateBus!
      </div>
      <div className="trial__sub-text">
        You’re about to begin a Free 10 Minute Trial. After your trial period you’ll
      </div>
      <div className="trial__sub-text">need to upgrade to Premum to continue using RebateBus.</div>
      <div className="trial__sub-text trial__sub-text-second">You can upgrade at any time by clicking the timer at the top of the screen</div>
      <IconButtonTrial
        onClick={() => {
          userService.startTrial().then(() => {
            window.location.href = pathKeys.REBATES
          })
        }}
        className="trial__logo-second"
      />
      <div className="trial__question">Already have Premium? <a className="trial__link" href="mailto:support@rebatebus.com">Contact</a> Us if this is a mistake.</div>
    </div>
  );
};

export default TrialView;
