/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Dropdown } from 'react-bootstrap'
import cn from 'classnames'
import { Checkbox, Text } from ".."
import { IconChevronRightDropdown } from "../../../assests/icons"
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import { DropDownItem } from "./dropDownItemMultiple"
import './style.scss'
import { common } from "../../../utils";

const { nanoid } = common;

const TopLabelDropDownGroup = React.forwardRef((props, ref) => {
  const {
    value,
    label,
    controlId,
    onChange,
    className,
    size,
    subLabel,
    status,
    message,
    placeholder,
    options,
    textLabel,
    textValue,
    isChecked,
    isMutiple,
    key
  } = props
  const [show, setShow] = useState(false)

  const dropdownProps = {
    size
  }

  function handleRenderValue() {
    let newText = ""
    value.forEach((item, index) => {
      if (index === 0) {
        newText += item[textLabel]
      } else {
        newText += `, ${item[textLabel]}`
      }

    })
    return newText
  }

  function renderDataTree(data, level, isParentShow) {
    const newLevel = level + 1
    return (
      <>
        {
          data && Array.isArray(data) ? (
            <>
              {
                data.map((item, index) => {
                  return (
                    <DropDownItem
                      key={nanoid()}
                      renderChildren={renderDataTree}
                      setShow={setShow}
                      item={item}
                      textLabel={textLabel}
                      level={newLevel}
                      isParentShow={isParentShow}
                      onChange={onChange}
                    />
                  )
                })
              }
            </>
          ) : null
        }
      </>
    )
  }

  return (
    <Form.Group
      key={key}
      controlId={controlId}
      className={cn('top-label-dropdown-group', status && status, [className])}
    >
      {
        label ? <Form.Label>{label} {subLabel ? <div className="subLabel">{subLabel}</div> : null}</Form.Label> : null
      }
      <Dropdown
        show={show}
        onToggle={(value, e, metadata) => {
          if (!isMutiple || metadata.source !== "select") {
            setShow(value)
          }
        }} className={`top-label-dropdown-group__dropDown ${value.length ? "" : "top-label-dropdown-group__placeholder"}`}>
        <Dropdown.Toggle >
          <Text as="div" lines={1} popover>
            {value.length ? handleRenderValue() : placeholder}
          </Text>
          <IconChevronRightDropdown />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            options.map(item => {
              const indexSelect = value.length ? value.findIndex(fi => fi[textValue] === item[textValue]) : -1
              const isCheck = indexSelect !== -1

              return (
                <>
                  {isMutiple ? (
                    <DropDownItem
                      key={`${item[textLabel]}_${nanoid()}`}
                      renderChildren={renderDataTree}
                      setShow={setShow}
                      item={item}
                      textLabel={textLabel}
                      level={0}
                      isParentShow
                      onChange={onChange}
                    />
                  ) : (
                    <Dropdown.Item onClick={() => { onChange(item) }} key={`${item[textLabel]}_${nanoid()}`}>
                      {isChecked ? (
                        <Checkbox key={nanoid()} onClick={() => { onChange(item) }} isFull checked={isCheck} > {item[textLabel]}</Checkbox>
                      ) : (
                        <>
                          {item[textLabel]}
                        </>
                      )}

                    </Dropdown.Item>
                  )}
                </>

              )
            })
          }
        </Dropdown.Menu>
      </Dropdown>

      {status && message && <div className="message">{message}</div>}
    </Form.Group>
  )
})

TopLabelDropDownGroup.displayName = "TopLabelDropDownGroup";

TopLabelDropDownGroup.propTypes = {
  controlId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
  isTextArea: PropTypes.bool,
  subLabel: PropTypes.string,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null, "", ``]),
  message: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  textLabel: PropTypes.string,
  textValue: PropTypes.string,
  isChecked: PropTypes.bool,
  isMutiple: PropTypes.bool,
  key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.symbol,
  ]),
}

TopLabelDropDownGroup.defaultProps = {
  onChange: null,
  value: [],
  label: undefined,
  readOnly: false,
  minRows: undefined,
  maxRows: undefined,
  className: undefined,
  isTextArea: true,
  size: 'sm',
  subLabel: null,
  status: null,
  message: null,
  placeholder: '',
  options: [],
  textLabel: "label",
  textValue: "value",
  isChecked: false,
  isMutiple: false,
  key: undefined
}

export default TopLabelDropDownGroup
