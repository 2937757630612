/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { toastr } from 'react-redux-toastr'
import {
  TabButtons,
  Avatar,
  TopLabelFormGroup,
  Button,
  Switches,
} from "../../components/common";
import { BOOTSTRAP_VARIANTS as Status } from "../../constants/common";
import { useAuth0 } from "../../components/Auth0";
import { profileActions } from "../../redux/actions";
import { userService } from "../../services"
import { validateEmail } from '../../utils/common'
import "./style.scss";

import {
  IconLeftChevron
} from "../../assests/icons";
import { useParams } from "react-router-dom";
import { pathKeys } from "../../constants";

export const MyProfile = (props) => {
  const { user = {}, requestChangePasswordEmail, setUser } = useAuth0();

  const { lastRouteState = {}, changeSelectDropdown } = props
  const [userProfile, setUserProfile] = useState(user);
  const [indexActive, setIndexActive] = useState(0);
  const [errorMessage, setErrorMessage] = useState({});
  const { tabIndex } = useParams();

  const uploadRef = useRef(null);
  const [t] = useTranslation();
  const DefaultPreferencesOptions = [
    {
      eventKey: "emailNewProduct",
      text: t("my_profile.email_me_updates"),
      checked: userProfile.emailNewProduct
    },
    {
      eventKey: "emailWeeklyUpdate",
      text: t("my_profile.email_weekly_batch"),
      checked: userProfile.emailWeeklyUpdate
    },
    {
      eventKey: "emailProductSurveys",
      text: t("my_profile.email_me_infrequent"),
      checked: userProfile.emailProductSurveys
    },
    {
      eventKey: "productUsabilityStudies",
      text: t("my_profile.Im_available_for"),
      checked: userProfile.productUsabilityStudies
    },
  ];
  const [preferencesOptions, setPreferencesOptions] = useState(DefaultPreferencesOptions)
  const menuItemPersonalKey = "personal-info";
  const menuItemContactKey = "contact-info";
  const menuItemPharmacyKey = "pharmacy-info";

  const profileMenuOptions = [
    {
      eventKey: menuItemPersonalKey,
      text: t("header.profile"),
      icon: <IconLeftChevron className="profile-menu-tabs-button__icon" />,
    },
    {
      eventKey: menuItemContactKey,
      text: t("header.preferences"),
      icon: <IconLeftChevron className="profile-menu-tabs-button__icon" />,
    },
    {
      eventKey: menuItemPharmacyKey,
      text: t("header.saved_items"),
      textSub: t("header.coming_soon"),
      icon: <IconLeftChevron className="profile-menu-tabs-button__icon" />,

    },
  ];

  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState(
    menuItemPersonalKey
  );

  const handleProfileMenuItemClick = (eventKey, index) => {
    if (indexActive !== index) {
      window.history.pushState(null, '', `${pathKeys.MY_PROFILE}/${index}`)
    }
    setSelectedMenuItemKey(eventKey);
    setIndexActive(index);
  };

  const listKeyForTab = [
    'personal-info',
    'contact-info',
    'pharmacy-info'
  ]

  useEffect(() => {
    if (tabIndex || tabIndex <= listKeyForTab.length - 1) {
      handleProfileMenuItemClick(listKeyForTab[tabIndex], tabIndex)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  function handleFormFieldChange(controlId, nextVal) {
    if (controlId === "emailAddress") {
      setErrorMessage({})
    }
    setUserProfile({ ...userProfile, [controlId]: nextVal });
  }

  function handleCheckValid() {
    let check = true
    if (!validateEmail(userProfile.emailAddress)) {
      check = false
      errorMessage.emailAddress = "Your email invalid!"
    }
    setErrorMessage({
      ...errorMessage
    })
    return check
  }

  function handleDeleteAvatar() {
    userService.deleteAvatar()
  }

  function handleSave(isCheck) {
    if (handleCheckValid()) {
      const query = {
        id: userProfile.id,
      }
      const newData = {
        emailAddress: userProfile.emailAddress,
        emailVerified: "true",
        fullName: userProfile.fullName,
        company: userProfile.company,
        phoneNumber: userProfile.phoneNumber,
        avatar: userProfile.avatar,
        emailNewProduct: userProfile.emailNewProduct,
        emailWeeklyUpdate: userProfile.emailWeeklyUpdate,
        emailProductSurveys: userProfile.emailProductSurveys,
        productUsabilityStudies: userProfile.productUsabilityStudies
      }
      userService.update(query, newData).then(res => {
        const { success } = res
        if (!isCheck) {
          if (success) {

            toastr.success("Actions successfully")
            setUser({ ...user, ...newData })
          } else {
            toastr.error("Actions failed please try again!")
          }
        }

      })
    }
  }

  const onUploadProfile = () => {
    uploadRef.current.click();
  };

  const onChangePhoto = (e) => {
    if (!e.target.files.length) {
      return;
    }

    const formData = new FormData();
    formData.append("FormFile", e.target.files[0]);
    formData.append("FileName", e.target.files[0].name);
    userService.uploadAvatar(formData).then(res => {
      const { success, data } = res
      if (success) {
        setUserProfile({
          ...userProfile,
          avatar: data
        });
      }
    })
  };

  const onRemovePhotoPreview = () => {
    handleDeleteAvatar()
    setUserProfile({
      ...userProfile,
      avatar: ""
    });
  }

  const personalForm = (
    <div className="profile-content-parent">
      <div className="parentAvatar">
        <Avatar
          size="lg"
          ref={uploadRef}
          onChangePhoto={(e) => onChangePhoto(e)}
          tempPhoto={userProfile.avatar}
        />
        <div className="parentAvatarContent">
          <div className="title">{t("my_profile.avatar")}</div>
          <div className="text">{t("my_profile.photo_must_be")}</div>
          <div className="text">
            <span className="text_link" onClick={onRemovePhotoPreview} role="button">{t("my_profile.remove_photo")}</span>{" "}
            {t("my_profile.or")}{" "}
            <span className="text_link" onClick={onUploadProfile} role="button">
              {t("my_profile.change_photo")}
            </span>
          </div>
        </div>
      </div>
      <div className="personal-form-wrapper">
        <TopLabelFormGroup
          label={t("my_profile.full_name")}
          controlId="fullName"
          value={userProfile.fullName || ""}
          onChange={handleFormFieldChange}
          isTextArea={false}
        />
        <TopLabelFormGroup
          label={t("my_profile.email")}
          controlId="emailAddress"
          value={userProfile.emailAddress}
          // onChange={handleFormFieldChange}
          readOnly
          isTextArea={false}
          required
          status={errorMessage.emailAddress && errorMessage.emailAddress !== "" ? Status.DANGER : null}
          message={errorMessage.emailAddress || ""}
        />
        <TopLabelFormGroup
          label={t("my_profile.company")}
          controlId="company"
          value={userProfile.company}
          onChange={handleFormFieldChange}
          isTextArea={false}
          subLabel={t("my_profile.optional")}
        />
        <TopLabelFormGroup
          label={t("my_profile.phone")}
          controlId="phoneNumber"
          value={userProfile.phoneNumber}
          onChange={handleFormFieldChange}
          isTextArea={false}
          subLabel={t("my_profile.optional")}
        />
        <div role="presentation"
          onClick={() => {
            if (user.email) {
              requestChangePasswordEmail({
                email: user.email,
                connection: "Username-Password-Authentication",
              }).then(result => {
                const { isSuccess, message } = result
                if (!isSuccess) {
                  toastr.warning(message)
                } else {
                  toastr.success(message)
                }
              })
            }
          }}
          className="text_link text_link_parent"
        >
          {t("my_profile.change_password")}
        </div>
        <Button onClick={() => { handleSave() }} typeClass="second" size="md" text={t("my_profile.save_changes")} />
      </div>
    </div>
  );

  const preferences = (
    <div className="preferences">
      <div className="preferences_title">
        {t("my_profile.use_the_selections")}
      </div>
      {preferencesOptions.map((item, index) => (
        <Switches
          key={item.eventKey}
          id={item.eventKey}
          label={item.text}
          checked={item.checked}
          onChange={(e) => {
            const { checked } = e.target
            const newPreferencesOptions = preferencesOptions
            newPreferencesOptions[index].checked = checked
            setPreferencesOptions([...newPreferencesOptions])
            setUserProfile({
              ...userProfile,
              [item.eventKey]: checked
            });
            setTimeout(() => { handleSave(true) }, 500)
          }}
        />
      ))}
    </div>
  );

  return (
    <div className="my-profile">
      <div className="title">My settings</div>
      <div className="my-profile-card">
        <div className="profile-menu">
          <TabButtons
            className="profile-menu-tabs-button"
            items={profileMenuOptions}
            controlledActiveKey={selectedMenuItemKey}
            onItemSelect={handleProfileMenuItemClick}
            type="material"
            onBack={() => {
              changeSelectDropdown(true)
            }}
            vertical
          />
        </div>
        <div className="profile-content">
          <div className="profile-forms-wrapper">
            <div className="profile-content-title">
              {profileMenuOptions[indexActive]
                ? profileMenuOptions[indexActive].text
                : ""}
            </div>
            <div className="profile-content-titleSub">
              {profileMenuOptions[indexActive] &&
                profileMenuOptions[indexActive].textSub
                ? profileMenuOptions[indexActive].textSub
                : ""}
            </div>
            {selectedMenuItemKey === menuItemPersonalKey && personalForm}
            {selectedMenuItemKey === menuItemContactKey && preferences}
          </div>
        </div>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = () => {
  return {
    // documents: fn(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  changeSelectDropdown: (value) => dispatch(profileActions.changeSelectDropdown(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
