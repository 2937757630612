/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"
import { toastr } from 'react-redux-toastr'
import { BOOTSTRAP_VARIANTS as Status } from "../../constants/common";
import { utilityService, programService, programUtilityService, coreService } from "../../services"
import { DataTable, Loading, Text, Pagination, TopFilterTable, Modal, TopLabelFormGroup, TopLabelSearchMultipleGroup, Tooltip } from "../../components/common"
import { IconEditRoles } from "../../assests/icons"
import './style.scss'
import { debounce } from 'lodash';

const pageSize = 10

export const UtilityView = () => {
  const [t] = useTranslation();
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize,
    SearchQuery: ''
  })

  const [isShowModalEdit, setShowModalEdit] = useState(false)
  const [items, setItems] = useState([])
  const [valuesProgram, setValuePrograme] = useState([])
  const [searchProgram, setSearchPrograme] = useState([])
  const [dataItem, setDataItem] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [total, setTotal] = useState(0)
  const [objectError, setObjectError] = useState({})

  const [allCounty, setAllCounty] = useState([])
  const [county, setCounty] = useState([])
  const [selectedCounty, setSelectedCounty] = useState([])

  let handleSearchProgram = null
  let handleSearch = null

  function handleFetchDefault(filterData, isSearch) {
    if (!isSearch) {
      setLoading(true)
    }
    const newFilter = {}
    Object.keys(filterData).forEach(key => {
      if ((filterData[key] && filterData[key] !== '') || key === "isDesc") {
        newFilter[key] = filterData[key]
      }
    })
    if (newFilter.skip) {
      newFilter.skip *= newFilter.count
    } else {
      newFilter.skip = 0
    }

    utilityService.getList(newFilter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setItems(newData)
        setTotal(data.totalEntities)
      }
      setLoading(false)
    })
  }

  function getAllProgramByUtilityId(filter) {
    programService.getAllProgramByUtilityId(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setValuePrograme(newData)
      }
    })
  }

  function handleFetchCounty() {
    coreService.getCounty().then(res => {
      const { success, data } = res
      if (success && data.length > 0) {
        const newCounty = []
        data.forEach(el => {
          newCounty.push({
            ...el,
            name: `${el.name}, ${el.state}`,
            value: el.id,
            searchingName: el.name
          })
        })
        setCounty(newCounty)
        setAllCounty(newCounty)
      }
    })
  }

  function getProgramBySearch(text) {
    if (!text || text === "") {
      setSearchPrograme([])
    } else {
      setLoadingSearch(true)
      programService.getProgramBySearch(text).then(res => {
        const { success, data } = res
        if (success && data.entities) {
          const newData = data.entities
          setSearchPrograme(newData)
        }
        setLoadingSearch(false)
      })
    }
  }

  function handlePageSizeChange(pageSize) {
    const newFilter = {
      skip: 0,
      count: pageSize,
    }
    setFilter(newFilter)
    handleFetchDefault(newFilter)
  }

  function handlePageChange(page) {
    const newFilter = {
      ...filter,
      skip: page - 1,
    }
    setFilter(newFilter)
    handleFetchDefault(newFilter)
  }

  function handleOnChange(name, value) {
    setDataItem({
      ...dataItem,
      [name]: value
    })

    delete objectError[name]
    setObjectError({
      ...objectError
    })
  }

  function handleValidate() {
    let isPass = true
    const message = "This field not allow empty"
    if (!dataItem.name || dataItem.name === "") {
      objectError.name = message
      isPass = false
    }

    if (dataItem.eia_Id && dataItem.eia_Id !== "") {
      if (isNaN(dataItem.eia_Id)) {
        objectError.eia_Id = "This field is number only"
        isPass = false
      }
    }

    if (!isPass) {
      setObjectError({
        ...objectError
      })
    } else {
      setObjectError({})
    }

    return isPass
  }

  function addOrUpdateMultipleByUtilityId(data, programs, isAdd) {
    const query = {
      utilityId: data.id
    }
    const newData = []
    programs.forEach(item => {
      newData.push(item.id)
    })

    programUtilityService.addOrUpdateMultipleByUtilityId(query, newData).then(res => {
      const { success } = res
      if (!success) {
        toastr.error("Actions update multiple program failed please try again!")
      }
      if (isAdd) {
        handleFetchDefault({ ...filter, skip: 0 })
      } else {
        handleFetchDefault(filter)
      }

    })

  }

  function handleMappingCounties (counties) {
    return counties.map((item) => {
      return item.id
    })
  }

  function handleAddUtility() {
    const newData = {
      ...dataItem,
      counties: handleMappingCounties(selectedCounty)
    }
    utilityService.create(newData).then(res => {
      const { success, data } = res
      if (success && data && data.id) {
        addOrUpdateMultipleByUtilityId(data, valuesProgram, true)
        setShowModalEdit(false)
        toastr.success("Utility creation successfully")
      } else {
        toastr.error("Actions failed please try again!")
      }
    })
  }

  function handleUpdateUtility() {
    const query = {
      id: dataItem.id,
    }
    const newData = {
      ...dataItem,
      counties: handleMappingCounties(selectedCounty)
    }
    utilityService.update(query, newData).then(res => {
      const { success } = res
      if (success) {
        addOrUpdateMultipleByUtilityId(query, valuesProgram)

        toastr.success("Utility update successfully")
        setShowModalEdit(false)
      } else {
        toastr.error("Actions failed please try again!")
      }

    })
  }

  function handleOnSaveModal() {
    if (handleValidate()) {
      if (dataItem.id) {
        handleUpdateUtility()
      } else {
        handleAddUtility()
      }
    }
  }

  function renderTooltipItem (countiesName) {

    return (<div className="counties-tooltip-context">
      {
        countiesName.slice(0, 11).map((item, index) => {         
          return (
            <span key={`${item}`}>
              {index === 10 ? "..." : item}
            </span>
          )
        })
      }
    </div>)
  }

  function renderCountiesColumn (rowItem, indexRow) {
    const { counties } = rowItem;
    if (counties && Array.isArray(counties) && counties.length > 0) {
      const countiesName = counties.map((item) => {
        return item.name
      })

      if (countiesName.length <= 2) {
        return (
          <span className="utility__counties-name-column">
            {
              countiesName.join(', ')
            }
          </span>
        )
      }

      return (<Tooltip childrenTooltip={renderTooltipItem(countiesName)} key={`${indexRow}-tooltip-utility__counties-name`} align="bottom">
              <span className="utility__counties-name-column">
                {
                  countiesName.join(', ')
                }
              </span>
            </Tooltip>)
    }
    return null;
  }

  function checkSelectedCountiesByUtility (rowItem) {
    const { counties } = rowItem;
    setSelectedCounty(counties.map((item) => {
      return {
        ...item,
        name: `${item.name}, ${item.state}`,
      }
    }))
  }

  const handleSearchCounties = debounce((value, itemsAll) => {
    const data = itemsAll.filter((item) => {
      return item.searchingName.toLowerCase().includes(value.toLowerCase());
    });

    setCounty(data);
    setLoadingSearch(false)
  }, 500, 'later');

  useEffect(() => {
    handleFetchDefault(filter)
    handleFetchCounty()
  }, [])

  const columns = [
    {
      key: "Eia_Id",
      header: <div>Eia_Id</div>,
      colSpan: 2,
      sortable: true,
      renderItem: (record) => {
        const { eia_Id } = record;
        return (<Text>{eia_Id}</Text>)
      }
    },
    {
      key: "Name",
      header: <div>{t("utility.title")}</div>,
      colSpan: 6,
      sortable: true,
      renderItem: (record) => {
        const { name } = record

        return (
          <div className="Roles__contain__user">
            <Text className="table-tootip-float " as="div" lines={1} popover>
              {name || "N/A"}
            </Text>
          </div>
        )
      }
    },
    {
      key: "WebsiteUrl",
      header: <div>{t("utility.website")}</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { websiteUrl } = record

        return (
          <>
            {
              websiteUrl ? (
                <a className="utility__link" href={websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://") ? 
                        websiteUrl : `https://${websiteUrl}`} target="_blank" rel="noreferrer">
                  {websiteUrl}
                </a>
              ) : (<>N/A</>)
            }
          </>
        )
      }
    },
    {
      key: "counties",
      header: <div>Counties</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record, index) => renderCountiesColumn(record, index)
    },
    {
      key: "CommercialRate",
      header: <div>{t("utility.comercial_rate")}</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { commercialRate } = record
        return (
          <div >
            {commercialRate ? commercialRate.toFixed(0) : 0}
          </div>
        )
      }
    },
    {
      key: "IndustrialRate",
      header: <div>{t("utility.industrial_rate")}</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { industrialRate } = record
        return (
          <div >
            {industrialRate ? industrialRate.toFixed(0) : 0}
          </div>
        )
      }
    },
    {
      key: "ResidentialRate",
      header: <div>{t("utility.residential_rate")}</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { residentialRate } = record
        return (
          <div >
            {residentialRate ? residentialRate.toFixed(0) : 0}
          </div>
        )
      }
    },
    {
      className: "tab-center",
      key: "Project",
      header: <div>{t("utility.action")}</div>,
      colSpan: 2,
      renderItem: (record) => {
        return (
          <div className="table-groupIcon">
            <IconEditRoles onClick={() => {
              setDataItem(record); 
              setShowModalEdit(true); 
              setObjectError({}); 
              getAllProgramByUtilityId({
                utilityId: record.id,
              });
              checkSelectedCountiesByUtility(record)
            }} />
          </div>
        )
      }
    }
  ];

  return (

    <div className="Roles">
      <Loading loading={loading} />
      <div className="Roles__contain">
        <div className="Roles__contain__title">
          {t("side_menu.utilityManagement")}
        </div>
        <div className="Roles__contain__subTitle">
          {t("utility.utility")}
        </div>

        <DataTable
          sortable
          noDataText="No data"
          data={items}
          columns={columns}
          orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
          onSort={str => {
            if (str) {
              const arrayValue = str.split("-")
              if (arrayValue[0] && arrayValue[1]) {
                const dataBody = {
                  ...filter,
                  orderField: arrayValue[0],
                  isDesc: arrayValue[1] !== 'asc',
                  skip: 0,
                  count: pageSize,
                }
                setFilter(dataBody)
                handleFetchDefault(dataBody)
              }
            }
          }}
          topChildren={(
            <TopFilterTable
              isSearch
              isAdd
              searchText={filter.SearchQuery}
              pageSize={filter.count}
              textButton="Create Utility"
              onPageSizeChange={(value) => { handlePageSizeChange(value) }}
              onClick={() => { setDataItem({}); setShowModalEdit(true); setObjectError({}); setSelectedCounty([]) }}
              onSearch={(value) => {
                const newFilter = {
                  ...filter,
                  skip: 0,
                  SearchQuery: value,
                }
                setFilter(newFilter)
                if (handleSearch) {
                  clearTimeout(handleSearch)
                  handleSearch = null
                }
                handleSearch = setTimeout(() => {
                  handleFetchDefault(newFilter, true)
                }, 1000)
              }}
            />
          )}
        >
          <Pagination data={
            {
              totalItems: total,
              pageSize: filter.count,
              currentPage: filter.skip + 1
            }
          }
            onPageChange={(value) => {
              handlePageChange(value)
            }}
          />
        </DataTable>


        <Modal
          onHide={() => { setShowModalEdit(false); setValuePrograme([]); setSelectedCounty([]) }}
          handleOnSave={() => {
            handleOnSaveModal()
          }}
          size="md"
          centered
          show={isShowModalEdit}
          title={`${dataItem.id ? "Update" : "Create"} Utility`}
        >
          <div>
            <TopLabelFormGroup
              label={t("utility.title")}
              controlId="name"
              placeholder="Input Name"
              value={dataItem.name || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
              status={objectError.name ? Status.DANGER : null}
              message={objectError.name}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label="Eia Id"
              placeholder="Input Id"
              controlId="eia_Id"
              value={dataItem.eia_Id || ""}
              onChange={(name, value) => {
                handleOnChange(name, value ? value : null)
              }}
              isTextArea={false}
              status={objectError.eia_Id ? Status.DANGER : null}
              message={objectError.eia_Id}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("utility.website")}
              placeholder="Input Website"
              controlId="websiteUrl"
              value={dataItem.websiteUrl || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("utility.comercial_rate")}
              placeholder="Input Comercial Rate"
              controlId="commercialRate"
              value={dataItem.commercialRate || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("utility.industrial_rate")}
              placeholder="Input Industrial Rate"
              controlId="industrialRate"
              value={dataItem.industrialRate || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />
            <TopLabelFormGroup
              className="Roles__form-group"
              label={t("utility.residential_rate")}
              placeholder="Input Residential Rate"
              controlId="residentialRate"
              value={dataItem.residentialRate || ""}
              onChange={(name, value) => {
                handleOnChange(name, value)
              }}
              isTextArea={false}
            />
            <TopLabelSearchMultipleGroup
              className="Roles__form-group"
              label="Program"
              placeholder="Search Program"
              values={valuesProgram}
              loading={loadingSearch}
              onChangeText={(text) => {
                if (handleSearchProgram) {
                  clearTimeout(handleSearchProgram)
                  handleSearchProgram = null
                }
                handleSearchProgram = setTimeout(() => {
                  getProgramBySearch(text)
                }, 1000)
              }}
              onChange={(values) => {
                setValuePrograme(values)
              }}
              options={searchProgram}
            />

            <TopLabelSearchMultipleGroup
              className="Roles__form-group"
              label="Counties"
              placeholder="Search County"
              values={selectedCounty}
              loading={loadingSearch}
              onChangeText={(text) => {
                setLoadingSearch(true)
                handleSearchCounties(text, allCounty)
              }}
              onChange={(values) => {
                setSelectedCounty(values)
              }}
              options={county}
            />
          </div>
        </Modal>
      </div>
    </div>

  )
}

UtilityView.propTypes = {

}

UtilityView.defaultProps = {


}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = () => ({


})

export default connect(mapStateToProps, mapDispatchToProps)(UtilityView)
