import { fromJS } from 'immutable'

import { createReducer } from '../../utils/reduxHelpers'
import { CHANGE_PROGRAMS_SUCCESS } from '../constants/programActions'

const initialState = fromJS({
  programs: []
})

const changePrograms = (state, action) => {
  const { items } = action.payload
  return state.set('programs', items)
}

export default createReducer(initialState, {

  [CHANGE_PROGRAMS_SUCCESS]: changePrograms
})
