import { createSelector } from 'reselect'
import { ProgramFilterType } from "../../constants/common";
import { filter } from 'lodash';

const productsReducer = state => state.get('productsReducer')
const filterItems = createSelector(productsReducer, products => products.get("filterItems"))

export const productSelectors = {
  selectItems: createSelector(productsReducer, state => {
    const items = state.get('items')
    return Array.isArray(items) ? items : []
  }
  ),
  selectFilterItems: createSelector(filterItems, items => {
    return Array.isArray(items) ? items : []
  }),
  countCounties: createSelector(filterItems, items => {
    if (Array.isArray(items)) {
      return items.filter(item => item.type === ProgramFilterType.County).length
    }
    return 0;
  }),
  countCategories: createSelector(filterItems, items => {
    if (Array.isArray(items)) {
      return items.filter(item => item.type === ProgramFilterType.Category).length
    }
    return 0;
  }),
  countUtilities: createSelector(filterItems, items => {
    if (Array.isArray(items)) {
      return items.filter(item => item.type === ProgramFilterType.Utility).length
    }
    return 0;
  }),
  countProducts: createSelector(filterItems, items => {
    if (Array.isArray(items)) {
      return items.filter(item => item.type === ProgramFilterType.Product).length;
    }
    return 0;
  }),
  productDataForHeaderDropdown: createSelector(productsReducer, state => {
      const items = state.get('productDataForHeaderDropdown')
      return Array.isArray(items) ? items : []
  }),
}
