
import BaseService from "./baseService";
import programUitilityApi from "../api/programUitilityApi";
import { getQueryString } from "../utils/common";


class ProgramUtilityService extends BaseService {
  addOrUpdateMultipleByUtilityId(query, params) {
    const newQuery = getQueryString(query)
    return programUitilityApi.addOrUpdateMultipleByUtilityId(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  addOrUpdateMultipleByProgramId(query, params) {
    const newQuery = getQueryString(query)
    return programUitilityApi.addOrUpdateMultipleByProgramId(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new ProgramUtilityService();
