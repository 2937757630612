import { getCookie } from "utils/cookie";
import BaseApi from "./baseApi";
import { common } from "../constants";
import { DEFAULT_PRODUCT_PAGE_SIZE } from "../constants/common";

class CoreApi extends BaseApi {

  getCounty(queryString, headers, isAuthen = true) {
    return super.execute(this.Methods.GET, `${this.Urls.baseUrl}County/get-all${queryString}`, headers, isAuthen)
      .then((res) => {
        return res;
      });
  }

  getProgram(data) {
    return super.execute(this.Methods.POST, `${this.Urls.baseUrl}Program/filter/`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  getByCountyName(query) {
    return super.execute(this.Methods.GET, `${this.Urls.baseUrl}Utility/GetByCountyName${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  getByUtilityId(query) {
    return super.execute(this.Methods.GET, `${this.Urls.baseUrl}Program/GetByUtilityId${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  getProgramByUtilityId(id, rebateValuePageIndex, filterItems = []) {
    let url =  `${this.Urls.baseUrl}${this.Urls.programs.GetProgramMetadatas}`;

    // In request:  rebatePageIndex is 0 by default,
    // consider not sending it or using default when it is null or not a number
    const body = {
      id: id,
      ...(rebateValuePageIndex !== null && !isNaN(rebateValuePageIndex)  && {rebatePageIndex: rebateValuePageIndex}),
      ...(filterItems && filterItems.length > 0 && {filterData: filterItems})
    };
    return super.execute(this.Methods.POST, url, null, true, body)
      .then((res) => {
        return res;
      });
  }

  getQPLs(query) {
    return super.execute(this.Methods.GET, `${this.Urls.baseUrl}Product/QPLs${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  checkApplicationUrl(url) {
    return fetch(url, {
      method: "GET",
    }).then(resp => {
      return resp;
    });
  }

  getProductFilterData(query) {
    const manufacturers = getCookie(common.MANUFACTURER_KEY);
    const page = parseInt((query.page || 1) <= 0 ? 1 : query.page, 10);
    const skip = (page - 1) * DEFAULT_PRODUCT_PAGE_SIZE;

    let param = { skip, count: DEFAULT_PRODUCT_PAGE_SIZE };
    if (manufacturers) {
      param = {
        ...param,
        manufacturers
      }
    }

    if (query.searchText?.trim?.()) {
      param = {
        ...param,
        searchText: query.searchText?.trim()
      }
    }

    return super.execute(this.Methods.POST, `${this.Urls.product.getFilterData}`, null, true, param)
      .then((res) => res);
  }
}

export default new CoreApi();
