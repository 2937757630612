/* eslint-disable */
export const DateTimeFormat = {
  DATE_TIME: "MMM DD, YYYY hh:mm A",
  SHORT: "MMM DD, YYYY",
  DATE: "MM/DD/YYYY",
  TIME: "hh:mm",
  AM_PM: "A",
  ISO_DATE_TIME: "YYYY-MM-DD[T]HH:mm:ss",
  WEEK_TIME: "MMM DD",
  DATE_PICKER: "dddd, MMMM DD",
  CREATED_DATE: "ddd, MMM DD",
  QUERY_DATE_TIME: "YYYY-MM-DD",
  IMPORT_DATE_TIME: 'M/D/YYYY HH:mm'
};

export const DAYS_OF_WEEK_ORDER = [0, 1, 2, 3, 4, 5, 6]
export const DEFAULT_PRODUCT_PAGE_SIZE = 30;
export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_DOCUMENT_COUNT = 30;
export const DEFAULT_CLIENT_NOTE_COUNT = 5;
export const MAX_CLIENT_SYMPTOMS = 5;
export const DEFAULT_TAGS_PAGE_SIZE = 30;
export const DEFAULT_STANDARD_ACTIVITIES_PAGE_SIZE = 30;
export const DOCTORS_DROPDOWN_LIMIT = 20;
export const COACHES_DROPDOWN_LIMIT = 20;

export const TOKEN_KEY = 'token';
export const MANUFACTURER_KEY = 'manufacturers';
export const DEFAULT_SEARCHBAR_MIN_LENGTH = 3;

export const BOOTSTRAP_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  LIGHT_BLUE: 'light-blue',
  OUTLINE: 'outline',

  OUTLINE_PRIMARY: 'outline-primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  OUTLINE_SUCCESS: 'outline-success',
  OUTLINE_WARNING: 'outline-warning',
  OUTLINE_DANGER: 'outline-danger',
  OUTLINE_INFO: 'outline-info',
  OUTLINE_LIGHT: 'outline-light',
  OUTLINE_DARK: 'outline-dark',
  OUTLINE_LIGHT_BLUE: 'outline-light-blue',

  LINK: 'link',
  PILLS: 'pills',
}

export const FILTER_TYPES = {
  STRING_SEARCH: 'string',
  DROPDOWN_FILTER: 'dropdown-filter',
}

export const DEFAULT_TOAST_OPTIONS = {
  timeOut: 3000,
  // timeOut: 0,
  closeOnToastrClick: true,
  showCloseButton: true,
  newestOnTop: false,
  position: 'bottom-right',
  transitionIn: 'bounceIn',
  transitionOut: 'fadeOut',
}
export const GENERAL_ERROR_MESSAGE = 'Something went wrong. Please try again'

export const TERM_OF_USE = process.env.REACT_APP_HOME_PAGE
export const PRIVACY_POLICY = process.env.REACT_APP_HOME_PAGE
export const CONTACT_US = process.env.REACT_APP_HOME_PAGE

export const ProgramFilterType = {
  County: "Counties",
  Category: "category",
  Utility: "Utilities",
  RebatePrograms: "Rebate Programs",
  State: "State",
  Product: "Product"
}

export const MAPBOX_CONFIG = {
  REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN || "pk.eyJ1IjoiZGFuaWVsLXJlYmF0ZWJ1cyIsImEiOiJja25jNzI3OXoxeDFwMnhtbG1saWpoMXpuIn0.w8I6muosL_e8pKriBjAMDw",
  REACT_APP_MAPBOX_STYLE: process.env.REACT_APP_MAPBOX_STYLE || "mapbox://styles/daniel-rebatebus/cknc72oye2btr17nq4a6ki3dw"
}

export const countyHistoryTmp = "countyHistoryTmp"
export const countyHistory = "countyHistory"
export const utilityHistoryTmp = "utilityHistoryTmp"
export const utilityHistory = "utilityHistory"
export const categoryHistory = "categoryHistory"
export const categoryHistoryTmp = "categoryHistoryTmp"
export const productHistory = "productHistory"

export const ProjectCap = "ProjectCap"
export const MaterialCap = "MaterialCap"
export const LaborCap = "LaborCap"
export const Active = "active"
export const Closed = "closed"
export const Upcoming = "upcoming"
export const Administrator = "Administrator"
// Trial
export const NewMember = "NewMember"
export const PremiumMember = "PremiumMember"

export const RebateValueType = [
  {
    name: "Default",
    value: "Default",
    label: "Default",
  },
  {
    name: "DLCPremium",
    value: "DLCPremium",
    label: "DLCPremium",
  }
]
