/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'
import { coreService } from "../../../services"
import { Drawer, DrawerUtilites } from '../../common'
import { handleGetParentRootCategory } from '../../../utils/common'
import { useTranslation } from 'react-i18next'

export const GroupRightSidebar = (props) => {
  const {
    isShowCounty,
    setIsShowCounty,
    isShowUtilities,
    setIsShowUtilities,
    filterData,
    detail,
    utilityId,
    handleReplaceUrl,
    categories,
    onClose,
    filterItems
  } = props;

  const [t] = useTranslation();

  const [county, setCounty] = useState({})
  const [utilites, setUtilites] = useState({})
  const [programDetail, setProgramDetail] = useState({})
  const [qpls, setQpls] = useState([])
  const [indexActive, setIndexActive] = useState(-1)
  const [isGettingProgramDetail, setIsGettingProgramDetail] = useState(true)

  function getProgramMetadatas(filterData, detail, index, rebateValuePageIndex) {
    if (rebateValuePageIndex === 0) {
      setIsGettingProgramDetail(true)
    }
    coreService.getProgramByUtilityId(filterData.id, rebateValuePageIndex, filterItems).then(res => {
      const { success, data } = res
      if (success) {
        const newCategroy = []
        const categoryRoots = []
        const { rebates = [] } = data
        rebates.forEach(item => {
          if (item.categories) {
            item.categories.forEach(el => {
              const index = newCategroy.findIndex(el2 => el2.id === el.id)
              if (index === -1) {
                newCategroy.push(el)
              }
            })
          }
        })

        newCategroy.forEach(category => {
          handleGetParentRootCategory(category, categories, categoryRoots)
        })

        setProgramDetail(data)
        setIndexActive(index)
        setUtilites({
          ...utilites,
          ...detail,
          categoryRoots
        })

      } else {
        const { name } = detail
        toastr.warning(`${name} no Programs available `)
      }

      setIsGettingProgramDetail(false)
    })
  }

  function getByUtilityId(filterData, detail) {
    coreService.getByUtilityId(filterData).then(res => {
      const { success, data } = res
      const { programId, utility = {}, name: stateName, countyName } = detail

      if (success && data.entities && data.entities.length) {
        const newUtitlies = {
          ...detail,
          items: data.entities
        }

        setIsShowCounty(false, true)
        setIsShowUtilities(true)

        const { name: utilityName } = utility
        if (data.entities.length === 1) {

          handleReplaceUrl(stateName, countyName, utilityName)
          getProgramMetadatas({ id: data.entities[0].id, }, newUtitlies, 0)

        } else if (programId) {
          setUtilites({ ...newUtitlies })
          data.entities.forEach((item, index) => {
            if (item.id === programId) {
              setIndexActive(index)
              handleReplaceUrl(stateName, countyName, utilityName)
            }
          })
        } else {
          setUtilites({ ...newUtitlies })
          setIndexActive(-1)
          handleReplaceUrl(stateName, countyName, utilityName)
        }
      } else {
        // show Utiliy side bar with no program available APP-409
        setIsShowCounty(false);
        setIsShowUtilities(true);
        setUtilites(detail);
        toastr.warning(`${utility.name} ${t("utility.not_found_rebate")}`)
      }
    })
  }

  function getByCountyName(filterData, detail) {
    coreService.getByCountyName(filterData).then(res => {
      const { success, data } = res

      if (success && data.entities && data.entities.length) {
        const { name } = filterData
        const { name: state } = detail
        if (data.entities.length > 1) {

          setIsShowCounty(true)
          setIndexActive(-1)
          setIsShowUtilities(false, true)


          handleReplaceUrl(state, name)
        } else {
          const newItem = data.entities[0]
          if (newItem.programNumber) {
            getByUtilityId({
              utilityId: newItem.id,
              skip: 0,
              count: 1000
            }, {
              ...detail,
              utility: newItem
            })
          } else {
            setIsShowCounty(true)
            setIsShowUtilities(false, true)
          }
        }

        setCounty({
          ...county,
          name: state,
          countyName: name,
          items: data.entities
        })
      } else {
        toastr.warning(`${filterData.name} has no Rebates available  `)
      }

    })
  }



  function getQPLs() {
    coreService.getQPLs().then(res => {
      const { success, data } = res
      if (success) {
        setQpls(data.items)
      }
    })
  }

  useEffect(() => {
    getQPLs()
  }, [])

  useEffect(() => {
    if (filterData.name) {
      filterData.state = detail.name;
      getByCountyName(filterData, detail)
    }
  }, [filterData])

  useEffect(() => {
    if (utilityId) {
      getByUtilityId({ utilityId, skip: 0, count: 1000 }, detail)
    }
  }, [utilityId])

  useEffect(() => {
    if (filterItems.length > 0 && programDetail && programDetail.id && !isGettingProgramDetail && !isShowCounty && isShowUtilities) {
      getProgramMetadatas(programDetail, {}, 0, 0);
    }
  }, [filterItems])

  return (
    <div className="rebates">
      <Drawer onClick={(el, detail) => {
        setTimeout(() => {
          getByUtilityId({
            utilityId: el.id,
            skip: 0,
            count: 1000
          }, {
            utility: el,
            ...detail,
          })
        }, 500)
      }}
        onClose={() => {
          setIsShowCounty(false)
          onClose()
        }}
        isShow={isShowCounty}
        data={county}
        className="drawer-county"
      />
      <DrawerUtilites
        onClick={(el, index, rebateValuePageIndex) => {
          getProgramMetadatas({ id: el.id, }, {}, index, rebateValuePageIndex)
        }}
        categories={categories}
        indexActive={indexActive}
        data={utilites}
        onClose={() => {
          setIsShowUtilities(false);
          onClose()
        }}
        isShow={isShowUtilities}
        itemData={programDetail}
        qpls={qpls}
        reloadingRebate={isGettingProgramDetail}
      />
    </div>
  )
}

/* istanbul ignore next */
GroupRightSidebar.propTypes = {
  isShowCounty: PropTypes.bool,
  isShowUtilities: PropTypes.bool,
  setIsShowCounty: PropTypes.func,
  setIsShowUtilities: PropTypes.func,
  filterData: PropTypes.object,
  detail: PropTypes.object,
  utilityId: PropTypes.string,
  handleReplaceUrl: PropTypes.func,
  categories: PropTypes.array,
  onClose: PropTypes.func,
  filterItems: PropTypes.array,
}

GroupRightSidebar.defaultProps = {
  isShowCounty: false,
  isShowUtilities: false,
  setIsShowCounty: () => { },
  setIsShowUtilities: () => { },
  filterData: {},
  detail: {},
  utilityId: '',
  handleReplaceUrl: () => { },
  categories: [],
  onClose: () => { },
  filterItems: []
}


export default GroupRightSidebar