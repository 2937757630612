import BaseService from "./baseService";
import userApi from "../api/userApi";
import { User } from '../models'
import { getQueryString } from "../utils/common";

class UserService extends BaseService {
  getUserInfo() {
    return userApi.getUserInfo().then(response => {
      return {
        data: new User(response.data),
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getList(query = {}) {
    const newQuery = getQueryString(query)
    return userApi.getList(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  create(params) {
    return userApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return userApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  delete(query) {
    const newQuery = getQueryString(query)
    return userApi.delete(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  assignMultipleRoles(query, params) {
    const newQuery = getQueryString(query)
    return userApi.assignMultipleRoles(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  uploadAvatar(params = {}) {
    return userApi.uploadAvatar(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  deleteAvatar(params = {}) {
    return userApi.deleteAvatar(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  startTrial() {
    return userApi.startTrial().then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError)
  }

  setPremiumExpiration(params = {}) {
    return userApi.setPremiumExpiration(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new UserService();
