import get from "lodash/get";

export default class Category {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data)
    }
  }

  init(data) {
    if (!data) return;
    this.id = get(data, 'id', null)
    this.created = get(data, 'created', null)
    this.modified = get(data, 'modified', null)
    this.name = get(data, 'name', null)
    this.parentCategoryId = get(data, 'parentCategoryId', null)
    this.qplId = get(data, 'qplId', null)
    this.isParent = get(data, 'isParent', null)
  }

  toJSON() {
    const { id, created, modified, name, parentCategoryId, qplId, isParent } = this;
    return { id, created, modified, name, parentCategoryId, qplId, isParent };
  }
}
