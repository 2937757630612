/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import { Scrollbars } from 'react-custom-scrollbars';
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from "lodash"
import moment from "moment"
import { toastr } from 'react-redux-toastr'
import { emptyTable } from '../../assests/images'
import { rebateService } from "../../services"
import { ProgramFilterType } from "../../constants/common";
import { productSelectors } from '../../redux/selectors/productSelectors';
import { programSelectors } from '../../redux/selectors/programSelectors';
import { bulkImportActions, programActions } from "../../redux/actions"
import { DataTable, Loading, Text, Tooltip, EmptyState, Button, Modal } from "../../components/common"
import { convertExcelToJson, downlLoadFile } from '../../utils/common'
import { IconRX, IconCalCulate } from "../../assests/icons"
import { pathKeys, common } from '../../constants'
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/dates';

const pageSize = 50
export const BulkRebates = (props) => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize
  })
  const { setIsBulk, filterItems, setRebateItems } = props
  const [isShowExportModal, setIsShowExportModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFirstFresh, setIsFirstFresh] = useState(true)
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState([])
  const uploadRef = useRef(null);

  function handleFetchDefault(filterData) {
    setLoading(true)
    rebateService.exportFilter(filterData).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        const newData = data.entities;
        setTotal(data.totalEntities)
        setItems(newData)
        if (isFirstFresh) {
          setIsFirstFresh(false)
        }
      } else {
        setItems([])
      }
      setLoading(false)
    })
  }

  function handleFetchRebates(filter) {
    setLoading(true)
    rebateService.exportFilter(filter).then(res => {
      const { success, data } = res
      if (success && data.entities) {
        setItems([...items, ...data.entities])
      }
      setLoading(false)
    })
  }


  function handleExportAll() {
    setLoading(true)
    rebateService.exportAll().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "rebatesAll")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportBlank() {
    setLoading(true)
    rebateService.exportBlank().then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "rebatesBlank")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  function handleExportByFilter(filterData) {
    setLoading(true)
    rebateService.exportByFilter(filterData).then(res => {
      const { success } = res
      if (success) {
        setIsShowExportModal(false)
        downlLoadFile(res, "rebatesList")
      } else {
        toastr.error("Actions failed please try again!")
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  async function handleImportFile(file) {
    const result = await convertExcelToJson(file)
    const newData = []
    result.forEach((item, index) => {
      newData.push({
        ...item,
        StartDate: item.StartDate && moment(item.StartDate, common.DateTimeFormat.IMPORT_DATE_TIME).isValid() ? moment(item.StartDate, common.DateTimeFormat.IMPORT_DATE_TIME).toDate() : null,
        ExpirationDate: item.ExpirationDate && moment(item.ExpirationDate, common.DateTimeFormat.IMPORT_DATE_TIME).isValid() ? moment(item.ExpirationDate, common.DateTimeFormat.IMPORT_DATE_TIME).toDate() : null,
        Created: moment().format(common.DateTimeFormat.IMPORT_DATE_TIME),
        Requirement1: item['Existing Wattage'],
        Requirement2: item['Controls Required'],
        Requirement3: item['Minimum Watts Saved (percentage)'],
        Requirement4: item['New Watts per Foot'],
        Requirement5: item['New Wattage'],
        Requirement6: item['New Length'],
        Requirement7: item['New Lumens'],
        Requirement8: item['Watts Controlled'],
        Requirement9: item['New Efficacy'],
        Requirement10: item['Motion Sensors Required'],
        Requirement11: item['Minimum Watts Saved'],
        Requirement12: item['New Lifetime'],
        Requirement13: item['Operating Hours'],
        KeepChange: true
      })
    })
    setRebateItems(newData)
    navigate(pathKeys.BULK_REBATES_IMPORT)
  }

  useEffect(() => {
    const locations = []
    const categories = []
    const utilities = []
    const products = [];

    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        locations.push(item.id)
      } else if (item.type === ProgramFilterType.Category) {
        categories.push(item.id)
      } else if (item.type === ProgramFilterType.Utility) {
        utilities.push(item.id)
      } else if (item.type === ProgramFilterType.Product) {
        products.push(item.id)
      }
    })
    const dataBody = {
      skip: 0,
      count: pageSize,
      locations,
      categories,
      utilities,
      products
    }

    setFilter(dataBody)
    handleFetchDefault(dataBody)
  }, [filterItems])


  const columns = [
    {
      key: "Program",
      colSpan: 6,
      header: <div>Programs Name</div>,
      renderItem: (record) => {
        const { name } = _.get(record, "program", {});
        return <Text className="table-tootip-float" as="div" lines={1} popover>{name}</Text>
      }
    },
    {
      key: "Name",
      colSpan: 6,
      sortable: true,
      header: <div>Name</div>,
      renderItem: (record) => {
        const { name } = record
        return <Text className="table-tootip-float" as="div" lines={1} popover>{name}</Text>
      }
    },
    {
      key: "StartDate",
      colSpan: 4,
      sortable: true,
      header: <div>Start Date</div>,
      renderItem: (record) => {
        const { startDate } = record
        return <div className="table-time"> {startDate ? formatDate(startDate) : 'N/A'}</div>
      }
    },
    {
      key: "ExpirationDate",
      header: <div>Expiration</div>,
      sortable: true,
      colSpan: 4,
      renderItem: (record) => {
        const { expirationDate } = record
        return (
          <>
            {
              formatDate(expirationDate)
            }
          </>
        )

      }
    },
    {
      key: "Notes",
      sortable: true,
      header: <div>Notes</div>,
      colSpan: 4,
      renderItem: (record) => {
        const { notes } = record
        return (
          <>
            {notes ?
              (
                <Text className="table-tootip-float" as="div" lines={2} popover>
                  {notes}
                </Text>
              ) : "N/A"}

          </>
        )
      }
    },
    {
      key: "RebateType",
      sortable: true,
      header: <div>Type</div>,
      colSpan: 2,
      renderItem: (record) => {
        const { rebateType } = record
        return (
          <div >
            {rebateType === "Calculated" ? (
              <Tooltip childrenTooltip="Calculated" >
                <IconCalCulate />
              </Tooltip>
            ) : (
              <Tooltip childrenTooltip="Prescriptive" >
                <IconRX />
              </Tooltip>
            )}
          </div>
        )
      }
    },
    {
      key: "MinCustomerContribution",
      sortable: true,
      header: <div className="min_Customer">Min Customer Contribution</div>,
      colSpan: 6,
      renderItem: (record) => {
        const { minCustomerContribution } = record
        return (
          <div >
            {minCustomerContribution}
          </div>
        )
      }
    },
  ];

  return (
    <>
      <Loading loading={loading} />

      <Scrollbars
        onScrollFrame={(values) => {
          const { top } = values
          const { skip, count } = filter
          if (top >= 0.9 && (total >= (skip + 1) * count)) {
            const filterNow = {
              ...filter,
              skip: skip + 1
            }
            setFilter(filterNow)
            const fillterCurrent = {
              ...filterNow,
              skip: (skip + 1) * count
            }
            handleFetchRebates(fillterCurrent)
          }
        }}
        autoHide >
        <div className="bulk">
          <div className="bulk__WrapButton">
            <Button
              onClick={() => {
                setIsShowExportModal(true)
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Export" />
            <Button
              onClick={() => {
                uploadRef.current.click();
              }}
              className="modal-content__button"
              typeClass="second"
              size="md"
              text="Import" />
            <input
              type="file"
              className="hide"
              ref={uploadRef}
              onChange={(e) => {
                const file = e.target.files[0]
                handleImportFile(file)
              }}
              multiple={false}
            />
          </div>
          <DataTable
            sortable
            data={items}
            columns={columns}
            orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
            onSort={str => {
              if (str) {
                const arrayValue = str.split("-")
                if (arrayValue[0] && arrayValue[1]) {
                  const dataBody = {
                    ...filter,
                    orderField: arrayValue[0],
                    isDesc: arrayValue[1] !== 'asc',
                    skip: 0,
                    count: pageSize,
                  }
                  setFilter(dataBody)
                  handleFetchDefault(dataBody)
                }
              }
            }}
            noDataText={(
              <EmptyState
                className="custom-empty-state-second"
                photo={emptyTable}
                text={() => {
                  return (
                    <>
                      <div className="custom-empty-state-second__title">
                        NO DATA
                      </div>

                    </>
                  );
                }}
              />
            )}
          />
        </div>

      </Scrollbars>

      <Modal
        onHide={() => { setIsShowExportModal(false) }}
        size="md"
        centered
        isHiddenFooter
        show={isShowExportModal}
        title="Export Rebates to CSV"
      >
        <div className="bulk__center">
          Select which Rebates to include in the export
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                const newFilter = {
                  ...filter,
                  skip: 0,
                  count: total
                }
                handleExportByFilter(newFilter)
              }}
              typeClass="second"
              size="md"
              text="Current Filtered List" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains all Rebates that match the current filters in the list
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportAll()
              }}
              typeClass="second"
              size="md"
              text="All Rebates" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains all Rebates in RebatesBus
            </p>
          </div>
        </div>
        <div className="row bulk__row">
          <div className="col-12 col-md-6 bulk__col">
            <Button
              onClick={() => {
                handleExportBlank()
              }}
              typeClass="second"
              size="md"
              text="Blank Template" />
          </div>
          <div className="col-12 col-md-6 bulk__col">
            <p className="bulk__text">
              Contains only the column headers for Rebates
            </p>
          </div>
        </div>
      </Modal >

    </>

  )
}

BulkRebates.propTypes = {
  setIsBulk: PropTypes.func,
  setRebateItems: PropTypes.func,
  filterItems: PropTypes.array
}

BulkRebates.defaultProps = {
  setIsBulk: () => { },
  filterItems: [],
  setRebateItems: () => { },
}

const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
    items: programSelectors.selectProgramsItems(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setItems: (items) => dispatch(programActions.changePrograms(items)),
  setRebateItems: (items) => dispatch(bulkImportActions.changeRebateImportData(items)),

})

export default connect(mapStateToProps, mapDispatchToProps)(BulkRebates)
