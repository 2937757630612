
import BaseService from "./baseService";
import categoryApi from "../api/categoryApi";
import { getQueryString } from "../utils/common";


class CategoryService extends BaseService {
  getCategory(query = {}, headers, isAuthen = true) {
    const newQuery = getQueryString(query)
    return categoryApi.getCategory(newQuery, headers, isAuthen).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  create(params) {
    return categoryApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return categoryApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  delete(query) {
    const newQuery = getQueryString(query)
    return categoryApi.delete(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  rebateAndProductNumber(params) {
    return categoryApi.rebateAndProductNumber(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  updateWithRebateProductNumber(query, params) {
    const newQuery = getQueryString(query)
    return categoryApi.updateWithRebateProductNumber(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }
}

export default new CategoryService();
