import { fromJS } from 'immutable'

import { createReducer } from '../../utils/reduxHelpers'
import { CHANGE_PRODUCT_DATA_FOR_HEADER_DROPDOWN, CHANGE_PRODUCT_FILTER_SUCCESS, CHANGE_PRODUCT_SUCCESS } from '../constants/productActions'

const initialState = fromJS({
  items: [],
  filterItems: [],
  programs: [],
  productDataForHeaderDropdown: []
})

const changeItems = (state, action) => {
  const { items } = action.payload
  return state.set('items', items)
}
const changeFilterItems = (state, action) => {
  const { items } = action.payload
  return state.set('filterItems', items)
}

const changeProductDataForHeaderDropdown = (state, action) => {
  const { items } = action.payload
  return state.set('productDataForHeaderDropdown', items)
}

export default createReducer(initialState, {
  [CHANGE_PRODUCT_FILTER_SUCCESS]: changeFilterItems,
  [CHANGE_PRODUCT_SUCCESS]: changeItems,
  [CHANGE_PRODUCT_DATA_FOR_HEADER_DROPDOWN]: changeProductDataForHeaderDropdown
})
