/* eslint-disable no-sequences */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import queryString from 'query-string'
import * as XLSX from "xlsx";
import moment from 'moment'
import _ from "lodash"
import {
  Active,
  Closed,
  Upcoming,
  Administrator
} from "../constants/common";

export function nanoid(size = 21) {
  if(size <= 0) size = 21;
  
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  const nanoid = [];
  const bytes = new Uint8Array(size);
  const randomBytes = crypto.getRandomValues(bytes);
  
  for(const byte of randomBytes) {
    nanoid.push(alphabet[byte % alphabet.length]);
  }
  
  return nanoid.join('');
}
export function checkAuthorize(user) {
  const roles = _.get(user, "roles", []);

  const indexAutherize = roles.findIndex(item => item.roleType === Administrator)
  return indexAutherize !== -1
}

export function validateEmail(email) { // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePass(pass) {
  const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
  return passw.test(pass);
}

export function validateNumberPhone(number) {
  let c1 = number.substring(0, 4);
  if (c1 === "+855") {
    return true;
  }
  c1 = number.substring(0, 3);
  return c1 === "+60" || c1 === "+84";
}

export function isObjectUndefinedOrNull(obj) {
  return obj === undefined || obj === null;
}

export function isEmpty(obj) {
  return obj === undefined || obj === null || obj === "";
}

export function getShortVersionText(text, length) {
  return text.length <= length ? text : `${text.slice(0, length)}...`
}

export function replaceUnsetString(value) {
  return String(value).toLowerCase() === 'unset' ? '' : value
}

export function getModelPropDefaultValue(value, valueType, overrideValue) {
  if (value != null) return valueType === 'string' ? replaceUnsetString(value) : value
  if (value === undefined && overrideValue !== undefined) return overrideValue
  switch (valueType) {
    default:
    case 'date':
    case 'number':
    case 'any':
      return null
    case 'object':
      return {}
    case 'array':
      return []
    case 'boolean':
      return false
    case 'string':
      return ''
  }
}

export function getRandNum(from, to) {
  return Math.floor(Math.random() * to) + from
}

export function randomRGBA(opacity = null) {
  const o = Math.round;
  const r = Math.random;
  const s = 255;

  return `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},${opacity != null ? opacity : r().toFixed(1)})`;
}

function isYoutubeURL(src) {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = src.match(regExp)
  return match
}

function isVimeoURL(src) {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  const match = src.match(regExp)
  return match
}

export function detectVideoProvider(src) {
  if (typeof src !== 'string') return null
  if (isYoutubeURL(src)) return 'youtube'
  if (isVimeoURL(src)) return 'vimeo'
  return null
}

export function parseGridViewState(gridViewState = {}, sortingKeysMap = {}) {
  const {
    pageSize = 0,
    pageIndex = 10,
    sortBy = [],
  } = gridViewState
  // -- not support multi sorting at this time -->
  const { id: sortedField, desc } = sortBy[0] || {}
  const sort = sortedField ? `${sortingKeysMap[sortedField] || sortedField}.${desc ? 'desc' : 'asc'}` : null

  return {
    skip: pageSize * pageIndex,
    count: pageSize,
    sort,
  }
}

export const formatDocSize = docSizeByMB => {
  const docSize = docSizeByMB < 1
    ? `${Math.round(docSizeByMB * 1000)} KB`
    : `${Number(docSizeByMB).toFixed(2)} MB`
  return docSize
}


export const removeVietnameseAccent = (str) => {
  let newString = str
  newString = newString.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  newString = newString.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  newString = newString.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  newString = newString.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  newString = newString.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  newString = newString.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  newString = newString.replace(/đ/g, "d");
  newString = newString.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  newString = newString.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  newString = newString.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  newString = newString.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  newString = newString.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  newString = newString.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  newString = newString.replace(/Đ/g, "D");
  return newString;
}

const deleteObjectEmptyField = (obj = {}) => {
  const newObj = {};
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === "object") {
        newObj[key] = deleteObjectEmptyField(obj[key]); // recurse
      } else if (obj[key] != null) {
        newObj[key] = obj[key]; // copy value
      }
    });
  }
  return newObj;
};

export const getQueryString = (query = {}) => {
  const result = queryString.stringify(
    deleteObjectEmptyField(query)
  )
  if (!result) return ''
  return `?${result}`
}

export const searchTree = (nodeId, parent) => {
  const stack = [parent];
  while (stack.length) {
    const node = stack.pop();
    if (node.id === nodeId) {
      return node;
    }
    if (node.children) {
      stack.push(...node.children);
    }

  }
  return stack.pop() || null;
}

export const handleGetArraychild = (dataset) => {
  const hashTable = Object.create(null);
  dataset.forEach(aData => {
    return hashTable[aData.value] = { ...aData, children: [] }
  });
  const dataTree = [];
  dataset.forEach(aData => {
    if (hashTable[aData.parentId] && !hashTable[aData.parentId].children) {
      hashTable[aData.parentId].children = []
    }
    if (aData.parentId && hashTable[aData.parentId]) hashTable[aData.parentId].children.push(hashTable[aData.value])
    else dataTree.push(hashTable[aData.value])
  });
  return dataTree;
}

export const printTree = (child, slug, arrayString) => {
  slug = slug || '/';
  for (let i = 0; i < child.length; i++) {
    if (!child[i].children.length || child[i].isFull) {
      arrayString.push({
        ...child[i],
        textSub: `${slug + child[i].label}`
      })
    }
    if (child[i].children.length && !child[i].isFull) {
      printTree(child[i].children, `${slug + child[i].label} > `, arrayString)
    }
  }
}

export function handleRenderCardName(name) {
  let newCard = name
  if (newCard && newCard.indexOf("|")) {
    const arrayString = newCard.split("|")
    if (arrayString[1]) {
      newCard = arrayString[1]
    }
  }
  return newCard
}

export const numberToPrice = (v) => {
  if (v === 0) { return '0'; }
  v = v.toString();

  v = v.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  // v = v.split('.').join('*').split(',').join('.').split('*').join(',');
  return v;
}

export const sortBy = (field, reverse, primer) => {
  const key = primer ?
    (x) => {

      return primer(x[field] || '')
    } :
    (x) => {
      return x[field] || ''
    };

  reverse = !reverse ? 1 : -1;

  return (a, b) => {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
  }
}

export const handleRenderArrayText = (value = [], textLabel) => {
  let newText = ""
  if (Array.isArray(value)) {
    value.forEach((item, index) => {
      if (index === 0) {
        newText += item[textLabel]
      } else {
        newText += `, ${item[textLabel]}`
      }

    })
  }
  return newText
}


export const handleGetParentRootCategory = (category, arrayTree, arrayResult) => {
  for (let i = 0; i < arrayTree.length; i++) {
    const data = searchTree(category.id, arrayTree[i])
    if (data) {
      const index = arrayResult.findIndex(el => el.id === arrayTree[i].id)
      if (index === -1) {
        arrayResult.push({
          ...arrayTree[i],
          children: []
        })
      }
      break
    }
  }
}

export const handleRenderStatus = (isActive) => {
  if (isActive) {
    return Active
  } else if (typeof isActive === 'object') {
    return Upcoming
  }
  return Closed
}


export const handleGetTextParentbyChild = (text, parentId, arrayValue) => {
  let check = true
  for (let i = 0; i < arrayValue.length; i++) {
    if (arrayValue[i].value === parentId) {
      check = false
      parentId = arrayValue[i].parentId
      text = `${arrayValue[i].label} > ${text}`
      break
    }
  }
  if (check || !parentId) {
    return text
  }
  return handleGetTextParentbyChild(text, parentId, arrayValue)
}

const convertToJson = (csv) => {
  const lines = csv.split("\n");

  const result = [];

  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
}


export const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.readAsBinaryString(blob)
  })
}

export const convertExcelToJson = async (file) => {
  const bstr = await blobToData(file)
  const wb = XLSX.read(bstr, { type: "binary", raw: true });
  /* Get first worksheet */
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  /* Convert array of arrays */
  return XLSX.utils.sheet_to_json(ws);
}

export const JsonToFile = (data) => {
  const items = data
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(items[0])
  const csv = [
    header.join(','), // header row first
    ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  ].join('\r\n')

  const file = new File([csv], { type: 'text/csv' });
  return file
}

export const downlLoadFile = (response, name) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name || "fileExport"}.csv`);
  document.body.appendChild(link);
  link.click();
}

export const compareEqualFieldExcecl = (fieldOne, fieldTwo) => {
  let check = true
  if (!fieldOne) {
    fieldOne = ""
  }
  if (!fieldTwo) {
    fieldTwo = ""
  }

  if (fieldOne !== fieldTwo) {
    check = false
    if (fieldOne.toString().toLowerCase() === fieldTwo.toString().toLowerCase()) {
      check = true
    }
  }

  return check
}
export const compareEqualTimeFieldExcecl = (fieldOne, fieldTwo) => {
  if (!fieldOne) {
    fieldOne = ""
  }
  if (!fieldTwo) {
    fieldOne = ""
  }
  const newFieldOne = moment(fieldOne).format("MM-DD-YYYY")
  const newFieldTow = moment(fieldTwo).format("MM-DD-YYYY")

  return newFieldOne === newFieldTow
}

export const compareEqualString = (string1, string2) => {
  if (!string1 || !string2) {
    return false;
  }

  if (string1.length > 0 && string2.length > 0) {
    return string1.toLowerCase() === string2.toLowerCase();
  }

  return false;
}

export const checkImportBoolValue = (value) => {
  let isChecked = false;
  if ((value === true) || (value && value.toLowerCase() === "true")) {
    isChecked = true;
  }

  return isChecked;
}

export const handleGotoRebateWebsite = (websiteUrl) => {
  if (!websiteUrl || websiteUrl.length === 0) {
    return null;
  }
  const newUrl = websiteUrl.startsWith("http://") || websiteUrl.startsWith("https://") ? websiteUrl : `https://${websiteUrl}`
  window.open(newUrl, '_blank');
}

export const inIframe = () => {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}