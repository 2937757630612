/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from "lodash"
import { useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr'
import Card from "../../components/modules/Card";
import { emptyCard } from '../../assests/images'
import { Loading, DrawerUtilites, EmptyState } from "../../components/common"
import { coreService, programService } from "../../services"
import { productSelectors } from '../../redux/selectors/productSelectors';
import { dropDownCategorySelectors } from '../../redux/selectors/dropDownSelectors';
import { programSelectors } from '../../redux/selectors/programSelectors';
import { programActions } from "../../redux/actions"
import { ProgramFilterType } from "../../constants/common"
import { pathKeys, STATE_NAME, UTILITY_NAME, PROGRAM_NAME } from "../../constants/pathKeys"
import "./style.scss";

const pageSize = 30
export const CardView = (props) => {
  const [filter, setFilter] = useState({
    skip: 0,
    count: pageSize
  })
  const { filterItems, items, setItems, categories } = props
  const [isFirstFresh, setIsFirstFresh] = useState(true)
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const { state_name, utility_name, program_name } = useParams();
  const [isShowUtilities, setIsShowUtilities] = useState(false)
  const [utilites, setUtilites] = useState({})
  const [programDetail, setProgramDetail] = useState({})
  const [qpls, setQpls] = useState([])
  const [indexActive, setIndexActive] = useState(-1)
  const [isGettingProgramDetail, setIsGettingProgramDetail] = useState(true)

  const handleFetchProgram = (filter) => {
    setLoading(true)
    coreService.getProgram(filter).then(res => {
      setLoading(false)
      const { success, data } = res
      if (success && data.entities) {
        setItems([...items, ...data.entities])
      }

    })
  }

  function historyPush(path) {
    window.history.replaceState(null, null, path)
  }

  function getQPLs() {
    coreService.getQPLs().then(res => {
      const { success, data } = res
      if (success) {
        setQpls(data.items)
      }
    })
  }

  function handleFetchDefault(filterData) {
    setLoading(true)
    coreService.getProgram(filterData).then(res => {
      const { success, data } = res

      if (success && data.entities) {
        const newData = data.entities;
        setItems(newData)
        setTotal(data.totalEntities)
        if (isFirstFresh) {
          setIsFirstFresh(false)
        }
      } else {
        setItems([])
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    const locations = []
    const categories = []
    const utilities = []
    const products = [];

    filterItems.forEach((item) => {
      if (item.type === ProgramFilterType.County) {
        locations.push(item.id)
      } else if (item.type === ProgramFilterType.Category) {
        categories.push(item.id)
      } else if (item.type === ProgramFilterType.Utility) {
        utilities.push(item.id)
      } else if (item.type === ProgramFilterType.Product) {
        products.push(item.id)
      }
    })
    const dataBody = {
      skip: 0,
      count: 30,
      locations,
      categories,
      utilities,
      products
    }

    setFilter(dataBody)
    handleFetchDefault(dataBody)
  }, [filterItems])

  function getProgramMetadatasByName(name, programs) {
    setIsGettingProgramDetail(true)
    programService.getProgramMetadatasByName(name).then(res => {
      const { success, data } = res

      if (success && data) {
        programs.forEach((item, index) => {
          if (item.id === data.id) {
            setProgramDetail(data)
            setIndexActive(index)
          }
        })
      }
      setIsGettingProgramDetail(false)
    })
  }

  function getByStateAndUtilityName(filterData) {
    programService.getByStateAndUtilityName(filterData).then(res => {
      const { success, data } = res

      if (success && data.programs && data.programs.length) {
        if (program_name) {
          getProgramMetadatasByName(program_name, data.programs)
        }
        setUtilites({
          name: filterData.state,
          utility: data,
          items: data.programs
        })
        setIsShowUtilities(true)
      } else {
        const { utility } = filterData
        toastr.warning(`${utility} no Utilities available`)
      }
    })
  }

  useEffect(() => {
    if (state_name && utility_name) {
      getByStateAndUtilityName({
        utility: utility_name,
        state: state_name
      })
    }
  }, [state_name, utility_name])

  useEffect(() => {
    getQPLs()
  }, [])


  function getProgramByUtilityId(filterData, detail, index) {
    if (index === 0) {
      setIsGettingProgramDetail(true)
    }
    coreService.getProgramByUtilityId(filterData.id).then(res => {
      const { success, data } = res
      if (success) {
        setProgramDetail(data)
        setIndexActive(index)

      } else {
        const { name } = detail
        toastr.warning(`${name} no Programs available `)
      }
      setIsGettingProgramDetail(false)
    })
  }

  function getByUtilityId(filterData, detail, program) {
    coreService.getByUtilityId(filterData).then(res => {
      const { success, data } = res
      setIndexActive(-1)
      if (success && data.entities && data.entities.length) {
        data.entities.forEach((item, index) => {
          if (item.id === program.id) {
            getProgramByUtilityId({ id: item.id, }, item, index)
          }
        })
        setUtilites({
          ...detail,
          items: data.entities
        })
        setIsShowUtilities(true)
      } else {
        const { utility = {} } = detail
        toastr.warning(`${utility.name} no Utilities available  `)
      }
    })
  }

  function handleReplaceUrl(state, utility, program) {
    if (state) {
      let { MAPCARDVIEW_UTILITY } = pathKeys
      MAPCARDVIEW_UTILITY = MAPCARDVIEW_UTILITY.replace(UTILITY_NAME, utility);
      MAPCARDVIEW_UTILITY = MAPCARDVIEW_UTILITY.replace(STATE_NAME, state);
      MAPCARDVIEW_UTILITY = MAPCARDVIEW_UTILITY.replace(PROGRAM_NAME, program);
      historyPush(MAPCARDVIEW_UTILITY)
    }
  }

  function handleClickCard(record) {
    const state = _.get(record, "key.state", "");
    const utility = _.get(record, "key.utility", {});
    const program = _.get(record, "key.program", {});
    const categoryRoots = _.get(record, "categoryRoots", []);
    if (utility.id) {
      getByUtilityId({
        utilityId: utility.id,
        skip: 0,
        count: 1000
      }, {
        name: state,
        utility,
        categoryRoots
      }, program)
      handleReplaceUrl(state, utility.name, program.name)
    } else {
      toastr.warning(`${state} no Utilities available  `)
    }

  }

  return (
    <>
      <Loading loading={loading} />
      {
        items.length || isFirstFresh ? (
          <Scrollbars
            onScrollFrame={(values) => {
              const { top } = values
              const { skip, count } = filter

              if (top >= 0.9 && (total >= (skip + 1) * count)) {
                const filterNow = {
                  ...filter,
                  skip: skip + 1
                }
                setFilter(filterNow)
                const fillterCurrent = {
                  ...filterNow,
                  skip: (skip + 1) * count
                }
                handleFetchProgram(fillterCurrent)
              }
            }}
            autoHide >

            <div className="card-view">
              {items.map((item, index) => (
                <Card onApplyClicked={(data) => {
                  handleClickCard(data)
                }}
                  data={item}
                  key={`${item.id}-${index}`}
                />
              ))}
            </div>

            <DrawerUtilites
              onClick={(el, index, rebateValuePageIndex) => {
                getProgramByUtilityId({ id: el.id, }, el, index)
              }}
              categories={categories}
              indexActive={indexActive}
              data={utilites}
              onClose={() => {
                setIsShowUtilities(false);
              }} isShow={isShowUtilities}
              itemData={programDetail}
              qpls={qpls}
              reloadingRebate={isGettingProgramDetail}
            />
          </Scrollbars>
        ) : (
            <EmptyState
              className="custom-empty-state-second"
              photo={emptyCard}
              text={() => {
                return (
                  <>
                    <div className="custom-empty-state-second__title">
                      NO PROGRAMS MATCH YOUR SELECTED FILTERS.
                    </div>
                    <div className="custom-empty-state-second__title-sub">
                      Please update your filters to find matching programs or
                    </div>
                    <a className="custom-empty-state-second__link" href="mailto:support@rebatebus.com">
                      let us know if we are missing a program.
                    </a>
                  </>
                );
              }}
            />
          )
      }

    </>
  );
};

CardView.propTypes = {
  filterItems: PropTypes.array,
  items: PropTypes.array,
  setItems: PropTypes.func,
  categories: PropTypes.array,
}

CardView.defaultProps = {
  filterItems: [],
  items: [],
  setItems: () => { },
  categories: []
}

const mapStateToProps = (state) => {
  return {
    filterItems: productSelectors.selectFilterItems(state),
    items: programSelectors.selectProgramsItems(state),
    categories: dropDownCategorySelectors.category(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  setItems: (items) => dispatch(programActions.changePrograms(items)),

})

export default connect(mapStateToProps, mapDispatchToProps)(CardView)




