import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import cn from 'classnames'
import { IconSearch } from "../../../assests/icons"
import { BOOTSTRAP_VARIANTS } from "../../../constants/common";
import './style.scss'
import TextArea from '../TextArea'

const TopLabelFormGroup = React.forwardRef((props, inputRef) => {
  const {
    value,
    type,
    label,
    controlId,
    readOnly,
    minRows,
    maxRows,
    onChange,
    className,
    isTextArea,
    size,
    subLabel,
    status,
    message,
    placeholder,
    isSearch,
      key
  } = props

  const handleTextAreaValueChange = (nextVal) => {
    typeof onChange === 'function' && onChange(controlId, nextVal)
  }
  const handleInputValueChange = (event) => {
    typeof onChange === 'function' && onChange(controlId, event.target.value)
  }

  let inputProps = {
    as: TextArea,
    minRows,
    maxRows,
    plaintext: readOnly,
    text: value,
  }

  if (!isTextArea) {
    inputProps = {
      as: 'input',
      size
    }
  }

  return (
    <Form.Group key={key}
      controlId={controlId}
      className={cn('top-label-form-group', {
        readOnly, 'form-input': !isTextArea,
      }, status && status, [className])}
    >
      {
        label ? <Form.Label>{label} {subLabel ? <div className="subLabel">{subLabel}</div> : null}</Form.Label> : null
      }
      <div className={isSearch ? "top-label-form-group__search" : ""}>
        {isSearch ? <IconSearch className="top-label-form-group__icon" /> : null}
        <Form.Control
          ref={inputRef}
          type={type}
          readOnly={readOnly}
          value={value}
          placeholder={placeholder}
          onChange={isTextArea ? handleTextAreaValueChange : handleInputValueChange}
          {...inputProps}
        />
      </div>
      {status && message && <div className="message">{message}</div>}
    </Form.Group>
  )
})

TopLabelFormGroup.displayName = "TopLabelFormGroup";

TopLabelFormGroup.propTypes = {
  controlId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  className: PropTypes.string,
  size: PropTypes.string,
  isTextArea: PropTypes.bool,
  subLabel: PropTypes.string,
  status: PropTypes.oneOf([BOOTSTRAP_VARIANTS.DANGER, BOOTSTRAP_VARIANTS.WARNING, BOOTSTRAP_VARIANTS.SUCCESS, null]),
  message: PropTypes.string,
  placeholder: PropTypes.string,
  isSearch: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.any,
  ]),
  key: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.symbol,
  ]),
}

TopLabelFormGroup.defaultProps = {
  onChange: null,
  value: undefined,
  type: "string",
  label: undefined,
  readOnly: false,
  minRows: undefined,
  maxRows: undefined,
  className: undefined,
  isTextArea: true,
  size: 'md',
  subLabel: null,
  status: null,
  message: null,
  placeholder: null,
  isSearch: false,
  key: undefined,
}

export default TopLabelFormGroup
