/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import debounce from 'lodash/debounce'
import {
  IconDropDown,
  IconSearch,
  IconClearSearch,
  IconTime,
  IconArrow,
  IconLeftChevron
} from "../../../assests/icons";
import { Text, Checkbox, Tooltip } from ".."
import { removeVietnameseAccent, handleGetTextParentbyChild } from "../../../utils/common";
import "./style.scss";
import { ProgramFilterType } from "../../../constants/common";

const Dropdown = (props) => {
  const {
    className,
    lable,
    items,
    itemsHistory,
    onChange,
    selectedValues,
    isSelectOpen,
    setIsSelectOpen,
    onBack,
    onClickParentItem,
    itemsAll,
    changeCategoryItem,
    type,
    dropdownClass,
    total,
    onChangeHistory,
    onHandleTextChanged,
    placeholder,
    onChangeSelectedProduct,
    changeProductItem,
    footerText
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isChildren, setIsChilDren] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState(items);
  const [objectBack, setObjectBack] = useState([])
  const [isReset, setIsReset] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [maxScroll, setMaxScroll] = useState(30);

  const DropdownClasses = cn("Dropdown-wrapper"); const dropDownRef = useRef(null);

  function handleScroll() {
    const element = document.querySelector(`.Dropdown-wrapper__scroll.${type}`);
    let scrollPrev = 0
    if (element) {
      element.addEventListener("scroll", (e) => {
        const { scrollTop } = e.target
        if (scrollTop % 100 > 0 && scrollTop > 100) {
          const newMaxScroll = scrollTop / 100 * 30
          if (newMaxScroll > scrollPrev) {
            scrollPrev = newMaxScroll
            setMaxScroll(newMaxScroll)
          }
        }
      }, false)
    }
  }

  useEffect(() => {
    handleScroll()
  }, []);

  useEffect(() => {
    setIsOpen(isSelectOpen)
  }, [isSelectOpen]);

  useEffect(() => {
    setSearchData(items)
  }, [items]);

  useEffect(() => {
    if (total) {
      setIsReset(true)
    } else if (!isChildren) {
      setIsReset(false)
    }
  }, [total]);

  useEffect(() => {
    function handleClickOutside(event) {
      const dropDownOpen = dropDownRef.current.getAttribute("data-open");
      if (dropDownRef.current && !dropDownRef.current.contains(event.target) && dropDownOpen === 'true') {
        setIsOpen(false);
        if (setIsSelectOpen) {
          setIsSelectOpen(false)
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef, setIsSelectOpen]);

  const flatten = (array) => array.flatMap((item) => [
    { ...item },
    ...flatten(item.children || [])
  ]);

  const handleSearch = debounce((value, itemsAll) => {
    const newData = [];

    (type === ProgramFilterType.Product ? flatten(itemsAll) : itemsAll).forEach((element) => {
      const { label } = element;
      if ( removeVietnameseAccent(label.toLowerCase()).indexOf(removeVietnameseAccent(value.toLowerCase())) > -1 ) {
        if (value && value !== '') {
          newData.push(element);
        } else if ((!value || (value && value === '')) && !element.parentId) {
          newData.push(element);
        }
      }
    });

    if (type === ProgramFilterType.Product) {
      changeProductItem(newData);
    } else {
      changeCategoryItem(newData);
    }
    setObjectBack({});
    setIsLoading(false);
  }, 100, 'later');

  const handleOnChangeValue = (newValue) => {
    onChange([...newValue]);
  }

  const hadleGetChildren = (arrayTmp) => {
    let check = true
    const newTmp = [...arrayTmp]
    for (let i = 0; i < arrayTmp.length; i++) {
      for (let j = 0; j < itemsAll.length; j++) {
        if (itemsAll[j].parentId === arrayTmp[i].value) {
          const index = newTmp.findIndex(el => el.value === itemsAll[j].value)

          if (index === -1) {
            newTmp.push({
              ...itemsAll[j],
            })
            if (itemsAll[j].isParent) {
              check = false
            }

          }
        }
      }
    }
    if (check) {
      return newTmp
    } else {
      return hadleGetChildren(newTmp)
    }
  }



  const handleSetHisotry = (newItem, newValue) => {
    // eslint-disable-next-line prefer-const
    let { label, parentId } = newItem
    const newText = handleGetTextParentbyChild(label, parentId, newValue)
    newItem.label = newText
    onChangeHistory(newItem)
  }

  const handleGetChildrenByArray = (arrayChildren, arrayCurrent) => {
    let check = true
    const newArray = [...arrayChildren]
    for (let i = 0; i < newArray.length; i++) {
      for (let j = 0; j < arrayCurrent.length; j++) {
        if (arrayCurrent[j].parentId === newArray[i].value) {
          const index = newArray.findIndex(el => el.value === arrayCurrent[j].value)

          if (index === -1) {
            newArray.push({
              ...arrayCurrent[j],
            })
            if (arrayCurrent[j].isParent) {
              check = false
            }

          }
        }
      }
    }
    if (check) {
      return newArray
    } else {
      return handleGetChildrenByArray(newArray, arrayCurrent)
    }
  }

  const handleSetIsFull = (newValue) => {
    const TmpArray = []
    newValue.forEach(el => {
      const arrayNow = newValue.filter(item => item.parentId === el.value)
      const arrayParent = itemsAll.filter(item => item.parentId === el.value)
      const newItem = { ...el }
      if ((arrayNow.length && arrayNow.length === arrayParent.length) || !el.isParent) {
        newItem.isFull = true
      } else {
        newItem.isFull = false
      }
      TmpArray.push(newItem)
    })

    const RealArray = []
    for (let i = 0; i < TmpArray.length; i++) {
      const newData = handleGetChildrenByArray([{ ...TmpArray[i] }], TmpArray)
      let newItem = TmpArray[i]
      for (let j = 1; j < newData.length; j++) {

        if (!newData[j].isFull) {
          newItem = {
            ...TmpArray[i],
            isFull: false
          }
          break
        }
      }
      RealArray[i] = newItem
    }
    return RealArray
  }

  const handleAutoCheckParent = (newValue, el) => {
    if (!el.parentId) {
      return newValue
    } else {
      const index = itemsAll.findIndex(item => item.value === el.parentId)
      if (index !== -1) {
        const index2 = newValue.findIndex(item => item.value === itemsAll[index].value)
        const newDataValue = [...newValue]
        if (index2 === -1) {
          newDataValue.push({
            ...itemsAll[index],

          })

        }
        return handleAutoCheckParent(newDataValue, itemsAll[index])
      } else {
        return newValue
      }
    }
  }

  const handleRemoveAutoCheckParent = (newValue, el) => {
    if (!el.parentId) {
      return newValue
    } else {
      const index = itemsAll.findIndex(item => item.value === el.parentId)
      const arrayNow = newValue.filter(item => item.parentId === el.parentId)
      if (index !== -1) {
        let newValueData = [...newValue]
        if (!arrayNow.length) {
          newValueData = newValueData.filter(item => item.value !== el.parentId)
        }
        return handleRemoveAutoCheckParent(newValueData, itemsAll[index])
      } else {
        return newValue
      }
    }
  }

  const handleChangeItem = (isCheck, item) => {
    const { value } = item;
    let newValue = []
    if (isCheck) {
      newValue = selectedValues.filter(el => el.value !== item.value)
      newValue = handleRemoveAutoCheckParent(newValue, item)

    } else {
      newValue = [...selectedValues]
      const index = newValue.findIndex(el => (el.value === item.value) || (el.id === item.id))
      if (index === -1) {
        const newItem = {
          ...item,

          value
        }
        newValue.push(newItem)
        //newValue = handleAutoCheckParent(newValue, item)
        handleSetHisotry(newItem, newValue)
      }
    }
    const newRealValue = handleSetIsFull(newValue)
    handleOnChangeValue(newRealValue)
  }

  const handleChangeItemRoot = (isCheck, item) => {
    const { value } = item;
    let newValue = []
    if (isCheck) {
      newValue = selectedValues.filter(el => el.value !== item.value)
    } else {
      newValue = [...selectedValues]
      const index = newValue.findIndex(el => (el.value === item.value) || (el.id === item.id))
      if (index === -1) {
        const newItem = {
          ...item,
          value
        }
        newValue.push(newItem)
        handleSetHisotry(newItem, newValue)
      }
    }
    handleOnChangeValue(newValue)
  }


  const handleChangeCheckParent = (isCheck, item) => {
    const newItem = { ...item }
    const arrayTmp = [newItem]
    const newArray = hadleGetChildren(arrayTmp)
    let newValue = [...selectedValues]

    if (!isCheck) {

      newArray.forEach(el => {
        const index = selectedValues.findIndex(el2 => el2.value === el.value)
        if (index === -1) {

          newValue.push({
            ...el,
          })
        }
      })
      handleSetHisotry(newItem, newValue)
    } else {
      newArray.forEach(el => {
        const index = newValue.findIndex(el2 => el2.value === el.value)
        if (index !== -1) {
          newValue.splice(index, 1)
        }
      })
      newValue = handleRemoveAutoCheckParent(newValue, item)
    }
    const newRealValue = handleSetIsFull(newValue)
    handleOnChangeValue(newRealValue)
  }

  function handleOnClickGroup(item, isCheck) {
    const { isParent, parentId } = item
    if (isParent) {
      handleChangeCheckParent(isCheck, item)
    } else if (!parentId) {
      handleChangeItemRoot(isCheck, item)
    } else {
      handleChangeItem(isCheck, item)
    }
  }

  function getLeafNodes(nodes, result = []){
    for(let i = 0, length = nodes.length; i < length; i++){
      if(!nodes[i].children || nodes[i].children.length === 0){
        result.push(nodes[i]);
      } else {
        result = getLeafNodes(nodes[i].children, result);
      }
    }
    return result;
  }

  function handleOnClickGroupProduct(item, isCheck) {
    // find all leafs in item
    // call onChangeSelectedProduct with all that leaf
    const allLeaf = getLeafNodes([item]);
    const selectedProducts = selectedValues.filter(x => x.type === ProgramFilterType.Product) || [];

    let selectData = [];
    if (!isCheck) {
      // add to selected values
      selectData = [...new Set([...selectedProducts, ...allLeaf])];
    } else {
      // remove from selected values
      selectedProducts.forEach((selectedProductItem) => {
        const index = allLeaf.findIndex((itemLeaf) => {
          return itemLeaf.dataType === selectedProductItem.dataType && itemLeaf.itemName === selectedProductItem.itemName
        });

        if (index === -1) {
          selectData.push(selectedProductItem);
        }
      });
    }

    onChangeHistory(item);
    onChangeSelectedProduct([...selectedValues.filter(x => x.type !== ProgramFilterType.Product), ...selectData]);
  }

  const renderProductDropdown = () => {
    if (isLoading) {
      return <div className="Dropdown-wrapper_dropdown__paren"><div className="Dropdown-wrapper_item">Loading ...</div></div>
    }

    const dataForRenderSearch =  (objectBack.children && objectBack.children.length > 0) ? (
      objectBack.children
    ) : searchData;

    const selectedProducts = selectedValues.filter(x => x.type === ProgramFilterType.Product) || [];
    
    return dataForRenderSearch.length ? dataForRenderSearch.map((item, indexDropDown) => {
      const { label = "", itemName: value, isParent, parentId, subLabel, isCheck, isFull, historylabel } = item;
      const className = cn("Dropdown-wrapper_item");

      const allLeaf = getLeafNodes([item]);
      const isFullRetrive = allLeaf.every(x => selectedProducts.includes(x));
      const isCheckRetrive = (allLeaf.findIndex(x => selectedProducts.includes(x)) !== -1);

      if (indexDropDown < maxScroll) {
        return (
          <div key={`${value}_${lable}_${indexDropDown}_renderDropDownItem`} className={className}>
            <Checkbox 
              data-testid="test-checkbox"
              isFull={!isParent || (isCheck && isFull) || isFullRetrive} 
              onClick={() => {
                handleOnClickGroupProduct(item, isCheck || isFullRetrive)
              }} 
              checked={isCheck || isCheckRetrive} 
            />
            {label && (
              <div
                onClick={() => {
                  if (isParent) {
                    setSearchText("")
                    setIsChilDren(true);
                    setObjectBack(item);
                  } else {
                    handleOnClickGroupProduct(item, isCheck || isFullRetrive)
                  }
                }}
                role="presentation"
                className="Dropdown-wrapper_item_text"
                data-testid="test-dropdown-wrapper-item-text"
              >
                <Tooltip childrenTooltip={historylabel} key={`prodown_${indexDropDown}`}>
                  <span className="Dropdown-wrapper_item_hoverText">
                    {label} {subLabel ? <div className="Dropdown-wrapper_item_subText">({subLabel})</div> : ''}
                  </span>
                </Tooltip>
              </div>
            )}
            {isParent && (
              <IconArrow
                onClick={() => {
                  setTimeout(() => {
                    setSearchText("")
                    setIsChilDren(true);
                    setObjectBack(item)
                  }, 100)
                }}
                role="presentation"
                className="Dropdown-wrapper_item__icon Dropdown-wrapper_item__icon-2"
                data-testid="test-wrapper-item-icon"
              />
            )}
          </div>
        )
      }
      
      return null
    }) : (
      <div className="Dropdown-wrapper_dropdown__paren">
        <div className="Dropdown-wrapper_item">{!searchText || searchText === "" ? placeholder : "No data"}</div>
      </div>
    )
  }

  const renderDropDownItem = () => {
    if (isLoading) {
      return <div className="Dropdown-wrapper_dropdown__paren"><div className="Dropdown-wrapper_item">Loading ...</div></div>
    }

    return searchData.length ? searchData.map((item, indexDropDown) => {
      const { label = "", value, isParent, parentId, subLabel } = item;
      const className = cn("Dropdown-wrapper_item");
      const indexSelect = selectedValues.findIndex(fi => fi.value === value)
      const isCheck = indexSelect !== -1;
      if (indexDropDown < maxScroll) {

        return (
          <div key={`${value} ${lable} renderDropDownItem`} className={className}>
            <Checkbox data-testid="test-checkbox" isFull={!isParent || (isCheck && selectedValues[indexSelect].isFull)} onClick={() => {
              handleOnClickGroup(item, isCheck)
            }} checked={isCheck} />
            {label ? (
              <div
                onClick={() => {
                  if (isParent) {
                    setSearchText("")
                    setIsChilDren(true);
                    onClickParentItem(value)
                    setObjectBack(item)
                  } else if (!parentId) {
                    handleChangeItemRoot(isCheck, item)
                  } else {
                    handleChangeItem(isCheck, item)
                  }

                }}
                role="presentation"
                className="Dropdown-wrapper_item_text"
                data-testid="test-dropdown-wrapper-item-text"

              >
                {label} {subLabel ? <div className="Dropdown-wrapper_item_subText">({subLabel})</div> : ''}
              </div>
            ) : null}
            {isParent ? (
              <IconArrow
                onClick={() => {
                  setTimeout(() => {
                    setSearchText("")
                    setIsChilDren(true)
                    onClickParentItem(value)
                    setObjectBack(item)
                  }, 100)
                }}
                role="presentation"
                className="Dropdown-wrapper_item__icon Dropdown-wrapper_item__icon-2"
                data-testid="test-wrapper-item-icon"
              />
            ) : null}
          </div>
        )
      } else {
        return null
      }
    }) : <div className="Dropdown-wrapper_dropdown__paren"><div className="Dropdown-wrapper_item">{!searchText || searchText === "" ? placeholder : "No data"}</div></div>
  };

  const renderDropDownHisotryItem = () => {
    return itemsHistory.map((item) => {
      const { value = '', label, isParent, subLabel } = item;
      const className = cn("Dropdown-wrapper_item");
      const indexSelect = selectedValues.findIndex(fi => fi.value === value)
      const isCheck = indexSelect !== -1

      return (
        <div
          onClick={() => {
            handleOnClickGroup(item, isCheck)
          }}
          key={`${label} renderDropDownHisotryItem`}
          className={className}
          role="presentation"
        >
          <Checkbox onClick={() => {
            handleOnClickGroup(item, isCheck)
          }} isFull={!isParent || (isCheck && selectedValues[indexSelect].isFull)} checked={isCheck} />
          {label ? (
            <Text popover lines={2} as="div">
              <div className="Dropdown-wrapper_item_text">
                {label} {subLabel ? <div className="Dropdown-wrapper_item_subText">({subLabel})</div> : ''}
              </div>
            </Text>
          ) : null}
          <IconTime className="Dropdown-wrapper_item__icon" />
        </div>
      );
    });
  };

  const renderHistoryProductDropdown = () => {
    const selectedProducts = selectedValues.filter(x => x.type === ProgramFilterType.Product) || [];
    const allSelectedId = selectedProducts.map((item) => item.id);

    return itemsHistory.map((item, index) => {
      const { historylabel, label, type } = item;
      const className = cn("Dropdown-wrapper_item");

      const allLeaf = getLeafNodes([item]).map((x) => x.id);
      const isFullRetrive = allLeaf.every(x => allSelectedId.includes(x));
      const isCheckRetrive = (allLeaf.findIndex(x => allSelectedId.includes(x)) !== -1);

      return (
        <div
          onClick={() => {
            handleOnClickGroupProduct(item, isCheckRetrive)
          }}
          key={`HistoryProductDropdown-${index}`}
          className={className}
          role="presentation"
        >
          <Checkbox onClick={() => {
            handleOnClickGroupProduct(item, isCheckRetrive)
          }} 
          isFull={isFullRetrive}
          checked={isCheckRetrive}
          />
          {
            historylabel !== label ? (
              <Tooltip childrenTooltip={historylabel} key={`${type}_${index}`}>
                <span className="Dropdown-wrapper_item__history-text">
                  {
                    label
                  }
                </span>
              </Tooltip>
            ) : (
              <span className="Dropdown-wrapper_item__history-text">
                {
                  historylabel
                }
              </span>
            )
          }
          <IconTime className="Dropdown-wrapper_item__icon" />
        </div>
      );
    });
  }

  const renderSearch = () => {
    return (
      <div className="Dropdown-wrapper-search">
        <input
          onChange={(e) => {
            const { value } = e.target;
            setSearchText(value);
            setIsLoading(true)
            if (onHandleTextChanged) {
              onHandleTextChanged(value, setIsLoading)
            } else {
              handleSearch(value, itemsAll)
            }
          }}
          placeholder="Search"
          value={searchText}
          className="Dropdown-wrapper-search-input"
          data-testid="test-search-input"
        />
        {searchText.length ? (
          <IconClearSearch
            onClick={() => {
              setTimeout(() => {
                setSearchText("")
                setIsChilDren(true)
                onClickParentItem(null)
                setIsLoading(false)
              }, 100)
            }}
            className="Dropdown-wrapper-search__icon Dropdown-wrapper-search__icon-2"
            data-testid="test-clear-search"
          />
        ) : (
          <IconSearch className="Dropdown-wrapper-search__icon " />
        )}
      </div>
    );
  };

  const handleOnClickBack = () => {
    setSearchText("")
    onClickParentItem(objectBack.parentId)
    const index = itemsAll.findIndex(item => item.value === objectBack.parentId)
    if (index !== -1) {
      setObjectBack(itemsAll[index])
    } else {
      setIsChilDren(false);
      setObjectBack({})
    }
  }

  const renderClearFilterMobile = () => {
    return (
      <div className="Dropdown-wrapper_dropdown__clearFilter">
        <div className="Dropdown-wrapper_dropdown__clearFilter__parent" onClick={() => {
          setIsOpen(false)
          if (onBack) {
            onBack()
          }
        }} data-testid="test-dropdown-clear-filter-parent">
          <IconLeftChevron className="Dropdown-wrapper_dropdown__clearFilter__icon" />
          {
            lable
          }
        </div>
        <div
          onClick={() => {
            setSearchText("");
            setSearchData(items);
            setIsOpen(!isOpen);
            if (setIsSelectOpen && !isOpen) {
              setIsSelectOpen()
            }
          }}
          className="Dropdown-wrapper_dropdown__clearFilter_item Dropdown-wrapper_dropdown__clearFilter__parent"
          data-testid="test-set-search-data"
        >
          Close Filter
          <IconClearSearch
            className=" Dropdown-wrapper_dropdown__clearFilter__icon Dropdown-wrapper_dropdown__clearFilter__icon-2"
          />
        </div>

      </div>
    )
  }

  function searchTreeParent(element, compareItem, parent) {
    if(element.type === ProgramFilterType.Product && element.dataType === compareItem.dataType && element.itemName === compareItem.itemName){
      return parent;
    } else if (element.children !== null) {
      let result = null;
      for(let i=0; result == null && i < element.children.length; i++){
          result = searchTreeParent(element.children[i], compareItem, element);
      }
      return result;
    }
    return null;
  }

  const handleOnClickBackProduct = (item) => {
    // "Manu" > "brand" > "QPLModelNumber" > "ExternalProductId"
    setSearchText("")

    let targetItem = null;
    itemsAll.forEach((itemInAll) => {
      if (targetItem) {
        return targetItem;
      } else {
        targetItem = searchTreeParent(itemInAll, item, null);
      }
    })

    let targetItemSelected = null;
    selectedValues.forEach((itemInAll) => {
      if (targetItemSelected) {
        return targetItemSelected;
      } else {
        targetItemSelected = searchTreeParent(itemInAll, item, null);
      }
    })

    setIsChilDren(targetItemSelected ? true : targetItem ? true : false)
    setObjectBack(targetItemSelected ? targetItemSelected : targetItem ? targetItem : {})
    onClickParentItem(targetItemSelected ? targetItemSelected : targetItem ? targetItem : null);
  }

  const renderItemLength = () => {
    if (type === ProgramFilterType.Product) {
      return `${(objectBack && objectBack.children) ? objectBack.children.length : items.length} items`
    }
    return `${items.length} items`
  }

  return (
    <div className={`Dropdown-wrapper-parent CustormScrollBar ${className}`} ref={dropDownRef} data-open={isOpen} data-testid="test-dropdown">
      <div
        role="presentation"
        onClick={() => {
          setIsOpen(!isOpen);
          if (setIsSelectOpen && !isOpen) {
            setIsSelectOpen()
          }
        }}
        className={`${DropdownClasses} ${dropdownClass} ${isOpen ? "Dropdown-wrapper__active" : ""
          } hiddenMobile`}
        data-testid="test-dropdown-children"
      >
        {lable}
        <IconDropDown className="Dropdown-wrapper__icon" />
      </div>
      <div
        className={`Dropdown-wrapper_dropdown ${!isOpen ? "Dropdown-wrapper_dropdown-close" : ""}`}
      >
        {renderClearFilterMobile()}
        {renderSearch()}
        {searchText.length ? null : type !== ProgramFilterType.Product ? renderDropDownHisotryItem() : renderHistoryProductDropdown()}
        <div className="Dropdown-wrapper_dropdown__parent">
          <div
            onClick={() => {
              if (searchText.length || isChildren || isReset) {
                setIsChilDren(false);
                setSearchText("");
                onClickParentItem(null);
                const newValue = selectedValues.filter(item => item.type !== type)
                handleOnChangeValue(newValue)
                setObjectBack({})

                if (type === ProgramFilterType.Product) {
                  // clear selected Product
                  onChangeSelectedProduct(selectedValues.filter(x => x.type !== ProgramFilterType.Product));
                }
              }
            }}
            className={`Dropdown-wrapper_dropdown__reset ${searchText.length || isChildren || isReset ? "active" : ""
              }`}
            data-testid="test-dropdown-reset"
          >
            Reset
          </div>
          <div className="Dropdown-wrapper_dropdown__total">
            {searchText.length
              ? `${searchData.length} of ${items.length} `
              : renderItemLength()}
          </div>
        </div>
        {
          (type === ProgramFilterType.Product) ? (
            <>
              {(objectBack.children && objectBack.children.length > 0) ? (
                <div role="presentation" onClick={() => {
                  handleOnClickBackProduct(objectBack)
                }} className="Dropdown-wrapper__back" data-testid="test-dropdown-back">
                  <IconLeftChevron />
                  <div className="Dropdown-wrapper__back-text"> {objectBack.label}</div>
                </div>
              ) : null}
              <div className={`Dropdown-wrapper__scroll ${type}`} data-testid="test-dropdown-scroll">
                {renderProductDropdown()}
              </div>
            </>
          ) : (
            <>
              {objectBack.value ? (
                <div role="presentation" onClick={() => {
                  handleOnClickBack()
                }} className="Dropdown-wrapper__back" data-testid="test-dropdown-back">
                  <IconLeftChevron />
                  <div className="Dropdown-wrapper__back-text"> {objectBack.label}</div>
                </div>
              ) : null}
              <div className={`Dropdown-wrapper__scroll ${type}`} data-testid="test-dropdown-scroll">
                {renderDropDownItem()}
              </div>
            </>
          )
        }
        {
          footerText && (
            <div className="card-footer">
              <span className="footer-text">
                {
                  footerText
                }
              </span>
            </div>
          )
        }
      </div>
    </div >
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  lable: PropTypes.string,
  items: PropTypes.array,
  itemsHistory: PropTypes.array,
  itemsAll: PropTypes.array,
  onChange: PropTypes.func,
  selectedValues: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
  ]),
  isSelectOpen: PropTypes.bool,
  setIsSelectOpen: PropTypes.func,
  onBack: PropTypes.func,
  onClickParentItem: PropTypes.func,
  changeCategoryItem: PropTypes.func,
  type: PropTypes.string,
  dropdownClass: PropTypes.string,
  total: PropTypes.number,
  onChangeHistory: PropTypes.func,
  onHandleTextChanged: PropTypes.func,
  placeholder: PropTypes.string,
  onChangeSelectedProduct: PropTypes.func,
  changeProductItem: PropTypes.func,
  footerText: PropTypes.string
};

Dropdown.displayName = "Dropdown";

Dropdown.defaultProps = {
  className: "",
  lable: "",
  items: [],
  itemsHistory: [],
  onChange: () => { },
  selectedValues: [],
  isSelectOpen: false,
  setIsSelectOpen: () => { },
  onBack: () => { },
  onClickParentItem: () => { },
  itemsAll: [],
  changeCategoryItem: () => { },
  type: 'category',
  dropdownClass: "",
  total: 0,
  onChangeHistory: () => { },
  onHandleTextChanged: undefined,
  placeholder: "No data",
  onChangeSelectedProduct: () => { },
  changeProductItem: () => { },
  footerText: null
};

export default Dropdown;
