import BaseApi from "./baseApi";

class AuthenticationApi extends BaseApi {
  checkEmbedAuthentication(header) {
    return super.execute(this.Methods.GET, `${this.Urls.authentication.check}`, header, false)
      .then((res) => {
        return res;
      });
  }
}

export default new AuthenticationApi();
