import BaseApi from "./baseApi";

class ProgramApi extends BaseApi {
  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.programs.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  getList(params) {
    return super
      .execute(this.Methods.GET, this.Urls.programs.getList, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(queryString, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.programs.update}${queryString}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  delete(params) {
    return super
      .execute(this.Methods.DELETE, this.Urls.programs.delete, null, true, params)
      .then((res) => {
        return res;
      });
  }

  getById(params) {
    return super
      .execute(this.Methods.GET, this.Urls.programs.getById, null, true, params)
      .then((res) => {
        return res;
      });
  }

  import(params) {
    return super
      .execute(this.Methods.POST, this.Urls.programs.import, null, true, params)
      .then((res) => {
        return res;
      });
  }

  filter(params) {
    return super
      .execute(this.Methods.POST, this.Urls.programs.filter, null, true, params)
      .then((res) => {
        return res;
      });
  }

  getbyUtilityId(params) {
    return super
      .execute(this.Methods.GET, this.Urls.programs.getbyUtilityId, null, true, params)
      .then((res) => {
        return res;
      });
  }

  GetProgramMetadatas(programId) {
    return super
      .execute(this.Methods.GET, this.Urls.programs.GetProgramMetadatas.replace('{id}', programId), null, true)
      .then((res) => {
        return res;
      });
  }

  GlobalSearch(searchText) {
    return super
      .execute(
        this.Methods.GET,
        this.Urls.programs.search.replace('{searchText}', searchText),
        null,
        true
      )
      .then((res) => {
        return res;
      });
  }

  grogramSearch(searchText) {
    return super
      .execute(
        this.Methods.GET,
        this.Urls.programs.grogramSearch.replace('{searchText}', searchText),
        null,
        true
      )
      .then((res) => {
        return res;
      });
  }

  getByStateAndUtilityName(query) {
    return super.execute(this.Methods.GET, `${this.Urls.programs.getByStateAndUtilityName}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  getProgramMetadatasByName(name) {
    return super.execute(this.Methods.GET, this.Urls.programs.getProgramMetadatasByName.replace('{name}', name), null, true)
      .then((res) => {
        return res;
      });
  }

  getAllProgramByUtilityId(query) {
    return super
      .execute(this.Methods.GET, `${this.Urls.programs.getAllProgramByUtilityId}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  filterBySearchTextAndUtilityId(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.filterBySearchTextAndUtilityId}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportByIds(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.exportByIds}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportAll(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.exportAll}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportByFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.exportByFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.exportFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }


  exportBlank(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.exportBlank}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  getByIds(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.getByIds}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  importFile(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programs.importFile}`, null, true, data)
      .then((res) => {
        return res;
      });
  }
}

export default new ProgramApi();