import BaseApi from "./baseApi";

class RoleApi extends BaseApi {
  getList(query) {
    return super.execute(this.Methods.GET, `${this.Urls.role.list}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.role.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.role.update}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  delete(query) {
    return super
      .execute(this.Methods.DELETE, `${this.Urls.role.delete}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }
}

export default new RoleApi();
