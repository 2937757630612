/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { DataTable, Progress, Button, Checkbox, TopLabelFormGroup } from "../../components/common"
import SuccessFul from './successful'
import ErrorImport from './errorImport'
import { pathKeys, common } from '../../constants'
import { utilityService } from "../../services"
import { JsonToFile, compareEqualFieldExcecl, compareEqualString } from '../../utils/common'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { bulkImportSelector } from '../../redux/selectors/bulkImportSelectors';
import { connect } from 'react-redux';
import { programSelectors } from 'redux/selectors/programSelectors';

const Add = "add"
const Update = "update"
const Delete = "delete"

export const ImportUtilities = (props) => {
  const navigate = useNavigate()
  const { setIsBulk, utilityImportData: data } = props
  const [DataAdd, setDataAdd] = useState([])
  const [DataUpdate, setDataUpdate] = useState([])
  const [DataDelete, setDataDelete] = useState([])
  const [dataError, setDataError] = useState([])
  const [isImport, setIsImport] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isProgress, setIsProgress] = useState(false)
  const [isError, setIsError] = useState(false)
  const [now, setNow] = useState(0);

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  function getListUpdateByIds(ids, dataItem = []) {
    utilityService.getByIds(ids).then(res => {
      const { success, data } = res
      if (success && data.items) {

        data.items.forEach(item => {
          const index = dataItem.findIndex(el => el.Id === item.id)
          if (index !== -1 && dataItem[index]) {
            if (!compareEqualFieldExcecl(item.name, dataItem[index].Name)) {
              dataItem[index].isWarningName = true
            }
            if (!compareEqualFieldExcecl(item.eia_Id, dataItem[index].Eia_Id)) {
              dataItem[index].isWarningEia_Id = true
            }

            if (!compareEqualFieldExcecl(item.websiteUrl, dataItem[index].WebsiteUrl)) {
              dataItem[index].isWarningWebsiteUrl = true
            }

            if (!compareEqualFieldExcecl(item.commercialRate, dataItem[index].CommercialRate)) {
              dataItem[index].isWarningCommercialRate = true
            }

            if (!compareEqualFieldExcecl(item.industrialRate, dataItem[index].IndustrialRate)) {
              dataItem[index].isWarningIndustrialRate = true
            }

            if (!compareEqualFieldExcecl(item.residentialRate, dataItem[index].ResidentialRate)) {
              dataItem[index].isWarningResidentialRate = true
            }
          }
        })
        setDataUpdate([
          ...dataItem
        ])
      }
    })
  }

  useEffect(() => {
    const DataAddDefault = data.filter(item => compareEqualString(item.Action, Add))
    const DataUpdateDefault = data.filter(item => compareEqualString(item.Action, Update))
    const DataDeleteDefault = data.filter(item => compareEqualString(item.Action, Delete))

    const DataUpdateIds = []
    data.forEach(item => {
      if (item.Id && item.Id !== "") {
        DataUpdateIds.push(item.Id)
      }
    })
    setDataAdd(DataAddDefault)
    setDataUpdate(DataUpdateDefault)
    setDataDelete(DataDeleteDefault)
    getListUpdateByIds(DataUpdateIds, DataUpdateDefault)
  }, [data])


  function importData(file) {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", "importData.csv");

    utilityService.importFile(formData).then(res => {
      const { success, data } = res
      if (success && !data.errors.length) {
        setIsSuccess(true)
        setIsError(false)
        setDataError([])
      } else {
        setIsError(true)
        setIsSuccess(false)
        if (data.errors) {
          setDataError(data.errors)
        }
      }
      setNow(100)
      setIsProgress(false)
    })
  }

  function handleBackToList() {
    navigate(pathKeys.BULK_UTILITIES)
  }

  function handleOnChange(name, value, index, action) {
    if (compareEqualString(action, Add)) {
      DataAdd[index][name] = value
      if (name === "Name" && value !== "") {
        DataAdd[index].isErrorName = false
      }
      setDataAdd([...DataAdd])
    } else if (compareEqualString(action, Update)) {
      DataUpdate[index][name] = value
      if (name === "Name" && value !== "") {
        DataUpdate[index].isErrorName = false
      }
      if (name === "Id" && value !== "") {
        DataUpdate[index].isErrorId = false
      }
      setDataUpdate([...DataUpdate])
    } else {
      if (name === "Name" && value !== "") {
        DataDelete[index].isErrorName = false
      }
      if (name === "Id" && value !== "") {
        DataDelete[index].isErrorId = false
      }
      DataDelete[index][name] = value
      setDataDelete([...DataDelete])
    }
  }

  function handleValidateAdd() {
    let check = true
    DataAdd.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataAdd[index].isErrorName = true
        }
      }
    }
    )
    setDataAdd([...DataAdd])
    return check

  }

  function handleValidateUpdate() {
    let check = true
    DataUpdate.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataUpdate[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataUpdate[index].isErrorId = true
        }
      }
    }
    )
    setDataUpdate([...DataUpdate])
    return check
  }

  function handleValidateDelete() {
    let check = true
    DataDelete.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataDelete[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataDelete[index].isErrorId = true
        }
      }
    }
    )
    setDataDelete([...DataDelete])
    return check

  }

  const columns = [
    {
      key: "keepChange",
      colSpan: 3,
      header: <div>Keep Change</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("KeepChange", !record.KeepChange, index, record.Action)
          }} isFull checked={record.KeepChange} />
        )
      }
    },
    {
      key: "Eia_Id",
      colSpan: 6,
      header: <div>Eia_Id</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Eia_Id", value, index, record.Action)
            }}
            status={record.isWarningEia_Id ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.Eia_Id}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "NameUtility",
      colSpan: 6,
      header: <div>Name Utility</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Name", value, index, record.Action)
            }}
            status={record.isErrorName ? common.BOOTSTRAP_VARIANTS.DANGER : record.isWarningName ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.Name}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "WebsiteUrl",
      colSpan: 6,

      header: <div>WebsiteUrl</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("WebsiteUrl", value, index, record.Action)
            }}
            status={record.isWarningWebsiteUrl ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.WebsiteUrl}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "commercialRate",
      colSpan: 6,

      header: <div>Commercial Rate</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("CommercialRate", value, index, record.Action)
            }}
            status={record.isWarningCommercialRate ? common.BOOTSTRAP_VARIANTS.WARNING : null} value={record.CommercialRate} isTextArea={false} />
        )
      }
    },
    {
      key: "Industrial",
      colSpan: 6,

      header: <div>Industrial Rate</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("IndustrialRate", value, index, record.Action)
            }}
            status={record.isWarningIndustrialRate ? common.BOOTSTRAP_VARIANTS.WARNING : null} value={record.IndustrialRate} isTextArea={false} />
        )
      }
    },
    {
      key: "ResidentialRate",
      colSpan: 6,

      header: <div>Residential Rate</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("ResidentialRate", value, index, record.Action)
            }}
            status={record.isWarningResidentialRate ? common.BOOTSTRAP_VARIANTS.WARNING : null} value={record.ResidentialRate} isTextArea={false} />
        )
      }
    },
  ];

  const columnsV2 = [
    ...columns,
    {
      key: "Id",
      colSpan: 7,
      header: <div>Id</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Id", value, index, record.Action)
            }}
            status={record.isErrorId ? common.BOOTSTRAP_VARIANTS.DANGER : null}
            value={record.Id} isTextArea={false} />
        )
      }

    },]

  return (
    <>
      {
        isImport ? (
          <div className="import-view-second">
            <div className="import-view__progress">
              {isProgress ? <Progress now={now} text="Processing Import..." /> : null}
              {
                isSuccess ? <SuccessFul onClick={handleBackToList} /> : null
              }
              {
                isError ? <ErrorImport data={dataError} onClick={handleBackToList} /> : null
              }
            </div>
          </div>
        ) : (
          <Scrollbars
            onScrollFrame={() => {

            }}
            autoHide >
            <div className="Roles import-view">
              <div className="Roles__contain">
                <div className="Roles__contain__title">
                  Import Changes
                </div>

                <div className="Roles__contain__subTitle">
                  Add New Utilities
                </div>
                <DataTable
                  sortable
                  data={DataAdd}
                  columns={columns}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Update Existing Utilities
                </div>
                <DataTable
                  sortable
                  data={DataUpdate}
                  columns={columnsV2}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Delete Existing Utilities
                </div>
                <DataTable
                  sortable
                  data={DataDelete}
                  columns={columnsV2}
                />

                <div className="import-view__buton">
                  <Button
                    onClick={() => {
                      const checkAdd = handleValidateAdd()
                      const checkUpdate = handleValidateUpdate()
                      const checkDelete = handleValidateDelete()
                      if (checkAdd && checkUpdate && checkDelete) {
                        setIsImport(true)
                        setIsProgress(true)
                        setIsSuccess(false)
                        setIsError(false)
                        setNow(0)
                        setTimeout(() => {
                          setNow(10)

                          const newData = [...DataDelete, ...DataUpdate, ...DataAdd]
                          const realData = []
                          newData.forEach(item => {
                            if (item.KeepChange) {
                              realData.push({
                                Action: item.Action,
                                CommercialRate: item.CommercialRate,
                                Created: item.Created,
                                Eia_Id: item.Eia_Id,
                                Id: compareEqualString(item.Action, Add) ? "" : item.Id,
                                IndustrialRate: item.IndustrialRate,
                                Name: item.Name,
                                ResidentialRate: item.ResidentialRate,
                                WebsiteUrl: item.WebsiteUrl,
                              })

                            }
                          })
                          const newFile = JsonToFile(realData)
                          importData(newFile)
                        }, 500)
                        setTimeout(() => {
                          setNow(30)
                        }, 1000)
                        setTimeout(() => {
                          setNow(50)
                        }, 2000)
                        setTimeout(() => {
                          setNow(75)
                        }, 2500)
                      } else {
                        toastr.error("Import file has got filed invalid")
                      }

                    }}
                    typeClass="second"
                    size="md"
                    text="Import"
                  />
                </div>
              </div>

            </div>
          </Scrollbars>
        )
      }


    </>

  )
}

ImportUtilities.propTypes = {
  setIsBulk: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  utilityImportData: PropTypes.array
}

ImportUtilities.defaultProps = {
  setIsBulk: () => { },
  location: {
    state: {
      data: {}
    }
  },
  history: {
    push: () => { }
  },
  utilityImportData: []
}

const mapStateToProps = (state) => {
  return {
    utilityImportData: bulkImportSelector.utilityImportData(state)
  }
}

export default connect(mapStateToProps, null)(ImportUtilities)
