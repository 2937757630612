
import React, { useEffect } from 'react'
import { useAuth0 } from '../../components/Auth0'
import { Loading } from '../../components/common'
import './style.scss'

const LoginView = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => { loginWithRedirect() }, [loginWithRedirect])
  return (
    <>
      <Loading loading />
    </>
  );
};

export default LoginView;
