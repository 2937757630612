import {
  // NoneActive,
  // NoneInactive,
  PremiumActive,
  PremiumInactive,
  StanderInactive,
  StanderActive,
  StarActive,
  StarInactive
} from "../assests/qpls";

export const IconQplsActive = {
  // "None": NoneActive,
  "DLCPremium": PremiumActive,
  "DLCStandard": StanderActive,
  "EnergyStar": StarActive
};


export const IconQplsInActive = {
  // "None": NoneInactive,
  "DLCPremium": PremiumInactive,
  "DLCStandard": StanderInactive,
  "EnergyStar": StarInactive
};
