import BaseApi from "./baseApi";

class ProgramUitilityApi extends BaseApi {

  addOrUpdateMultipleByUtilityId(newQuery, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programUitilityApi.addOrUpdateMultipleByUtilityId}${newQuery}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  addOrUpdateMultipleByProgramId(newQuery, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.programUitilityApi.addOrUpdateMultipleByProgramId}${newQuery}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

}

export default new ProgramUitilityApi();
