/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import cn from 'classnames'

import './style.scss'

const CustomAccordion = (props) => {
  const { items, defaultActiveKey, renderHeader, renderBody } = props

  const [activeKey, setActiveKey] = useState(defaultActiveKey || null)

  const handleOnSelect = eventKey => {
    setActiveKey(eventKey)
  }

  return (
    <div className={cn('custom-accordion')}>
      <Accordion defaultActiveKey={defaultActiveKey} onSelect={handleOnSelect}>
        {items.map((item) => {
          const { eventKey, data } = item
          const isActive = activeKey === eventKey && eventKey
          return (
            <Card key={eventKey} className={cn('accordion-card', { active: isActive })}>
              <Accordion.Toggle as={Card.Header} eventKey={eventKey} className="accordion-header">
                {typeof renderHeader === 'function'
                  ? renderHeader(data, isActive)
                  : renderHeader}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className="accordion-body">
                  {typeof renderBody === 'function'
                    ? renderBody(data, isActive)
                    : renderBody}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        })}
      </Accordion>
    </div>
  )
}

CustomAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Object)]).isRequired,
    })
  ).isRequired,
  renderHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  renderBody: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CustomAccordion.defaultProps = {
  defaultActiveKey: undefined,
}

export default CustomAccordion
