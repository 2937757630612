
import BaseService from "./baseService";
import RoleApi from "../api/roleApi";
import { getQueryString } from "../utils/common";


class RoleService extends BaseService {
  getList(query = {}) {
    const newQuery = getQueryString(query)
    return RoleApi.getList(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  create(params) {
    return RoleApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return RoleApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  delete(query) {
    const newQuery = getQueryString(query)
    return RoleApi.delete(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new RoleService();
