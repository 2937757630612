/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './index.scss'

const TooltipCustom = ({ children, align, className, childrenTooltip }) => {
  const wrapperClasses = cn(
    'custom-tooltip',

  )
  const renderTooltip = useMemo(
    (props) => {
      return <Tooltip {...props} className={wrapperClasses}>{childrenTooltip}</Tooltip>
    },
    [childrenTooltip]
  )
  return (

    <OverlayTrigger
      placement={align}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      className={className}
    >
      {
        children
      }
    </OverlayTrigger>

  )
}

TooltipCustom.propTypes = {
  children: PropTypes.node,
  childrenTooltip: PropTypes.node,
  className: PropTypes.string,
  align: PropTypes.oneOf(['center', 'left', 'right', 'justify', 'top', 'bottom']),
}

TooltipCustom.defaultProps = {
  className: '',
  children: '',
  align: 'top',
  childrenTooltip: ''
}

export default TooltipCustom
