import BaseApi from "./baseApi";

class CustomerTypeServiceApi extends BaseApi {
  getAll(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.customerType.all}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  addOrUpdateMultipleByProgramId(newQuery, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.customerType.addOrUpdateMultipleByProgramId}${newQuery}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

}

export default new CustomerTypeServiceApi();
