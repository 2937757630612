import BaseApi from "./baseApi";

class CategoryApi extends BaseApi {
  getCategory(queryString, headers, isAuthen = true) {
    return super.execute(this.Methods.GET, `${this.Urls.category.getList}${queryString}`, headers, isAuthen)
      .then((res) => {
        return res;
      });
  }

  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.category.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.category.update}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  delete(query) {
    return super
      .execute(this.Methods.DELETE, `${this.Urls.category.delete}${query}`, null, true)
      .then((res) => {
        return res;
      });
  }

  rebateAndProductNumber(params) {
    return super
      .execute(this.Methods.POST, this.Urls.category.RebateAndProductNumber, null, true, params)
      .then((res) => {
        return res;
      });
  }

  updateWithRebateProductNumber(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.category.updateWithRebateProductNumber}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }
}

export default new CategoryApi();
