
import {

  CHANGE_PROGRAMS_SUCCESS
} from '../constants/programActions'

export function changePrograms(items) {
  return dispatch => {
    dispatch({
      type: CHANGE_PROGRAMS_SUCCESS,
      payload: {
        items
      }
    })
  }
}