import React from 'react'
import PropTypes from 'prop-types'
import { IconMessageInfo } from '../../../assests/icons'
import './style.scss'

const InitialVisitMessage = (props) => {
  const { title, message, buttonText, onClick } = props
  return (
    <>
      <div role="presentation" onClick={onClick} className="InitialVisitMessage" />
      <div className="InitialVisitMessage__parent">
        <IconMessageInfo className="InitialVisitMessage__icon" />
        <div className="InitialVisitMessage__title">
          {title}
          <div className="InitialVisitMessage__message">
            {message}
          </div>
        </div>
        <div role="presentation" onClick={onClick} className="InitialVisitMessage__button">
          {buttonText}
        </div>
      </div>
    </>
  )
}

InitialVisitMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func
}

InitialVisitMessage.defaultProps = {
  title: 'This map is interactive!',
  message: 'Use the dropdown filters above, or select any state on the map to find energy programs and rebates.',
  buttonText: 'Let’s go!',
  onClick: () => { }
}

export default InitialVisitMessage
