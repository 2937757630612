/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import {connect} from 'react-redux'
import {useTranslation} from "react-i18next"
import {toastr} from 'react-redux-toastr'
import moment from "moment"
import { RebateValueType, BOOTSTRAP_VARIANTS as Status } from "../../constants/common";
import { rebateRequirementService, rebateValueService, rebateService, rebateCategoryService, requirementService } from "../../services"
import { DataTable, Loading, Text, Pagination, TopFilterTable, Modal, TopLabelFormGroup, TopLabelSearchMultipleGroupSecond, Tooltip, Tabs, TabItem, DatePicker, DropDownItemMultipleSecond, TopLabelDropDownGroup } from "../../components/common"
import {IconEditRoles, IconRX, IconCalCulate, IconDeleteRoles} from "../../assests/icons"
import './style.scss'
import {useParams} from 'react-router-dom';
import { nanoid } from '../../utils/common'

const pageSize = 10
const HearderTitle = [
	{
		eventKey: '1',
		title: 'Rebate',

	},
	{
		eventKey: '2',
		title: 'Rebate Requirements',
	},
	{
		eventKey: '3',
		title: 'Rebates Values',
	}
]
const OPTIONS_TYPE = [
	{
		value: "Custom",
		label: "Custom"
	},
	{
		value: "Calculated",
		label: "Calculated"
	},
]

const DEFAULT_REQUIREMENT = {
	minAmount: "",
	maxAmount: "",
	amount: "",
	requirementId: "",
	range: ""
}
const DEFAULT_VALUE = {}
const DEFAULT_ERROR = {
	requirement: []
}
const DEFAULT_ACTIVE = "1"
const DEFAULT_REBATE = {
	startDate: new Date(),
	expirationDate: new Date(),
	minCustomerContribution: 0,
	name: "",
	notes: "",
	rebateType: [
		{
			value: "Custom",
			label: "Custom"
		}
	]
}
export const RebatesView = (props) => {
	const [t] = useTranslation();
	const [filter, setFilter] = useState({
		skip: 0,
		count: pageSize,
		SearchQuery: ''
	})

	const {category, categoryItemsParent} = props
	const {programId} = useParams();

	const { data } = props.location.state
	const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE)
	const [isShowModalDelete, setShowModalDetelte] = useState(false)
	const [isShowModalEdit, setShowModalEdit] = useState(false)
	const [optionRequirements, setOptionRequirement] = useState([])
	const [items, setItems] = useState([])
	const [valuesCategory, setCategoryPrograme] = useState([])
	const [dataItem, setDataItem] = useState({ ...DEFAULT_REBATE })
	const [dataItemRequirement, setDataItemRequirement] = useState([
		{ ...DEFAULT_REQUIREMENT }
	])
	const [dataItemValue, setDataItemValue] = useState([])
	const [loading, setLoading] = useState(false)
	const [total, setTotal] = useState(0)
	const [objectError, setObjectError] = useState({ ...DEFAULT_ERROR })
	const [listRebateRequirement, setListRebateRequirement] = useState([])
	const [listRebateValue, setListRebateValue] = useState([])
	const newOptionRequirements = []
	optionRequirements.forEach(item => {
		const index = dataItemRequirement.findIndex(el => el.requirement && el.requirement[0] && el.requirement[0].id === item.id)
		if (index === -1) {
			newOptionRequirements.push(item)
		}
	})

	function handleFetchDefault(filterData, isSearch) {
		if (!isSearch) {
			setLoading(true)
		}
		const newFilter = { programId }
		Object.keys(filterData).forEach(key => {
			if (filterData[key] && filterData[key] !== '') {
				newFilter[key] = filterData[key]
			}
		})
		if (newFilter.skip) {
			newFilter.skip *= newFilter.count
		} else {
			newFilter.skip = 0
		}

		rebateService.filterByProgramId(newFilter).then(res => {
			const { success, data } = res
			if (success && data.entities) {
				const newData = data.entities;
				setItems(newData)
				setTotal(data.totalEntities)
			}
			setLoading(false)
		})
	}

	function getAllRequirement() {
		requirementService.getAll().then(res => {
			const { success, data } = res
			if (success && data.entities) {
				const newData = data.entities;
				setOptionRequirement(newData)
			}
		})
	}

	function getListRequirementByRebateId(filter) {
		rebateRequirementService.getListByRebateId(filter).then(res => {
			const { success, data } = res
			if (success && data.entities) {
				const newData = [];
				data.entities.forEach(item => {
					newData.push({
						...item,
						name: `Rebate Requirement ID: ${item.id}`
					})
				})
				if (newData.length) {
					setDataItemRequirement([])
				}

				setListRebateRequirement(newData)
			}
		})
	}

	function getListValueByRebateId(filter) {
		rebateValueService.getListByRebateId(filter).then(res => {
			const { success, data } = res
			if (success && data.entities) {
				const newData = [];
				data.entities.forEach(item => {
					newData.push({
						...item,
						name: `Rebate Value ID: ${item.id}`
					})
				})
				setDataItemValue([])
				setListRebateValue(newData)
			}
		})
	}

	function handlePageSizeChange(pageSize) {
		const newFilter = {
			skip: 0,
			count: pageSize,
		}
		setFilter(newFilter)
		handleFetchDefault(newFilter)
	}

	function handlePageChange(page) {
		const newFilter = {
			...filter,
			skip: page - 1,
		}
		setFilter(newFilter)
		handleFetchDefault(newFilter)
	}

	function handleOnChange(name, value) {
		setDataItem({
			...dataItem,
			[name]: value
		})

		delete objectError[name]
		setObjectError({
			...objectError
		})
	}

	function handleOnChangeRebateValue(name, value, index) {
		dataItemValue[index][name] = value;
		setDataItemValue([...dataItemValue])
	}

	function handleOnChangeRebateRequire(name, value, index) {
		dataItemRequirement[index][name] = value
		setDataItemRequirement([...dataItemRequirement])
		if (objectError.requirement && objectError.requirement[index]) {
			objectError.requirement[index].requirementMessage = ""
		}

		setObjectError({
			...objectError
		})
	}

	function handleValidate() {
		let isPass = true
		const message = "This field not allow empty"
		if (!dataItem.name || dataItem.name === "") {
			objectError.name = message
			isPass = false
		}

		if (!dataItem.rebateType || !dataItem.rebateType.length) {
			objectError.rebateType = message
			isPass = false
		}
		objectError.requirement = []
		if (activeTab === "2") {
			dataItemRequirement.forEach((item) => {

				if (!item.requirement || !item.requirement[0]) {
					objectError.requirement.push({
						requirementMessage: message
					})
					isPass = false
				} else {
					objectError.requirement.push({
						requirementMessage: ""
					})
				}
			})
		}

		if (!isPass) {
			setObjectError({
				...objectError
			})
		} else {
			setObjectError({ ...DEFAULT_ERROR })
		}

		return isPass
	}

	function addOrUpdateMultipleByRebateId(data, programs, isAdd) {
		const query = {
			rebateId: data.id
		}
		const newData = []
		programs.forEach(item => {
			newData.push(item.id)
		})

		rebateCategoryService.addOrUpdateMultipleByRebateId(query, newData).then(res => {
			const { success } = res
			if (!success) {
				toastr.error("Actions update multiple program failed please try again!")
			}
			if (isAdd) {
				handleFetchDefault({ ...filter, skip: 0 })
			} else {
				handleFetchDefault(filter)
			}

		})

	}

	function handleRebateRequirementAddOrUpdateMultipleByRebateId(rebate, isUpdate) {
		const newData = []

		dataItemRequirement.forEach(item => {
			if (item.requirement && item.requirement[0]) {
				newData.push({
					minAmount: item.minAmount || 0,
					maxAmount: item.maxAmount || 0,
					range: item.range || 0,
					amount: item.amount || 0,
					rebateId: rebate.id,
					requirementId: item.requirement[0].id
				})
			}
		})
		if (isUpdate) {
			listRebateRequirement.forEach(item => {
				newData.push(item)
			})
		}
		const query = {
			rebateId: rebate.id
		}

		rebateRequirementService.addOrUpdateMultipleByRebateId(query, newData).then(res => {
			const { success } = res
			if (!success) {
				toastr.error("Actions create rebate requirement failed please try again!")
			}
		})

	}

	function handleRebateValueAddOrUpdateMultipleByRebateId(rebate, isUpdate) {
		const newData = []
		dataItemValue.forEach(item => {
			if (Object.keys(item).length) {
				newData.push({
					rebateId: rebate.id,
					units: item.units || "",
					value: item.value || 0,
					type: (item.type && item.type[0].value) || RebateValueType[0].value
				})
			}
		})

		if (isUpdate) {
			listRebateValue.forEach(item => {
				newData.push(item)
			})
		}

		const query = {
			rebateId: rebate.id
		}

		rebateValueService.addOrUpdateMultipleByRebateId(query, newData).then(res => {
			const { success } = res
			if (!success) {
				toastr.error("Actions create rebate value failed please try again!")
			}
		})
	}

	async function handleAddRebate() {
		const newData = {
			...dataItem,
			programId,
			expirationDate: dataItem.expirationDate.toISOString(),
			startDate: dataItem.startDate.toISOString(),
			rebateType: dataItem.rebateType[0].value
		}

		await rebateService.create(newData).then(res => {
			const { success, data } = res
			if (success && data && data.id) {
				addOrUpdateMultipleByRebateId(data, valuesCategory, true)
				handleRebateRequirementAddOrUpdateMultipleByRebateId(data)
				handleRebateValueAddOrUpdateMultipleByRebateId(data)
				setShowModalEdit(false)
				toastr.success("Actions creation successfully")
			} else {
				toastr.error("Actions failed please try again!")
			}
		})
	}


	function handleUpdateRebate() {
		const query = {
			id: dataItem.id,
		}
		const newData = {
			name: dataItem.name,
			notes: dataItem.notes,

			minCustomerContribution: dataItem.minCustomerContribution,
			programId,
			expirationDate: dataItem.expirationDate.toISOString(),
			startDate: dataItem.startDate.toISOString(),
			rebateType: dataItem.rebateType[0].value
		}
		rebateService.update(query, newData).then(res => {
			const { success } = res
			if (success) {
				addOrUpdateMultipleByRebateId(query, valuesCategory)
				handleRebateRequirementAddOrUpdateMultipleByRebateId(dataItem, true)
				handleRebateValueAddOrUpdateMultipleByRebateId(dataItem, true)
				toastr.success("Actions update successfully")
				setShowModalEdit(false)
			} else {
				toastr.error("Actions failed please try again!")
			}

		})
	}

	function handleOnSaveModal() {
		if (handleValidate()) {
			if (dataItem.id) {
				handleUpdateRebate()
			} else {
				handleAddRebate()
			}
		}
	}

	function handleDeleteRebate() {
		if (dataItem.id) {
			const newData = {
				id: dataItem.id
			}
			rebateService.delete(newData).then(res => {
				const { success } = res
				if (success) {
					handleFetchDefault(filter)
					setShowModalDetelte(false)
					toastr.success("Action remove successfully")

				} else {
					toastr.error("Actions failed please try again!")
				}
			})
		}
	}

	function renderReabateForm() {
		return (
			<div>
				<TopLabelFormGroup
					label="Name Rebate"
					controlId="name"
					placeholder="Input Name"
					value={dataItem.name || ""}
					onChange={(name, value) => {
						handleOnChange(name, value)
					}}
					isTextArea={false}
					status={objectError.name ? Status.DANGER : null}
					message={objectError.name}
				/>
				<DatePicker
					className="Roles__form-group"
					label="Start Date"
					placeholder="Choose Date"
					type="date-input"
					dateFormat="YYYY/MM/DD hh:mm:ss"
					showTimeSelect
					selectedDate={dataItem.startDate}
					onChange={(date) => {
						handleOnChange("startDate", date)
					}}
				/>
				<DatePicker
					className="Roles__form-group"
					label="Expiration Date"
					placeholder="Choose Date"
					type="date-input"
					dateFormat="YYYY/MM/DD hh:mm:ss"
					showTimeSelect
					selectedDate={dataItem.expirationDate}
					minDate={dataItem.startDate}
					onChange={(date) => {
						handleOnChange("expirationDate", date)
					}}
				/>
				<TopLabelFormGroup
					className="Roles__form-group"
					label="Notes"
					placeholder="Input Notes"
					controlId="notes"
					value={dataItem.notes || ""}
					onChange={(name, value) => {
						handleOnChange(name, value)
					}}
					isTextArea={false}
				/>


				< TopLabelDropDownGroup
					controlId="rebateType"
					className="Roles__form-group"
					label="Type"
					placeholder="Choose Type"
					value={dataItem.rebateType || []}
					onChange={(value) => {
						handleOnChange("rebateType", [{ ...value }])
					}}
					options={OPTIONS_TYPE}
					status={objectError.rebateType ? Status.DANGER : ""}
					message={objectError.rebateType}
				/>

				<TopLabelFormGroup
					className="Roles__form-group"
					label="Min Customer Contribution"
					placeholder="Input Min Customer Contribution"
					controlId="minCustomerContribution"
					value={dataItem.minCustomerContribution || ""}
					onChange={(name, value) => {
						handleOnChange(name, value)
					}}
					isTextArea={false}
				/>

				<TopLabelFormGroup
					className="Roles__form-group"
					label="Program"
					placeholder="Input Program"
					controlId="programId"
					value={data.name}
					readOnly
					isTextArea={false}
				/>

				<DropDownItemMultipleSecond
					label="Category"
					placeholder="- Choose Category -"
					values={valuesCategory}
					onChange={(values) => {
						setCategoryPrograme(values)
					}}
					textLabel="name"
					options={category}
					items={categoryItemsParent}
				/>
			</div>
		)
	}

	function renderReabateValue() {
		return (
			<div>
				{
					listRebateValue.length > 0 && (
						<TopLabelSearchMultipleGroupSecond
							values={listRebateValue}
							onChange={(values) => {
								setListRebateValue(values)
							}}
							onClick={(value) => {
								const newList = listRebateValue.filter(item => item.id !== value.id);
								dataItemValue.push({...value, type: [
										{
											value: value.type,
											label: value.type
										}
									]})
								setDataItemValue([...dataItemValue])
								setListRebateValue(newList)
							}}
						/>
					)
				}

				<div
					role="presentation"
					onClick={() => {
						dataItemValue.push({type: [
								{
									value: RebateValueType[0].value,
									label: RebateValueType[0].label
								}
							]})
						setDataItemValue([...dataItemValue])
					}} className="Roles__add"
				>
					<span>+</span> Create Rebate Values
				</div>
				{
					dataItemValue.map((item, index) => (
						<>
							<TopLabelFormGroup
								key={`${index}-units`}
								className="Roles__form-group"
								label="Units"
								placeholder="Input Units"
								controlId="units"
								value={item.units || ""}
								onChange={(name, value) => {
									handleOnChangeRebateValue(name, value, index)
								}}
								isTextArea={false}
							/>
							<TopLabelFormGroup
								key={`${index}-value`}
								className="Roles__form-group"
								label="Value"
								placeholder="Input value"
								controlId="value"
								value={item.value}
								onChange={(name, value) => {
									handleOnChangeRebateValue(name, value, index)
								}}
								isTextArea={false}
								type="number"
							/>
							<TopLabelDropDownGroup
								controlId="type"
								className="Roles__form-group"
								label="Type"
								value={item.type || [{
									value: RebateValueType[0].value,
									label: RebateValueType[0].label
								}]}
								textLabel="label"
								textValue="value"
								onChange={(value) => {
									handleOnChangeRebateValue('type', [{ ...value }], index)
								}}
								options={RebateValueType}
							/>
						</>
					))
				}
			</div>
		)
	}

	function rebateRequirements() {
		return (
			<div>
				{
					dataItemRequirement.map((item, index) => (
						<React.Fragment key={nanoid()}>
							<TopLabelFormGroup
								key={`${index}-minAmount`}
								className="Roles__form-group"
								label="Min Amount"
								placeholder="Input Min Amount"
								controlId="minAmount"
								value={item.minAmount}
								onChange={(name, value) => {
									handleOnChangeRebateRequire(name, value, index)
								}}
								isTextArea={false}
							/>
							<TopLabelFormGroup
								className="Roles__form-group"
								label="Max Amount"
								placeholder="Input MaxAmount"
								controlId="maxAmount"
								value={item.maxAmount}
								onChange={(name, value) => {
									handleOnChangeRebateRequire(name, value, index)
								}}
								isTextArea={false}
							/>
							<TopLabelFormGroup
								className="Roles__form-group"
								label="Amount"
								placeholder="Input Amount"
								controlId="amount"
								value={item.amount}
								onChange={(name, value) => {
									handleOnChangeRebateRequire(name, value, index)
								}}
								isTextArea={false}
							/>
							<TopLabelDropDownGroup
								controlId="requirement"
								className="Roles__form-group"
								label="Requirement"
								placeholder="Choose requirement"
								value={item.requirement || []}
								textLabel="name"
								textValue="id"
								onChange={(value) => {
									handleOnChangeRebateRequire("requirement", [{ ...value }], index)
								}}
								options={newOptionRequirements}
								status={objectError.requirement[index] && objectError.requirement[index].requirementMessage && objectError.requirement[index].requirementMessage !== "" ? Status.DANGER : ""}
								message={objectError.requirement[index] && objectError.requirement[index].requirementMessage ? objectError.requirement[index].requirementMessage : ""}
							/>
						</React.Fragment>
					))
				}
				<div
					role="presentation"
					onClick={() => {
						dataItemRequirement.push({ ...DEFAULT_REQUIREMENT })
						setDataItemRequirement([...dataItemRequirement])
					}} className="Roles__add"
				>
					<span>+</span> Create Rebate Requirement
				</div>
				{
					listRebateRequirement.length ? (
						<TopLabelSearchMultipleGroupSecond
							values={listRebateRequirement}
							onChange={(values) => {
								setListRebateRequirement(values)
							}}
							onClick={(value) => {
								const newList = listRebateRequirement.filter(item => item.id !== value.id)
								const requirement = optionRequirements.filter(item => item.id === value.requirementId)

								dataItemRequirement.push({
									...value,
									requirement
								})
								setDataItemRequirement([...dataItemRequirement])
								setListRebateRequirement(newList)
							}}
						/>
					) : null
				}
			</div>
		)
	}

	function handleSetEditValue(record) {
		setListRebateValue([])
		setListRebateRequirement([])
		setShowModalEdit(true);
		setObjectError({ ...DEFAULT_ERROR });
		const newData = []
		record.categories.forEach(item => {
			newData.push({
				...item,
				value: item.id,
				label: item.name,
				parentId: item.parentCategoryId
			})
		})
		setCategoryPrograme(newData)
		setDataItem({
			...record,
			expirationDate: record.expirationDate ? new Date(record.expirationDate) : new Date(),
			startDate: record.startDate ? new Date(record.startDate) : new Date(),
			rebateType: [
				{
					value: record.rebateType,
					label: record.rebateType
				}
			]
		});
		const filter = {
			rebateId: record.id,
		}
		getListRequirementByRebateId(filter)
		getListValueByRebateId(filter)
	}

	useEffect(() => {
		handleFetchDefault(filter)
		getAllRequirement()
	}, [])


	const columns = [
		{
			key: "Name",
			header: <div>Name Rebate</div>,
			colSpan: 6,
			sortable: true,
			renderItem: (record) => {
				const { name } = record

				return (
					<div className="Roles__contain__user">
						<Text className="table-tootip-float " as="div" lines={1} popover>
							{name || "N/A"}
						</Text>
					</div>
				)
			}
		},
		{
			key: "startDate",
			header: <div>Start Date</div>,
			colSpan: 4,
			renderItem: (record) => {
				const { startDate } = record
				return (
					<>
						{
							moment(startDate).format("YYYY/MM/DD hh:mm:ss")
						}
					</>
				)

			}
		},
		{
			key: "expirationDate",
			header: <div>Expiration</div>,
			colSpan: 4,
			renderItem: (record) => {
				const { expirationDate } = record
				return (
					<>
						{
							moment(expirationDate).format("YYYY/MM/DD hh:mm:ss")
						}
					</>
				)

			}
		},
		{
			key: "Notes",
			header: <div>Notes</div>,
			sortable: true,
			colSpan: 4,
			renderItem: (record) => {
				const { notes } = record
				return (
					<div >
						{notes || "N/A"}
					</div>
				)
			}
		},
		{
			key: "industrialRate",
			header: <div>Type</div>,
			colSpan: 4,
			renderItem: (record) => {
				const { rebateType } = record
				return (
					<div >
						{rebateType === "Calculated" ? (
							<Tooltip childrenTooltip="Calculated" >
								<IconCalCulate />
							</Tooltip>
						) : (
							<Tooltip childrenTooltip="Prescriptive" >
								<IconRX />
							</Tooltip>
						)}
					</div>
				)
			}
		},
		{
			key: "min_Customer",
			header: <div className="min_Customer">Min Customer Contribution</div>,
			colSpan: 3,
			renderItem: (record) => {
				const { minCustomerContribution } = record
				return (
					<div >
						{minCustomerContribution}
					</div>
				)
			}
		},

		{
			className: "tab-center",
			key: "Project",
			header: <div>{t("utility.action")}</div>,
			colSpan: 2,
			renderItem: (record) => {
				return (
					<div className="table-groupIcon">
						<IconEditRoles onClick={() => {
							handleSetEditValue(record)
						}} />
						<IconDeleteRoles onClick={() => { setShowModalDetelte(true); setDataItem(record); setObjectError({ ...DEFAULT_ERROR }) }} className="Roles__contain__delete" />
					</div>
				)
			}
		}
	];

	return (

		<div className="Roles rebates">
			<Loading loading={loading} />
			<div className="Roles__contain">
				<div className="Roles__contain__title">
					Rebates Management
				</div>
				<div className="Roles__contain__subTitle">
					Rebates
				</div>

				<DataTable
					sortable
					noDataText="No data"
					data={items}
					columns={columns}
					orderBy={filter.orderField ? `${filter.orderField}-${filter.isDesc ? 'desc' : 'asc'}` : ""}
					onSort={str => {
						if (str) {
							const arrayValue = str.split("-")
							if (arrayValue[0] && arrayValue[1]) {
								const dataBody = {
									...filter,
									orderField: arrayValue[0],
									isDesc: arrayValue[1] !== 'asc',
									skip: 0,
									count: pageSize,
								}
								setFilter(dataBody)
								handleFetchDefault(dataBody)
							}
						}
					}}
					topChildren={(
						<TopFilterTable
							isAdd
							searchText={filter.SearchQuery}
							pageSize={filter.count}
							textButton="Create New"
							onPageSizeChange={(value) => { handlePageSizeChange(value) }}
							onClick={() => {
								setDataItem({ ...DEFAULT_REBATE });
								setShowModalEdit(true);
								setObjectError({ ...DEFAULT_ERROR })
								setDataItemValue([{ ...DEFAULT_VALUE }])
								setDataItemRequirement([{ ...DEFAULT_REQUIREMENT }])
								setActiveTab(DEFAULT_ACTIVE)
								setCategoryPrograme([])
								setListRebateValue([])
								setListRebateRequirement([])
							}}
						/>
					)}
				>
					<Pagination data={
						{
							totalItems: total,
							pageSize: filter.count,
							currentPage: filter.skip + 1
						}
					}
					            onPageChange={(value) => {
						            handlePageChange(value)
					            }}
					/>
				</DataTable>


				<Modal
					onHide={() => { setShowModalEdit(false) }}
					handleOnSave={() => {
						handleOnSaveModal()
					}}
					size="md"
					centered
					show={isShowModalEdit}
					className="rebates__modal"
					title={`${dataItem.id ? "Update" : "Create"} Rebates`}
				>
					<Tabs
						onTabChange={(tab) => {
							setActiveTab(tab)
						}}
						defaultActiveKey="1"
						headerNames={HearderTitle}>
						{HearderTitle.map(item => {
							return (
								<TabItem key={item.eventKey} eventKey={item.eventKey}>
									{
										item.eventKey === "1" && (
											renderReabateForm()
										)
									}
									{
										item.eventKey === "2" && (
											rebateRequirements()
										)
									}
									{
										item.eventKey === "3" && (
											renderReabateValue()
										)
									}
								</TabItem>
							)
						})}
					</Tabs>

				</Modal>

				<Modal onHide={() => { setShowModalDetelte(false) }} handleOnSave={() => { handleDeleteRebate() }} size="md" centered show={isShowModalDelete} textSave="Yes" title="Remove Rebate">
					<div>
						Are you sure you want to remove this rebate ?
					</div>
				</Modal>
			</div>
		</div>

	)
}

RebatesView.propTypes = {
	match: PropTypes.object,
	category: PropTypes.array,
	categoryItemsParent: PropTypes.array,
	location: PropTypes.object,
}

RebatesView.defaultProps = {
	match: {
		params: {}
	},
	category: [],
	categoryItemsParent: [],
	location: {
		state: {
			data: {}
		}
	}
}

const mapStateToProps = () => {
	return {

	}
}

const mapDispatchToProps = () => ({


})

export default connect(mapStateToProps, mapDispatchToProps)(RebatesView)
