import BaseApi from "./baseApi";
import { getCookie } from "utils/cookie";
import { common } from "constants";

class ProductApi extends BaseApi {
    GlobalSearch(searchText) {
        const manufacturers = getCookie(common.MANUFACTURER_KEY)
        searchText = encodeURIComponent(searchText);
        if (manufacturers) {
            searchText = searchText + "?manufacturerCSVString=" + manufacturers;
        }
        return super
          .execute(
            this.Methods.GET,
            this.Urls.product.search.replace('{searchText}', searchText),
            null,
            true
          )
          .then((res) => {
            return res;
          });
    }

    SearchProductByQPLNumber(searchText, skip, pageCount) {
      // SearchProductByQPLModelNumber
      searchText = encodeURIComponent(searchText);
      let url = `${this.Urls.product.searchProductByQPLModelNumber}?QPLModelNumber=${searchText}`;
      if (skip !== -1 && pageCount !== -1) {
        url = url + `&skip=${skip}&take=${pageCount}`
      }
      return super.execute(
        this.Methods.GET,
        url,
        null,
        true
      ).then((res) => {
        return res;
      });
    }

    EstimateRebate(param) {
      return super.execute(
        this.Methods.POST,
        `${this.Urls.product.estimateValue}?utilityId=${param.utilityId}&productId=${param.productId}`,
        null,
        true,
        param
      ).then((res) => {
        return res;
      });
    }
}

export default new ProductApi();
