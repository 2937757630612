import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Button from 'react-bootstrap/Button'
import './styles.scss'
import { FaCaretRight } from 'react-icons/fa'
import { BOOTSTRAP_VARIANTS } from '../../../constants/common'
import Text from '../Text'

const LinkButton = forwardRef((props, ref) => {
  const {
    onClick,
    text,
    size,
    className,
    textClassName,
    icon,
    activeIcon,
    iconLeft,
    disabled,
    as,
    href,
    lines,
    ...otherProps
  } = props
  const [expand, changeExpand] = useState(false)
  const handleOnclick = (e) => {
    changeExpand(!expand)
    onClick && onClick(e)
  }
  /* istanbul ignore next */
  useImperativeHandle(ref, () => ({
    click: handleOnclick,
  }))
  
  const Icon = typeof icon === 'function' ? icon() : icon
  const ActiveIcon =
    typeof activeIcon === 'function' ? activeIcon() : activeIcon || Icon
  return (
    <Button
      ref={ref}
      className={cn('custom-link-button', [className], {'anchor-link': !!href})}
      variant={BOOTSTRAP_VARIANTS.LINK}
      as={as}
      onClick={handleOnclick}
      size={size}
      disabled={disabled}
      href={href}
      target={href ? '_blank' : undefined}
      {...otherProps}
    >
      {iconLeft && (expand ? Icon : ActiveIcon)}
      <Text
        as="span"
        size={size}
        lines={lines}
        className={cn('link-button-text', [textClassName], {
          disabled,
          'ellipsis-text': lines > 0,
        })}
      >
        {text}
      </Text>
      {!iconLeft && (expand ? Icon : ActiveIcon)}
    </Button>
  )
})

LinkButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  textClassName: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  activeIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  iconLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  as: PropTypes.string,
  href: PropTypes.string,
  lines: PropTypes.number,
}

LinkButton.defaultProps = {
  onClick: null,
  text: '',
  size: 'md',
  className: '',
  textClassName: '',
  icon: <FaCaretRight />,
  activeIcon: null,
  iconLeft: false,
  disabled: false,
  as: undefined,
  href: undefined,
  lines: null,
}
LinkButton.displayName = 'LinkButton'

export default LinkButton
