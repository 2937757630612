/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { DataTable, Progress, Button, Checkbox, TopLabelFormGroup, DatePicker, TopLabelDropDownGroup } from "../../components/common"
import SuccessFul from './successful'
import {
  ProjectCap,
  MaterialCap,
  LaborCap,
} from "../../constants/common";
import ErrorImport from './errorImport'
import { pathKeys, common } from '../../constants'
import { programService } from "../../services"
import { JsonToFile, compareEqualFieldExcecl, compareEqualTimeFieldExcecl, compareEqualString, checkImportBoolValue } from '../../utils/common'
import "./style.scss";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bulkImportSelector } from 'redux/selectors/bulkImportSelectors';

const Add = "add"
const Update = "update"
const Delete = "delete"
const OPTIONS_CAP_TYPE = [
  {
    value: ProjectCap,
    label: ProjectCap
  },
  {
    value: MaterialCap,
    label: MaterialCap
  },
  {
    value: LaborCap,
    label: LaborCap
  },
]

export const ImportPrograms = (props) => {
  const navigate = useNavigate()
  const { setIsBulk, programImportData: data } = props
  const [dataError, setDataError] = useState([])
  const [DataAdd, setDataAdd] = useState([])
  const [DataUpdate, setDataUpdate] = useState([])
  const [DataDelete, setDataDelete] = useState([])
  const [isImport, setIsImport] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isProgress, setIsProgress] = useState(false)
  const [isError, setIsError] = useState(false)
  const [now, setNow] = useState(0)

  useEffect(() => {
    setIsBulk(true)
    return () => {
      setIsBulk(false)
    }
  }, [])

  function getListUpdateByIds(ids, dataItem) {
    programService.getByIds(ids).then(res => {
      const { success, data } = res
      if (success && data.items) {
        data.items.forEach(item => {
          const index = dataItem.findIndex(el => el.Id === item.id)
          if (index !== -1 && dataItem[index]) {
            if (!compareEqualFieldExcecl(item.name, dataItem[index].Name)) {
              dataItem[index].isWarningName = true
            }
            if (!compareEqualFieldExcecl(item.isActive, dataItem[index].IsActive)) {
              dataItem[index].isWarningIsActive = true
            }
            if (!compareEqualTimeFieldExcecl(item.startDate, dataItem[index].StartDate)) {
              dataItem[index].isWarningStartDate = true
            }
            if (!compareEqualTimeFieldExcecl(item.endDate, dataItem[index].EndDate)) {
              dataItem[index].isWarningEndDate = true
            }
            if (!compareEqualTimeFieldExcecl(item.created, dataItem[index].Created)) {
              dataItem[index].isWarningCreated = true
            }
            if (!compareEqualFieldExcecl(item.websiteLink, dataItem[index].WebsiteLink)) {
              dataItem[index].isWarningWebsiteLink = true
            }
            if (!compareEqualFieldExcecl(item.applicationLink, dataItem[index].ApplicationLink)) {
              dataItem[index].isWarningApplicationLink = true
            }
            if (!compareEqualFieldExcecl(item.requirements, dataItem[index].Requirements)) {
              dataItem[index].isWarningRequirements = true
            }
            if (!compareEqualFieldExcecl(item.incentiveCap, dataItem[index].IncentiveCap)) {
              dataItem[index].isWarningIncentiveCap = true
            }
            if (!compareEqualFieldExcecl(item.capType, dataItem[index].CapType)) {
              dataItem[index].isWarningCapType = true
            }
            if (!compareEqualFieldExcecl(item.maxAnnualIncetive, dataItem[index].MaxAnnualIncetive)) {
              dataItem[index].isWarningMaxAnnualIncetive = true
            }
            if (!compareEqualFieldExcecl(item.isNewConstruction, dataItem[index].isNewConstruction)) {
              dataItem[index].isWarningisNewConstruction = true
            }
            if (!compareEqualFieldExcecl(item.isInstant, dataItem[index].isInstant)) {
              dataItem[index].isWarningisInstant = true
            }
            if (!compareEqualFieldExcecl(item.isRetroFit, dataItem[index].isRetroFit)) {
              dataItem[index].isWarningisRetroFit = true
            }
            if (!compareEqualFieldExcecl(item.preApprovalRequiredAmount, dataItem[index].preApprovalRequiredAmount)) {
              dataItem[index].isWarningPreApprovalRequiredAmount = true
            }
          }
        })

        setDataUpdate([
          ...dataItem
        ])
      }
    })
  }

  useEffect(() => {
    const DataAddDefault = data.filter(item => compareEqualString(item.Action, Add))
    const DataUpdateDefault = data.filter(item => compareEqualString(item.Action, Update))
    const DataDeleteDefault = data.filter(item => compareEqualString(item.Action, Delete))

    const DataUpdateIds = []
    data.forEach(item => {
      if (item.Id && item.Id !== "") {
        DataUpdateIds.push(item.Id)
      }
    })
    setDataAdd(DataAddDefault)
    setDataUpdate(DataUpdateDefault)
    setDataDelete(DataDeleteDefault)
    getListUpdateByIds(DataUpdateIds, DataUpdateDefault)
  }, [data])


  function importData(file) {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", "importData.csv");

    programService.importFile(formData).then(res => {
      const { success, data } = res
      if (success && !data.errors.length) {
        setIsSuccess(true)
        setIsError(false)
        setDataError([])
      } else {
        setIsError(true)
        setIsSuccess(false)
        if (data.errors) {
          setDataError(data.errors)
        }
      }
      setNow(100)
      setIsProgress(false)
    })
  }

  function handleBackToList() {
    navigate(pathKeys.BULK_PROGRAMS)
  }

  function handleOnChange(name, value, index, action) {
    if (compareEqualString(action, Add)) {
      DataAdd[index][name] = value
      if (name === "Name" && value !== "") {
        DataAdd[index].isErrorName = false
      }
      setDataAdd([...DataAdd])
    } else if (compareEqualString(action, Update)) {
      DataUpdate[index][name] = value
      if (name === "Name" && value !== "") {
        DataUpdate[index].isErrorName = false
      }
      if (name === "Id" && value !== "") {
        DataUpdate[index].isErrorId = false
      }
      setDataUpdate([...DataUpdate])
    } else {
      if (name === "Name" && value !== "") {
        DataDelete[index].isErrorName = false
      }
      if (name === "Id" && value !== "") {
        DataDelete[index].isErrorId = false
      }
      DataDelete[index][name] = value
      setDataDelete([...DataDelete])
    }
  }

  function handleValidateAdd() {
    let check = true
    DataAdd.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataAdd[index].isErrorName = true
        }
      }
    }
    )
    setDataAdd([...DataAdd])
    return check

  }

  function handleValidateUpdate() {
    let check = true
    DataUpdate.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataUpdate[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataUpdate[index].isErrorId = true
        }
      }
    }
    )
    setDataUpdate([...DataUpdate])
    return check
  }

  function handleValidateDelete() {
    let check = true
    DataDelete.forEach((item, index) => {
      if (item.KeepChange) {
        if (!item.Name || item.Name === "") {
          check = false
          DataDelete[index].isErrorName = true
        }
        if (!item.Id || item.Id === "") {
          check = false
          DataDelete[index].isErrorId = true
        }
      }
    }
    )
    setDataDelete([...DataDelete])
    return check
  }
  const columns = [
    {
      key: "keepChange",
      colSpan: 3,
      header: <div>Keep Change</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("KeepChange", !record.KeepChange, index, record.Action)
          }} isFull checked={record.KeepChange} />
        )
      }
    },
    {
      key: "Name",
      colSpan: 6,
      header: <div>Name</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Name", value, index, record.Action)
            }}
            status={record.isErrorName ? common.BOOTSTRAP_VARIANTS.DANGER : record.isWarningName ? common.BOOTSTRAP_VARIANTS.WARNING : null}
            value={record.Name}
            isTextArea={false}
          />
        )
      }
    },
    {
      key: "StartDate",
      colSpan: 6,
      header: <div>StartDate</div>,
      renderItem: (record, index) => {
        let dateSelected = new Date(record.StartDate)
        if (Number.isNaN(dateSelected.getTime())) {
          dateSelected = new Date()
        }

        return (
          <>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dateSelected}
              onChange={(date) => {
                handleOnChange("StartDate", date.toISOString(), index, record.Action)
              }}
              status={record.isWarningStartDate ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            />
          </>
        )
      }
    },
    {
      key: "EndDate",
      colSpan: 6,
      header: <div>EndDate</div>,
      renderItem: (record, index) => {
        let dateSelected = new Date(record.EndDate)
        if (Number.isNaN(dateSelected.getTime())) {
          dateSelected = new Date()
        }
        return (
          <>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dateSelected}
              onChange={(date) => {
                handleOnChange("EndDate", date.toISOString(), index, record.Action)
              }}
              status={record.isWarningEndDate ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            />
          </>
        )
      }
    },
    {
      key: "WebsiteLink",
      colSpan: 6,
      header: <div>Website Link</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("WebsiteLink", value, index, record.Action)
            }}
            value={record.WebsiteLink} isTextArea={false}
            status={record.isWarningWebsiteLink ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "ApplicationLink",
      colSpan: 6,
      header: <div>Application Link</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("ApplicationLink", value, index, record.Action)
            }}
            value={record.ApplicationLink}
            isTextArea={false}
            status={record.isWarningApplicationLink ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "IsActive",
      colSpan: 3,
      header: <div>IsActive</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("IsActive", !record.IsActive, index, record.Action)
          }}
            isFull
            checked={record.IsActive}
            status={record.isWarningIsActive ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
          />
        )
      }
    },
    {
      key: "Requirements",
      colSpan: 8,
      header: <div>Requirements</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Requirements", value, index, record.Action)
            }}
            value={record.Requirements}
            status={record.isWarningRequirements ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },

    {
      key: "CapType",
      colSpan: 8,
      header: <div>Cap Type</div>,
      renderItem: (record, index) => {
        let newValue = OPTIONS_CAP_TYPE.filter(item => item.value === record.CapType)
        if (!newValue.length) {
          newValue = OPTIONS_CAP_TYPE[0]
        }
        return (
          <TopLabelDropDownGroup
            controlId="capType"
            className="Roles__form-group"
            placeholder="Choose Cap Type"
            value={newValue}
            onChange={(data) => {
              handleOnChange("CapType", data.value, index, record.Action)
            }}
            options={OPTIONS_CAP_TYPE}
            status={record.isWarningCapType ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
          />

        )
      }
    },
    {
      key: "MaxAnnualIncentive",
      colSpan: 6,
      header: <div>Max Annual Incetive</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("MaxAnnualIncetive", value, index, record.Action)
            }}
            value={record.MaxAnnualIncetive}
            isTextArea={false}
            status={record.isWarningMaxAnnualIncetive ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "RetroFit",
      colSpan: 4,
      header: <div>RetroFit</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("isRetroFit", !record.isRetroFit, index, record.Action)
          }} isFull checked={checkImportBoolValue(record.isRetroFit)}
            status={record.isWarningisRetroFit ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
          />
        )
      }
    },
    {
      key: "NewConstruction",
      colSpan: 6,
      header: <div>New Construction</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("isNewConstruction", !record.isNewConstruction, index, record.Action)
          }} isFull checked={checkImportBoolValue(record.isNewConstruction)}
            status={record.isWarningisNewConstruction ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
          />
        )
      }
    },
    {
      key: "isInstant",
      colSpan: 6,
      header: <div>Instant</div>,
      renderItem: (record, index) => {
        return (
          <Checkbox onClick={() => {
            handleOnChange("isInstant", !record.isInstant, index, record.Action)
          }} isFull
            checked={checkImportBoolValue(record.isInstant)}
            status={record.isWarningisInstant ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
          />
        )
      }
    },
    {
      key: "PreApprovalRequiredAmount",
      colSpan: 8,
      header: <div>Pre-Approval Required</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("preApprovalRequiredAmount", value, index, record.Action)
            }}
            value={record.PreApprovalRequiredAmount}
            isTextArea={false}
            status={record.isWarningPreApprovalRequiredAmount ? common.BOOTSTRAP_VARIANTS.WARNING : null}
          />
        )
      }
    },
    {
      key: "Created",
      colSpan: 6,
      header: <div>Created</div>,
      renderItem: (record, index) => {
        let dateSelected = new Date(record.Created)
        if (Number.isNaN(dateSelected.getTime())) {
          dateSelected = new Date()
        }

        return (
          <>
            <DatePicker
              className="Roles__form-group"
              placeholder="Choose Date"
              type="date-input"
              dateFormat="YYYY/MM/DD hh:mm:ss"
              showTimeSelect
              selectedDate={dateSelected}
              onChange={(date) => {
                handleOnChange("Created", date.toISOString(), index, record.Action)
              }}
              status={record.isWarningCreated ? common.BOOTSTRAP_VARIANTS.WARNING : ""}
            />
          </>
        )
      }
    },
  ];

  const columnsV2 = [
    ...columns,
    {
      key: "programId",
      colSpan: 7,
      header: <div>Id</div>,
      renderItem: (record, index) => {
        return (
          <TopLabelFormGroup
            onChange={(_, value) => {
              handleOnChange("Id", value, index, record.Action)
            }}
            status={record.isErrorId ? common.BOOTSTRAP_VARIANTS.DANGER : null}
            value={record.Id}
            isTextArea={false}
          />
        )
      }

    },]

  return (
    <>
      {
        isImport ? (
          <div className="import-view-second">
            <div className="import-view__progress">
              {isProgress ? <Progress now={now} text="Processing Import..." /> : null}
              {
                isSuccess ? <SuccessFul onClick={handleBackToList} /> : null
              }
              {
                isError ? <ErrorImport data={dataError} onClick={handleBackToList} /> : null
              }
            </div>
          </div>
        ) : (
          <Scrollbars
            onScrollFrame={() => {

            }}
            autoHide >
            <div className="Roles import-view">
              <div className="Roles__contain">
                <div className="Roles__contain__title">
                  Import Changes
                </div>

                <div className="Roles__contain__subTitle">
                  Add New Programs
                </div>
                <DataTable
                  sortable
                  data={DataAdd}
                  columns={columns}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Update Existing Programs
                </div>
                <DataTable
                  sortable
                  data={DataUpdate}
                  columns={columnsV2}
                />

                <div className="Roles__contain__subTitle import-view__subTitle">
                  Delete Existing Programs
                </div>
                <DataTable
                  sortable
                  data={DataDelete}
                  columns={columnsV2}
                />

                <div className="import-view__buton">
                  <Button
                    onClick={() => {
                      const checkAdd = handleValidateAdd()
                      const checkUpdate = handleValidateUpdate()
                      const checkDelete = handleValidateDelete()
                      if (checkAdd && checkUpdate && checkDelete) {
                        setIsImport(true)
                        setIsProgress(true)
                        setIsSuccess(false)
                        setIsError(false)
                        setNow(0)
                        setTimeout(() => {
                          setNow(10)
                          const newData = [...DataDelete, ...DataUpdate, ...DataAdd]
                          const realData = []
                          newData.forEach(item => {
                            if (item.KeepChange) {
                              realData.push({
                                Action: item.Action,
                                Name: item.Name,
                                StartDate: item.StartDate,
                                EndDate: item.EndDate,
                                WebsiteLink: item.WebsiteLink,
                                ApplicationLink: item.ApplicationLink,
                                IsActive: item.IsActive,
                                Requirements: item.Requirements,
                                IncentiveCap: item.IncentiveCap,
                                CapType: item.CapType,
                                MaxAnnualIncetive: item.MaxAnnualIncetive,
                                isRetroFit: item.isRetroFit,
                                isNewConstruction: item.isNewConstruction,
                                isInstant: item.isInstant || false,
                                Id: compareEqualString(item.Action, Add) ? "" : item.Id,
                                Created: item.Created,
                                PreApprovalRequiredAmount: item.PreApprovalRequiredAmount || 0
                              })
                            }
                          })

                          const newFile = JsonToFile(realData)
                          importData(newFile)
                        }, 500)
                        setTimeout(() => {
                          setNow(30)
                        }, 1000)
                        setTimeout(() => {
                          setNow(50)
                        }, 2000)
                        setTimeout(() => {
                          setNow(75)
                        }, 2500)

                      } else {
                        toastr.error("Import file has got filed invalid")
                      }

                    }}
                    typeClass="second"
                    size="md"
                    text="Import"
                  />
                </div>
              </div>

            </div>
          </Scrollbars>
        )
      }


    </>

  )
}

ImportPrograms.propTypes = {
  setIsBulk: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  programImportData: PropTypes.array
}

ImportPrograms.defaultProps = {
  setIsBulk: () => { },
  location: {
    state: {
      data: {}
    }
  },
  history: {
    push: () => { }
  },
  programImportData: []
}

const mapStateToProps = (state) => {
  return {
    programImportData: bulkImportSelector.programImportData(state)
  }
}

export default connect(mapStateToProps, null)(ImportPrograms)
