import get from "lodash/get"

export default class MapboxFeature {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data);
    }
  }

  init(data) {
    if (!data) return;
    this.bbox = get(data, "bbox", []);
    this.center = get(data, "center", []);
    this.context = get(data, "context", []);
    this.geometry = get(data, "geometry", null);
    this.id = get(data, "id", null);
    this.placeName = get(data, "place_name", "");
    this.placeType = get(data, "place_type", null);
    this.properties = get(data, "properties", null);
    this.relevance = get(data, "relevance", null);
    this.text = get(data, "text", null);
    this.type = get(data, "type", 0);
  }

  toJSON() {
    const {
      id,
      bbox,
      center,
      context,
      geometry,
      placeName,
      placeType,
      properties,
      relevance,
      text,
      type
    } = this;
    return {
        id,
        bbox,
        center,
        context,
        geometry,
        placeName,
        placeType,
        properties,
        relevance,
        text,
        type
    };
  }
}
