
import BaseService from "./baseService";
import RebateApi from "../api/rebateApi";
import { getQueryString } from "../utils/common";


class RebateService extends BaseService {
  filterByProgramId(query = {}) {
    const newQuery = getQueryString(query)
    return RebateApi.filterByProgramId(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  getList(query = {}) {
    const newQuery = getQueryString(query)
    return RebateApi.getList(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  create(params) {
    return RebateApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return RebateApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  delete(query) {
    const newQuery = getQueryString(query)
    return RebateApi.delete(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportAll(params = {}) {
    return RebateApi.exportAll(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportByFilter(params = {}) {
    return RebateApi.exportByFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportFilter(params = {}) {
    return RebateApi.exportFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportBlank() {
    return RebateApi.exportBlank().then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getByIds(params = {}) {
    return RebateApi.getByIds(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  importFile(params = {}) {
    return RebateApi.importFile(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  sendEmail(params = {}) {
    return RebateApi.sendEmail(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  allRebateCountByCounty(param) {
    return RebateApi.allRebateCountByCounty(param).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  allRebateByState(param) {
    return RebateApi.allRebateByState(param).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }

  allRebate(param) {
    return RebateApi.allRebate(param).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }
}

export default new RebateService();
