import React, { useState } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import cn from "classnames";
import { connect } from "react-redux";
import { menuLogo } from "../../../assests/images";
import { IconToggle, IconQuestion } from "../../../assests/icons";
import LeftMenuItems from "./LeftMenuItems";
import { useAuth0 } from "../../Auth0";
import Avatar from "../Avatar";
import { routeActions, profileActions } from "../../../redux/actions";
import HelpModal from "../../modules/HelpModal";

import './style.scss'

export const LeftMenu = ({ showFullMenu, onSidebarToggle, changeSelectDropdown }) => {
  const { user = {} } = useAuth0();
  const [showHelpModal, setShowHelpModal] = useState(false);

  return (
    <div className={cn("sidebar custom-sidebar", { toggled: !showFullMenu })}>
      <div className="sidebar-content">
        <div className="sidebar-logo-toggle">
          <a className="sidebar-brand" onClick={(e) => { e.preventDefault() }} href="!#">
            <img className="logo" src={menuLogo} alt="" />
          </a>
          <IconToggle
            onClick={() => onSidebarToggle()}
            className="sidebar-toggle"
          />
        </div>
        <Scrollbars style={{ height: `${window.innerHeight - 105}px` }}>
          <ul className="sidebar-nav">
            <LeftMenuItems />
          </ul>
        </Scrollbars>
        <div className="hiddenDesktop footerButton">
          <IconQuestion role="presentation" onClick={() => setShowHelpModal(true)} className="iconHover iconHoverQuestion" />
          <div
            role="presentation"
            onClick={() => {
              changeSelectDropdown(true)
              onSidebarToggle(false)
            }} className="footerButton__avatar">
            <Avatar src={user.avatar} size="sm" />
          </div>

        </div>
      </div>
      <HelpModal show={showHelpModal} onHide={() => setShowHelpModal(false)} />
    </div>

  );
};

LeftMenu.propTypes = {
  showFullMenu: PropTypes.bool,
  onSidebarToggle: PropTypes.func,
  changeSelectDropdown: PropTypes.func,

};

/* istanbul ignore next */
export const mapStateToProps = () => {
  return {

  };
};

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch) => ({
  // historyPush: (path) => dispatch(routeActions.push(path)),
  changeSelectDropdown: (value) => dispatch(profileActions.changeSelectDropdown(value)),
});

export default connect(null, mapDispatchToProps)(LeftMenu);
