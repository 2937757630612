/* istanbul ignore file */
export default {
  baseUrl: `${process.env.REACT_APP_API_ROOT}/api/`,
  users: {
    me: "User/CurrentUser",
    list: "User/Filter",// List
    create: "User", // Post
    update: "User", // Put
    delete: "User", // Delete
    AssignMultipleRoles: "UserRole/AssignMultipleRoles",
    UploadAvatar: "User/UploadAvatar",
    DeleteAvatar: "User/DeleteAvatar",
    StartTrial: "User/start-trial",
    SetPremiumExpiration: "User/SetPremiumExpiration"
  },
  programs: {
    create: "Program", // Post
    getList: "Program", // Get
    update: "Program", // Put
    delete: "Program", // Delete
    getById: "Program/{id}",
    import: "Program/import", // Post
    filter: "Program/filter", // Post
    getbyUtilityId: "Program/GetByUtilityId",
    GetProgramMetadatas: "Program/GetProgramMetadatas",
    getProgramMetadatasByName: "Program/GetProgramMetadatasByName/{name}",
    search: "Program/search/{searchText}",
    getByStateAndUtilityName: "Program/GetByStateAndUtilityName",
    getAllProgramByUtilityId: "Program/GetProgramsByUtilityId",
    filterBySearchTextAndUtilityId: "Program/FilterBySearchTextAndUtilityId",
    grogramSearch: "Program/ProgramSearch/{searchText}",
    exportByIds: "Program/ExportByIds",
    exportAll: "Program/ExportAll",
    exportByFilter: "Program/ExportByFilter",
    exportFilter: "Program/ExportFilter",
    exportBlank: "Program/ExportBlank",
    getByIds: "Program/GetByIds",
    importFile: "Program/ImportFromCSV"
  },
  mapbox: {
    forwardGeocoding: "geocoding/v5/mapbox.places/{search_text}.json"
  },
  county: {
    getCountiesByGeoContext: "County/GetCountiesByGeoContext", // Post
    search: "Program/search/{searchText}",
    getCountiesByUtilityIds: "UtilityCounty/GetCountiesByUtilityIds",
  },
  category: {
    getList: "Category/get-all",// List
    create: "Category", // Post
    update: "Category", // Put
    delete: "Category", // Delete,
    RebateAndProductNumber: "Category/RebateAndProductNumber",
    updateWithRebateProductNumber: "Category/UpdatedWithReCountRebateProductNumber", // Put
  },
  role: {
    list: "Role",// List
    create: "Role", // Post
    update: "Role", // Put
    delete: "Role", // Delete
  },
  authentication: {
    check: "Authentication/CheckToken"
  },
  utility: {
    list: "Utility/filter",
    all: "Utility/get-all",
    create: "Utility", // Post
    update: "Utility", // Put
    getUtilitiesByProgramId: "Utility/GetUtilitiesByProgramId",
    exportAll: "Utility/ExportAll",
    exportByFilter: "Utility/ExportByFilter",
    exportFilter: "Utility/ExportFilter",
    exportBlank: "Utility/ExportBlank",
    getByIds: "Utility/GetByIds",
    importFile: "Utility/ImportFromCSV"
  },
  programUitilityApi: {
    addOrUpdateMultipleByUtilityId: "ProgramUtility/AddOrUpdateMultipleByUtilityId",
    addOrUpdateMultipleByProgramId: "ProgramUtility/AddOrUpdateMultipleByProgramId",
  },
  customerType: {
    all: "CustomerType/GetAll",
    addOrUpdateMultipleByProgramId: "ProgramCustomerType/AddOrUpdateMultipleByProgramId"
  },
  rebate: {
    create: "Rebate", // Post
    update: "Rebate", // Put
    filterByProgramId: "Rebate/FilterByProgramId",
    delete: "Rebate",
    getList: "Rebate",
    exportAll: "Rebate/ExportAll",
    exportByFilter: "Rebate/ExportByFilter",
    exportFilter: "Rebate/ExportFilter",
    exportBlank: "Rebate/ExportBlank",
    getByIds: "Rebate/GetByIds",
    importFile: "Rebate/ImportFromCSV",
    sendEmail: "Rebate/SendEmail",
    allRebateByCounty: "Rebate/AllRebateByCounty",
    allRebateByState: "Rebate/AllRebateByState",
    allRebateByStateForProductsAndCategories: "Rebate/AllRebateByStateForProductsAndCategories",
    allRebateByCountyForProductsAndCategories: "Rebate/AllRebateByCountyForProductsAndCategories",
    allRebate: "Rebate/AllRebateByForProductsAndCategories"
  },
  rebateRequirement: {
    getListByRebateId: "RebateRequirement/GetListByRebateId",
    addOrUpdateMultipleByRebateId: "RebateRequirement/AddOrUpdateMultipleByRebateId"
  },
  rebateValue: {
    getListByRebateId: "RebateValue/GetListByRebateId",
    addOrUpdateMultipleByRebateId: "RebateValue/AddOrUpdateMultipleByRebateId"
  },
  rebateCategory: {
    addOrUpdateMultipleByRebateId: "RebateCategory/AddOrUpdateMultipleByRebateId"
  },
  requirement: {
    getAll: "Requirement/GetAll"
  },
  billing: {
    create: "Billing/create-subscription"
  },
  product:{
    search: "Product/search/{searchText}",
    getFilterData: "Product/FilterProductData",
    searchProductByQPLModelNumber: "Product/SearchProductByQPLModelNumber",
    estimateValue: "Product/EstimateValue",
  }
};
