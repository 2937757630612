import React from 'react'
import PropTypes from 'prop-types'
import { IconDeleteRoles, IconEditRoles } from "../../../assests/icons"
import { Text } from ".."

const TopLabelSearchMultipleGroupSecond = (props) => {
  const {
    values,
    onChange,
    onClick
  } = props

  return (
    <>
      {
        values && values.length ? (
          <div className="top-label-search-mutiple-group__values">
            {values.map(item => {
              return (
                <div key={item.id} className="top-label-search-mutiple-group__values__item">
                  <Text as="div" lines={1} popover>
                    {item.name}
                  </Text>
                  <IconEditRoles onClick={() => { onClick(item) }} style={{ marginRight: '10px' }} />
                  <IconDeleteRoles onClick={() => {
                    const newFilter = values.filter(el => el.id !== item.id)
                    onChange(newFilter)
                  }} />
                </div>
              )
            })}
          </div>
        ) : null
      }
    </>
  )
}

TopLabelSearchMultipleGroupSecond.displayName = "TopLabelSearchMultipleGroup";

TopLabelSearchMultipleGroupSecond.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.array,
  onClick: PropTypes.func,
}

TopLabelSearchMultipleGroupSecond.defaultProps = {
  onChange: () => { },
  values: [],
  onClick: () => { }
}

export default TopLabelSearchMultipleGroupSecond
