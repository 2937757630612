
import {SET_UTILITY_IMPORT_DATA, SET_PROGRAM_IMPORT_DATA, SET_REBATE_IMPORT_DATA} 
    from '../constants/bulkImportActions'
  
export function changeUtilityImportData(items) {
    return dispatch => {
        dispatch({
            type: SET_UTILITY_IMPORT_DATA,
            payload: {
                items
            }
        })
    }
}

export function changeRebateImportData(items) {
    return dispatch => {
        dispatch({
            type: SET_REBATE_IMPORT_DATA,
            payload: {
                items
            }
        })
    }
}

export function changeProgramImportData(items) {
    return dispatch => {
        dispatch({
            type: SET_PROGRAM_IMPORT_DATA,
            payload: {
                items
            }
        })
    }
}
  