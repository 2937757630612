export const CHANGE_CATEGORY_DROPDOWN = 'CHANGE_CATEGORY_DROPDOWN'
export const CHANGE_CATEGORY_ITEM_DROPDOWN = 'CHANGE_CATEGORY_ITEM_DROPDOWN'
export const CHANGE_COUNTY_DROPDOWN = 'CHANGE_COUNTY_DROPDOWN'
export const CHANGE_UTILITY_DROPDOWN = 'CHANGE_UTILITY_DROPDOWN'
export const CHANGE_COUNTY_ITEM_DROPDOWN = 'CHANGE_COUNTY_ITEM_DROPDOWN'
export const CHANGE_UTILITY_ITEM_DROPDOWN = 'CHANGE_UTILITY_ITEM_DROPDOWN'
export const CHANGE_CATEGORY_HISTORY = 'CHANGE_CATEGORY_HISTORY'
export const CHANGE_UTILITY_HISTORY = 'CHANGE_UTILITY_HISTORY'
export const CHANGE_COUNTY_HISTORY = 'CHANGE_COUNTY_HISTORY'
export const CHANGE_PRODUCT_HISTORY = 'CHANGE_PRODUCT_HISTORY'
