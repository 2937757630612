import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { IconOpeninNew } from "../../../assests/icons";

import "./style.scss";

const ApplyLink = ({ children, className, ...props }) => {
  const wrapperClasses = cn("apply-link", [className]);
  return (
    <div className={wrapperClasses} {...props}>
      {children}
      <IconOpeninNew className="svg" />
    </div>
  );
};

ApplyLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

ApplyLink.defaultProps = {
  children: "",
  className: ""
};

export default ApplyLink;
