import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { PillButton } from '..'
import './style.scss'


const MultiSelect = props => {
  const {
    className,
    getOptionLabel,
    getOptionValue,
    options: controlledOptions,
    onChange,
    disabled,
    placeholder,
    onInputChange,
    defaultValue,
  } = props

  const initialSelectedItems = defaultValue || []
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems)


  const options = useMemo(() => {
    const isItemSelected = item => {
      return selectedItems.some(selectedItem => getOptionValue(selectedItem) === getOptionValue(item))
    }
    return controlledOptions.filter(item => !isItemSelected(item))
  }, [controlledOptions, selectedItems, getOptionValue])


  const handleToggleSelectionItem = item => {
    const index = selectedItems.findIndex(selectedItem => getOptionValue(selectedItem) === getOptionValue(item))

    const nextSelectedItems = index !== -1 
      ? [
        ...selectedItems.slice(0, index),
        ...selectedItems.slice(index + 1),
      ]
      : [
        ...selectedItems,
        item,
      ]
    setSelectedItems(nextSelectedItems)
    typeof onChange === 'function' && onChange(nextSelectedItems)
  }

  const handleInputChange = (value, { action }) => {
    action === 'input-change' &&
      typeof onInputChange === 'function' &&
      onInputChange(value)
  }

  const wrapperClass = cn('custom-multi-select', [className])
  return (
    <div className={wrapperClass}>
      <Select
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={handleToggleSelectionItem}
        options={options}
        value={null}
        isDisabled={disabled}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        defaultValue={defaultValue}
      />
      <div
        className={cn('selected-items-wrapper', {
          hide: !selectedItems.length,
        })}
      >
        {selectedItems.map((item) => (
          <PillButton
            key={getOptionValue(item)}
            onCloseButtonClick={() => handleToggleSelectionItem(item)}
            size="sm"
            text={getOptionLabel(item)}
            className="selected-items-pill"
          />
        ))}
      </div>
    </div>
  )
}

MultiSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.array,
}

MultiSelect.defaultProps = {
  className: '',
  options: [],
  getOptionLabel: ({ name }) => name,
  getOptionValue: ({ id }) => id,
  onChange: undefined,
  disabled: false,
  onInputChange: undefined,
  placeholder: '',
  defaultValue: undefined,
}

export default MultiSelect
