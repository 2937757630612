import { getModelPropDefaultValue as getDefault } from '../utils/common'
import Role from "./Role";

export default class User {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data)
    }
  }

  init(data) {
    if (!data) return

    this.id = getDefault(data.id, 'string')
    this.created = getDefault(data.created, 'date')
    this.modified = getDefault(data.modified, 'date')
    this.firstName = getDefault(data.firstName, 'string')
    this.lastName = getDefault(data.lastName, 'string')
    this.fullName = getDefault(data.fullName, 'string')
    this.company = getDefault(data.company, 'string')
    this.phoneNumber = getDefault(data.phoneNumber, 'string')
    this.emailNewProduct = getDefault(data.emailNewProduct, 'boolean')
    this.emailProductSurveys = getDefault(data.emailProductSurveys, 'boolean')
    this.emailWeeklyUpdate = getDefault(data.emailWeeklyUpdate, 'boolean')
    this.productUsabilityStudies = getDefault(data.productUsabilityStudies, 'boolean')
    this.avatar = getDefault(data.avatar, 'string')
    this.trialExpireDate = getDefault(data.trialExpireDate, 'string')
    this.emailAddress = getDefault(data.emailAddress, 'string')
    this.emailVerified = getDefault(data.emailVerified, 'string')
    this.userType = getDefault(data.userType, 'string')
    this.remainingSecond = getDefault(data.remainingSecond, 'string')
    this.roles = (data.roles || []).map(role => new Role(getDefault(role, 'object')))
  }
}
