/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import './style.scss'

// const TEXT_LINE_HEIGHT = 2.25


const Text = ({ size, children, as, lines, className, onClick, popover, align }) => {
  const wrapperClasses = cn(
    'custom-text-body',
    `text-${size}`,
    `text-${align}`,
    {
      'ellipsis-text': lines > 0,
    },
    [className]
  )
  const inlineStyle = {
    // lineHeight: TEXT_LINE_HEIGHT,
    // height: `${lines * TEXT_LINE_HEIGHT}em`
    ...(lines > 0 && { WebkitLineClamp: lines }),
  }

  const renderTooltip = useMemo(
    (props) => {
      return <Tooltip {...props} className="text-custom-tooltip">{children}</Tooltip>
    },
    [children]
  )

  const handleOnClick = () => typeof onClick === 'function' && onClick()

  let textNode
  switch (as) {
    case 'p':
    default:
      textNode = <p onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</p>
      break
    case 'div':
      textNode = <div onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</div>
      break
    case 'h5':
      textNode = <h5 onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</h5>
      break
    case 'h6':
      textNode = <h6 onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</h6>
      break
    case 'span':
      textNode = <span onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</span>
      break
    case 'i':
      textNode = <i onClick={handleOnClick} className={wrapperClasses} style={inlineStyle}>{children}</i>
      break
  }
  return popover ? (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {textNode}
    </OverlayTrigger>
  ) : (
      textNode
    )
}

Text.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  children: PropTypes.node,
  as: PropTypes.oneOf(['span', 'p', 'i', 'a', 'div', 'h5', 'h6']),
  align: PropTypes.oneOf(['center', 'left', 'right', 'justify']),
  className: PropTypes.string,
  lines: PropTypes.number,
  onClick: PropTypes.func,
  popover: PropTypes.bool,
}

Text.defaultProps = {
  size: null,
  children: '',
  as: 'p',
  align: 'left',
  className: '',
  lines: null,
  onClick: null,
  popover: false,
}

export default Text
