import BaseApi from "./baseApi";

class RebateCategoryApi extends BaseApi {
  addOrUpdateMultipleByRebateId(query, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebateCategory.addOrUpdateMultipleByRebateId}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }
}

export default new RebateCategoryApi();
