import {
  BussinesActive,
  FamilyActive,
  HomeActive,
  RobotActive,
  BussinesInactive,
  FamilyInactive,
  HomeInactive,
  RobotInactive,
  CommercialActive,
  CommercialInactive,
  IndividualActive,
  IndividualInactive,
  ConstructionActive,
  ConstructionInactive
} from "../assests/customers";

export const IconCustomerActive = {
  "Small Business": BussinesActive,
  "Multi-Family": FamilyActive,
  "Commercial": CommercialActive,
  "Industrial": RobotActive,
  "New Construction": ConstructionActive,
  "Individual": IndividualActive,
  "Residential": HomeActive,
};


export const IconCustomerInActive = {
  "Small Business": BussinesInactive,
  "Multi-Family": FamilyInactive,
  "Commercial": CommercialInactive,
  "Industrial": RobotInactive,
  "New Construction": ConstructionInactive,
  "Individual": IndividualInactive,
  "Residential": HomeInactive,
};
