
import BaseService from "./baseService";
import requirementApi from "../api/requirementApi";
import { getQueryString } from "../utils/common";


class RequirementService extends BaseService {
  getAll(query = {}) {
    const newQuery = getQueryString(query)
    return requirementApi.getAll(newQuery).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }
}

export default new RequirementService();
