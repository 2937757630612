/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import PropTypes from 'prop-types'
import Avatar from "../Avatar";
import './dropDownStyle.scss'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Nav.Link
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </Nav.Link>
))

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('')

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {React.Children.toArray(children).filter(
          child =>
            !value || child.props.children.toLowerCase().startsWith(value)
        )}
      </div>
    )
  }
)

const CustomDropdown = props => {
  const { user = {}, items, children } = props;
  return (
    <Dropdown className="username-dropdown">
      <Dropdown.Toggle as={CustomToggle}>{children || ""}</Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>

        <Dropdown.Item className="titleHeader" onClick={() => { }}>
          <Avatar src={user.avatar} size="md" />
          <div className="titleParent">
            <div className="userName">
              {user.fullName || 'Set up your profile'}
            </div>
            <div className="subName">
              {user.company || ''}
            </div>
          </div>
        </Dropdown.Item>
        <hr className="line" />


        {items.map(({ text, onClick, divider, textSub, isLogout }, index) => {
          const eventKey = (index + 1).toString()
          if (divider) return <hr key={`${eventKey} ${text} CustomDropdown`} className="line" />
          return (
            <Dropdown.Item className={`userName ${isLogout ? "subNameSecondLogout" : ""}`} key={`${eventKey} ${text} CustomDropdown`} eventKey={eventKey} onClick={onClick}>
              {text}
              {textSub ? (
                <div className="subName subNameSecond">
                  {textSub}
                </div>
              ) : null}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

CustomToggle.displayName = 'CustomDropdownToggle'
CustomMenu.displayName = 'CustomDropdownMenu'
CustomDropdown.propTypes = {
  user: PropTypes.object,
  items: PropTypes.instanceOf(Array),
  children: PropTypes.element
}
CustomDropdown.defaultValue = {
  user: {
    name: ''
  },
  items: [],
  children: ""
}
export default CustomDropdown
