import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import TopLabelFormGroup from "../TopLabelFormGroup";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "..";
import PropTypes from "prop-types";
import { debounce, isEmpty, omit } from "lodash";
import { BOOTSTRAP_VARIANTS as Status, NewMember } from "constants/common";
import ApplyRebateModal from "components/modules/ApplyRebateModal";
import { IconCategory5, IconRebateUrl } from "../../../assests/icons"
import InfiniteScroll from 'react-infinite-scroll-component';
import { productService } from "services";
import moment from "moment";
import { toastr } from 'react-redux-toastr'
import { handleGotoRebateWebsite } from "utils/common";

const SEARCH_PRODUCT_PAGE_COUNT = 100;

const Skeleton = () => {
    return (<div className="color-div" />)
}

const EstimateRebateValueModal = (props) => {
    const { setShowEstimateModal, utilityName, programName,
        onClickLink, showApplyRebateModal, onHideApplyRebateModal,
        indexAutherizeAdmin, userType, setShowApplyRebateModal,
        setShowTrialModal, utilityId, setObjectEl, ...otherProps } = props;

    const [t] = useTranslation();
    const [step, setStep] = useState(1);
    const [estimateFields, setEstimateFields] = useState({});

    const [selectedProductName, setSelectedProductName] = useState(null);
    const [errors, setErrors] = useState({});
    const [options, setOptions] = useState([])
    const [optionLength, setOptionLength] = useState(0)
    const [lastStepData, setLastStepData] = useState(null);
    const [isGettingProduct, setIsGettingProduct] = useState(false);
    const [isChangeInput, setIsChangeInput] = useState(false)
    const [searchText, setSearchText] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [isLastPagingSearchProduct, setIsLastPagingSearchProduct] = useState(true);
    const [searchValue, setsearchValue] = useState('');

    function renderProductLabel(productItem) {
        if (productItem.manufacturer && productItem.manufacturer.length > 0) {
            return `${productItem.qplModelNumber}, ${productItem.manufacturer}`
        }

        return productItem.qplModelNumber;
    }

    function getProductFilter(searchTest, pageIndex) {
        setIsGettingProduct(true);
        setIsChangeInput(false)
        productService.SearchProductByQPLNumber(searchTest, pageIndex, SEARCH_PRODUCT_PAGE_COUNT).then(res => {
            const { success, data } = res;
            if (success && data.listProduct) {
                const { listProduct, isLast } = data;
                setIsLastPagingSearchProduct(isLast)
                const optionMapped = listProduct.map((itemData) => {
                    return ({
                        label: renderProductLabel(itemData),
                        value: itemData.id,
                    })
                })

                if (pageIndex === 0) {
                    setOptions(optionMapped);
                    setOptionLength(optionMapped.length);
                } else {
                    setOptions([...options, ...optionMapped]);
                    setOptionLength([...options, ...optionMapped].length);
                }
            } else {
                setIsLastPagingSearchProduct(true)
                setOptions([]);
                setOptionLength(0);
            }
            setIsGettingProduct(false)
        }
        )
    }

    const handleFormFieldChange = (controlId, nextVal) => {
        setEstimateFields({ ...estimateFields, [controlId]: nextVal });
        const newErrors = omit(errors, [controlId]);
        setErrors(newErrors);
    };

    const handleSelectProduct = (field, value) => {
        if (value === null) {
            const result = omit(estimateFields, [field]);
            setEstimateFields(result);
        }
        if (field === "productId") {
            setSelectedProductName(value);
        }
        setEstimateFields({ ...estimateFields, [field]: value?.value });
        const newErrors = omit(errors, [field]);
        setErrors(newErrors);

        setsearchValue(value?.label)
        setPageIndex(0)
        setOptions([])
    };

    const checkError = () => {
        let tempErrors = {};
        if (estimateFields.wattage === undefined || estimateFields.wattage === "") {
            tempErrors = {
                ...tempErrors,
                wattage: t("estimateRebate.validateMessage.requireWattage"),
            };
        }

        if (estimateFields.productId === undefined && step === 2) {
            tempErrors = {
                ...tempErrors,
                productId: t("estimateRebate.validateMessage.requireProductName"),
            };
        }
        setErrors(tempErrors);
        return tempErrors;
    };

    const estimateValueRequest = async (param) => {
        await productService.EstimateRebate({ ...param, utilityId: utilityId }).then((res) => {
            const { success, data } = res;
            if (success && data) {
                setStep(3);
                setLastStepData(data)
            } else {
                setLastStepData(null)
                toastr.error(data.Message)
            }
        })
    }

    const handleNextStep = (stepInput) => {
        const errors = checkError();
        if (step === 1) {
            if (isEmpty(errors)) {
                setStep(stepInput);
            }
        } else if (step === 2 && stepInput === 3) {
            if (isEmpty(errors)) {
                // call request to estimate value
                estimateValueRequest(estimateFields)
            }
        }
    };

    const handleEstimateAnother = () => {
        setOptions([])
        setsearchValue('')
        setSelectedProductName(null);
        setEstimateFields({});
        setStep(1);
    };

    const handleCloseModal = () => {
        setEstimateFields({});
        setsearchValue('')
        setOptions([])
        setSelectedProductName(null);
        setShowEstimateModal(false);
        setErrors({})
        setStep(1);
    };

    const fetchMoreProducts = () => {
        setPageIndex(pageIndex + 1);
        getProductFilter(searchText, pageIndex + 1)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayQuery = useCallback(debounce(async searchStr => {
        if (searchStr && searchStr !== "") {
            setSearchText(searchStr);
            setPageIndex(0);
            getProductFilter(searchStr, 0)
        } else {
            setIsGettingProduct(false)
        }
    }, 1000, 'later'), [])

    const handleInputChange = (value, nextVal) => {
        setOptions([])
        setErrors({})
        setsearchValue(nextVal)
        const result = omit(estimateFields, ['productId']);
        setEstimateFields(result);
        setIsChangeInput(true)
        delayQuery(nextVal, 0, 10)
    }

    const renderItemName = (item) => {
        const { name, websiteUrl } = item;

        return (
            <span className={`rebate-name ${websiteUrl ? 'hover-cursor' : ''}`} onClick={() => handleGotoRebateWebsite(websiteUrl)}>{name}
                {
                    websiteUrl &&
                    <IconRebateUrl
                        className="hover-cursor"
                        onClick={() => handleGotoRebateWebsite(websiteUrl)}
                    />
                }
            </span>
        )
    }

    useEffect(() => {
        if (searchValue === ' ') {
            setPageIndex(0)
            setOptions([])
        }
    }, [searchValue])

    const renderByStep = (step) => {
        // eslint-disable-next-line default-case
        switch (step) {
            case 1:
                return (
                    <>
                        <p className="title-step"> {t("estimateRebate.whatProductReplacing")}</p>
                        <div className="estimate-form-wrapper">
                            <TopLabelFormGroup
                                label={t("estimateRebate.productNumber")}
                                controlId="productNumber"
                                value={estimateFields.productNumber || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                            />
                            <TopLabelFormGroup
                                label={t("estimateRebate.totalLumens")}
                                controlId="totalLumens"
                                value={estimateFields.totalLumens || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />

                            <TopLabelFormGroup
                                label={`${t("estimateRebate.wattage")} *`}
                                controlId="wattage"
                                value={estimateFields.wattage || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                status={errors.wattage ? Status.DANGER : null}
                                message={errors.wattage}
                                type="number"
                            />

                            <TopLabelFormGroup
                                label={t("estimateRebate.efficacy")}
                                controlId="efficacy"
                                value={estimateFields.efficacy || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />

                            <TopLabelFormGroup
                                label={`${t("estimateRebate.totalLength")} (ft.)`}
                                controlId="totalLength"
                                value={estimateFields.totalLength || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />
                            <TopLabelFormGroup
                                label={t("estimateRebate.lifeTime")}
                                controlId="lifeTime"
                                value={estimateFields.lifeTime || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />

                            <TopLabelFormGroup
                                label={t("estimateRebate.quantity")}
                                controlId="quantity"
                                value={estimateFields.quantity || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />
                            <TopLabelFormGroup
                                label={t("estimateRebate.operatingHours")}
                                controlId="operatingHours"
                                value={estimateFields.operatingHours || ""}
                                onChange={handleFormFieldChange}
                                isTextArea={false}
                                type="number"
                            />
                        </div>
                        <div className="footer-step-i">
                            <Button
                                typeClass="next"
                                onClick={(e) => handleNextStep(2)}
                                size="md"
                                text={t("estimateRebate.next")}
                            />
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <p className="title-step"> {t("estimateRebate.whatProductInstalling")}</p>
                        <div className="estimate-form-wrapper-step-ii">
                            <div className="custom-input">
                                <TopLabelFormGroup
                                    label={t("estimateRebate.productName")}
                                    controlId="productName"
                                    placeholder={t("estimateRebate.searchProduct")}
                                    value={searchValue || ""}
                                    onChange={handleInputChange}
                                    isTextArea={false}
                                />
                                {errors.productId && (
                                    <div className="message">{errors.productId}</div>
                                )}
                                {isGettingProduct && pageIndex === 0 && searchValue !== '' ? (
                                    <div className="loading-options" data-testid="test-loading-options">
                                        {
                                            [...Array(5).keys()].map((item) => {
                                                return <Skeleton key={item} />

                                            })}
                                    </div>) : (
                                    !estimateFields?.productId &&
                                    (
                                        options.length !== 0 ? (
                                            <div id="scrollableDiv">
                                                <InfiniteScroll
                                                    dataLength={optionLength}
                                                    next={fetchMoreProducts}
                                                    hasMore={!isLastPagingSearchProduct}
                                                    scrollableTarget="scrollableDiv"
                                                    scrollThreshold={0.8}
                                                    inverse
                                                    loader={isGettingProduct && searchValue !== '' && [...Array(3).keys()].map((item) => {
                                                        return <Skeleton key={item} />
                                                    })}
                                                >
                                                    <div className="option-product" data-testid="test-option-product">
                                                        {options?.map((option) => {
                                                            return (
                                                                <div key={`${option.value}-${pageIndex}`} className={`option-item ${option.value === selectedProductName?.value ? 'option-selected' : ''}`} onClick={() => handleSelectProduct("productId", option)}>
                                                                    {option.label}
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                </InfiniteScroll>
                                            </div>) : (
                                            searchValue !== '' && !isChangeInput && !estimateFields?.productId && <div className="no-options" >{t("estimateRebate.noResults")}</div>)
                                    )
                                )}
                            </div>
                        </div>
                        <div className="footer-step-ii">
                            <button className="btn custom-button back" onClick={() => { setStep(1) }}>{t("estimateRebate.back")}</button>
                            <Button
                                typeClass="next"
                                onClick={(e) => handleNextStep(3)}
                                size="md"
                                text={t("estimateRebate.next")}
                                disabled={!estimateFields?.productId}
                                data-testid="test-next-step-two"
                            />
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <p className="rebate-program">{lastStepData?.programItem?.name}</p>
                        <div className="estimate-form-wrapper-step-iii">
                            <p className="total-estimate-value">
                                {t("estimateRebate.totalEstimatedValue")}:
                            </p>
                            <p className="total-value" data-testid="test-total-value">${lastStepData?.highestValue.toLocaleString("en-US")}</p>
                            {
                                lastStepData?.programItem?.websiteLink && (
                                    <div onClick={() => {
                                        if (!indexAutherizeAdmin && (!userType || userType === NewMember)) {
                                            setShowTrialModal(true)
                                        } else {
                                            setShowApplyRebateModal(true)
                                        }
                                        setObjectEl(lastStepData.programItem)
                                    }} className="custom-custom-Drawer__title__text-link custom-custom-Drawer__title__text-link-second">{t('estimateRebate.applyForRebate')} <IconCategory5 />
                                    </div>
                                )
                            }

                            <hr className="line" />
                            <div className="estimate-infor">
                                <div>
                                    <p className="estimate-infor-product" data-testid="test-infor-product" >
                                        {
                                            lastStepData?.notes ? (
                                                <Tooltip key={lastStepData?.id} childrenTooltip={lastStepData?.notes} >
                                                    {
                                                        renderItemName(lastStepData)
                                                    }
                                                </Tooltip>
                                            ) : (
                                                renderItemName(lastStepData)
                                            )
                                        }
                                    </p>
                                    <p data-testid="test-expire">
                                        <span className="expire-time">
                                            {`${t("estimateRebate.expires")}: ${moment(lastStepData?.expirationDate).format('MM-DD-YY')}`}
                                        </span>
                                        {
                                            (lastStepData?.minCustomerContribution !== null && !isNaN(lastStepData?.minCustomerContribution)) ? (
                                                <span className="rebate-contribution" data-testid="test-rebate-contribution">
                                                    {`${t("estimateRebate.minContribution")}: ${lastStepData?.minCustomerContribution}`}
                                                </span>
                                            ) : (null)
                                        }
                                    </p>
                                </div>
                                <div className="estimate-infor-price">
                                    <p>
                                        <span className="estimate-infor-price-number" data-testid="test-rebate-value">{`$${lastStepData.rebateValue.value}`}</span>
                                        <span>per</span>
                                    </p>
                                    <p className="rebate-unit" data-testid="test-rebate-unit">{lastStepData.rebateValue.units}</p>
                                </div>
                            </div>
                        </div>
                        <div className="footer-step-ii">
                            <button disabled className="btn custom-button back">{t("estimateRebate.back")}</button>
                            <Button
                                typeClass="next"
                                onClick={handleEstimateAnother}
                                size="md"
                                text={t("estimateRebate.estimateAnotherProduct")}
                            />
                        </div>

                        <ApplyRebateModal
                            utilityName={utilityName}
                            programName={programName}
                            onClickLink={onClickLink}
                            show={showApplyRebateModal}
                            onHide={onHideApplyRebateModal}

                        />
                    </>
                );
        }
    };

    useEffect(() => {
        getProductFilter(null, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            dialogClassName="EstimateRebateValueModal"
            {...otherProps}
            centered
            animation={false}
            size="xl"
            onHide={() => handleCloseModal()}
            data-testid="test-estimate-rebate-value-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("estimateRebate.estimateRebateValue")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderByStep(step)}</Modal.Body>
        </Modal>
    );
};

EstimateRebateValueModal.propTypes = {
    setShowEstimateModal: PropTypes.func,
    utilityName: PropTypes.string,
    programName: PropTypes.string,
    onClickLink: PropTypes.func,
    showApplyRebateModal: PropTypes.bool,
    onHideApplyRebateModal: PropTypes.func,
    indexAutherizeAdmin: PropTypes.bool,
    userType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    setShowApplyRebateModal: PropTypes.func,
    setShowTrialModal: PropTypes.func,
    utilityId: PropTypes.string,
    setObjectEl: PropTypes.func
};

EstimateRebateValueModal.defaultProps = {
    setShowEstimateModal: () => { },
    utilityName: null,
    programName: null,
    onClickLink: null,
    showApplyRebateModal: false,
    onHideApplyRebateModal: null,
    indexAutherizeAdmin: false,
    userType: null,
    setShowApplyRebateModal: null,
    setShowTrialModal: null,
    utilityId: "",
    setObjectEl: null
};


export default EstimateRebateValueModal;
