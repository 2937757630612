import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import cn from 'classnames'

import { Text } from '..'
import './style.scss'

const useCustomTranslation = () => {
  const [t] = useTranslation()
  return {
    daysOfWeek: {
      0: t('date_time.day_of_week_0'),
      1: t('date_time.day_of_week_1'),
      2: t('date_time.day_of_week_2'),
      3: t('date_time.day_of_week_3'),
      4: t('date_time.day_of_week_4'),
      5: t('date_time.day_of_week_5'),
      6: t('date_time.day_of_week_6')
    },
  }
}

const useDatesOfMonth = (date) => {
  return useMemo(() => {
    const startOfMonth = moment(date).startOf('month')
    return [...Array(31)].reduce((days, _, dateIndex) => {
      const dateByIndexOfMonth = moment(startOfMonth).add(dateIndex, 'days')
      if (moment(dateByIndexOfMonth).isAfter(startOfMonth, 'month')) {
        return days
      }
      return [...days, dateByIndexOfMonth]
    }, [])
  }, [date])
}

const useGroupingDataItems = (dataItems, itemDateKey, datesOfMonth) => {
  return useMemo(() => {

    const initialDataItemsGrouped = datesOfMonth.map(() => [])
    if (!dataItems) return initialDataItemsGrouped

    const groupedDataItems = dataItems.reduce((resultGroup, dataItem) => {
      const dataItemDate = moment(dataItem[itemDateKey])
      if (!dataItemDate.isSame(datesOfMonth[0], 'month')) { return resultGroup }

      const cloneResultGroup = [...resultGroup]
      cloneResultGroup[dataItemDate.date() - 1].push(dataItem)
      return cloneResultGroup
    }, initialDataItemsGrouped)

    return groupedDataItems

  }, [dataItems, itemDateKey, datesOfMonth])
}

const MonthView = props => {
  const { startDate, dayOfWeekOrder, renderCell, dataItems, itemDateKey, loading } = props
  const translator = useCustomTranslation()
  const datesOfMonth = useDatesOfMonth(startDate)
  const dataItemsGrouped = useGroupingDataItems(dataItems, itemDateKey, datesOfMonth)


  const firstDateOffset = (datesOfMonth[0].day() + 7 - dayOfWeekOrder[0]) % 7
  const lastDateOffset = (- [...datesOfMonth].pop().day() + 7 + [...dayOfWeekOrder].pop()) % 7

  return (
    <div className="custom-month-view">
      <div className="days-header shadow-sm noselect">
        {dayOfWeekOrder.map((dayIndex) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Text as="span" className="day-header" key={dayIndex}>
              {translator.daysOfWeek[dayIndex]}
            </Text>
          )
        })}
      </div>
      <div className="month-view-container">
        {[...Array(firstDateOffset)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="month-cell cell_last-month">
            &nbsp;
          </div>
        ))}
        {datesOfMonth.map((momentDate, index) => (
          <div
            className={cn('month-cell', {
              [`dow__${firstDateOffset + 1}`]: !index
            })}
            key={momentDate.date()}
          >
            {typeof renderCell === 'function' && renderCell(momentDate, dataItemsGrouped[index], loading)}
          </div>
        ))}
        {[...Array(lastDateOffset)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="month-cell cell_next-month">
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  )
}

MonthView.propTypes = {
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]).isRequired,
  dayOfWeekOrder: PropTypes.arrayOf(PropTypes.number),
  renderCell: PropTypes.func,
  // renderCell params: 
  // momentDate: cell's date as moment object
  // dataItemsGrouped: cell's data items
  // loading: loading state
  dataItems: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  itemDateKey: PropTypes.string,
  loading: PropTypes.bool,
  
}

MonthView.defaultProps = {
  dayOfWeekOrder: [0, 1, 2, 3, 4, 5, 6],
  renderCell: null,
  dataItems: [],
  itemDateKey: 'date',
  loading: false,
}

export default MonthView
