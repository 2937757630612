import get from "lodash/get";
import _ from 'lodash'
import { getQueryString } from "../utils/common";
import BaseService from "./baseService";
import programApi from "../api/programApi";
import GlobalSeachOption from "../models/GlobalSeachOption";
import ProgramFilter from "../models/ProgramFilter";
import { ProgramFilterType } from "../constants/common";
import mapboxService from "./mapboxService"

class ProgramService extends BaseService {
  create(params) {
    return programApi.create(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getList(params) {
    return programApi.getList(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  update(query, params) {
    const newQuery = getQueryString(query)
    return programApi.update(newQuery, params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  delete(params) {
    return programApi.delete(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getById(params) {
    return programApi.getById(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  import(params) {
    return programApi.import(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  filter(params) {
    return programApi.filter(params).then((response) => {
      return {
        data: { entities: get(response, "data.entities", []).map(d => new ProgramFilter(d).toJSON()), totalEntities: get(response, "data.totalEntities", 0) },
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getbyUtilityId(params) {
    return programApi.getbyUtilityId(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  GetProgramMetadatas(programId) {
    return programApi.GetProgramMetadatas(programId).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getAllProgramByUtilityId(query) {
    const newQuery = getQueryString(query)
    return programApi.getAllProgramByUtilityId(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  async GlobalSearch(searchText) {
    const resultMap = await mapboxService.forwardGeocoding(searchText);
    const newData = [];
    if (resultMap.data && resultMap.data.length > 0) {
      resultMap.data.forEach(item => {
        newData.push({
          ...item,
          label: item.label,
          value: item.featureId,
          parentId: null,
          isParent: false,
          type: ProgramFilterType.County
        })
      });
    }

    return programApi.GlobalSearch(searchText).then((response) => {
      const programs = get(response, "data.entities", []).map(item => {
        const newItem = get(item, "program", {})
        newItem.utility = get(item, "utility", {})
        return newItem
      });
      const utilities = get(response, "data.entities", []).map(item => get(item, "utility", null));
      let counties = newData;
      get(response, "data.entities", []).forEach(item => {
        const subCounties = get(item, "utility.counties", []);
        counties = counties.concat(subCounties);
      });
      const options = [];
      counties.forEach(item => {
        const option = new GlobalSeachOption({
                name: item.name,
                label: `${item.name}, ${item.state}`, 
                type: ProgramFilterType.County, value: item.id, state: item.state }).toJSON();
        const isExists = options.findIndex(o => o.value === item.id) > -1;
        if (!isExists) {
          options.push(option);
        }
      });
      utilities.forEach(item => {
        const option = new GlobalSeachOption({ label: item.name, type: ProgramFilterType.Utility, value: item.id }).toJSON();
        const isExists = options.findIndex(o => o.value === item.id) > -1;
        if (!isExists) {
          options.push(option);
        }
      });
      programs.forEach(item => {
        const counties = _.get(item, "utility.counties", []);
        const state = counties[0] ? counties[0].state : '';
        const data = item
        const newCounties = counties.map(item => item.name)
        const option = new GlobalSeachOption({ label: item.name, type: ProgramFilterType.RebatePrograms, value: item.id, counties: newCounties, state, data }).toJSON();
        const isExists = options.findIndex(o => o.value === item.id) > -1;
        if (!isExists) {
          options.push(option);
        }
      });
      return {
        data: options,
        message: response.message,
        success: response.success,
      };
    }
    , this.handleError);
  }

  saveGlobalSearchHistory(item) {
    const maxHistorySize = 5;
    
    const history = new GlobalSeachOption({ label: item.label, type: item.subLabel, value: item.value, 
      isSearch: false, counties: item.counties, state: item.state, data: item.data, name: item.name }).toJSON();
      
    let histories = JSON.parse(localStorage.getItem('GLOBAL_SEARCH'));
    if (!histories) {
      histories = [];
    }
    const isExists = histories.findIndex(item => item.label === history.label && item.value === history.value && item.type === history.type);
    if (isExists > -1) {
      return;
    }
    if (histories.length > maxHistorySize) {
      histories.pop();
    }
    histories.unshift(history);
    localStorage.setItem('GLOBAL_SEARCH', JSON.stringify(histories));
  }

  getGlobalSearchHistories() {
    const histories = JSON.parse(localStorage.getItem('GLOBAL_SEARCH'))
    return histories || [];
  }

  getByStateAndUtilityName(query = {}) {
    const newQuery = getQueryString(query)
    return programApi.getByStateAndUtilityName(newQuery).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getProgramMetadatasByName(name) {
    return programApi.getProgramMetadatasByName(name).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getProgramBySearch(text) {
    return programApi.grogramSearch(text).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  filterBySearchTextAndUtilityId(params = {}) {

    return programApi.filterBySearchTextAndUtilityId(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportByIds(data) {
    return programApi.exportByIds(data).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportAll(params = {}) {

    return programApi.exportAll(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportByFilter(params = {}) {
    return programApi.exportByFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportFilter(params = {}) {
    return programApi.exportFilter(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  exportBlank() {
    return programApi.exportBlank().then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getByIds(params = {}) {
    return programApi.getByIds(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  importFile(params = {}) {
    return programApi.importFile(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

}

export default new ProgramService();
