import get from "lodash/get";

export default class Rebate {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data);
    }
  }

  init(data) {
    if (!data) return;
    this.id = get(data, "id", null);
    this.created = get(data, "created", null);
    this.modified = get(data, "modified", null);
    this.name = get(data, "name", null);
    this.startDate = get(data, "startDate", null);
    this.notes = get(data, "notes", null);
    this.websiteUrl = get(data, "websiteUrl", null);
    this.rebateType = get(data, "rebateType", null);
    this.expirationDate = get(data, "expirationDate", null);
    this.minCustomerContribution = get(data, "minCustomerContribution", 0);

  }

  toJSON() {
    const {
      id,
      created,
      modified,
      name,
      startDate,
      notes,
      websiteUrl,
      rebateType,
      expirationDate,
      minCustomerContribution,
    } = this;

    return {
      id,
      created,
      modified,
      name,
      startDate,
      notes,
      websiteUrl,
      rebateType,
      expirationDate,
      minCustomerContribution,
    };
  }
}
