import { fromJS } from 'immutable'

import { createReducer } from '../../utils/reduxHelpers'
import {
  CHANGE_CATEGORY_DROPDOWN,
  CHANGE_CATEGORY_ITEM_DROPDOWN,
  CHANGE_COUNTY_DROPDOWN,
  CHANGE_UTILITY_DROPDOWN,
  CHANGE_COUNTY_ITEM_DROPDOWN,
  CHANGE_UTILITY_ITEM_DROPDOWN,
  CHANGE_CATEGORY_HISTORY,
  CHANGE_UTILITY_HISTORY,
  CHANGE_COUNTY_HISTORY,
  CHANGE_PRODUCT_HISTORY
} from '../constants/dropDownActions'

const initialState = fromJS({
  category: [],
  categoryHistory: [],
  categoryItems: [],
  utility: [],
  utilityItems: [],
  utilityHistory: [],
  county: [],
  countyItems: [],
  countyHistory: [],
  productHistory: []
})

const changeCategoryDropdown = (state, action) => {
  const value = action.payload
  return state.set('category', value)
}

const changeCategoryItemDropdown = (state, action) => {
  const value = action.payload
  return state.set('categoryItems', value)
}

const changeCategoryDropdownUtility = (state, action) => {
  const value = action.payload
  return state.set('utility', value)
}

const changeCategoryDropdownCounty = (state, action) => {
  const value = action.payload
  return state.set('county', value)
}

const changeCountyItemDropdown = (state, action) => {
  const value = action.payload
  return state.set('countyItems', value)
}
const changeUtilityItemDropdown = (state, action) => {
  const value = action.payload
  return state.set('utilityItems', value)
}

const changeCategoryHistory = (state, action) => {
  const value = action.payload
  return state.set('categoryHistory', value)
}

const changeUtilityHistory = (state, action) => {
  const value = action.payload
  return state.set('utilityHistory', value)
}

const changeCountyHistory = (state, action) => {
  const value = action.payload
  return state.set('countyHistory', value)
}

const changeProductHistory = (state, action) => {
  const value = action.payload;
  return state.set('productHistory', value)
}

export default createReducer(initialState, {
  [CHANGE_CATEGORY_DROPDOWN]: changeCategoryDropdown,
  [CHANGE_CATEGORY_ITEM_DROPDOWN]: changeCategoryItemDropdown,
  [CHANGE_COUNTY_DROPDOWN]: changeCategoryDropdownCounty,
  [CHANGE_UTILITY_DROPDOWN]: changeCategoryDropdownUtility,
  [CHANGE_COUNTY_ITEM_DROPDOWN]: changeCountyItemDropdown,
  [CHANGE_UTILITY_ITEM_DROPDOWN]: changeUtilityItemDropdown,
  [CHANGE_CATEGORY_HISTORY]: changeCategoryHistory,
  [CHANGE_UTILITY_HISTORY]: changeUtilityHistory,
  [CHANGE_COUNTY_HISTORY]: changeCountyHistory,
  [CHANGE_PRODUCT_HISTORY]: changeProductHistory,
});


