import BaseApi from "./baseApi";

class RebateRequirementApi extends BaseApi {
  getListByRebateId(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.rebateRequirement.getListByRebateId}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  addOrUpdateMultipleByRebateId(query, params) {
    return super
      .execute(this.Methods.POST, `${this.Urls.rebateRequirement.addOrUpdateMultipleByRebateId}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }
}

export default new RebateRequirementApi();
