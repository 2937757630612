/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash"
import CardItem from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { StatusText, Text, Tooltip } from "../../common";
import BookMark from "./BookMark";
import ApplyLink from "./ApplyLink";
import { Icons } from "../../../constants/categories";
import { handleRenderCardName, inIframe } from "../../../utils/common"
import "./style.scss";

const useHandleTranslation = () => {
  const [t] = useTranslation();
  return {
    applyForRebate: t("card_view.apply_for_rebate"),
  };
};

const Card = ({
  className,
  onBookMarkClicked,
  onApplyClicked,
  data,
  ...props
}) => {
  const wrapperClasses = cn("card-item", [className])
  const translate = useHandleTranslation()
  const { isActive, name, websiteLink } = _.get(data, "key.program", {})
  const nameSate = _.get(data, "key.state", "");
  const { name: nameUtility } = _.get(data, "key.utility", {});
  const categoryRoots = _.get(data, "categoryRoots", []);
  function handleRenderStatus() {
    if (isActive) {
      return 'active'
    } else if (typeof isActive === 'object') {
      return 'closed'
    }
    return 'upcoming'
  }

  let IconList = categoryRoots.map((el) => {
    const Icon = el ? Icons[el.name] : undefined;
    return Icon ? (
      <Tooltip childrenTooltip={el.name} key={el.name}>
        <Icon key={el.name} />
      </Tooltip>
    ) : null;
  })
  IconList = IconList.filter(r => r !== null);

  return (
    <CardItem
      onClick={() => {
        if (onApplyClicked) {
          onApplyClicked(data)
        }
      }}
      className={wrapperClasses}
      {...props}
    >
      <CardItem.Header>
        <StatusText status={handleRenderStatus()}>{handleRenderStatus()}</StatusText>
        <BookMark onClick={onBookMarkClicked} />
      </CardItem.Header>
      {
        nameSate ? <Text className="state" as="div" lines={1} popover>{nameSate}</Text> : <div className="state" />}
      <CardItem.Body>
        {
          nameUtility ?
            (
              <Text className="title" as="h5" lines={1} popover>
                {nameUtility}
              </Text>
            ) : null
        }
        {name ? (<Text className="sub-title" as="h6" lines={1} popover>{handleRenderCardName(name)}</Text>) : null}


        {websiteLink && !inIframe() ? (
          <ApplyLink onClick={() => {
            window.open(websiteLink, '_blank');
          }}>
            {translate.applyForRebate}
          </ApplyLink>
        ) : null}

      </CardItem.Body>
      <CardItem.Footer>
        {
          IconList
        }
      </CardItem.Footer>
    </CardItem >
  );
};

Card.propTypes = {
  className: PropTypes.string,
  onBookMarkClicked: PropTypes.func,
  onApplyClicked: PropTypes.func,
  data: PropTypes.object,
};

Card.defaultProps = {
  className: "",
  onBookMarkClicked: (f) => f,
  onApplyClicked: (f) => f,
  data: {},
};


export default Card; 
