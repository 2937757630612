import get from "lodash/get"

export default class Program {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data);
    }
  }

  init(data) {
    if (!data) return;
    this.id = get(data, "id", null);
    this.created = get(data, "created", null);
    this.modified = get(data, "modified", null);
    this.name = get(data, "name", null);
    this.startDate = get(data, "startDate", null);
    this.endDate = get(data, "endDate", null);
    this.websiteLink = get(data, "websiteLink", null);
    this.applicationLink = get(data, "applicationLink", null);
    this.isActive = get(data, "isActive", null);
    this.requirements = get(data, "requirements", null);
    this.incentiveCap = get(data, "incentiveCap", 0);
    this.capType = get(data, "capType", null);
    this.maxAnnualIncetive = get(data, "maxAnnualIncetive", 0);
    this.isRetroFit = get(data, "isRetroFit", null);
    this.isNewConstruction = get(data, "isNewConstruction", null);
    this.isInstant = get(data, "isInstant", null);
  }

  toJSON() {
    const {
      id,
      created,
      modified,
      name,
      startDate,
      endDate,
      websiteLink,
      applicationLink,
      isActive,
      requirements,
      incentiveCap,
      capType,
      maxAnnualIncetive,
      isRetroFit,
      isNewConstruction,
      isInstant
    } = this;
    return {
      id,
      created,
      modified,
      name,
      startDate,
      endDate,
      websiteLink,
      applicationLink,
      isActive,
      requirements,
      incentiveCap,
      capType,
      maxAnnualIncetive,
      isRetroFit,
      isNewConstruction,
      isInstant
    };
  }
}
