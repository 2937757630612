import { createSelector } from 'reselect'
import { common } from "../../constants";

const dropDownReducer = state => state.get('dropDownReducer')

export const dropDownSelectors = {
  isSelect: createSelector(dropDownReducer, state => {
    return state.get('isSelect')
  }
  )
}
export const dropDownCategorySelectors = {
  category: createSelector(dropDownReducer, state => {
    const category = state.get('category')
    return Array.isArray(category) ? category : []
  }
  ),
  categoryItems: createSelector(dropDownReducer, state => {
    const category = state.get('categoryItems')
    return Array.isArray(category) ? category : []
  }
  ),
  categoryHistory: createSelector(dropDownReducer, state => {
    let categoryHistory = state.get('categoryHistory')
    const data = window.localStorage.getItem(common.categoryHistory)
    if (data) {
      categoryHistory = JSON.parse(data)
    }
    return Array.isArray(categoryHistory) ? categoryHistory : []
  }),
  utility: createSelector(dropDownReducer, state => {
    const utility = state.get('utility')
    return Array.isArray(utility) ? utility : []
  }),
  utilityItems: createSelector(dropDownReducer, state => {
    const utilityItems = state.get('utilityItems')
    return Array.isArray(utilityItems) ? utilityItems : []
  }),
  utilityHistory: createSelector(dropDownReducer, state => {
    let utilityHistory = state.get('utilityHistory')
    const data = window.localStorage.getItem(common.utilityHistory)
    if (data) {
      utilityHistory = JSON.parse(data)
    }
    return Array.isArray(utilityHistory) ? utilityHistory : []
  }),
  county: createSelector(dropDownReducer, state => {
    const county = state.get('county')
    return Array.isArray(county) ? county : []
  }),
  countyItems: createSelector(dropDownReducer, state => {
    const countyItems = state.get('countyItems')
    return Array.isArray(countyItems) ? countyItems : []
  }),
  countyHistory: createSelector(dropDownReducer, state => {
    let countyHistory = state.get('countyHistory')
    const data = window.localStorage.getItem(common.countyHistory)
    if (data) {
      countyHistory = JSON.parse(data)
    }
    return Array.isArray(countyHistory) ? countyHistory : []
  }),
  productHistory: createSelector(dropDownReducer, state => {
    let productHistory = state.get('productHistory')
    const data = window.localStorage.getItem(common.productHistory)
    if (data) {
      productHistory = JSON.parse(data)
    }
    return Array.isArray(productHistory) ? productHistory : []
  }),
}



