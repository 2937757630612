/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Collapse from 'react-bootstrap/Collapse'
import cn from 'classnames'
import TextAreaAutoSize from 'react-textarea-autosize'
import { FaChevronRight, FaChevronDown, FaPen, FaCheck } from 'react-icons/fa'
import { Heading } from '..'
import './style.scss'

const ExpandableInput = props => {
  const {
    label,
    name,
    text,
    onSave,
    minRows,
    maxRows,
    expand: defaulExpand,
    placeholder,
    enableEditWhenExpanding,
    loading,
  } = props
  const [expand, setExpand] = useState(defaulExpand)
  const [edit, setEdit] = useState(false)
  const textareaEl = useRef(null)
  const toggleEditable = () => {
    if (loading) return
    if (edit) {
      // -- in edit mode at this moment -->
      typeof onSave === 'function' &&
        onSave({ name, value: textareaEl.current.value })
    }
    if (!edit) {
      // -- focus to textarea if clicked edit -->
      textareaEl.current.focus()
    }
    setEdit(!edit)
  }
  const toggleExpand = () => {
    setExpand(!expand)
    if (enableEditWhenExpanding && !expand && !edit) {
      toggleEditable()
    }
  }
  useEffect(() => {
    textareaEl.current.value = text
  }, [text])

  const moveCaretAtEnd = e => {
    const tmpVal = e.target.value
    e.target.value = ''
    e.target.value = tmpVal
  }

  const textareaClasses = cn('textarea-autosize', {
    'show-scrollbar': maxRows,
    edit
  })

  const editToggleBtnClass = cn(
    'edit-button',
    'animated fadeIn',
    edit ? 'to-save' : 'to-edit',
    {
      loading,
    }
  )

  return (
    <div className="custom-expandable-input shadow-sm animated fadeIn">
      <div className="label-wrapper" onClick={toggleExpand}>
        <Heading as="h5" className="input-label-heading">
          {label}
        </Heading>
        {expand ? <FaChevronDown /> : <FaChevronRight />}
      </div>
      <Collapse in={expand}>
        <div className="expand-box">
          <TextAreaAutoSize
            minRows={minRows}
            maxRows={maxRows}
            inputRef={textareaEl}
            defaultValue={text}
            readOnly={!edit || loading}
            className={textareaClasses}
            onFocus={moveCaretAtEnd}
            placeholder={placeholder}
          />
          {edit && <FaCheck onClick={toggleEditable} className={editToggleBtnClass} />}
          {!edit && <FaPen onClick={toggleEditable} className={editToggleBtnClass} />}
        </div>
      </Collapse>
    </div>
  )
}

ExpandableInput.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  onSave: PropTypes.func,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  expand: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  enableEditWhenExpanding: PropTypes.bool,
  loading: PropTypes.bool,
}

ExpandableInput.defaultProps = {
  label: '',
  text: '',
  onSave: null,
  minRows: 1,
  maxRows: null,
  expand: false,
  placeholder: '',
  name: '',
  enableEditWhenExpanding: false,
  loading: false,
}

export default ExpandableInput
