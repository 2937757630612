import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { IconFilter, IconFilterUp, IconFilterDown } from '../../../assests/icons'

import './styles.scss'
import Text from '../Text'

const SORT_TYPE_ASC = 'asc'
const SORT_TYPE_DESC = 'desc'

const SortingIndicator = props => {
  const {
    text,
    sortKey,
    sort,
    onToggleSorting,
    align,
  } = props

  const isAscSorted = sort === SORT_TYPE_ASC
  const isDescSorted = sort === SORT_TYPE_DESC
  const wrapperClass = cn('sorting-indicator', `align-${align}`, {
    sorted: isAscSorted || isDescSorted,
    asc: isAscSorted,
    desc: isDescSorted,
  })
  const setAscSorted = () => {
    onToggleSorting({ sortKey, value: SORT_TYPE_ASC })
  }

  const setDescSorted = () => {
    onToggleSorting({ sortKey, value: SORT_TYPE_DESC })
  }

  const unSort = () => {
    onToggleSorting({ sortKey, value: null })
  }

  return (
    <div className={wrapperClass}>
      {!!text && (
        <Text as="span" className="text-value" align="center">
          {text}
        </Text>
      )}
      {!isAscSorted && !isDescSorted && <IconFilter className="indicator-btn" onClick={setAscSorted} />}
      {isAscSorted && <IconFilterDown className="indicator-btn" onClick={setDescSorted} />}
      {isDescSorted && <IconFilterUp className="indicator-btn" onClick={unSort} />}
    </div>
  )
}

SortingIndicator.propTypes = {
  onToggleSorting: PropTypes.func.isRequired,
  text: PropTypes.string,
  sortKey: PropTypes.string.isRequired,
  sort: PropTypes.oneOf([
    SORT_TYPE_ASC,
    SORT_TYPE_DESC,
    false,
  ]),
  align: PropTypes.oneOf([
    'right', 'center', 'left'
  ]),
}

SortingIndicator.defaultProps = {
  text: undefined,
  sort: undefined,
  align: 'left',
}

export default SortingIndicator
