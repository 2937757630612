/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { IconChevronButton } from "../../../assests/icons"
import './style.scss'

const IconGroup = (props) => {
  const { items, className, indexActive } = props
  const [isSelect, setIsSelect] = useState(false)
  const renderItems = () => {
    return items.map((item, index) => {
      const { key, onClick, icon, isText, text } = item
      const buttonClassName = cn('custom-icon-button', [className], {
        first: index === 0,
        last: index === items.length - 1,
        active: index === indexActive,
        default: indexActive < 0 && index === 0
      })
      return (
        <Button
          as="span"
          onClick={() => { onClick(index) }}
          key={key}
          className={buttonClassName}
          test-id={`icon-button-${key}`}
        >
          {
            isText ? text : (
              <>
                {typeof icon === 'function' ? icon() : icon}
              </>
            )
          }

        </Button>
      )
    })
  }

  const renderDropDownItem = () => {
    return items.map((item, index) => {
      const { key, onClick, icon, text, disable } = item
      const className = cn('custom-contact-group_item', {
        active: index === indexActive,
        disable
      })
      return (
        <div
          onClick={() => {
            if (!disable) {
              onClick(index)
              setIsSelect(false)
            }
          }}
          key={`${key} dropdown`}
          className={className}
          role="presentation"
        >
          {typeof icon === 'function' ? icon() : icon}

          {text ? <div className="custom-contact-group_item_text">{text}</div> : null}

        </div>
      )
    })
  }

  return (
    <div className="custom-contact-group_blur" tabIndex={1} onBlur={() => { setIsSelect(false) }} >
      <div className="custom-contact-group_parent">
        <ButtonGroup className="custom-contact-group">{renderItems()}</ButtonGroup>
        <div role="presentation" onClick={() => { setIsSelect(!isSelect) }} className={`custom-contact-group_chevron ${isSelect ? "active" : ""}`}>
          <IconChevronButton />
        </div>
      </div>
      <div className={`custom-contact-group_dropdown ${!isSelect ? "custom-contact-group_dropdown-close" : ""}`}>
        {renderDropDownItem()}
      </div>
    </div>
  )
}

IconGroup.propTypes = {
  items: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  indexActive: PropTypes.number
}

IconGroup.defaultProps = {
  items: [],
  className: '',
  indexActive: 0
}

export default IconGroup
