import React, {
  useState,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './style.scss'
import { DEFAULT_TOOLBAR } from './constant'

const useInitialEditorState = (initialHTML) => {
  return useMemo(() => {
    const validHTML = initialHTML || '' 
    const contentBlock = htmlToDraft(validHTML)
    if (!contentBlock) return EditorState.createEmpty()
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    return EditorState.createWithContent(contentState)
  }, [initialHTML])
}

const WYSIWYG = forwardRef((props, ref) => {
  const {
    initialHTML,
    wrapperClass,
    editorClass,
    toolbarClass,
    toolbar,
    readOnly,
    placeholder,
  } = props

  const initialEditorState = useInitialEditorState(initialHTML)
  const [editorState, setEditorState] = useState(initialEditorState)

  const onEditorStateChange = (nextEditorState) => {
    setEditorState(nextEditorState)
  }

  /* istanbul ignore next */
  useImperativeHandle(ref, () => ({
    getContentHTML: () => {
      const draftContent = convertToRaw(editorState.getCurrentContent())
      return draftToHtml(draftContent)
    },
  }))

  return (
    <Editor
      ref={ref}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{ ...DEFAULT_TOOLBAR, ...(toolbar || {}) }}
      wrapperClassName={cn('wysiwyg-root', [wrapperClass])}
      editorClassName={cn('wysiwyg-editor', [editorClass])}
      toolbarClassName={cn([toolbarClass])}
      readOnly={readOnly}
      placeholder={placeholder}
    />
  )
})

WYSIWYG.displayName = 'WYSIWYG'

WYSIWYG.propTypes = {
  initialHTML: PropTypes.string,
  wrapperClass: PropTypes.string,
  editorClass: PropTypes.string,
  toolbarClass: PropTypes.string,
  toolbar: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
}

WYSIWYG.defaultProps = {
  initialHTML: undefined,
  wrapperClass: null,
  editorClass: null,
  toolbarClass: null,
  toolbar: null,
  readOnly: false,
  placeholder: '',
}

export default WYSIWYG
