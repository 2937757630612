/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {
  IconArrow,
} from "../../../assests/icons";
import './style.scss'

const DropdownMobile = (props) => {
  const { items, className, children, isSelect, setIsSelect, maxItems } = props
  const dropDownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      const dropDownOpen = dropDownRef.current.getAttribute("data-open");
      if (dropDownRef.current && !dropDownRef.current.contains(event.target) && dropDownOpen === 'true') {
        setIsSelect(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef, setIsSelect]);

  const renderDropDownItem = () => {
    return items.map((item, index) => {
      const { key, onClick, text, disable, textSub, divider, } = item
      const className = cn('DropdownMobile_item', {
        disable
      })

      if (maxItems && index > maxItems) {
        return null
      }
      if (divider) return <hr style={{ margin: "unset" }} key={`${text} CustomDropdown`} className="line" />
      return (
        <div
          onClick={() => {
            if (!disable) {
              onClick(index)
              setIsSelect(false)
            }
          }}
          key={`${key} dropdownMobile ${text}`}
          className={className}
          role="presentation"
        >


          {text ? (
            <div className="DropdownMobile_item_text">
              {text}
              {textSub ? <div className="DropdownMobile_item_text-sub">{textSub}</div> : null}
            </div>
          ) : null}
          <IconArrow className="DropdownMobile_item_icon" />

        </div>
      )
    })
  }

  return (
    <div className={`DropdownMobile_blur ${className}`} ref={dropDownRef} data-open={isSelect}>
      <div role="presentation" onClick={() => { setIsSelect(!isSelect) }} className={`DropdownMobile_parent ${!isSelect ? "" : "active"}`}>
        {children}
      </div>
      <div className={`DropdownMobile_dropdown ${!isSelect ? "DropdownMobile_dropdown-close " : ""}`}>
        {renderDropDownItem()}
      </div>
    </div>
  )
}

DropdownMobile.propTypes = {
  items: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  isSelect: PropTypes.bool,
  children: PropTypes.element,
  setIsSelect: PropTypes.func,
  maxItems: PropTypes.number,
}

DropdownMobile.defaultProps = {
  items: [],
  className: '',
  setIsSelect: () => { },
  children: <></>,
  isSelect: false,
  maxItems: null
}

export default DropdownMobile
