import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import cn from 'classnames'

import './style.scss'

const InlineFormGroup = (props) => {
  const { label, controlId, placeholder, type, readonly, value: controlledValue, onChange, required } = props
  const handleChange = e => {
    const { value: nextVal } = e.target
    typeof onChange ==='function' && onChange(controlId, nextVal)
  }
  return (
    <Form.Group
      controlId={controlId}
      className={cn('inline-form-group', { readonly })}
    >
      <Form.Label className="ifg-label">{label}</Form.Label>
      <Form.Control
        className="ifg-input"
        type={type}
        placeholder={placeholder}
        readOnly={readonly}
        plaintext={readonly}
        value={controlledValue === null ? '' : controlledValue}
        onChange={handleChange}
        required={required}
      />
    </Form.Group>
  )
}

InlineFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
}

InlineFormGroup.defaultProps = {
  placeholder: '',
  type: 'text',
  readonly: false,
  required: false,
  value: undefined,
  onChange: null,
}

export default InlineFormGroup
