
import BaseService from "./baseService";
import AuthenticationApi from "../api/authenticationApi";
import { getQueryString } from "../utils/common";


class AuthenticationService extends BaseService {
  checkEmbedAuthentication(header) {
    return AuthenticationApi.checkEmbedAuthentication(header).then(response => {
      return {
        data: response.data,
        message: response.message,
        success: response.success
      };
    }, this.handleError);
  }



}

export default new AuthenticationService();
