import React from "react";
import PropTypes from "prop-types";

import Avatar from "./Avatar";
import AvatarLoader from "./loader";

const AvatarWithLoader = React.forwardRef(
  ({ loading, size, ...otherProps }, uploadInput) => {
    return loading ? (
      <AvatarLoader size={size} />
    ) : (
      <Avatar size={size} {...otherProps} ref={uploadInput} />
    );
  }
);

AvatarWithLoader.displayName = "AvatarWithLoader";

AvatarWithLoader.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["sm", "md", "lg"]),
  ]),
  loading: PropTypes.bool,
  uploading: PropTypes.bool,
  onChangePhoto: PropTypes.func,
  tempPhoto: PropTypes.string
};

AvatarWithLoader.defaultProps = {
  src: null,
  size: "sm",
  loading: false,
  uploading: false,
  onChangePhoto: (f) => f,
  tempPhoto: null
};

export default AvatarWithLoader;
