import BaseApi from "./baseApi";

class CountyApi extends BaseApi {
  getCountiesByGeoContext(params) {
    return super
      .execute(
        this.Methods.POST,
        this.Urls.county.getCountiesByGeoContext,
        null,
        true,
        params
      )
      .then((res) => {
        return res;
      });
  }

  getCountiesByUtilityIds(params) {
    return super
      .execute(
        this.Methods.POST,
        this.Urls.county.getCountiesByUtilityIds,
        null,
        true,
        params
      )
      .then((res) => {
        return res;
      });
  }

  getCountyByUtilityId(id) {
    return super
      .execute(
        this.Methods.GET,
        `${this.Urls.baseUrl}UtilityCounty/GetCountiesByUtilityId/${id}`,
        null,
        true
      )
      .then((res) => {
        return res;
      });
  }
}

export default new CountyApi();
