import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const LeftMenuItem = props => {
  const { icon, text, group, to, onClick, isShow, replace, subText, subIcon } = props

  if (!isShow) return null

  if (group) {
    return <li className="sidebar-header">{text}</li>
  }

  return (
    <li className="sidebar-item">
      <NavLink className="sidebar-link" to={to} onClick={onClick} replace={replace}>
        {typeof icon === 'function' ? icon() : icon}
        <div className="sidebar-item-text">
          <span>{text}</span>
          {subText ? <span>{subText}</span> : ''}
        </div>
        {subIcon ? <div className="sidebar-item-sub-icon">{typeof subIcon === 'function' ? subIcon() : subIcon}</div> : ''}
      </NavLink>
    </li>
  )
}

LeftMenuItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  text: PropTypes.string,
  group: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
  isShow: PropTypes.bool,
  replace: PropTypes.bool,
  subText: PropTypes.string,
  subIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

LeftMenuItem.defaultProps = {
  to: '',
  isShow: true,
  replace: true,
}

export default LeftMenuItem
