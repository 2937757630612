import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import cn from "classnames";

import { Loading } from "..";
import { defaultAvatar } from "../../../assests/images";
import "./style.scss";

const Avatar = React.memo(
  React.forwardRef((props, uploadRef) => {
    const {
      src,
      size,
      uploading,
      noBorder,
      tempPhoto,
      noAvatarSrc,
      onChangePhoto,
    } = props;
    const wrapperClasses = cn(
      "custom-avatar-wrapper",
      {
        [size]: typeof size === "string",
      },
      {
        "no-border": noBorder,
      }
    );

    const handleOnclick = () => {
      if (uploadRef && uploadRef.current) {
        uploadRef.current.click();
      }
    };

    let wrapperStyle = {};

    if (typeof size === "number") {
      wrapperStyle = {
        width: size,
        height: size,
      };
    }

    return (
      <div
        className={wrapperClasses}
        style={wrapperStyle}
        test-id="custom-avatar-wrapper"
      >
        <Image
          src={tempPhoto || src || noAvatarSrc || defaultAvatar}
          onClick={handleOnclick}
          className={cn("custom-avatar animated fadeIn", {
            "default-avatar": src == null && tempPhoto == null,
          })}
          roundedCircle
        />
        {onChangePhoto && (
          <>
            <input
              type="file"
              className="hide"
              ref={uploadRef}
              accept="image/*"
              onChange={onChangePhoto}
              multiple={false}
              test-id="avatar-hidden-input"
            />
          </>
        )}
        <Loading
          childLoading
          loading={uploading}
          className="uploading-overlay"
        />
      </div>
    );
  })
);

Avatar.displayName = "Avatar";
Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["sm", "md", "lg"]),
  ]),
  uploading: PropTypes.bool,
  noBorder: PropTypes.bool,
  noAvatarSrc: PropTypes.string,
  onChangePhoto: PropTypes.func,
  tempPhoto: PropTypes.string
};

Avatar.defaultProps = {
  src: null,
  size: "sm",
  uploading: false,
  noBorder: false,
  noAvatarSrc: null,
  onChangePhoto: (f) => f,
};

export default Avatar;
