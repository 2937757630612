import get from "lodash/get";
import BaseService from "./baseService";
import countyApi from "../api/countyApi";
import County from "../models/County";

class CountyService extends BaseService {
  getCountiesByGeoContext(params) {
    return countyApi.getCountiesByGeoContext(params).then((response) => {
      return {
        data: get(response, "data.entities", []).map(item => new County(item).toJSON()),
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getCountiesByUtilityIds(params) {
    return countyApi.getCountiesByUtilityIds(params).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }

  getCountyByUtilityId(Id) {
    return countyApi.getCountyByUtilityId(Id).then((response) => {
      return {
        data: response.data,
        message: response.message,
        success: response.success,
      };
    }, this.handleError);
  }
}

export default new CountyService();
