import productApi from "api/productApi";
import { ProgramFilterType } from "constants/common";
import get from "lodash/get";
import GlobalSeachOption from "models/GlobalSeachOption";
import BaseService from "./baseService";
import mapboxService from "./mapboxService";
import { number } from "prop-types";

class ProductService extends BaseService {
    async GlobalSearch(searchText) {
        const resultMap = await mapboxService.forwardGeocoding(searchText);
        const countyData = [];
        if (resultMap.data && resultMap.data.length > 0) {
          resultMap.data.forEach(item => {
            countyData.push({
              ...item,
              label: item.label,
              value: item.featureId,
              parentId: null,
              isParent: false,
              type: ProgramFilterType.County
            })
          });
        }
        
        return productApi.GlobalSearch(searchText).then((response) => {
            const { data } = response;
            // mapping counties
            const allCountyResponse = [
              ...get(data, "county", []).map((item) => {
                return {
                  ...item,
                  type: ProgramFilterType.County,
                  parentId: null,
                  isParent: false,
                  value: item.id,
                  label: `${item.name}, ${item.state}`
                }
              }), ...countyData];

            const uniqueCounty = [...new Map(allCountyResponse.map((item) => [item["id"], item])).values()];

            // mapping utilities
            const utilities = get(data, "utility", []).map(item => {
              return new GlobalSeachOption({ label: item.name, type: ProgramFilterType.Utility, value: item.id, state: item.state }).toJSON()
            });
            
            // mapping products
            const products = get(data, "product", []).map(item => {
              return new GlobalSeachOption({
                label: `${item.qplModelNumber}, ${item.manufacturer}`,
                value: item.id,
                type: ProgramFilterType.Product,
              }).toJSON()               
            })

            return {
              data: {
                ...data,
                product: products,
                county: uniqueCounty,
                utility: utilities
              },
              message: response.message,
              success: response.success,
            };
          }, this.handleError);
    }

    async SearchProductByQPLNumber(searchText, skip, pageCount) {
      if (!searchText) {
        return [];
      }
      return productApi.SearchProductByQPLNumber(searchText, 
        isNaN(skip) && skip < 0 ? -1 : skip * pageCount,
        isNaN(pageCount) && pageCount < 0 ? -1 : pageCount)
    }

    async EstimateRebate(param) {
      if (!param.utilityId || !param.productId) {
        return {
          success: false,
          error: "Please select a product"
        };
      }

      if (!param.wattage || isNaN(param.wattage) || param.wattage < 0) {
        return {
          success: false,
          error: "wattage is not valid"
        };
      }

      return productApi.EstimateRebate(param)
    }
}

export default new ProductService();
