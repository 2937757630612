
import {
  CHANGE_PRODUCT_DATA_FOR_HEADER_DROPDOWN,
  CHANGE_PRODUCT_FILTER_SUCCESS,
  CHANGE_PRODUCT_SUCCESS,
} from '../constants/productActions'

export function changeFilterItems(items) {
  return dispatch => {
    dispatch({
      type: CHANGE_PRODUCT_FILTER_SUCCESS,
      payload: {
        items
      }
    })
  }
}

export function changeItems(items) {
  return dispatch => {
    dispatch({
      type: CHANGE_PRODUCT_SUCCESS,
      payload: {
        items
      }
    })
  }
}

export function changeProductDataForHeaderDropdown(items) {
  return dispatch => {
    dispatch({
      type: CHANGE_PRODUCT_DATA_FOR_HEADER_DROPDOWN,
      payload: {
        items
      }
    })
  }
}
