import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { IconQuestionHelper, IconLearn, IconRead, IconReport, IconMessage, IconTargetLink, IconTwitter, IconFacebook, IconIntagram, IconLinkin } from "../../../assests/icons"
import "./style.scss";

const HelpModal = (props) => {
  const [t] = useTranslation();
  return (
    <Modal
      dialogClassName="help-modal"
      {...props}
      animation={false}
    >
      <Modal.Header closeButton>
        <IconQuestionHelper className="iconHelper" />
        <Modal.Title>
          Help
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="helperTitle">{t("helper.how_many_account")}</div>
        <p className="helperDes">
          {t("helper.a_single_organization")} <a className="helperlink" href="mailto:support@rebatebus.com">support@rebatebus.com</a> {t("helper.to_merge_individual")}
        </p>
        <div className="helperTitle">{t("helper.what_should_i_do")}</div>
        <p className="helperDes">{t("helper.please_copy_the_url")} <a className="helperlink" href="mailto:support@rebatebus.com">support@rebatebus.com</a></p>

        <div className="helperParentLink"><IconRead /> <a className="helperTextLink" rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HOME_PAGE}>{t("helper.read_help_articles")}  <IconTargetLink className="IconTargetLink" /></a></div>
        <div className="helperParentLink"><IconLearn /> <a className="helperTextLink" rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HOME_PAGE}>{t("helper.learn_what_new")}  <IconTargetLink className="IconTargetLink" /></a></div>
        <div className="helperParentLink"><IconReport /> <a className="helperTextLink" rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HOME_PAGE}>{t("helper.report_a_problem")}  <IconTargetLink className="IconTargetLink" /></a></div>
        <div className="helperParentLink"><IconMessage /> <a className="helperTextLink" rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HOME_PAGE}>{t("helper.give_us_feedback")}  <IconTargetLink className="IconTargetLink" /></a></div>
        <hr className="helperLine" />
        <div className="connectWithUs"> {t("helper.connect_with_us_on_social")} </div>
        <div className="helperFooter">
          <a href="https://twitter.com/rebatebus/" rel="noopener noreferrer" target="_blank">
            <IconTwitter />
          </a>
          <a href="https://www.linkedin.com/company/rebate-bus/" rel="noopener noreferrer" target="_blank">
            <IconLinkin />
          </a>
          <a href="https://www.instagram.com/accounts/login/?next=/rebatebus/" rel="noopener noreferrer" target="_blank">
            <IconIntagram />
          </a>
          <a href="https://www.facebook.com/rebatebus/" rel="noopener noreferrer" target="_blank">
            <IconFacebook />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

HelpModal.propTypes = {};

HelpModal.defaultProps = {};

export default HelpModal;
