import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { IconBookmark } from "../../../assests/icons";

import "./style.scss";

const BookMark = ({ children, className, ...props }) => {
  const wrapperClasses = cn("bookmark", [
    className,
  ]);

  return (
    <a className={wrapperClasses} {...props}>
      {children} <IconBookmark />
    </a>
  );
};

BookMark.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

BookMark.defaultProps = {
  children: "",
  className: "",
};

export default BookMark;
