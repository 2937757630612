/* istanbul ignore file */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { EmptyState } from '../../components/common'
import { pathKeys } from '../../constants'
import './styles.scss'

const useCustomTranslation = () => {
  const { t } = useTranslation()
  return {
    unauthorizedTitle: t('forbidden.unauthorized_title'),
    unauthorizedMessage: t('forbidden.unauthorized_message'),
    unauthorizedLinkText: t('forbidden.unauthorized_link_text')
  }
}

const Forbidden = () => {
  const translator = useCustomTranslation()
  const navigate = useNavigate();
  const handleBackToHome = () => {
    navigate(pathKeys.ROOT)
  }
  return (
    <div className="forbidden-page page-content">

      <EmptyState
        title={translator.unauthorizedTitle}
        text={() => {
          return (
            <>
              <div>
                {translator.unauthorizedMessage}
              </div>
              <div className="link-to-home">
                <a href="#top"
                  onClick={handleBackToHome}
                >{translator.unauthorizedLinkText}</a> {' to return home.'}
              </div>
            </>
          );
        }}
      />
    </div>
  )
}
/* istanbul ignore next */
const mapStateToProps = () => {
  return {
    // documents: fn(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = () => ({
  // getDocuments: (clientId, skip) => dispatch(fn(clientId, skip)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forbidden);

