import BaseApi from "./baseApi";

class UtilityApi extends BaseApi {
  getAllUtility(queryString, headers, isAuthen = true) {
    return super.execute(this.Methods.GET, `${this.Urls.utility.all}${queryString}`, headers, isAuthen)
      .then((res) => {
        return res;
      });
  }

  getList(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.utility.list}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  getUtilitiesByProgramId(queryString) {
    return super.execute(this.Methods.GET, `${this.Urls.utility.getUtilitiesByProgramId}${queryString}`, null, true)
      .then((res) => {
        return res;
      });
  }

  create(params) {
    return super
      .execute(this.Methods.POST, this.Urls.utility.create, null, true, params)
      .then((res) => {
        return res;
      });
  }

  update(query, params) {
    return super
      .execute(this.Methods.PUT, `${this.Urls.utility.update}${query}`, null, true, params)
      .then((res) => {
        return res;
      });
  }

  exportAll(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.exportAll}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportByFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.exportByFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  exportFilter(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.exportFilter}`, null, true, data)
      .then((res) => {
        return res;
      });
  }


  exportBlank(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.exportBlank}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  getByIds(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.getByIds}`, null, true, data)
      .then((res) => {
        return res;
      });
  }

  importFile(data) {
    return super
      .execute(this.Methods.POST, `${this.Urls.utility.importFile}`, null, true, data)
      .then((res) => {
        return res;
      });
  }
}

export default new UtilityApi();
