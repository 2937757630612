import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ReactPlayer from 'react-player'

import './style.scss'

const VideoPlayer = props => {
  const { className, videoURL } = props
  const wrapperClass = cn('custom-video-player', [className])

  return (
    <div className={wrapperClass}>
      <ReactPlayer
        url={videoURL}
        className="react-player"
        width="100%"
        height="100%"
        controls
      />
    </div>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  videoURL: PropTypes.string,
}

VideoPlayer.defaultProps = {
  className: '',
  videoURL: '',
}

export default VideoPlayer
