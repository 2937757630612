/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import TextAreaAutoSize from 'react-textarea-autosize'
import './style.scss'

export const moveCaretAtEnd = e => {
  const tmpVal = e.target.value
  e.target.value = ''
  e.target.value = tmpVal
}

const TextArea = React.forwardRef((props, ref) => {
  const {
    text,
    minRows,
    maxRows,
    placeholder,
    onChange,
    className,
    readOnly,
    onFocus,
    onBlur,
  } = props
  const textareaEl = useRef(null)

  /* istanbul ignore next */
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      textareaEl.current.focus()
    },
  }))


  const handleChange = () => {
    const newVal = textareaEl.current.value
    typeof onChange === 'function' && onChange(newVal)
  }

  const handleOnFocus = (e) => {
    moveCaretAtEnd(e)
    const { value } = textareaEl.current
    typeof onFocus === 'function' && onFocus(value)
  }

  const handleOnBlur = () => {
    const { value } = textareaEl.current
    typeof onBlur === 'function' && onBlur(value)
  }

  useEffect(() => {
    if (textareaEl) {
      textareaEl.current.value = text
    }
  }, [text])

  const textareaClasses = cn(
    'custom-textarea',
    'textarea-autosize',
    [className]
  )

  return (
    <div className="textarea-wrapper" ref={ref}>
      <TextAreaAutoSize
        minRows={minRows}
        maxRows={maxRows}
        inputRef={textareaEl}
        defaultValue={text}
        className={textareaClasses}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={readOnly}
        // onHeightChange={handleOnHeightChange}
      />
    </div>
  )
})

TextArea.propTypes = {
  text: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

TextArea.displayName = 'TextArea'

TextArea.defaultProps = {
  text: '',
  minRows: 1,
  maxRows: null,
  placeholder: '',
  onChange: null,
  className: '',
  readOnly: false,
  onFocus: null,
  onBlur: null,
}

export default TextArea
