/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalPremium from "../../modules/ModalPremium";
import { NewMember } from "../../../constants/common"
import { useAuth0 } from "../../Auth0";
import './index.scss'

const CountDown = ({ onClick, time }) => {
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [showTrialModalExpire, setShowTrialModalExpire] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const { user = {} } = useAuth0();
  const { userType } = user
  const [objectTime, setObjectTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  let myTime = null
  function secondsToTime(secs) {
    const hours = Math.floor(secs / (60 * 60));
    const divisor_for_minutes = secs % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);

    const obj = {
      hours,
      minutes,
      seconds
    }
    return obj;
  }

  useEffect(() => {
    let secondsNow = +time

    if (typeof secondsNow !== "number") {
      secondsNow = 0
    }
    if (secondsNow > 0) {
      myTime = setInterval(() => {
        secondsNow -= 1

        setObjectTime(secondsToTime(secondsNow))
        if (!secondsNow) {
          setShowTrialModalExpire(true)
          clearInterval(myTime);
          setIsExpire(true)
        }

      }, 1000);
    } else {
      setShowTrialModalExpire(true)
      setIsExpire(true)
    }
  }, [time])

  return (
    <>
      {
        !userType || userType === NewMember ? (
          <>
            <div role="presentation"
              onClick={() => {
                if (!showTrialModalExpire) {
                  setShowTrialModal(true)
                }
              }} className="CountDown"
            >
              {objectTime.hours ? `${objectTime.hours}:` : ""}{objectTime.minutes ? objectTime.minutes : "00"}:{objectTime.seconds ? objectTime.seconds : "00"} <span className="CountDown__text">remaining</span>
            </div>
          </>
        ) : null
      }

      <ModalPremium onHide={() => { setShowTrialModal(false) }} title="RebateBus Premium" show={showTrialModal} actionClick={() => { setShowTrialModal(false) }} />
      <ModalPremium isExpire={isExpire} onHide={() => { setShowTrialModalExpire(false) }} actionClick={() => { onClick() }} actionText="SIGN OUT" title="Your trial period has ended" show={showTrialModalExpire} />
    </>
  )
}
CountDown.propTypes = {
  time: PropTypes.number,
  onClick: PropTypes.func,
}

CountDown.defaultProps = {
  onClick: () => { },
  time: 0,
}

export default CountDown

