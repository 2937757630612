/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '../../components/Auth0'
import { imgSignup, logoNew } from '../../assests/images'
import { Button, DatePicker, Avatar, TopLabelFormGroup } from '../../components/common'
import { common, pathKeys } from '../../constants'

import './style.scss'

export const Signup = ({
  updateProfileAvatar, profilePictureUrl, updateProfileAvatarFetching, updateProfileDetails
}) => {
  const [t] = useTranslation()
  const { user } = useAuth0()
  const navigate = useNavigate();

  const [dob, setDOB] = useState(new Date())
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleUpdateAvatar = (avatar, callback) => {
    updateProfileAvatar(user.id, avatar, callback)
  }
  const handleSignUp = () => {
    const signupCallback = () => navigate(pathKeys.COGNITIVE_BASELINE) // routerHistory.push(pathKeys.COGNITIVE_BASELINE)
    const data = {
      ...user,
      GivenName: firstName,
      FamilyName: lastName,
      Birthdate: dob.toISOString(),
      ProfilePictureUrl: profilePictureUrl,
      miosStage: 'Unset'
    }
    updateProfileDetails(user.id, data, signupCallback)
  }

  return (
    <div className="signup-page">
      <div className="img">
        <img src={imgSignup} alt="" />
      </div>
      <div className="signup-content">
        <div className="text-center"><img className="logo" src={logoNew} alt="" /></div>
        <div className="wellcome-text">{t('signup.welcome_text')}</div>
        <div className="signup-form">
          <div className="text-center">
            <Avatar
              src={profilePictureUrl}
              size="lg"
              loading={updateProfileAvatarFetching}
              onUpdate={handleUpdateAvatar}
              uploading={updateProfileAvatarFetching}
              autoUpload
            />
          </div>
          <div className="input-group">
            <TopLabelFormGroup
              label={t('signup.first_name')}
              size="sm"
              isTextArea={false}
              type="text"
              value={firstName}
              onChange={setFirstName}
            />
            <TopLabelFormGroup
              label={t('signup.last_name')}
              size="sm"
              isTextArea={false}
              type="text"
              value={lastName}
              onChange={setLastName}
            />
          </div>
          <div>
            <DatePicker
              type="date-input"
              onChange={setDOB}
              selectedDate={dob}
              options={{
                dateFormat: 'M/dd/yyyy',
                shouldCloseOnSelect: true,
              }}
              useCustomHeader={{ toYear: new Date().getFullYear() }}
            />
          </div>
          <Button
            variant={common.BOOTSTRAP_VARIANTS.LIGHT_BLUE}
            size="lg"
            className="signup-button"
            onClick={handleSignUp}
            text={t("signup.signup_button")}
          />
        </div>
      </div>
    </div>
  )
}

/* istanbul ignore next */
const mapStateToProps = () => {
  return {
    // updateProfileAvatarFetching: fn(state),
    // profilePictureUrl: fn(state),
  }
}

/* istanbul ignore next */
const mapDispatchToProps = () => ({
  // updateProfileAvatar: (userId, avatar, callback) => dispatch(fn(userId, avatar, callback)),
  // updateProfileDetails: (userId, data, callback) => dispatch(fn(userId, data, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
