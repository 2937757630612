import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Nav from 'react-bootstrap/Nav'
import Badge from 'react-bootstrap/Badge'
import cn from 'classnames'
import { BOOTSTRAP_VARIANTS } from '../../../constants/common'
import './styles.scss'

const TabButtons = (props) => {
  const {
    items,
    defaultActiveKey,
    onItemSelect,
    className,
    type,
    controlledActiveKey,
    vertical,
    onBack
  } = props

  const [activeKey, changeActiveKey] = useState(controlledActiveKey || defaultActiveKey)

  const handleSelectItem = useCallback((eventKey, index) => {
    if (activeKey === eventKey) { return }
    changeActiveKey(eventKey)
    typeof onItemSelect === 'function' && onItemSelect(eventKey, index)
  }, [activeKey, onItemSelect])

  const handleControlledActiveKeyChange = useCallback(eventKey => {
    if (activeKey === eventKey || !eventKey) { return }
    changeActiveKey(eventKey)
  }, [activeKey])

  useEffect(() => {
    handleControlledActiveKeyChange(controlledActiveKey)
  }, [controlledActiveKey, handleControlledActiveKeyChange])

  const tabsNavClass = cn('tabs-nav', { vertical })

  return (
    <div className={cn('custom-tab-buttons', [className])}>
      <Nav
        variant={BOOTSTRAP_VARIANTS.PILLS}
        className={tabsNavClass}
        as="ul"
        activeKey={activeKey}
      >
        {items.map(({ eventKey, text, badge, icon, textSub }, index) => {
          return (
            <Nav.Item key={eventKey} as="li">
              <Nav.Link
                className={cn([type])}
                eventKey={eventKey}
                onSelect={() => {
                  handleSelectItem(eventKey, index)
                  if (onBack) {
                    onBack()
                  }
                }}
              >

                {!!icon && (
                  <div className="icon-wrapper">
                    {icon}
                  </div>
                )}
                <div>
                  {text}
                  {textSub ? <div className="textSub">{textSub}</div> : null}
                </div>

                {badge != null && badge > 0 && <Badge variant="danger" className={cn({ cycle: badge <= 9 })}>{badge}</Badge>}

              </Nav.Link>

            </Nav.Item>
          )
        })}

      </Nav>
    </div >
  )
}

TabButtons.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      eventKey: PropTypes.string,
      text: PropTypes.string,
      badge: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      icon: PropTypes.node,
    })
  ).isRequired,
  defaultActiveKey: PropTypes.string,
  controlledActiveKey: PropTypes.string,
  onItemSelect: PropTypes.func,
  vertical: PropTypes.bool,
  type: PropTypes.oneOf([
    'flat',
    'material',
  ]),
  onBack: PropTypes.func,
}

TabButtons.defaultProps = {
  className: null,
  defaultActiveKey: null,
  controlledActiveKey: undefined,
  onItemSelect: null,
  type: 'flat',
  vertical: false,
  onBack: () => { }
}

export default TabButtons
