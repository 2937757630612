import BaseApi from "./baseApi"
import { MAPBOX_CONFIG } from "../constants/common"

class MapboxApi extends BaseApi {
    constructor() {
        super();
        this.BaseUrl = process.env.REACT_APP_MAPBOX_API_ROOT;
        this.limit = process.env.REACT_APP_MAPBOX_GEOCODING_LIMIT;
        this.types = "country,region,postcode,district,place,locality,neighborhood,address";
    }

    appendAccessToken(url) {
        if (url.indexOf('?') > -1) {
            return `${url}&access_token=${MAPBOX_CONFIG.REACT_APP_MAPBOX_TOKEN}`;
        }
        return `${url}?access_token=${MAPBOX_CONFIG.REACT_APP_MAPBOX_TOKEN}`;
    }

    forwardGeocoding(searchText) {
        const endPoint = this.appendAccessToken(this.Urls.mapbox.forwardGeocoding.replace('{search_text}', searchText).concat(`?country=US,CA&limit=${this.limit}&types=${this.types}`))
        return super.executeWithBaseUrl(this.BaseUrl, this.Methods.GET, endPoint, null, false).then(res => {
            return res
        })
    }
}

export default new MapboxApi();