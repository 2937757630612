import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { Scrollbars } from 'react-custom-scrollbars'
import cn from 'classnames'

import { Button, Modal, Heading, Text } from '..'
import { common } from '../../../constants'

import './style.scss'


const useCustomTranslate = () => {
  const [t] = useTranslation()
  return {
    uploadModalTitle: t('upload_modal.title'),
    uploadModalText: t('upload_modal.text'),
    uploadModalTextOr: t('upload_modal.text_or'),
    uploadModalSelectButton: t('upload_modal.select_button'),
    uploadModalFilesList: t('upload_modal.files_list'),
    uploadModalUploadBtn: t('upload_modal.upload_button'),
    uploadModalUploadingBtn: t('upload_modal.uploading_button'),
    uploadModalCancelBtn: t('upload_modal.cancel_button'),
  }
}

const FileUploadModal = forwardRef((props, ref) => {
  const { size, uploadHandler, accept, loading, onDropRejected, headerActions } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  const translator = useCustomTranslate()


  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setSelectedFiles([])
    setIsModalOpen(false)
  }

  /* istanbul ignore next */
  useImperativeHandle(ref, () => ({
    open: handleOpenModal,
    close: handleCloseModal,
  }))

  /* istanbul ignore next */
  const handleOnDropRejected = files => {
    typeof onDropRejected === 'function' && onDropRejected(files)
  }

  /* istanbul ignore next */
  const handleOnDropAccepted = files => {
    setSelectedFiles(files)
  }

  const {
    getRootProps,
    open,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    disabled: loading,
    onDropRejected: handleOnDropRejected,
    onDropAccepted: handleOnDropAccepted,
  })

  const uploadFiles = () => {
    if (typeof uploadHandler === 'function' && selectedFiles.length) {
      uploadHandler(selectedFiles)
    }
  }

  const hasSelectedFiles = selectedFiles.length > 0
  const dropZoneClass = cn('dropzone', {
    'drag-active': isDragActive,
    'drag-accept': isDragAccept,
    'drag-reject': isDragReject,
  })
  return (
    <Modal
      className="file-upload-modal"
      show={isModalOpen}
      size={size}
      centered
      onHide={handleCloseModal}
    >
      <Heading as="h3">{translator.uploadModalTitle}</Heading>
      {headerActions && (
        <div className="download-template-actions">
          {headerActions()}
        </div>
      )}
      <div
        {...getRootProps({ className: dropZoneClass })}
        test-id="files-upload-dropzone"
      >
        <input {...getInputProps()} />
        <FaCloudUploadAlt className="upload-icon" />
        <Text className="message">{translator.uploadModalText}</Text>
        <Text className="message">{translator.uploadModalTextOr}</Text>
        <Button
          text={translator.uploadModalSelectButton}
          onClick={open}
          className="upload-button"
          variant={common.BOOTSTRAP_VARIANTS.INFO}
          disabled={loading}
        />
      </div>
      {hasSelectedFiles && (
        <>
          <Heading as="h3">{translator.uploadModalFilesList}</Heading>
          <div className="scrollable-files-list">
            <Scrollbars>
              <ul>
                {selectedFiles.map((file) => (
                  <li key={file.path} className="file-list-item">
                    {file.path} - {file.size} bytes
                  </li>
                ))}
              </ul>
            </Scrollbars>
          </div>
          <div className="buttons-wrapper">
            <Button
              text={translator.uploadModalCancelBtn}
              onClick={handleCloseModal}
              variant={common.BOOTSTRAP_VARIANTS.DARK}
              disabled={loading}
            />
            <Button
              text={translator.uploadModalUploadBtn}
              loadingText={translator.uploadModalUploadingBtn}
              onClick={uploadFiles}
              variant={common.BOOTSTRAP_VARIANTS.INFO}
              loading={loading}
            />
          </div>
        </>
      )}
    </Modal>
  )
})

FileUploadModal.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  uploadHandler: PropTypes.func,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  loading: PropTypes.bool,
  onDropRejected: PropTypes.func,
  headerActions: PropTypes.func
}

FileUploadModal.defaultProps = {
  size: 'sm',
  uploadHandler: null,
  accept: undefined,
  loading: false,
  onDropRejected: null,
  headerActions: null
}

FileUploadModal.displayName = 'FileUploadModal'

export default FileUploadModal
