import get from "lodash/get";

export default class GlobalSeachOption {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data)
    }
  }

  init(data) {
    if (!data) return;
    this.label = get(data, 'label', null)
    this.subLabel = get(data, 'type', null)
    this.value = get(data, 'value', null)
    this.isSearch = get(data, 'isSearch', true)
    this.counties = get(data, 'counties', [])
    this.data = get(data, 'data', {})
    this.state = get(data, 'state', '')
    this.type = get(data, 'type', null)
    this.name = get(data, 'name', null)
  }

  toJSON() {
    const { label, subLabel, value, isSearch, counties, state, data, name, type } = this;
    return { label, subLabel, value, isSearch, counties, state, data, name, type };
  }
}
