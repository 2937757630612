/* istanbul ignore file */
import React from "react";
import * as Sentry from '@sentry/react'
import ReactDOM from "react-dom/client";
import "normalize.css";
import "./styles/styles.scss";

import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./redux/store/configureStore";

import { Auth0Provider } from "./components/Auth0";
import { redirect } from 'react-router-dom';
const store = configureStore();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  redirect(appState && appState.targetUrl
    ? appState.targetUrl
    : window.location.pathname)
};

const { REACT_APP_SENTRY_ENVIRONMENT: sentryEnvironment, REACT_APP_PACKAGE_VERSION, REACT_APP_PACKAGE_NAME } = process.env

const sentryReleaseVersion = `${REACT_APP_PACKAGE_NAME}@${REACT_APP_PACKAGE_VERSION}`

if (sentryReleaseVersion && sentryEnvironment && sentryEnvironment !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: sentryEnvironment,
    release: sentryReleaseVersion,
  })
}

// ReactDOM.render(
//   <Auth0Provider
//     domain={process.env.REACT_APP_AUTH0_DOMAIN}
//     client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
//     redirect_uri={window.location.origin}
//     onRedirectCallback={onRedirectCallback}
//     audience={process.env.REACT_APP_AUTH0_AUDIENCE}
//   >
//     <Root store={store} history={history} />
//   </Auth0Provider>,
//   document.getElementById("root")
// );

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }}
    onRedirectCallback={onRedirectCallback}

  >
    <Root store={store} history={history} />
  </Auth0Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
