import get from "lodash/get";

export default class GeoContext {
  constructor(data, initModel = true) {
    if (initModel) {
      this.init(data)
    }
  }

  init(data) {
    if (!data) return;
    const context = get(data, 'context', []);
    
    this.name = get(data, 'text', '').replace('County', '').trim();
    context.forEach(item => {
      if (item.id.indexOf('place') > -1) {
        this.name = get(item, 'text', '').trim();
      }
      if (item.id.indexOf('district') > -1) {
        this.name = get(item, 'text', '').replace('County', '').trim();
      }
      if (item.id.indexOf('region') > -1) {
        this.state = get(item, 'text', '').trim();
      }
    });
  }

  toJSON() {
    const { name, state } = this;
    return { name, state };
  }
}
