/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import { Button, TopLabelFormGroup } from '../../common'
import { common } from '../../../constants'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  hidePostalCode: true,
  style: {
    base: {
      color: 'black',
      fontWeight: 500,
      fontFamily: 'Muli',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#707070',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="form-group">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({ processing, children, disabled }) => {
  const [t] = useTranslation()

  return (
    <div className="modalPremium__submit">
      <Button
        variant={common.BOOTSTRAP_VARIANTS.LIGHT_BLUE}
        className={processing || disabled ? "modalPremium__submit__button" : "modalPremium__submit__button second"}
        type="submit"
        text={processing ? t('cognitive_baseline.stripe.button_processing') : children}
        disabled={processing || disabled}
      />
    </div>
  )
}

const CheckoutForm = (props) => {
  const {
    createPayment,
    clientSecret,
    showErrorNotification,
    onPaymentSuccess,
    setClienSecret
  } = props
  const [t] = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)


  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address: '',
    city: '',
    state: ''
  });

  const confirmCardPayment = () => {
    if (!billingDetails.name || billingDetails.name === '') {

      setProcessing(false)
      setError({
        ...error,
        name: true
      });
    } else {
      stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              city: billingDetails.city,
              state: billingDetails.state,
              line1: billingDetails.address
            },
            name: billingDetails.name
          }
        }
      }).then(result => {

        if (result.error) {
          setProcessing(false)
          showErrorNotification(result.error.message)
          setClienSecret(null)
        } else {
          onPaymentSuccess(() => { setProcessing(false) })
        }
      }).catch(err => {
        setClienSecret(null)
        setProcessing(false)
        // eslint-disable-next-line no-console
        console.log('err', err)
        showErrorNotification(err.toString())
      })
    }
    /* istanbul ignore next */

  }

  useEffect(() => {
    if (clientSecret) {
      confirmCardPayment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret])

  function handleSubmit(event) {
    event.preventDefault()

    /* istanbul ignore next */
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    /* istanbul ignore next */
    if (error) {
      elements.getElement('card').focus()
      return;
    }

    /* istanbul ignore next */
    if (cardComplete) {
      setProcessing(true)
    }

    if (!clientSecret) {
      createPayment()
    } else {
      confirmCardPayment()
    }
  }


  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset>
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      <fieldset>
        <Form.Group as={Row}>
          <Col sm={12}>
            <TopLabelFormGroup
              required
              size="sm"
              value={billingDetails.name}
              autoComplete="cc-name"
              isTextArea={false}
              placeholder={t('cognitive_baseline.stripe.name_on_card')}
              onChange={(_, value) => {
                setBillingDetails({ ...billingDetails, name: value });
                if (error) {
                  setError({
                    ...error,
                    name: false
                  })
                }
              }}
              status={error && error.name ? common.BOOTSTRAP_VARIANTS.DANGER : null}
              message={error && error.name ? "This field not allow empty" : ""}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={12}>
            <TopLabelFormGroup

              size="sm"
              value={billingDetails.address}
              autoComplete="address"
              placeholder={t('cognitive_baseline.stripe.address')}
              isTextArea={false}
              onChange={(_, value) => {
                setBillingDetails({ ...billingDetails, address: value });
              }}

            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={6} className="city-control">
            <TopLabelFormGroup

              size="sm"
              value={billingDetails.city}
              autoComplete="address-level2"
              placeholder={t('cognitive_baseline.stripe.city')}
              isTextArea={false}
              onChange={(_, value) => {
                setBillingDetails({ ...billingDetails, city: value });
              }}

            />
          </Col>
          <Col sm={6}>
            <TopLabelFormGroup
              required
              size="sm"
              value={billingDetails.state}
              autoComplete="address-level1"
              placeholder={t('cognitive_baseline.stripe.state')}
              isTextArea={false}
              onChange={(_, value) => {
                setBillingDetails({ ...billingDetails, state: value });
              }}

            />

          </Col>
        </Form.Group>
      </fieldset>

      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        {t('cognitive_baseline.stripe.purchase_button')}
      </SubmitButton>
    </form>
  )
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripeForm = props => {
  return (
    <div>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm {...props} />
      </Elements>
    </div>
  );
};

export default StripeForm;
