import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { IconLeftTableChervon } from "../../../assests/icons"
import { Text } from ".."

const LEFT_SPACE = 20

export const DropDownItem = ({ item, textLabel, level, isParentShow, setShow, renderChildren, onChange, key }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHidden, setIsHidden] = useState(level > 0)
  const hasChild = item.children && item.children.length
  useEffect(() => {
    setIsHidden(!isParentShow)
  }, [isParentShow])

  return (
    <React.Fragment key={key}>
      <Dropdown.Item className="dropdown-item-mutipule" >
        <div className="dropdown-item-mutipule__content" style={{ marginLeft: level ? `${level * LEFT_SPACE}px` : "0px" }}>
          {
            hasChild ? (
              <IconLeftTableChervon onClick={() => { setIsOpen(!isOpen) }} className={`dropdown-item-mutipule__icon ${isOpen ? "dropdown-item-mutipule__icon-open" : ""}`} />
            ) : <div className="dropdown-item-mutipule__icon__second" />
          }
          <Text className="dropdown-item-mutipule__content__text" onClick={() => { onChange(item); setShow(false) }} as="div" lines={1} popover>
            {item[textLabel]}
          </Text>
        </div>
      </Dropdown.Item>
      {
        !isHidden && isOpen && item.children && item.children.length ? (
          renderChildren(item.children, level, isOpen)
        ) : null
      }
    </React.Fragment>
  )
}

DropDownItem.propTypes = {
  textLabel: PropTypes.string,
  item: PropTypes.object,
  level: PropTypes.number,
  isParentShow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.any,
  ]),
  setShow: PropTypes.func,
  renderChildren: PropTypes.func,
  onChange: PropTypes.func,
  key: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.symbol,
  ]),
}

DropDownItem.defaultProps = {
  textLabel: "label",
  item: {
    label: ''
  },
  level: 0,
  isParentShow: false,
  setShow: () => { },
  renderChildren: () => { },
  onChange: () => { },
  key: undefined,
}

export default DropDownItem
