import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import BaseService from "./baseService";
import mapboxApi from "../api/mapboxApi";
import countyApi from "../api/countyApi";
import { MapboxFeature, GeoContext, County } from "../models";

class MapboxService extends BaseService {
  forwardGeocoding(searchText) {
    return mapboxApi.forwardGeocoding(searchText).then((response) => {
      const features = get(response, "data.features", []).map(item => new MapboxFeature(item).toJSON());
      let params = features.map(item => {
        const temp = new GeoContext(item).toJSON()
        return {
          ...temp,
          name: temp.name.replace(' Parish', '')
        }
      })
      params = uniqBy(params, p => {
        return `${p.name}_${p.state}`;
      });
      return countyApi.getCountiesByGeoContext(params).then(resp => {
        const counties = get(resp, "data.entities", []).map(item => new County(item).toJSON());
        let data = features.map(item => {
          const geoContext = new GeoContext(item);
          geoContext.name = geoContext.name.replace(' Parish', '')

          const county = counties.find(c => c.name && c.state && geoContext.name && geoContext.state && c.name.toLowerCase().indexOf(geoContext.name.toLowerCase()) > -1 && c.state.toLowerCase().indexOf(geoContext.state.toLowerCase()) > -1);
          if (county) {
            const temp = { ...county };
            temp.label = item.placeName.replace(', United States', '');
            temp.featureId = `${item.id}_${temp.id}`;
            return temp;
          }
          return null;
        });
        data = data.filter(item => item != null);
        return {
          data,
          message: response.message,
          success: response.success,
        };
      }, this.handleError);
    }, this.handleError);
  }
}

export default new MapboxService();
