import React from "react";
import PropTypes from "prop-types";
// import { ConnectedRouter } from 'connected-react-router/immutable';
import { Provider as ReduxProvider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import { useAuth0 } from "./components/Auth0";

import App from "./views/App";
import { Loading } from "./components/common";
import { appSelectors } from './redux/selectors/appSelectors'

const Root = ({ store, history }) => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <ReduxProvider store={store}>
      {/* <ConnectedRouter history={history}> */}
        <App />
      {/* </ConnectedRouter> */}
      <ReduxToastr
        getState={appSelectors.selectToastr} // This is the default
        newestOnTop={false}
        position="bottom-right"
        transitionIn="bounceIn"
        transitionOut="fadeOut"
      />
    </ReduxProvider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
